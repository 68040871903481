import React from 'react';
import styled from 'styled-components/macro';

const ContentTitleWrapper = styled.div`
  position: relative;
  display: flex;
`;

const Title = styled.p`
  font-family: Montserrat;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: ${props => (props.align ? props.align : 'cener')};
  font-size: 1rem;
  padding: 0.5rem 1rem;
  color: ${props => (props.textColor ? props.textColor : '#000')};
`;
const ContentTitle = ({ textColor, item }) => {
  return (
    <ContentTitleWrapper>
      <Title textColor={textColor} align={item.align}>
        {item.text}
      </Title>
    </ContentTitleWrapper>
  );
};

export default ContentTitle;
