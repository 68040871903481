import React from 'react';
import styled from 'styled-components/macro';
import { highlightYellow, highlightBlue } from 'styles/colors';

const Headline = styled.p`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.75;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  span.outputSpan {
    background: ${highlightYellow};
    color: #000000;
  }
  span.insertSpan {
    background: ${highlightBlue};
    color: #000000;
  }
`;
const FbHeadline = ({ html = false, text }) => {
  if (html) {
    return (
      <Headline
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    );
  }
  return <Headline>{text}</Headline>;
};

export default FbHeadline;
