import React from 'react';
import styled from 'styled-components/macro';
// import Avatar from '@material-ui/core/Avatar';

import { ReactComponent as ThumbsUp } from 'assets/icons/thumbsUp.svg';
import { ReactComponent as ShareOutline } from 'assets/icons/shareOutline.svg';
import { ReactComponent as ThumbsUpOutline } from 'assets/icons/thumbsUpOutline.svg';
import { ReactComponent as CommentOutline } from 'assets/icons/commentOutline.svg';
// const BlueLikeCircle = styled(Avatar)`
//   background-color: #5e91ff;
// `;

const ThumbsUpIcon = styled(ThumbsUp)`
  width: 12px;
  height: 12px;
`;
const BlueLikeCircle = styled.div`
  width: 18px;
  height: 18px;
  display: flex;
  overflow: hidden;
  position: relative;
  /* font-size: 1.25rem; */
  align-items: center;
  flex-shrink: 0;
  user-select: none;
  border-radius: 50%;
  justify-content: center;
  background-color: #5e91ff;
`;

const FootWrapper = styled.div`
  border-radius: 0 0 3px 3px;
  color: #1c1e21;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  width: 100%;
  background-color: #fff;
`;

const FootStats = styled.div`
  align-items: center;
  border-bottom: 1px solid #dadde1;
  color: #606770;
  display: flex;
  line-height: 20px;
  margin: 10px 12px 0 12px;
  padding: 0 0 10px 0;
  height: 32px;
`;
const FootShare = styled.div`
  display: flex;
  height: 32px;
  padding: 4px 0;
  flex-direction: row;
  flex-grow: 1;
  order: 1;
  margin-bottom: 12px;
`;
const FootStatsLeft = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: #606770;
  display: flex;
`;

const FootStatsRight = styled.div`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  color: #606770;
  display: flex;
`;
const FootsStatsRightText = styled.span`
  color: #606770;
  display: flex;
  line-height: 20px;
  font-size: 13px;
`;
const FootStatsLikeIcon = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: -2px;
  margin-right: 2px;
  margin-top: -2px;
`;

const FootStatsLikeTotal = styled.span`
  color: inherit;
  display: block;
  line-height: 16px;
  max-height: 16px;
  overflow: hidden;
`;

const FootShareBox = styled.div`
  align-items: center;
  color: #606770;
  display: flex;
  flex: 1 0;
  font-weight: 600;
  height: 32px;
  justify-content: center;
  line-height: 14px;
  padding: 0 2px;
  position: relative;
  text-decoration: none;
  transition: 400ms cubic-bezier(0.08, 0.52, 0.52, 1) transform;
  > svg {
    fill: #606770;
    margin-right: 0.5rem;
  }
`;

const GroupCardFooter = () => {
  return (
    <FootWrapper>
      <FootStats>
        <FootStatsLeft>
          <FootStatsLikeIcon>
            <BlueLikeCircle>
              <ThumbsUpIcon />
            </BlueLikeCircle>
          </FootStatsLikeIcon>
          <FootStatsLikeTotal>165</FootStatsLikeTotal>
        </FootStatsLeft>

        <FootStatsRight>
          <FootsStatsRightText>60 Comments 24 Shares</FootsStatsRightText>
        </FootStatsRight>
      </FootStats>
      <FootShare>
        <FootShareBox>
          <ThumbsUpOutline />
          Like
        </FootShareBox>
        <FootShareBox>
          <CommentOutline />
          Comment
        </FootShareBox>
        <FootShareBox>
          <ShareOutline />
          Share
        </FootShareBox>
      </FootShare>
    </FootWrapper>
  );
};
export default GroupCardFooter;
