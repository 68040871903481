import React from 'react';
import styled from 'styled-components/macro';
// import * as _ from 'lodash';
// import ResultFieldChange from 'components/ResultFieldChange';
import ResultContentBuilder from 'components/ResultContentBuilder';
// import UserTempField from 'components/UserTempField';
// import _ from 'lodash';
import { colors } from 'styles/default';

const BlockText = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.common.black};
  margin: 0.5rem 0;
`;
const ParaText = styled.span`
  color: #000000;
`;
const UserParaText = styled.span`
  /* background: #cfe2f3; */
  color: ${colors.text.primary};
  background-color: ${colors.template.highlightBlue};
`;
// const InsertSpan = styled.span`
//   background: #cfe2f3;
//   color: #000000;
// `;

const InsertSpan = styled.span`
  background-color: ${colors.template.highlightBlue};
  color: ${colors.text.primary};
`;
const EditBase = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  /* margin-top: 10px; */
`;

const EditArea = ({
  editBlocks,
  tempValues,
  handleTempValues,
  outputs,
  changeOutputValue,
}) => {
  return (
    <EditBase>
      {editBlocks.map((blocks, index) => {
        return (
          <BlockText key={index}>
            {blocks['block'].map(item => {
              if (item.component === 'ParaText') {
                return <ParaText key={item.order}>{item.text}</ParaText>;
              }

              if (item.component === 'InsertSpan') {
                return <InsertSpan key={item.order}>{item.text}</InsertSpan>;
              }
              if (item.component === 'UserTempField') {
                return (
                  <UserParaText key={item.order}>{item.text}</UserParaText>
                );
              }

              return (
                <ResultContentBuilder
                  key={item.outputField}
                  fieldId={item.outputField}
                  fieldVariant={item.variant ? item.variant : 'normal'}
                  fieldType={item.type ? item.type : 'content'}
                  fieldValue={
                    outputs[item.outputField]
                      ? outputs[item.outputField]
                      : item.outputField
                  }
                  handleValueChange={changeOutputValue}
                />
              );
            })}
          </BlockText>
        );
      })}
    </EditBase>
  );
};

export default EditArea;
