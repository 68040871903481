import React, { useRef, useEffect, useState } from 'react';
import { gsap, CSSPlugin } from 'gsap/all';
// import TweenMax, { TimelineMax, Sine, Back } from 'gsap';
// import * as _ from 'lodash';
gsap.registerPlugin(CSSPlugin);
const LogoEaGSAP = props => {
  let tri01 = useRef(null);
  let tri02 = useRef(null);
  let tri03 = useRef(null);
  let tri04 = useRef(null);
  let tri05 = useRef(null);
  let tri06 = useRef(null);
  let tri07 = useRef(null);
  let tri08 = useRef(null);
  let tri09 = useRef(null);
  let tri10 = useRef(null);
  let tri11 = useRef(null);
  let tri12 = useRef(null);
  let tri13 = useRef(null);
  let tri14 = useRef(null);
  let tri15 = useRef(null);
  let tri16 = useRef(null);

  const [tl] = useState(gsap.timeline({ repeat: -1, yoyo: true }));

  useEffect(() => {
    const triArray = [
      tri01,
      tri02,
      tri03,
      tri04,
      tri05,
      tri06,
      tri07,
      tri08,
      tri09,
      tri10,
      tri11,
      tri12,
      tri13,
      tri14,
      tri15,
      tri16,
    ];
    // const reverseArray = _.reverse(triArray);
    // tl.set(
    //   [
    //     tri01,
    //     tri02,
    //     tri03,
    //     tri04,
    //     tri05,
    //     tri06,
    //     tri07,
    //     tri08,
    //     tri09,
    //     tri10,
    //     tri11,
    //     tri12,
    //     tri13,
    //     tri14,
    //     tri15,
    //     tri16,
    //   ],
    //   { transformOrigin: 'center center' }
    // );
    tl.set(triArray, { transformOrigin: 'center center' });
    tl.from(triArray, { opacity: 0, y: 900, duration: 2, stagger: 0.5 });
    //  tl.from(
    //    [
    //      tri01,
    //      tri02,
    //      tri03,
    //      tri04,
    //      tri05,
    //      tri06,
    //      tri07,
    //      tri08,
    //      tri09,
    //      tri10,
    //      tri11,
    //      tri12,
    //      tri13,
    //      tri14,
    //      tri15,
    //      tri16,
    //    ],
    //    { opacity: 0, y: -900, duration: 2, stagger: 0.5 }
    //  );
    // tl.from(tri01, { opacity: 0, y: -900, duration: 2 });
    // tl.from(tri02, { opacity: 0, y: -900, duration: 2 });
    // tl.from(tri03, { opacity: 0, y: -900, duration: 2 });
    tl.play();
  }, [tl]);

  return (
    <svg viewBox="0 0 237.48 309.2" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1" fillRule="evenodd">
          <path
            className="tri09"
            ref={el => (tri09 = el)}
            fill="#f7a31c"
            d="M164.09 138.2l-23.92 23.92-23.92 23.92-8.75-32.67-8.75-32.68 32.67 8.76 32.67 8.75z"
          />
          <path
            className="tri08"
            ref={el => (tri08 = el)}
            fill="#ea3e25"
            d="M98.75 120.69l32.67 8.76 32.67 8.75-8.75-32.67-8.76-32.67-23.92 23.92-23.91 23.91z"
          />
          <path
            className="tri07"
            ref={el => (tri07 = el)}
            fill="#ed1c24"
            d="M211.92 90.37L188 114.29l-23.91 23.91-8.76-32.67-8.75-32.67 32.67 8.75 32.67 8.76z"
          />
          <path
            className="tri14"
            ref={el => (tri14 = el)}
            fill="#f7a31c"
            d="M189.65 235.5l-23.92 23.92-23.92 23.91-8.75-32.67-8.76-32.67 32.67 8.76 32.68 8.75z"
          />
          <path
            className="tri13"
            ref={el => (tri13 = el)}
            fill="#ea3e25"
            d="M124.3 217.99l32.67 8.76 32.68 8.75-8.76-32.67-8.75-32.67-23.92 23.92-23.92 23.91z"
          />
          <path
            className="tri12"
            ref={el => (tri12 = el)}
            fill="#ed1c24"
            d="M237.48 187.67l-23.92 23.91-23.91 23.92-8.76-32.67-8.75-32.67 32.67 8.75 32.67 8.76z"
          />
          <path
            className="tri01"
            ref={el => (tri01 = el)}
            fill="#1e459d"
            d="M65.34 113.18L56.59 80.5l-8.76-32.67 32.67 8.76 32.68 8.75-23.92 23.92-23.92 23.92z"
          />
          <path
            className="tri02"
            ref={el => (tri02 = el)}
            fill="#23368e"
            d="M95.67 0L71.75 23.92 47.83 47.83l32.67 8.76 32.68 8.75-8.76-32.67L95.67 0z"
          />
          <path
            className="tri03"
            ref={el => (tri03 = el)}
            fill="#1b2368"
            d="M113.18 65.34l-8.76-32.67L95.67 0l32.67 8.75 32.67 8.76-23.92 23.91-23.91 23.92z"
          />
          <path
            className="tri04"
            ref={el => (tri04 = el)}
            fill="#2d4ea2"
            d="M47.83 47.83L23.92 71.75 0 95.67l32.67 8.75 32.67 8.75-8.75-32.67-8.76-32.67z"
          />
          <path
            className="tri05"
            ref={el => (tri05 = el)}
            fill="#2b54a5"
            d="M17.51 161.01l-8.76-32.67L0 95.67l32.67 8.75 32.67 8.76-23.92 23.91-23.91 23.92z"
          />
          <path
            className="tri06"
            ref={el => (tri06 = el)}
            fill="#355caa"
            d="M65.34 113.18l-23.92 23.91-23.91 23.92 32.67 8.75 32.67 8.76-8.75-32.67-8.76-32.67z"
          />
          <path
            className="tri10"
            ref={el => (tri10 = el)}
            fill="#3b6fb7"
            d="M35.02 226.35l-8.76-32.67-8.75-32.67 32.67 8.75 32.67 8.76-23.92 23.91-23.91 23.92z"
          />
          <path
            className="tri11"
            ref={el => (tri11 = el)}
            fill="#4994d0"
            d="M82.85 178.52l-23.92 23.91-23.91 23.92 32.67 8.75 32.67 8.76-8.75-32.67-8.76-32.67z"
          />
          <path
            className="tri15"
            ref={el => (tri15 = el)}
            fill="#44c2f1"
            d="M52.52 291.69l-8.75-32.67-8.75-32.67 32.67 8.75 32.67 8.76-23.92 23.92-23.92 23.91z"
          />
          <path
            className="tri16"
            ref={el => (tri16 = el)}
            fill="#76cfe5"
            d="M100.36 243.86l-23.92 23.92-23.91 23.91 32.67 8.76 32.67 8.75-8.76-32.67-8.75-32.67z"
          />
        </g>
      </g>
    </svg>
  );
};

export default LogoEaGSAP;
