import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'styles/default';
import { mediaMinDevice } from 'styles/responsive';

const BtnWrapper = styled.button`
  /* display: flex;
  justify-content: center; */

  padding: 1rem 0.5rem;

  height: auto;

  cursor: pointer;
  border-radius: 6px;
  border-style: solid;
  border-color: ${colors.btnResult.text};
  color: ${colors.btnResult.text};
  background-color: ${colors.btnResult.fill};
  min-height: 44px;
  width: 100%;
  margin: 1rem 0 0 0;
  ${mediaMinDevice.deviceM} {
    min-height: 88px;
    margin: 1rem;
    width: calc(50% - 2rem);
    justify-content: space-between;
  }
  ${mediaMinDevice.deviceL} {
    margin: 1rem 0;
    width: calc(25% - 1rem);
    justify-content: space-between;
  }

  &:hover {
    background-color: ${colors.btnResult.hover};
  }
  &:disabled {
    cursor: default;
    background-color: ${colors.btnResult.active};
  }

  &:focus {
    outline: none;
  }
`;

// const BtnWrapperActive = styled(BtnWrapper)`
//   background-color: ${colors.btnResult.active};
//   &:focus {
//     outline-color: ${colors.btnMain.text};
//   }
//   &:hover {
//     background-color: ${colors.btnResult.active};
//   }
// `;
const BtnLabel = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.2;
`;

const BtnResults = ({ label, currentType, type, ...props }) => {
  //console.log(`currentType - ${currentType} ==== type - ${type}`);
  return (
    <BtnWrapper disabled={currentType === type} {...props}>
      <BtnLabel>{label}</BtnLabel>
    </BtnWrapper>
  );
};

export default BtnResults;
