import React, { useState, useCallback } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styled from 'styled-components/macro';
import Icon from 'elements/Icon';
import { mdiCheckboxBlankCircleOutline, mdiCheckboxBlankCircle } from '@mdi/js';
import { colors } from 'styles/default';
import CardContent from './CardContent'; // '190px'
import BtnSlideNav from 'elements/BtnSlideNav';

import { mediaMinDevice } from 'styles/responsive';

const CAROUSEL_HEIGHT = 150;
const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const CarouselContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 224px;
  height: ${CAROUSEL_HEIGHT}px;

  ${mediaMinDevice.deviceM} {
    width: 264px;
    height: ${CAROUSEL_HEIGHT}px;
  }
`;

const CarouselContent = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  height: ${CAROUSEL_HEIGHT}px;
  width: 224px;
  background: #ffffff;
`;

const CarouselText = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  display: flex;
  color: ${colors.marketPlace.primaryTextColor};
  font-weight: 500;
  margin: 0;
  > p {
    font-size: 0.9rem;
    line-height: 1.2;
    text-align: center;
    ${mediaMinDevice.deviceM} {
      font-size: 1rem;
    }
  }
`;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
`;
const Dot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
`;

const styles = {
  slide: {
    padding: 0,
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
};

const CardSwiper = ({ text, slides }) => {
  console.log(`slids=> ${JSON.stringify(slides)}`);
  const [totalSlides] = useState(slides.length);
  const [isDisabled] = useState(false);
  const [screenIndex, setScreenIndex] = useState(0);
  const handleChangeIndex = useCallback(index => {
    console.log(`handleChangeIndex===${index}`);
    setScreenIndex(index);
  }, []);
  const changeSlide = (index, e) => {
    console.log(`handleChangeIndex===${index}`);
    setScreenIndex(index);
  };
  const nextSlide = e => {
    const nextIndex = screenIndex + 1;
    // const nextIndex = addIndex > totalSlides ? 0 : addIndex;
    setScreenIndex(nextIndex);
  };
  const prevSlide = e => {
    const prevIndex = screenIndex - 1;
    setScreenIndex(prevIndex);
  };

  return (
    <CarouselWrapper>
      <CarouselText dangerouslySetInnerHTML={{ __html: text }} />
      <CarouselContainer>
        <BtnSlideNav
          type="prev"
          width="20px"
          height={`${CAROUSEL_HEIGHT}px`}
          isDisabled={screenIndex <= 0 ? true : false}
          onClick={prevSlide}
        />

        <SwipeableViews
          index={screenIndex}
          onChangeIndex={handleChangeIndex}
          // onSwitching={handleSwitching}
          enableMouseEvents
          slideStyle={styles.slide}
        >
          {slides.map((item, index) => {
            return <CardContent key={index} slide={item} />;
          })}
        </SwipeableViews>

        <BtnSlideNav
          type="next"
          width="20px"
          height={`${CAROUSEL_HEIGHT}px`}
          isDisabled={screenIndex >= totalSlides - 1 ? true : false}
          onClick={nextSlide}
        />
      </CarouselContainer>
      <Dots>
        {slides.map((_, index) => (
          <Dot key={index}>
            {index === screenIndex ? (
              <Icon
                path={mdiCheckboxBlankCircle}
                size="12px"
                color={isDisabled ? '#000000' : colors.btnMain.fill}
              />
            ) : (
              <Icon
                path={mdiCheckboxBlankCircleOutline}
                size="12px"
                color={isDisabled ? '#000000' : colors.btnMain.fill}
                onClick={e => changeSlide(index, e)}
              />
            )}
          </Dot>
        ))}
      </Dots>
    </CarouselWrapper>
  );
};

export default CardSwiper;
