import React, { useEffect, useState } from 'react';
import styled from 'styled-components/macro';
import { hexToRGBa } from 'utils/colors';

const BtnWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  cursor: pointer;
  border-radius: 4px;
  border-style: none;
  color: ${props => (props.textColor ? props.textColor : '#fff')};
  background-color: ${props => (props.fillColor ? props.fillColor : '#999999')};
  padding: 4px 20px;
  &:hover {
    color: ${props => (props.textColor ? props.textColor : '#fff')};
    background-color: ${props =>
      props.hoverColor ? props.hoverColor : '#999999'};
  }
  &:disabled {
    color: #fff;
    background-color: #999999;
    cursor: not-allowed;
  }

  /* margin-bottom: 25px; */
`;
const BtnLabel = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 12px;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
`;

const BtnSmall = ({ label, textColor, fillColor, ...props }) => {
  const [txtClr, setTxtClr] = useState();
  const [fillClr, setFillClr] = useState();
  const [hoverClr, setHoverClr] = useState();

  useEffect(() => {
    const tcolor = hexToRGBa(textColor, 1);
    const fcolor = hexToRGBa(fillColor, 0.25);
    const hcolor = hexToRGBa(fillColor, 0.6);
    setTxtClr(tcolor);
    setFillClr(fcolor);
    setHoverClr(hcolor);
  }, [textColor, fillColor]);

  //console.log(`textColor=>${textColor} fillColor=> ${fillColor}`);
  // const tcolor = hexToRGBa(textColor, 1);
  // const fcolor = hexToRGBa(fillColor, 0.25);
  // const hcolor = hexToRGBa(fillColor, 0.6);
  return (
    <BtnWrapper
      textColor={txtClr}
      fillColor={fillClr}
      hoverColor={hoverClr}
      {...props}
    >
      <BtnLabel>{label}</BtnLabel>
    </BtnWrapper>
  );
};

// const BtnSmall = ({ label, textColor, fillColor }) => {
//   console.log(`textColor=>${textColor} fillColor=> ${fillColor}`);
//   const tcolor = hexToRGBa(textColor, 1);
//   const fcolor = hexToRGBa(fillColor, 0.25);
//   const hcolor = hexToRGBa(fillColor, 0.6);
//   return (
//     <BtnWrapper textColor={tcolor} fillColor={fcolor} hoverColor={hcolor}>
//       <BtnLabel>{label}</BtnLabel>
//     </BtnWrapper>
//   );
// };

export default BtnSmall;
