/**
 * ACTION TYPES
 */
import {
  ACTIVITY_ID_SET,
  ACTIVITY_SET,
  ACTIVITY_RESET,
} from 'store/actions/types';

export const activityIdSet = actid => dispatch => {
  return new Promise(function(resolve, reject) {
    dispatch({
      type: ACTIVITY_ID_SET,
      actId: actid,
    });
    resolve(actid);
  });
};

export const activitySet = ({ actid, name, type }) => dispatch => {
  return new Promise(function(resolve, reject) {
    dispatch({
      type: ACTIVITY_SET,
      actId: actid,
      actName: name,
      actType: type,
    });
    resolve(actid);
  });
};

// export const setActivityData = (actid, name, type, data) => dispatch => {
//   return new Promise(function(resolve, reject) {
//     dispatch({
//       type: SET_ACTIVITY_DATA,
//       actId: actid,
//       actName: name,
//       actType: type,
//       actData: data,
//     });
//     resolve(actid);
//   });
// };

export const activityReset = () => dispatch => {
  return new Promise(function(resolve, reject) {
    dispatch({
      type: ACTIVITY_RESET,
    });
    resolve();
  });
};
