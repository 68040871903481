import React from 'react';
import styled from 'styled-components/macro';

const Copy = styled.div`
  color: ${props => (props.color ? props.color : '#000')};
  text-align: ${props => (props.textAlign ? props.textAlign : 'left')};
  padding: 0.5rem 1rem;
`;

const ContentCopy = ({ color, content }) => {
  // console.log(`content=> ${JSON.stringify(content)}`);
  const { text, align } = content;
  return (
    <Copy
      textAlign={align}
      color={color}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};

export default ContentCopy;
