import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { Avatar } from '@material-ui/core';
import { colors, fontWeight } from 'styles/default';
import Icon from 'elements/Icon';
import { mdiMenu } from '@mdi/js';

const HamButton = styled.button`
  border: none;
  outline: none;
  margin: 30px 0 0 0;
  padding: 10px;
  background: #ffffff;
`;
const HamButtonClosed = styled.button`
  margin: 30px 0;
  padding: 10px;
  outline: none;
  background: #ffffff;
  border: solid 1px #ccc;
  border-radius: 50%;
  border-color: #ccc;
  box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.24);
`;
const IconHam = styled(Icon)``;

const SidebarArea = styled.div`
  top: 0;
  left: 0;
  position: relative;
  display: flex;
  width: 240px;
`;
const SidebarHamburger = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${({ isDesktop }) => (isDesktop ? '240px' : '130px')};
  /* opacity: ${({ open }) => (open ? '0' : '1')};
  transition: all 0.3s ease-in-out 0.4s; */
  z-index: 80;
    /* min-height: 100vh; */
`;
const SidebarMenu = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${({ isDesktop }) => (isDesktop ? '240px' : '130px')};
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out 0.4s;
  background-color: #ffffff;
  border-radius: 0 42px 0 0;
  box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.24);
  z-index: 81;
  min-height: 100vh;
`;
const SidebarTopArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
// const SidebarTopAreaClosed = styled(SidebarTopArea)`
//   border-radius: 50%;
//   box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.24);
// `;
const UserAvatar = styled(({ isDesktop, ...other }) => <Avatar {...other} />)`
  && {
    margin-top: 50px;
    width: ${props => (props.isDesktop ? '80px' : '65px')};
    height: ${props => (props.isDesktop ? '80px' : '65px')};
    background-color: ${colors.primary.dark};
    color: ${colors.primary.contrastText};
    font-size: ${props => (props.isDesktop ? '36px' : '28px')};
    font-weight: ${fontWeight.light};
    font-family: 'Montserrat';
    transition: transform 300ms ease-in-out;
    &:hover {
      transform: scale(1.2);
    }
  }
`;
const UserWelcome = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const UserWelcomeText = styled.div`
  text-align: center;
  font-size: 24pt;
  line-height: 1;
  color: ${colors.text.primary};
  font-weight: ${fontWeight.bold};
  font-family: 'Montserrat';
`;

const SidebarMenuArea = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

export const SidebarDesktop = ({ authUser, handleAction, children }) => {
  return (
    <SidebarArea>
      <SidebarMenu isDesktop={true} open={true}>
        <SidebarTopArea>
          <UserAvatar isDesktop={true} onClick={handleAction} className="joy4">
            {authUser.initials}
          </UserAvatar>

          <UserWelcome>
            <UserWelcomeText>Hello</UserWelcomeText>
            <UserWelcomeText>{authUser.firstName}!</UserWelcomeText>
          </UserWelcome>
        </SidebarTopArea>
        <SidebarMenuArea>{children}</SidebarMenuArea>
      </SidebarMenu>
    </SidebarArea>
  );
};
export const Sidebar = ({
  authUser,
  handleAction,
  isOpen,
  handleMenuToggle,
  children,
}) => {
  // const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = e => {
    e.preventDefault();
    console.log(`open open ${isOpen}`);
    handleMenuToggle(!isOpen);
  };
  return (
    <SidebarArea>
      <SidebarMenu isDesktop={false} open={isOpen}>
        <SidebarTopArea>
          <HamButton onClick={toggleMenu}>
            <IconHam
              path={mdiMenu}
              size="45px"
              color={`${colors.primary.dark}`}
            />
          </HamButton>

          <UserAvatar isDesktop={false} onClick={handleAction} className="joy4">
            {authUser.initials}
          </UserAvatar>
        </SidebarTopArea>
        <SidebarMenuArea>{children}</SidebarMenuArea>
      </SidebarMenu>
      <SidebarHamburger>
        <SidebarTopArea>
          <HamButtonClosed onClick={toggleMenu}>
            <IconHam
              path={mdiMenu}
              size="45px"
              color={`${colors.primary.dark}`}
            />
          </HamButtonClosed>
        </SidebarTopArea>
      </SidebarHamburger>
    </SidebarArea>
  );
};
