import React from 'react';

const TemplateImageBanner = ({ item }) => {
  return (
    <img
      src={require(`assets/ResultArchive/${item.type}/${item.media.filename}`)}
      alt={item.media.caption}
    />
  );
};

export default TemplateImageBanner;
