import {
  PROJECT_SET,
  PROJECT_RESET,
  PROJECT_DATA_SET,
  PROJECT_STATUS_SET,
} from 'store/actions/types';

const INITIAL_STATE = {
  projectId: null,
  projectName: null,
  projectStatus: null,
  resultId: null,
  data: null,
};

const applySetProject = (state, action) => ({
  ...state,
  projectId: action.projectId,
  projectName: action.projectName,
  projectStatus: action.projectStatus,
  resultId: action.resultId,
  data: null,
});
// const applySetProjectOutputs = (state, action) => ({
//   ...state,
//   outputs: action.outputs,
// });
// const applySetProjectClipboard = (state, action) => ({
//   ...state,
//   clipboard: action.clipboard,
// });
const applySetProjectData = (state, action) => ({
  ...state,
  data: action.data,
});
const applySetProjectStatus = (state, action) => ({
  ...state,
  projectStatus: action.status,
});
// const applySetProjectActivity = (state, action) => ({
//   ...state,
//   activityId: action.activityId,
//   activityName: action.activityName,
// });
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case PROJECT_RESET:
      return INITIAL_STATE;
    case PROJECT_SET: {
      return applySetProject(state, action);
    }
    // case SET_PROJECT_OUTPUTS: {
    //   return applySetProjectOutputs(state, action);
    // }
    // case SET_PROJECT_ACTIVITY: {
    //   return applySetProjectActivity(state, action);
    // }
    // case SET_PROJECT_CLIPBOARD: {
    //   return applySetProjectClipboard(state, action);
    // }
    case PROJECT_DATA_SET: {
      return applySetProjectData(state, action);
    }
    case PROJECT_STATUS_SET: {
      return applySetProjectStatus(state, action);
    }
    default:
      return state;
  }
}
