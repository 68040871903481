import React from 'react';

const SvgBottomLeftSquare = props => (
  <svg viewBox="0 0 250 150" preserveAspectRatio="xMidYMax slice" {...props}>
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        d="M250,150H25A25,25,0,0,1,0,125V0H250Z"
        fill="fill"
        opacity={0.49}
      />
    </g>
  </svg>
);
export default SvgBottomLeftSquare;
