import React from 'react';

const IconHelpTip = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 25" {...props}>
      <g id="Layer_2" data-name="Layer 2">
        <g id="IconToolTipHelp">
          <g id="IconHelpFont">
            <rect
              fill="#fff"
              stroke="#000"
              strokeMiterlimit="10"
              strokeWidth="1.82px"
              x="1"
              y="1"
              width="20"
              height="20"
              rx="4"
            />
            <text
              fontSize="22px"
              fontFamily="Montserrat-Medium, Montserrat"
              fontWeight="500"
              transform="translate(5 19) scale(1.01 1)"
            >
              ?
            </text>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default IconHelpTip;
