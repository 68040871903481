import React from 'react';

function SvgSimpleFormBot2(props) {
  return (
    <svg viewBox="0 0 199.28 193.4" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Layer 2">
            <g data-name="SimpleForm Bot">
              <ellipse
                cx={99.34}
                cy={178.3}
                rx={79.2}
                ry={15.1}
                style={{
                  isolation: 'isolate',
                }}
                fill={props.golda}
                opacity={0.3}
              />
              <path
                fill={props.main}
                d="M14.54 28.2l2.9 15.6 9.6-11.4M165.44 33.8l10.7 11.7 2.2-14.6"
              />
              <path
                data-name="Banner"
                d="M.64 24.3s99.6 37.5 198 0"
                fill="none"
                stroke={props.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.285}
              />
              <path
                d="M55.9 46.55l-4.58-.39-1 2-3.12-.27 5.66-10.39 3 .25L59.76 49l-3.19-.27zm-.71-2.34l-1.1-3.58L52.41 44zM67.63 49.76a5.4 5.4 0 01-1.82-2.31 5.85 5.85 0 01.86-5.77A5.41 5.41 0 0169.08 40a6.43 6.43 0 013.14-.26 6.61 6.61 0 012.58.92 5.23 5.23 0 011.74 1.79l-2.2 1.47a3.27 3.27 0 00-4-1.49 2.72 2.72 0 00-1.22.93 3.71 3.71 0 00-.48 3.21 2.66 2.66 0 00.9 1.24 3.26 3.26 0 001.5.62 3.22 3.22 0 002.72-.89l1.68 2a5.13 5.13 0 01-2.19 1.21 6.51 6.51 0 01-2.73.13 6.42 6.42 0 01-2.89-1.12zM91.42 50.82L91 53.17l-8.61-1.38 1.71-10.73 8.42 1.34-.38 2.35-5.41-.87-.29 1.81 4.77.77-.36 2.27-4.73-.73-.31 2zM108.91 47a2.66 2.66 0 01.56 1.66 2.53 2.53 0 01-1.1 2.23 6 6 0 01-3.31.84l-5.87.12L99 40.94l5.56-.11a5.41 5.41 0 013.18.7 2.41 2.41 0 011.13 2 2.55 2.55 0 01-1.44 2.47 3.13 3.13 0 011.48 1zm-6.85-3.86v2.09h2.11c1 0 1.55-.38 1.53-1.09s-.53-1-1.57-1zm4.3 5.24c0-.74-.56-1.09-1.64-1.07h-2.58v2.21l2.58-.05q1.66-.03 1.64-1.13zM133.84 49.4a5.65 5.65 0 01-1-8.07 5.59 5.59 0 012.44-1.68 7 7 0 016.14.93 5.54 5.54 0 011.83 2.33 5.55 5.55 0 01-3.25 7.42 6.91 6.91 0 01-6.13-.93zm4.87-1.49a2.81 2.81 0 001.21-.93 3.23 3.23 0 00.63-1.55 3.38 3.38 0 00-.14-1.66 2.74 2.74 0 00-.89-1.24 2.81 2.81 0 00-1.41-.61 3 3 0 00-1.54.16 2.81 2.81 0 00-1.21.93 3.44 3.44 0 00-.63 1.54 3.31 3.31 0 00.15 1.67 2.77 2.77 0 00.88 1.24 3.06 3.06 0 003 .45zM117.08 50.6a5.65 5.65 0 01-1-8.07 5.59 5.59 0 012.44-1.68 7 7 0 016.14.93 5.54 5.54 0 011.83 2.33 5.55 5.55 0 01-3.31 7.42 6.91 6.91 0 01-6.13-.93zm4.92-1.49a2.81 2.81 0 001.21-.93 3.27 3.27 0 00.63-1.55 3.38 3.38 0 00-.14-1.66 2.74 2.74 0 00-.89-1.24 2.81 2.81 0 00-1.41-.61 3 3 0 00-1.54.16 2.81 2.81 0 00-1.21.93 3.32 3.32 0 00-.62 1.54 3.2 3.2 0 00.14 1.67 2.77 2.77 0 00.88 1.24 3.06 3.06 0 003 .45zM153.71 44.94l-1.08 1.29.15 2.71-3 .17-.59-10.85 3-.17.25 4.51 4-4.74 3.38-.18-4.17 5 5 5.79-3.56.19zM36.76 37l-.36 2.4 4.75.72-.36 2.35-4.79-.72-.56 3.69-3-.46 1.63-10.76 8.44 1.28-.36 2.35z"
                fill={props.main}
              />
              <g>
                <path
                  d="M77.94 135.1S59.24 124.7 59 104.3l4.5-1.5s1.7 17.2 16.7 27.6"
                  fill={props.greymd}
                />
                <path
                  d="M77.94 135.1S59.24 124.7 59 104.3l4.5-1.5s1.7 17.2 16.7 27.6"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.285}
                />
                <path
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={0.59}
                  d="M73.94 132.2l3.2-4.1M70.54 128.7l3.5-3.5M67.34 125.6l3.9-3.4M61.14 114.1l4.4-2.3M65.04 122l4.1-3M62.64 117.9l4.4-2.7M59.84 109l4.4-1.5"
                />
                <path
                  d="M63.94 94.9c.8 4.4.9 9-2.7 9.6s-5.4-3.4-6.2-7.9l2.7.5c.4 2.2.9 4.2 2.7 3.9s1.5-2 1.1-4.3z"
                  fill={props.greymd}
                />
                <path
                  d="M63.94 94.9c.8 4.4.9 9-2.7 9.6s-5.4-3.4-6.2-7.9l2.7.5c.4 2.2.9 4.2 2.7 3.9s1.5-2 1.1-4.3z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={0.955}
                />
                <path
                  d="M75.84 105.8a4.5 4.5 0 010-9"
                  fill={props.greydk}
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.285}
                />
                <path
                  d="M122 136s18.7-10.4 18.9-30.8l-4.5-1.5s-1.7 17.2-16.7 27.6"
                  fill={props.greymd}
                />
                <path
                  d="M122 136s18.7-10.4 18.9-30.8l-4.5-1.5s-1.7 17.2-16.7 27.6"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.285}
                />
                <path
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={0.59}
                  d="M126.04 133.1l-3.2-4M129.44 129.7l-3.5-3.5M132.64 126.5l-3.8-3.3M138.84 115l-4.4-2.2M134.94 123l-4.1-3.1M137.34 118.9l-4.4-2.7M140.14 110l-4.4-1.5"
                />
                <path
                  d="M136 95.9c-.8 4.4-.9 9 2.7 9.6s5.4-3.4 6.2-7.9l-2.7.5c-.4 2.2-.9 4.2-2.7 3.9s-1.5-2-1.1-4.3z"
                  fill={props.greymd}
                />
                <path
                  d="M136 95.9c-.8 4.4-.9 9 2.7 9.6s5.4-3.4 6.2-7.9l-2.7.5c-.4 2.2-.9 4.2-2.7 3.9s-1.5-2-1.1-4.3z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={0.955}
                />
                <path
                  d="M123.94 96.7a4.5 4.5 0 010 9"
                  fill={props.greydk}
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.285}
                />
                <path
                  d="M122.14 169.2h-43a2.26 2.26 0 01-2.3-2.3v-40.4a2.26 2.26 0 012.3-2.3h43a2.26 2.26 0 012.3 2.3v40.4a2.26 2.26 0 01-2.3 2.3z"
                  fill={props.greymd}
                />
                <circle cx={113.24} cy={71.7} r={4} fill={props.greymd} />
                <path
                  d="M121.44 121h-43.2a2.22 2.22 0 01-2.2-2.2v-37a2.22 2.22 0 012.2-2.2h43.2a2.22 2.22 0 012.2 2.2v37.1a2.14 2.14 0 01-2.2 2.1z"
                  fill={props.greymd}
                />
                <path
                  d="M100.64 81.6s.4-7.1 7.3-8.6"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.285}
                />
                <circle
                  cx={112.84}
                  cy={71.7}
                  r={4}
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.285}
                />
                <path
                  d="M121.44 121h-43.2a2.22 2.22 0 01-2.2-2.2v-37a2.22 2.22 0 012.2-2.2h43.2a2.22 2.22 0 012.2 2.2v37.1a2.14 2.14 0 01-2.2 2.1z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.285}
                />
                <path
                  d="M121.34 169.2h-43a2.26 2.26 0 01-2.3-2.3v-40.4a2.26 2.26 0 012.3-2.3h43a2.26 2.26 0 012.3 2.3v40.4a2.2 2.2 0 01-2.3 2.3z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.338}
                />
                <path
                  fill={props.greymd}
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.108}
                  d="M90.44 121.2h20.4v3.1h-20.4z"
                />
                <path
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={0.59}
                  d="M93.34 121.2v2.9M96.44 121.2v2.9M99.34 121.4v2.9M102.24 121.4v2.9M108.14 121.2v2.9M105.24 121.4v2.9"
                />
                <path
                  d="M110.64 145h-21a.79.79 0 01-.8-.8v-9.9a.79.79 0 01.8-.8h21a.79.79 0 01.8.8v9.9a.77.77 0 01-.7.8h-.1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bota}
                  opacity={0.3}
                />
                <path
                  d="M110.64 144.7h-21a.79.79 0 01-.8-.8h0V134a.79.79 0 01.8-.8h21a.79.79 0 01.8.8h0v9.9a.77.77 0 01-.7.8h-.1z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.285}
                />
                <g
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.285}
                >
                  <path d="M89.44 149.7v9M93.94 149.7v9M98.24 149.7v9M102.34 149.7v9M110.84 149.7v9M106.64 149.7v9" />
                </g>
                <path fill={props.greymd} d="M104.14 170.1h14.6v6.6h-14.6z" />
                <path
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.488}
                  d="M104.14 170.1h14.6v6.2h-14.6z"
                />
                <path
                  d="M119.94 179.3c0-3.3-3.7-5.9-8.3-5.9s-8.3 2.7-8.3 5.9z"
                  fill={props.greymd}
                />
                <path
                  d="M119.74 179.3c0-3.3-3.7-5.9-8.3-5.9s-8.3 2.7-8.3 5.9z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.411}
                />
                <path fill={props.greymd} d="M82.34 170.1h14.6v6.6h-14.6z" />
                <path
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.376}
                  d="M82.34 170.1h14.6v6.2h-14.6z"
                />
                <path
                  d="M98.14 179.3c0-3.3-3.7-5.9-8.3-5.9s-8.3 2.7-8.3 5.9z"
                  fill={props.greymd}
                />
                <path
                  d="M98 179.3c0-3.3-3.7-5.9-8.3-5.9s-8.3 2.7-8.3 5.9z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.411}
                />
                <path
                  d="M107.24 99.5a2.29 2.29 0 114.5-.9v.2M87.94 99.5a2.29 2.29 0 014.5-.9v.2"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeMiterlimit={10}
                  strokeWidth={1.407}
                />
                <path
                  d="M105.14 104.8c.1 2-2 3.8-4.7 4s-5-1.4-5.1-3.4"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeMiterlimit={10}
                  strokeWidth={1.692}
                />
                <circle
                  cx={86.14}
                  cy={107.5}
                  r={4.1}
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bota}
                  opacity={0.3}
                />
                <circle
                  cx={114.94}
                  cy={107.5}
                  r={4.1}
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bota}
                  opacity={0.3}
                />
              </g>
              <g>
                <path
                  d="M39.34 78.7h-4.8a.22.22 0 01-.2-.2v-1a.22.22 0 01.2-.2h4.8a.22.22 0 01.2.2v1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.reda}
                  opacity={0.3}
                />
                <path
                  d="M35.54 71.1l1.4-4.6c0-.1.1-.1.2-.1l.9.3c.1 0 .1.1.1.2l-1.4 4.6c0 .1-.1.1-.2.1l-.9-.3c-.1 0-.1-.1-.1-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.golda}
                  opacity={0.3}
                />
                <path
                  d="M41 84.2l3.9-2.9c0-.1.1-.1.2 0l.6.8c.1 0 .1.1 0 .2l-3.9 2.9c0 .1-.1.1-.2 0l-.6-.8c-.06-.1-.06-.2 0-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bluea}
                  opacity={0.3}
                />
                <path
                  d="M50.34 86.1l4.3 2.2c.1 0 .1.1.1.2l-.4.8c0 .1-.1.1-.2.1l-4.3-2.2c-.1 0-.1-.1-.1-.2l.4-.8c0-.1.1-.2.2-.1zM49.64 74.8h-4.8a.22.22 0 01-.2-.2v-1a.22.22 0 01.2-.2h4.8a.22.22 0 01.2.2v1a.22.22 0 01-.2.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.main}
                  opacity={0.3}
                />
                <path
                  d="M48.54 67.2l1.4-4.6c0-.1.1-.1.2-.1l.9.3c.1 0 .1.1.1.2l-1.4 4.6c0 .1-.1.1-.2.1l-.9-.3c-.1 0-.2-.1-.1-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.reda}
                  opacity={0.3}
                />
                <path
                  d="M52.64 79l3.9-2.9c0-.1.1-.1.2 0l.6.8c.1 0 .1.1 0 .2l-3.9 2.9c0 .1-.1.1-.2 0l-.6-.8z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.golda}
                  opacity={0.3}
                />
                <path
                  d="M56.84 64.1l4.3 2.2c.1 0 .1.1.1.2l-.4.8c0 .1-.1.1-.2.1l-4.3-2.2c-.1 0-.1-.1-.1-.2l.4-.8c0-.1.1-.2.2-.1zM61.64 81.5l1.3 4.6c0 .1 0 .2-.1.2l-.9.3c-.1 0-.2 0-.2-.1l-1.3-4.6c0-.1 0-.2.1-.2l.9-.3c.1 0 .2 0 .2.1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bluea}
                  opacity={0.3}
                />
                <path
                  d="M62.74 74.3l2.1-4.4c0-.1.1-.1.2-.1l.9.4c.1 0 .1.1.1.2l-2.1 4.4c0 .1-.1.1-.2.1l-.9-.4c-.1 0-.2-.1-.1-.2zM161.24 80H166a.22.22 0 00.2-.2v-1a.22.22 0 00-.2-.2h-4.8a.22.22 0 00-.2.2v1c.14.1.14.2.24.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.reda}
                  opacity={0.3}
                />
                <path
                  d="M165 72.4l-1.4-4.6c0-.1-.1-.1-.2-.1l-.9.3c-.1 0-.1.1-.1.2l1.4 4.6c0 .1.1.1.2.1l.9-.3c.1 0 .1-.1.1-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.golda}
                  opacity={0.3}
                />
                <path
                  d="M159.54 85.4l-3.9-2.9c0-.1-.1-.1-.2 0l-.6.8c-.1.1-.1.1 0 .2l3.9 2.9c0 .1.1.1.2 0l.6-.8c.1 0 .1-.1 0-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.reda}
                  opacity={0.3}
                />
                <path
                  d="M150.24 87.4l-4.3 2.2c-.1 0-.1.1-.1.2l.4.8c0 .1.1.1.2.1l4.3-2.2c.1 0 .1-.1.1-.2l-.4-.8c0-.2-.2-.2-.2-.1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.golda}
                  opacity={0.3}
                />
                <path
                  d="M150.94 76.1h4.8a.22.22 0 00.2-.2v-1a.22.22 0 00-.2-.2h-4.8a.22.22 0 00-.2.2v1a.22.22 0 00.2.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bluea}
                  opacity={0.3}
                />
                <path
                  d="M152 68.5l-1.4-4.6c0-.1-.1-.1-.2-.1l-.9.3c-.1 0-.1.1-.1.2l1.4 4.6c0 .1.1.1.2.1l.9-.3c.1 0 .24-.1.1-.2zM147.94 80.3L144 77.4c0-.1-.1-.1-.2 0l-.6.8c-.1.1-.1.1 0 .2l3.9 2.9c0 .1.1.1.2 0l.6-.8v-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.main}
                  opacity={0.3}
                />
                <path
                  d="M143.74 65.4l-4.3 2.2c-.1 0-.1.1-.1.2l.4.8c0 .1.1.1.2.1l4.3-2.2c.1 0 .1-.1.1-.2l-.4-.8c0-.2-.1-.2-.2-.1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.reda}
                  opacity={0.3}
                />
                <path
                  d="M138.84 82.8l-1.3 4.6c0 .1 0 .2.1.2l.9.3c.1 0 .2 0 .2-.1l1.3-4.6c0-.1 0-.2-.1-.2l-.9-.3a.31.31 0 00-.2.1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bluea}
                  opacity={0.3}
                />
                <path
                  d="M137.84 75.6l-2.1-4.4c0-.1-.1-.1-.2-.1l-.9.4c-.1 0-.1.1-.1.2l2.1 4.4c0 .1.1.1.2.1l.9-.4c.1 0 .2-.1.1-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.golda}
                  opacity={0.3}
                />
                <path
                  d="M76.84 58H72a.22.22 0 01-.2-.2v-1a.22.22 0 01.2-.2h4.8a.22.22 0 01.2.2v1a.22.22 0 01-.16.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bluea}
                  opacity={0.3}
                />
                <path
                  d="M60.14 58.2l1.4-4.6c0-.1.1-.1.2-.1l.9.3c.1 0 .1.1.1.2l-1.4 4.6c0 .1-.1.1-.2.1l-.9-.3c-.1 0-.1-.1-.1-.2zM73.34 69.9l3.9-2.9c0-.1.1-.1.2 0l.6.8c.1 0 .1.1 0 .2l-3.9 2.9c0 .1-.1.1-.2 0l-.6-.8c-.1 0 0-.1 0-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.golda}
                  opacity={0.3}
                />
                <path
                  d="M85.34 73.1l4.3 2.2c.1 0 .1.1.1.2l-.4.8c0 .1-.1.1-.2.1l-4.3-2.2c-.1 0-.1-.1-.1-.2l.4-.8c0-.1.1-.1.2-.1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bluea}
                  opacity={0.3}
                />
                <path
                  d="M97.54 26.9h-4.8a.22.22 0 01-.2-.2v-1a.22.22 0 01.2-.2h4.8a.22.22 0 01.2.2v1c0 .2-.1.2-.2.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.reda}
                  opacity={0.3}
                />
                <path
                  d="M56.24 18.1l1.4-4.6c0-.1.1-.1.2-.1l.9.3c.1 0 .1.1.1.2l-1.4 4.6c0 .1-.1.1-.2.1l-.9-.3c0-.1 0-.1-.1-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.golda}
                  opacity={0.3}
                />
                <path
                  d="M66.94 31.1l3.9-2.9c0-.1.1-.1.2 0l.6.8c.1 0 .1.1 0 .2l-4 2.9c0 .1-.1.1-.2 0l-.6-.8s0-.2.1-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.main}
                  opacity={0.3}
                />
                <path
                  d="M91.74 60.2L96 62.4c.1 0 .1.1.1.2l-.4.8c0 .1-.1.1-.2.1l-4.3-2.2c-.1 0-.1-.1-.1-.2l.4-.8c.14-.1.24-.2.24-.1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.reda}
                  opacity={0.3}
                />
                <path
                  d="M96.64 68.6l1.3 4.6c0 .1 0 .2-.1.2l-.9.3c-.1 0-.2 0-.2-.1l-1.3-4.6c0-.1 0-.2.1-.2l.9-.3c.1 0 .2 0 .2.1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.golda}
                  opacity={0.3}
                />
                <path
                  d="M84.74 16.1l2.1-4.4c0-.1.1-.1.2-.1l.9.4c.1 0 .1.1.1.2l-2.1 4.4c0 .1-.1.1-.2.1l-.9-.4c-.1 0-.2-.1-.1-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bluea}
                  opacity={0.3}
                />
                <path
                  d="M111.74 55.4h-4.8a.22.22 0 01-.2-.2v-1a.22.22 0 01.2-.2h4.8a.22.22 0 01.2.2v1a.22.22 0 01-.2.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.golda}
                  opacity={0.3}
                />
                <path
                  d="M122.24 16.8l1.4-4.6c0-.1.1-.1.2-.1l.9.3c.1 0 .1.1.1.2l-1.4 4.6c0 .1-.1.1-.2.1l-.9-.3c-.1-.1-.1-.1-.1-.2zM113.54 60.9l3.9-2.9c0-.1.1-.1.2 0l.6.8c.1 0 .1.1 0 .2l-3.9 2.9c0 .1-.1.1-.2 0l-.6-.8c-.1-.1-.1-.2 0-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.main}
                  opacity={0.3}
                />
                <path
                  d="M122.84 62.8l4.3 2.2c.1 0 .1.1.1.2l-.4.8c0 .1-.1.1-.2.1l-4.3-2.2c-.1 0-.1-.1-.1-.2l.4-.9c.1-.1.2-.1.2 0z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.reda}
                  opacity={0.3}
                />
                <path
                  d="M109.14 20.5h-4.8a.22.22 0 01-.2-.2v-1a.22.22 0 01.2-.2h4.8a.22.22 0 01.2.2v1a.22.22 0 01-.2.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.golda}
                  opacity={0.3}
                />
                <path
                  d="M131.34 29.7l1.4-4.6c0-.1.1-.1.2-.1l.9.3c.1 0 .1.1.1.2l-1.4 4.6c0 .1-.1.1-.2.1l-.9-.3c-.1 0-.1-.1-.1-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.reda}
                  opacity={0.3}
                />
                <path
                  d="M140.64 55.7l3.9-2.9c0-.1.1-.1.2 0l.6.8c.1 0 .1.1 0 .2l-3.9 2.9c0 .1-.1.1-.2 0l-.6-.8zM150 18.8l4.3 2.2c.1 0 .1.1.1.2l-.4.8c0 .1-.1.1-.2.1l-4.3-2.2c-.1 0-.1-.1-.1-.2l.4-.8c.04-.1.14-.2.2-.1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.golda}
                  opacity={0.3}
                />
                <path
                  d="M158.74 58.2l1.3 4.6c0 .1 0 .2-.1.2l-.9.3c-.1 0-.2 0-.2-.1l-1.3-4.6c0-.1 0-.2.1-.2l.9-.3c.1 0 .2 0 .2.1zM130 58.8l2.1-4.4c0-.1.1-.1.2-.1l.9.4c.1 0 .1.1.1.2l-2.1 4.4c0 .1-.1.1-.2.1l-.9-.4c-.1 0-.1 0-.1-.2zM27.64 63.2h-4.8a.22.22 0 01-.2-.2v-1a.22.22 0 01.2-.2h4.8a.22.22 0 01.2.2v1a.22.22 0 01-.2.2zM18.74 11.6l1.4-4.6c0-.1.1-.1.2-.1l.9.3c.1 0 .1.1.1.2l-1.4 4.6c0 .1-.1.1-.2.1l-.9-.3c-.1 0-.1-.1-.1-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bluea}
                  opacity={0.3}
                />
                <path
                  d="M29.34 24.6l3.9-2.9c0-.1.1-.1.2 0l.6.8c.1 0 .1.1 0 .2l-3.9 2.9c0 .1-.1.1-.2 0l-.6-.8c-.1 0 0-.1 0-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.reda}
                  opacity={0.3}
                />
                <path
                  d="M14.84 52.3l2.1-4.4c0-.1.1-.1.2-.1l.9.4c.1 0 .1.1.1.2L16 52.8c0 .1-.1.1-.2.1l-.9-.4c-.1 0-.1-.1-.1-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.main}
                  opacity={0.3}
                />
                <path
                  d="M166.24 53l1.4-4.6c0-.1.1-.1.2-.1l.9.3c.1 0 .1.1.1.2l-1.4 4.6c0 .1-.1.1-.2.1l-.9-.3c-.1 0-.1-.1-.1-.2zM39.34 56.7h-4.8a.22.22 0 01-.2-.2v-1a.22.22 0 01.2-.2h4.8a.22.22 0 01.2.2v1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.reda}
                  opacity={0.3}
                />
                <path
                  d="M175.34 65.9l1.4-4.6c0-.1.1-.1.2-.1l.9.3c.1 0 .1.1.1.2l-1.4 4.6c0 .1-.1.1-.2.1l-.9-.3c-.1 0-.1-.1-.1-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.main}
                  opacity={0.3}
                />
                <path
                  d="M178.44 51.1l4.3 2.2c.1 0 .1.1.1.2l-.4.8c0 .1-.1.1-.2.1l-4.3-2.2c-.1 0-.1-.1-.1-.2l.4-.8a.31.31 0 01.2-.1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bluea}
                  opacity={0.3}
                />
                <path
                  d="M160.44 4.7l1.4-4.6c0-.1.1-.1.2-.1l.9.3c.1 0 .1.1.1.2l-1.4 4.6c0 .1-.1.1-.2.1l-.9-.3c-.1 0-.1-.1-.1-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.reda}
                  opacity={0.3}
                />
                <path
                  d="M169.54 17.6l1.4-4.6c0-.1.1-.1.2-.1l.9.3c.1 0 .1.1.1.2l-1.4 4.6c0 .1-.1.1-.2.1l-.9-.3c-.1 0-.2-.1-.1-.2z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bluea}
                  opacity={0.3}
                />
                <path
                  d="M188.24 6.7l4.3 2.2c.1 0 .1.1.1.2l-.4.8c0 .1-.1.1-.2.1l-4.4-2.2c-.1 0-.1-.1-.1-.2l.4-.8c.06-.1.2-.2.3-.1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.reda}
                  opacity={0.3}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgSimpleFormBot2;
