import React from 'react';
import styled from 'styled-components/macro';
import Img from 'react-image';
// import QuizButton from 'elements/QuizButton';
import { Grid } from '@material-ui/core';
import { colors } from 'styles/default';
import TextAreaAutoSize from 'elements/TextAreaAutoSize';
import BtnMain from 'elements/BtnMain';
import { FormHelperText } from '@material-ui/core';
import Loading from 'components/Loading';
import { mediaMinDevice } from 'styles/responsive';
import useCharMaxLimit from 'hooks/useCharMaxLimit';
import CharsMaxHelper from 'components/CharsMaxHelper';
const GridForButtons = styled(Grid)`
  justify-content: center;
  flex-wrap: wrap;
  ${mediaMinDevice.deviceS} {
    justify-content: space-around;
  }
  ${mediaMinDevice.deviceM} {
    justify-content: space-between;
  }
  ${mediaMinDevice.deviceL} {
    justify-content: space-between;
  }
`;

const QuizButtonLabel = styled.div`
  transition: transform 300ms ease-in-out;
  font-family: Montserrat;
  font-weight: 700;
  line-height: 1.2;
  text-transform: uppercase;
  text-align: center;
  font-size: 1.8rem;
  ${mediaMinDevice.deviceS} {
    font-size: 2rem;
  }
  ${mediaMinDevice.deviceM} {
    font-size: 2.2rem;
  }
`;
const QuizButton = styled.button`
  cursor: pointer;
  color: ${props => props.textColor || colors.text.primary};
  background-color: ${props => props.backColor || colors.background.default};
  /* padding: 6px 16px; */
  border-radius: 12px;

  /* min-width: 64px; */
  box-sizing: border-box;
  /* box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); */
  margin: 1rem 0;

  padding: 0;
  width: 310px;
  height: 200px;
  ${mediaMinDevice.deviceS} {
    width: 310px;
    height: 200px;
    margin: 1rem 0.5rem;
  }
  ${mediaMinDevice.deviceM} {
    width: 320px;
    height: 200px;
    margin: 1rem 0;
  }
  ${mediaMinDevice.deviceL} {
    width: 340px;
    height: 213px;
    margin: 1rem 0;
  }
  &:hover ${QuizButtonLabel} {
    transform: scale(1.1);
  }
  &:focus {
    outline: #fff solid 3px;
  }
`;
const ButtonSelectImgArea = styled.div`
  /* display: flex;
  height: 120px; */
  box-sizing: border-box;
  overflow: hidden;
  > img {
    transition: transform 300ms ease-in-out;
    width: 320px;
    height: 200px;
    object-fit: cover;
    overflow: hidden;
    &:hover,
    &:focus {
      transform: scale(1.2);
    }
  }
`;
const QuizButtonImg = styled.button`
  &&&& {
    overflow: hidden;
    padding: 0;
    margin: 1rem 0;
    color: ${props => props.textColor || colors.text.primary};
    background-color: ${props => props.backColor || colors.background.default};
    /* padding: 6px 16px; */
    width: 320px;
    height: 200px;
    /* min-width: 64px; */
    box-sizing: border-box;
    border-radius: 12px;
    border-style: none;
    border-width: 0;
    text-transform: uppercase;
    &:focus {
      outline: none;
    }
  }
`;
const CharMaxHelperArea = styled.div`
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-end;
  position: relative;
`;

const HelperText = styled(FormHelperText)`
  text-align: right;
  font-style: italic;
  color: #cdcdcd;
`;

const QuizQuestionFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* height: 200px;*/
  min-height: 220px;
`;
// const QuizButtonArea = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
// `;

const ResultFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: 220px;
  border-radius: 10px;
  border: solid 5.4px ${colors.primary.dark};
  justify-content: center;
  align-items: center;
  margin-top: 0.8rem;
  padding: 1rem;
  width: 100%;
`;
// const QuestionFieldWrapper = styled.div`
//   /* width: 700px; */
//   display: flex;
//   flex-direction: row;
//   flex-wrap: wrap;
//   justify-content: space-between;
//   align-content: center;
//   margin: 0;
//   width: 100%;
//   max-width: 800px;
// `;
const QuestionResultsFieldWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const ResultsTitle = styled.div`
  width: 100%;
  color: ${colors.primary.dark};
  line-height: 1.2;
  text-align: left;

  font-family: Montserrat;
  font-weight: 700;
  margin: 0;
  margin-bottom: 10px;
  font-size: 2.2rem;
  ${mediaMinDevice.deviceS} {
    font-size: 2.6rem;
  }
`;
const LabelArea = styled.div`
  display: flex;
  width: 100%;
  border-radius: 9.2px;
  border: solid 5.4px ${colors.primary.dark};
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  margin-top: 0.8rem;

  padding: 0.5rem;
  ${mediaMinDevice.deviceS} {
    padding: 1rem;
  }
`;
const Label = styled.div`
  font-family: Montserrat;
  color: ${colors.primary.dark};
  text-align: center;
  font-weight: 700;
  line-height: 1.2;
  font-size: 1.8rem;
  ${mediaMinDevice.deviceS} {
    font-size: 2rem;
  }
  ${mediaMinDevice.deviceM} {
    font-size: 2.3rem;
  }
`;
const LabelResult = styled.div`
  margin-bottom: 1rem;
  & > p {
    color: ${colors.text.primary};
    line-height: 1.2;
    text-align: left;
    font-size: 1rem;
    font-family: Montserrat;
    font-weight: 400;
  }
  & > h3 {
    color: ${colors.primary.dark};
    line-height: 1.2;
    text-align: left;
    font-size: 2.7rem;
    font-family: Montserrat;
    font-weight: 700;
  }
  & > h4 {
    color: ${colors.primary.dark};
    line-height: 1.2;
    text-align: left;
    font-size: 1.9rem;
    font-family: Montserrat;
    font-weight: 700;
    margin: 0;
    margin-bottom: 10px;
  }
  flex: 1 1 100%;
  ${mediaMinDevice.deviceM} {
    flex: 1 1 50%;
    padding-right: 1.5rem;
  }
`;

const ResultImgArea = styled.div`
  display: flex;
  flex: 1 1 50%;
  justify-content: center;
`;

const ResultImg = styled.img`
  transition: transform 300ms ease-in-out;
  object-fit: cover;
  width: 100%;
`;
// const ResultImgAreaX = styled.img`

//   flex: 0 0;
//   width: 300px;

//   transition: transform 300ms ease-in-out;
//   object-fit: cover;
// `;

// const QuizTextInput = styled.input`
//   border: 1px solid ${colors.common.black};
//   width: 100%;

//   padding: 10px;
//   margin: 1rem 0;
// `;

// const QuizTextAreaInput = styled.textarea`
//   border: 1px solid ${colors.common.black};

//   vertical-align: top;
//   width: 100%;
//   min-height: 200px;
//   padding: 10px;
//   margin: 0 0 10px 0;
// `;

const QuizTextArea = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  &&& > textarea {
    font-family: 'Roboto';
    font-size: 1rem;
  }
`;
const QuizBtnArea = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;
const QuestionLabel = ({ label }) => {
  return (
    <LabelArea>
      <Label>{label}</Label>
    </LabelArea>
  );
};

const QuizQuestionField = ({
  step,
  doc,
  inputValue,
  onChange,
  onBlur,
  onSelectChange,
  onContinue,
  onPrevious,
  onSubmit,
}) => {
  // const [setSelectValue] = useState(inputValue ? inputValue : '');
  const {
    hasCharsMax,
    charsMaxWarnValue,
    chars,
    charsLeft,
    charsMax,
  } = useCharMaxLimit(inputValue, doc);

  const handleSelectChange = (id, value) => {
    //setSelectValue(value);
    onSelectChange(id, value);
  };

  // if (doc.type === 'select') {
  //   const menuitems = JSON.parse(doc.examples);
  //   return (
  //     <QuizQuestionFieldContainer>
  //       <QuestionLabel label={doc.label} />
  //       <QuestionFieldWrapper>
  //         {menuitems.map((item, index) => (
  //           <QuizButton
  //             backColor={colors.primary.light}
  //             textColor={colors.primary.contrastText}
  //             key={index}
  //             onClick={e => handleSelectChange(item.id, item.label)}
  //           >
  //             <QuizButtonLabel>{item.label}</QuizButtonLabel>
  //           </QuizButton>
  //         ))}
  //       </QuestionFieldWrapper>
  //     </QuizQuestionFieldContainer>
  //   );
  // }
  if (doc.type === 'select-imgs' || doc.type === 'select') {
    const buttonitems = JSON.parse(doc.examples.trim());
    //console.log(`buttonItems=> ${buttonitems}`);
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <QuestionLabel label={doc.label} />
        </Grid>
        <GridForButtons container spacing={0}>
          {buttonitems.map((item, index) =>
            item.filename ? (
              <QuizButtonImg
                backColor={colors.primary.light}
                textColor={colors.primary.contrastText}
                key={index}
                onClick={e => handleSelectChange(doc.id, item.label)}
              >
                <ButtonSelectImgArea>
                  <Img
                    src={require(`assets/buzzQuiz/${item.filename}`)}
                    loader={<Loading />}
                    container={children => {
                      return (
                        <ButtonSelectImgArea>{children}</ButtonSelectImgArea>
                      );
                    }}
                  />
                </ButtonSelectImgArea>
              </QuizButtonImg>
            ) : (
              <QuizButton
                backColor={colors.primary.light}
                textColor={colors.primary.contrastText}
                key={index}
                onClick={e => handleSelectChange(doc.id, item.label)}
              >
                <QuizButtonLabel>{item.label}</QuizButtonLabel>
              </QuizButton>
            )
          )}
        </GridForButtons>

        <QuizBtnArea>
          {step !== 1 && <BtnMain label="back" onClick={onPrevious} />}
        </QuizBtnArea>
      </Grid>
    );
  }

  if (doc.type === 'result') {
    return (
      <ResultFieldContainer>
        <ResultsTitle>Congrats!</ResultsTitle>
        <QuestionResultsFieldWrapper>
          <LabelResult dangerouslySetInnerHTML={{ __html: doc.label }} />
          <ResultImgArea>
            <ResultImg
              src={require(`assets/buzzQuiz/endgif.gif`)}
              alt="result consumer"
            />
          </ResultImgArea>
        </QuestionResultsFieldWrapper>
        <QuizBtnArea>
          <BtnMain label="Save & Exit" onClick={onContinue} />
        </QuizBtnArea>
      </ResultFieldContainer>
    );
  }

  return (
    <QuizQuestionFieldContainer>
      <QuestionLabel label={doc.label} />
      <QuizTextArea>
        <TextAreaAutoSize
          autoFocus
          id={doc.id}
          value={inputValue}
          onChange={onChange}
          rows="3"
          fsize="1.8rem"
          maxLength={charsMax ? charsMax : 100}
        />
      </QuizTextArea>
      <CharMaxHelperArea>
        <CharsMaxHelper
          hasMax={hasCharsMax}
          max={charsMax}
          chars={chars}
          warnValue={charsMaxWarnValue}
        />
      </CharMaxHelperArea>

      <QuizBtnArea>
        <BtnMain label="back" onClick={onPrevious} />
        <BtnMain label="Next" disabled={inputValue === ''} onClick={onSubmit} />
      </QuizBtnArea>
    </QuizQuestionFieldContainer>
  );
};
// return (
//   <QuizQuestionFieldContainer>
//     <QuestionFieldWrapper>
//       <QuestionLabel label={doc.label} />
//       <QuizTextInput
//         type="text"
//         id={doc.id}
//         value={inputValue}
//         onChange={onChange}
//         autoFocus
//       />
//     </QuestionFieldWrapper>
//   </QuizQuestionFieldContainer>
// );
// };

export default QuizQuestionField;

// return (
//   <QuizQuestionFieldContainer>
//     <QuestionFieldWrapper>
//       <QuestionLabel label={doc.label} />
//       <QuizTextInput
//         type="text"
//         id={doc.id}
//         value={inputValue}
//         onChange={onChange}
//         autoFocus
//       />
//     </QuestionFieldWrapper>
//   </QuizQuestionFieldContainer>
// );
