import React from 'react';

const SvgPatternTopRight = props => (
  <svg viewBox="0 0 493.08 280.69" {...props}>
    <defs>
      <pattern
        id="PatternTopRight_svg__a"
        data-name="New Pattern 6"
        width={9}
        height={9}
        patternTransform="matrix(1.9 0 0 1.9 -136.1 464.25)"
        patternUnits="userSpaceOnUse"
      >
        <path fill="none" d="M0 0h9v9H0z" />
        <path d="M4.5 3L3 6h3L4.5 3z" />
      </pattern>
    </defs>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path fill="#e82127" d="M69.72 72.02V.85h71.17v71.17z" />
        <path fill="url(#PatternTopRight_svg__a)" d="M140.88.86v71.16h71.16" />
        <path fill="#1d4797" d="M212.05 72.02V.85h71.17v71.17z" />
        <path fill="#76cbe2" d="M212.33 142.5h70.17V72.33" />
        <path fill="#282965" d="M141.24 142.29V72.12H71.07" />
        <path fill="#1d4797" d="M141.24 72.09v70.17h70.17" />
        <path fill="#f2a01e" d="M0 71.89h70.17V1.72" />
        <path fill="#e54025" d="M282.77 142.45H212.6v70.17" />
        <path fill="url(#PatternTopRight_svg__a)" d="M352.59 70.17h70.17V0" />
        <path fill="#76cbe2" d="M493.08 70.27V.1h-70.17" />
        <path fill="#282965" d="M422.91.1h-70.17v70.17" />
        <path fill="#4990cc" d="M352.74 70.27V.1h-70.17" />
        <path fill="#f2a01e" d="M422.91 70.27v70.17h70.17" />
        <path fill="#282965" d="M422.91 140.44V70.27h-70.17" />
        <path fill="#e82127" d="M422.91.1v70.17h70.17" />
        <path fill="#f2a01e" d="M352.59 140.34V70.17h-70.17" />
        <path fill="#76cbe2" d="M422.76 140.34v70.17h70.17" />
        <path fill="#1d4797" d="M492.93 210.51v-70.17h-70.17" />
        <path
          fill="url(#PatternTopRight_svg__a)"
          d="M422.56 210.52v70.17h70.17v-70.17h-70.17z"
        />
        <path fill="#e82127" d="M353.04 140.79v70.17h70.17" />
        <path
          fill="url(#PatternTopRight_svg__a)"
          d="M352.86 210.96v-70.17h-70.17"
        />
      </g>
    </g>
  </svg>
);

export default SvgPatternTopRight;
