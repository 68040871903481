import React from 'react';

const ArrowNext = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 42" {...props}>
      <g data-name="ArrowNext">
        <path
          fill="none"
          stroke="stroke"
          strokeLinejoin="round"
          strokeWidth="3.21"
          d="M1.22 41.05l17.22-20-17.22-20"
          data-name="ArrowNext"
        />
      </g>
    </svg>
  );
};

export default ArrowNext;
