// Material helpers
import { createMuiTheme } from '@material-ui/core';

import palette from './palette';
import typography from './typography';
// import overrides from './overrides';
import { breakpoints } from 'styles/responsive';

const theme = createMuiTheme({
  palette,
  typography,
  breakpoints: {
    values: breakpoints,
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
});

export default theme;
