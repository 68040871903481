import { useState, useEffect } from 'react';
const textshortMax = 90;
const textshortTrigger = 10;
const useCharMaxLimit = (stringValue, doc) => {
  const [hasCharsMax, setHasCharsMax] = useState(false);
  const [charsMax, setCharsMax] = useState();
  const [charsMaxWarnValue, setCharsMaxWarnValue] = useState();
  const [charsLeft, setCharsLeft] = useState();
  const [chars, setChars] = useState();

  useEffect(() => {
    const { type, charLimit } = doc;

    switch (type) {
      case 'text-short':
        const warnValue = textshortMax - textshortTrigger;
        setCharsMaxWarnValue(warnValue);
        setCharsMax(textshortMax);
        setHasCharsMax(true);
        break;
      default:
        setHasCharsMax(true);
    }
  }, [doc.type, doc.charLimit, doc]);

  useEffect(() => {
    const chars = stringValue.length;
    const csleft = charsMax - chars;
    setChars(chars);
    setCharsLeft(csleft);
  }, [charsMax, chars, stringValue.length]);

  return { hasCharsMax, charsMaxWarnValue, chars, charsLeft, charsMax };
};
export default useCharMaxLimit;
