import styled from 'styled-components/macro';
export const CardContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  /* justify-content: space-between; */
  min-height: ${props => props.minHeight};
  max-height: ${props => props.maxHeight};
  transition: max-height 0.5s ease;
  overflow: hidden;
`;

export default CardContent;
