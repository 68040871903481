import React from 'react';

const BackArrow = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 74 74" {...props}>
      <g data-name="BackArrow">
        <path
          fill="#fff"
          d="M60.29 31.51H44.93V20.4a2.12 2.12 0 00-3.17-1.84L13 35.19a2.11 2.11 0 000 3.66l28.76 16.63a2.12 2.12 0 003.17-1.84V42.53h15.36a1.84 1.84 0 001.84-1.84v-7.34a1.84 1.84 0 00-1.84-1.84z"
        />
      </g>
    </svg>
  );
};

export default BackArrow;
