import * as React from "react";

function SvgMarketplaceCard(props) {
  return (
    <svg data-name="Layer 1" viewBox="0 0 320 380" {...props}>
      <defs>
        <pattern
          id="MarketplaceCard_svg__a"
          data-name="New Pattern 6"
          width={9}
          height={9}
          patternTransform="matrix(.83 0 0 .98 52.93 89.62)"
          patternUnits="userSpaceOnUse"
        >
          <path fill="none" d="M0 0h9v9H0z" />
          <path d="M4.5 3L3 6h3L4.5 3z" />
        </pattern>
      </defs>
      <path
        d="M0 178h244.05v202H33a33 33 0 01-33-33V178z"
        fill={props.primaryclr}
        opacity={0.58}
      />
      <path
        opacity={0.67}
        fill="url(#MarketplaceCard_svg__a)"
        d="M121.52 53H320v200H121.52z"
      />
      <path
        d="M16 0h255a33 33 0 0133 33v331H49a33 33 0 01-33-33V0z"
        fill={props.primaryclr}
      />
      <path
        d="M28 12h231a33 33 0 0133 33v307H61a33 33 0 01-33-33V12z"
        fill={props.backclr}
      />
    </svg>
  );
}

export default SvgMarketplaceCard;
