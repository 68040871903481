import styled from 'styled-components/macro';
import { globals, colors } from 'styles/default';
const DashCardShadow = styled.div`
  display: flex;
  align-items: flex-start;

  /* justify-content: center; */
  /* border-radius:  28px; */
  border-radius: ${globals.borderRadius};
  background-color: ${colors.card.backgroundColor};
  box-shadow: 0 0 14px 8px ${colors.card.boxShadowColor};
  padding: ${globals.padding};
  height: auto;
  width: 100%;
  /* margin: 2rem auto; */
  /* width: calc(100% - 4rem); */
  /* max-width: 1024px; */
`;

export default DashCardShadow;
