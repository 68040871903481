import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import { SceneHeader, SceneSubHeader } from 'elements/Headers';
import { withFirebase } from 'api/Firebase';

import ProjectItem from './ProjectItem';
import { projectSet, projectReset } from 'store/actions/projectPlatform';
import { resultOutputsReset } from 'store/actions/projectResults';

import { GridContainer, GridBase } from 'elements/LayoutElements';
import { mediaMinDevice } from 'styles/responsive';
import * as PATHS from 'routes/paths';

const GridCardHolder = styled(GridBase)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  ${mediaMinDevice.deviceM} {
    justify-content: space-between;
  }
`;
// width="270px" height="210px"
const ProductItemWrapper = styled.div`
  margin-bottom: 1rem;
  width: calc(50% - 1rem);
  min-width: 250px;
  max-width: 270px;
  justify-content: center;
  ${mediaMinDevice.deviceM} {
    justify-content: space-between;
  }
  ${mediaMinDevice.deviceL} {
    justify-content: flex-start;
  }
`;

const Workzones = ({ history, firebase }) => {
  const dispatch = useDispatch();
  const screenSize = useSelector(state => state.app.screenSize);
  const authUser = useSelector(state => state.sessionState.authUser);
  const [loading, setLoading] = useState(true);
  const [projects, setProjects] = useState();

  const memberProjectsCollectionRef = useRef(
    firebase.fstore
      .collection('members')
      .doc(authUser.uid)
      .collection('projects')
  );
  const mainProjectsCollectionRef = useRef(
    firebase.fstore.collection('projects')
  );

  useEffect(() => {
    const fetchProjects = async () => {
      dispatch(projectReset());
      dispatch(resultOutputsReset());
      //console.log(`fetchProjects`);
      const memberProjectsRef = memberProjectsCollectionRef.current;
      const mainProjectsRef = mainProjectsCollectionRef.current;

      const memberProjects = await memberProjectsRef
        .orderBy('datePurchased')
        .get();
      const combodata = [];
      for (const project of memberProjects.docs) {
        //console.log(`project.id+> ~${project.id}`);
        const memberProjectData = project.data();
        const mainProject = await mainProjectsRef.doc(project.id).get();
        const mainProjectData = mainProject.data();
        const cdata = {
          ...mainProjectData,
          ...memberProjectData,
        };
        combodata.push(cdata);
      }
      //console.log(`combodata----${combodata.length}`);
      setProjects(combodata);
      setLoading(false);
      // memberProjectsRef
      //   .orderBy('datePurchased')
      //   .get()
      //   .then(async qsnap => {
      //     const mergedData = [];
      //     await qsnap.forEach(async doc => {
      //       console.log(doc.id, ' => ', doc.data());
      //       const mainProjectData = await mainProjectsRef.doc(doc.id).get();
      //       const mainData = mainProjectData.data();
      //       const mergedProject = { ...mainData, ...doc.data() };
      //       mergedData.push(mergedProject);
      //     });
      //     return mergedData;
      //   })

      // .then(mergedData => {
      //   console.log(
      //     `fetchProjects __${mergedData.length} ${JSON.stringify(mergedData)}`
      //   );
      //   setProjects(mergedData);
      //   setLoading(false);
      // });
      // const combinedProjects = await memberProjects.map(snapshot => {});
      // Promise.all([
      //   memberProjectsRef.orderBy('datePurchased').get(),
      //   mainProjectsRef.get(),
      // ])
      //   .then(promiseResults => {
      //     const mergedData = [];
      //     promiseResults.forEach(snapshot => {
      //       snapshot.forEach(doc => mergedData.push(doc.data()));
      //     });
      //     return mergedData;
      //   })
      //   .then(mergedData => {
      //     console.log(
      //       `fetchProjects __${mergedData.length} ${JSON.stringify(mergedData)}`
      //     );
      //     const data = _.groupBy(mergedData, 'uid');
      //     console.log(`data __${data.length} ${JSON.stringify(data)}`);
      //     setProjects(mergedData);
      //     setLoading(false);
      //   });
      //   });
      //const snapshot = await memberProjectsRef.orderBy('datePurchased').get();

      // const data = snapshot;
      // console.log(`fetchProjects=>${data}`);
      // setProjects(data);
      // setLoading(false);
    };
    fetchProjects();
  }, [authUser.uid, dispatch, firebase]);
  // const [startDisabled, setStartDisabled] = useState(false);
  // const getDocData = async docRef => {
  //   const data = await docRef.get().then(doc => doc.data());
  //   return data;
  // };
  const getCollData = async collRef => {
    const snapshot = await collRef.get().then(snap => snap);
    return snapshot;
  };
  const launchProject = async project => {
    const { uid, name, type } = project;

    const fb = firebase.fstore;
    const batch = fb.batch();

    const mainProjectColls = mainProjectsCollectionRef.current;
    const mainProjectRef = mainProjectColls.doc(uid);

    const memberProjectColls = memberProjectsCollectionRef.current;
    const memberProjectRef = memberProjectColls.doc(uid);

    // const memberProjectRef = fb
    //   .collection('members')
    //   .doc(authUser.uid)
    //   .collection('projects')
    //   .doc(projectId);

    const memberActivityCollRef = memberProjectRef.collection('activitys');

    // const mainProjectRef = fb.collection('projects').doc(project.uid);

    //const mainProjectData = await getDocData(mainProjectRef);

    const projectData = {
      uid: uid,
      name: name,
      active: true,
      status: 'current',
    };
    batch.update(memberProjectRef, projectData);

    const activityCollRef = mainProjectRef.collection('activitys');

    const activitys = await getCollData(activityCollRef);
    activitys.forEach(docsnap => {
      // console.log(`docsnap.id=> ${docsnap.id}`);
      const actRef = memberActivityCollRef.doc(docsnap.id);
      const docData = docsnap.data();
      batch.set(actRef, {
        ...docData,
        uid: docsnap.id,
        started: false,
        completed: false,
      });
    });

    batch.commit().then(() => {
      firebase.sendAnalyticsLogEvent('project_started', {
        uid: uid,
        name: name,
        type: type,
      });
      continueProject(projectData);
    });

    //console.log(`activitys.length=> ${mainProjectData.activitys.length}`);
  };

  const continueProject = project => {
    //console.log(`pid=> ${project.uid}`);
    const workPath =
      project.status === 'complete'
        ? PATHS.WORKZONE_PROJECT_RESULTS_BUILDER
        : PATHS.WORKZONE;
    dispatch(
      projectSet(project.uid, project.name, project.status, project.resultId)
    ).then(() => history.push(workPath));
  };

  //console.log(`list`);
  return (
    <GridContainer container>
      <GridBase container justify="flex-start" spacing={0}>
        <GridBase item xs={12}>
          <SceneHeader screenSize={screenSize}>
            WELCOME TO THE Work zone!
          </SceneHeader>
        </GridBase>
        <GridBase item xs={12}>
          <SceneSubHeader screenSize={screenSize}>
            Access your projects and get to work.
          </SceneSubHeader>
        </GridBase>
      </GridBase>
      {!loading && projects && (
        <GridCardHolder
          container
          justify={screenSize === 'sm' ? 'center' : 'flex-start'}
          spacing={0}
        >
          {projects.map(doc => (
            <ProductItemWrapper key={doc.uid}>
              <ProjectItem
                key={doc.uid}
                userId={authUser.uid}
                project={doc}
                handleContinueProject={continueProject}
                handleLaunchProject={launchProject}
              />
            </ProductItemWrapper>
          ))}
        </GridCardHolder>
      )}
    </GridContainer>
  );
};

export default withRouter(withFirebase(Workzones));
