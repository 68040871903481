import React, { useState, useEffect } from 'react';
import { MadLibField } from 'components/FormFields';

const FormField = ({ doc, initialValue, handleFieldChange }) => {
  const [inputValue, setInputValue] = useState('');
  //console.log(`doc-id=${doc.id}=> ${initialValue} inputValue=${inputValue}`);
  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);
  const handleInputChange = e => {
    //console.log(`handleInputChange=> ${e.target.value}`);
    setInputValue(e.target.value);
  };
  const handleInputBlur = e => {
    //setInputValue(e.target.value);
    const { inputCompleted, inputStarters, ...other } = doc;
    //console.log(`handleInputBlur=> ${e.target.value}`);
    handleFieldChange({
      [doc.id]: {
        ...other,
        value: e.target.value,
      },
    });
  };

  return (
    <MadLibField
      doc={doc}
      inputValue={inputValue}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
    />
  );
};

const MadLibForm = ({ inputs, values, handleChange }) => {
  //console.log(`values=> ${JSON.stringify(values)}`);
  if (values) {
    return inputs.map(doc => {
      //console.log(`values yes id ${doc.id}`);
      const iValue = values.hasOwnProperty(doc.id) ? values[doc.id].value : '';
      return (
        <FormField
          key={doc.id}
          doc={doc}
          initialValue={iValue}
          handleFieldChange={handleChange}
        />
      );
    });
  }
  return inputs.map(doc => (
    <FormField
      key={doc.uid}
      doc={doc}
      initialValue=""
      handleFieldChange={handleChange}
    />
  ));
};

export default MadLibForm;
