import React from 'react';

function SvgLuBotBotSingle(props) {
  return (
    <svg viewBox="0 0 466.6 472.48" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Layer 2">
            <g data-name="LuBot Bot-Single">
              <ellipse
                cx={233.3}
                cy={435.08}
                rx={233.3}
                ry={37.4}
                style={{
                  isolation: 'isolate',
                }}
                fill={props.golda}
                opacity={0.3}
              />
              <path d="M248.6 395.08h56.1v25.1h-56.1z" fill={props.greymd} />
              <path
                d="M248.6 395.08h56.1V419h-56.1z"
                fill="none"
                stroke={props.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={7.37}
              />
              <path
                d="M309 430.38c0-12.5-14.3-22.7-32-22.7s-32 10.2-32 22.7z"
                fill={props.greymd}
              />
              <path
                d="M308.7 430.38c0-12.5-14.3-22.7-32-22.7s-32 10.2-32 22.7z"
                fill="none"
                stroke={props.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={6.99}
              />
              <path d="M165.3 395.08h56.1v25.1h-56.1z" fill={props.greymd} />
              <path
                d="M165.3 395.08h56.1V419h-56.1z"
                fill="none"
                stroke={props.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={6.82}
              />
              <path
                d="M225.7 430.38c0-12.5-14.3-22.7-32-22.7s-32 10.2-32 22.7z"
                fill={props.greymd}
              />
              <path
                d="M225.3 430.38c0-12.5-14.3-22.7-32-22.7s-32 10.2-32 22.7z"
                fill="none"
                stroke={props.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={6.99}
              />
              <g>
                <path
                  d="M322.6 257.08s73.8-35.1 80-112.8l-17-6.9s-10.9 65.2-70.9 101.1"
                  fill={props.greymd}
                />
                <path
                  d="M322.6 257.08s73.8-35.1 80-112.8l-17-6.9s-10.9 65.2-70.9 101.1"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={6.36}
                />
                <path
                  d="M338.7 247l-11.1-16.2m24.9 4l-12.3-14.4m25.3 3.1l-13.7-13.8m40.5-28.6l-16.4-9.5m-.5 38.8l-15.1-12.5m25.2-2.5l-16.1-11.6m29-21.8l-16.4-6.8"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.92}
                />
                <path
                  d="M385.7 107.28c-4.1 16.8-5.6 34 8.1 37.3s21.6-11.8 25.7-28.6l-10.7 1c-2 8.4-4.6 15.9-11.4 14.3s-5.1-8.2-3.1-16.6z"
                  fill={props.greymd}
                />
                <path
                  d="M385.7 107.28c-4.1 16.8-5.6 34 8.1 37.3s21.6-11.8 25.7-28.6l-10.7 1c-2 8.4-4.6 15.9-11.4 14.3s-5.1-8.2-3.1-16.6z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={4.73}
                />
              </g>
              <g>
                <path
                  d="M150.1 236.08S78.7 276 77.6 353.88l17.4 5.8s6.7-65.8 64.2-105.5"
                  fill={props.greymd}
                />
                <path
                  d="M150.1 236.08S78.7 276 77.6 353.88l17.4 5.8s6.7-65.8 64.2-105.5"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={6.36}
                />
                <path
                  d="M134.6 247.18l12.2 15.5m-25.2-2.4l13.3 13.5m-25.5-1.4l14.6 12.9m-38.5 31.2l17 8.4m-2.1-38.7l15.9 11.4m-25 4.1l16.8 10.6m-27.5 23.6l16.8 5.7"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.92}
                />
                <path
                  d="M96.5 389.88c3-17.1 3.3-34.3-10.5-36.7s-20.8 13.2-23.8 30.2l10.6-1.7c1.5-8.5 3.6-16.2 10.5-15s5.6 7.8 4.1 16.4l9.1 6.8z"
                  fill={props.greymd}
                />
                <path
                  d="M96.5 389.88c3-17.1 3.3-34.3-10.5-36.7s-20.8 13.2-23.8 30.2l10.6-1.7c1.5-8.5 3.6-16.2 10.5-15s5.6 7.8 4.1 16.4l9.1 6.8z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={4.73}
                />
              </g>
              <g>
                <path
                  d="M317.6 391.78H152.9a8.72 8.72 0 01-8.7-8.7v-154.6a8.72 8.72 0 018.7-8.7h164.6a8.72 8.72 0 018.7 8.7v154.7a8.54 8.54 0 01-8.48 8.6h-.12z"
                  fill={props.greymd}
                />
                <path
                  d="M314.7 391.78H150a8.72 8.72 0 01-8.7-8.7v-154.6a8.72 8.72 0 018.7-8.7h164.6a8.72 8.72 0 018.7 8.7v154.7a8.47 8.47 0 01-8.34 8.6h-.26z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={6.63}
                />
                <path
                  d="M196.2 208.08h78.1v12.1h-78.1z"
                  fill={props.greymd}
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={5.49}
                />
                <path
                  d="M207.2 208.08v11.1m11.9-11.1v11.1m11.5-10.5v11.1m10.8-11.1v11.1m22.5-11.7v11.1m-11.2-10.5v11.1"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.92}
                />
                <path
                  d="M273.6 299.08H193a3 3 0 01-3-3v-37.8a3 3 0 013-3h80.6a3 3 0 013 3v37.8a3 3 0 01-3 3z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bota}
                  opacity={0.3}
                />
                <path
                  d="M209.4 267.48v17.4h16.3m31.5-17.4v17.4H241v-17.4"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={6.36}
                />
                <path
                  d="M273.6 298.08H193a3 3 0 01-3-3v-37.8a3 3 0 013-3h80.6a3 3 0 013 3v37.8a3 3 0 01-3 3zM192.4 317v34.4m17.1-34.4v34.4m16.6-34.4v34.4m15.6-34.4v34.4m32.5-34.4v34.4M258.1 317v34.4"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={6.36}
                />
              </g>
              <g>
                <path
                  d="M140.3 149.08a17.5 17.5 0 010-35m184.1 0a17.5 17.5 0 010 35"
                  fill={props.greydk}
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={6.36}
                />
                <path
                  d="M315 207.48H149.7a8.28 8.28 0 01-8.3-8.26V57.28a8.28 8.28 0 018.26-8.3H315a8.28 8.28 0 018.3 8.26v141.94a8.22 8.22 0 01-8.14 8.3H315z"
                  fill={props.greymd}
                />
                <path
                  d="M315 207.48H149.7a8.28 8.28 0 01-8.3-8.26h0V57.28a8.28 8.28 0 018.26-8.3H315a8.28 8.28 0 018.3 8.26h0v141.94a8.22 8.22 0 01-8.14 8.3H315z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={6.36}
                />
                <circle
                  cx={183.2}
                  cy={154.78}
                  r={15.8}
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bota}
                  opacity={0.3}
                />
                <circle
                  cx={283.5}
                  cy={154.78}
                  r={15.8}
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.bota}
                  opacity={0.3}
                />
                <path
                  d="M252.3 139.28c.5 7.9-7.7 14.9-18.2 15.5s-19.4-5.3-19.9-13.3"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeMiterlimit={10}
                  strokeWidth={8.49}
                />
                <circle cx={193} cy={114.08} r={9.8} fill={props.main} />
                <circle cx={266.6} cy={114.08} r={9.8} fill={props.main} />
                <path
                  d="M235.2 56.38s1.6-27.3 28.2-32.9"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={6.36}
                />
                <circle cx={283.5} cy={18.68} r={15.5} fill={props.greymd} />
                <circle
                  cx={282.1}
                  cy={18.68}
                  r={15.5}
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={6.36}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgLuBotBotSingle;
