import React from 'react';
import styled from 'styled-components/macro';
import { hexToRGBa } from 'utils/colors';

// function hexToRGBa(h, a) {
//   let r = 0,
//     g = 0,
//     b = 0;

//   // 3 digits
//   if (h.length === 4) {
//     r = '0x' + h[1] + h[1];
//     g = '0x' + h[2] + h[2];
//     b = '0x' + h[3] + h[3];

//     // 6 digits
//   } else if (h.length === 7) {
//     r = '0x' + h[1] + h[2];
//     g = '0x' + h[3] + h[4];
//     b = '0x' + h[5] + h[6];
//   }

//   return 'rgba(' + +r + ',' + +g + ',' + +b + ',' + +a + ')';
// }
// const BtnWrapper = styled.button`
//   position: relative;
//   height: 42px;
//   object-fit: contain;
//   border-radius: 5.8px;
//   border-style: none;
//   color: hex &.btnLabel {
//     opacity: 1;
//   }
//   &:hover {
//     color: ${props => (props.textColor ? props.textColor : 'rgba(')};
//   }
// `;
// const BtnBack = styled.div`
//   position: absolute;
//   height: 42px;
//   width: 100%;
//   border-radius: 5.8px;
//   border-style: none;
//   background-color: ${props => (props.fillColor ? props.fillColor : '#999999')};
//   opacity: 0.6;
//   &:hover {
//     opacity: 0.2;
//   }
// `;

// const BtnBack = styled.div

//   background-color: ${props => (props.fillColor ? props.fillColor : '#999999')};
//   opacity: 0.6;
//   width: 100%;
//   height: 100%;
//   &:hover {
//     opacity: 0.2;
//   }
// `;

// const BtnLabel = styled.div`
//   position: absolute;
//   color: ${props => (props.textColor ? props.textColor : '#fff')};
//   text-transform: uppercase;
//   font-family: Montserrat;
//   font-size: 31.8px;
//   font-weight: bold;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1;
//   letter-spacing: normal;
//   text-align: center;
// `;

const BtnWrapper = styled.button`
  height: 42px;
  object-fit: contain;
  cursor: pointer;
  border-radius: 5.8px;
  border-style: none;
  color: ${props => (props.textColor ? props.textColor : '#fff')};
  background-color: ${props => (props.fillColor ? props.fillColor : '#999999')};
  padding: 0 8px;

  &:hover {
    color: ${props => (props.textColor ? props.textColor : '#fff')};
    background-color: ${props =>
      props.hoverColor ? props.hoverColor : '#999999'};
  }
  &:disabled {
    pointer-events: none;
  }

  /* margin-bottom: 25px; */
`;
const BtnLabel = styled.div`
  font-family: Montserrat;
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
`;
const ActCardBtn = ({ label, textColor, fillColor, ...others }) => {
  //console.log(`textColor=>${textColor} fillColor=> ${fillColor}`);
  const tcolor = hexToRGBa(textColor, 1);
  const fcolor = hexToRGBa(fillColor, 0.25);
  const hcolor = hexToRGBa(fillColor, 0.6);
  return (
    <BtnWrapper
      textColor={tcolor}
      fillColor={fcolor}
      hoverColor={hcolor}
      {...others}
    >
      <BtnLabel>{label}</BtnLabel>
    </BtnWrapper>
  );
};
export default ActCardBtn;
