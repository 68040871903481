import React from 'react';
import styled from 'styled-components/macro';
import { mediaMinDevice } from 'styles/responsive';
import { IconArrowBack } from 'components/SvgIcons';

// import BackArrow from 'assets/icons/BackArrow';
// import { ReactComponent as BackBtn } from 'assets/svg/BackBtn.svg';
import { colors } from 'styles/default';

const BtnIcon = styled.div`
  transition: transform 300ms ease-in-out;
  width: 1.5rem;
  height: 1.5rem;
  ${mediaMinDevice.deviceM} {
    width: 1.8rem;
    height: 1.8rem;
  }
`;
const BtnWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  margin: 0;
  padding: 0;
  background-color: ${colors.primary.dark};

  width: 2.25rem;
  height: 2.25rem;
  border: solid 2px #ffffff;

  ${mediaMinDevice.deviceM} {
    width: 3rem;
    height: 3rem;
    border: solid 4px #ffffff;
  }

  &:hover ${BtnIcon} {
    transform: scale(1.3);
  }
  outline: none;
  /* &:focus {
    outline: #fff solid 3px;
  } */
  /* margin-bottom: 25px; */
`;
const BtnBack = props => {
  return (
    <BtnWrapper {...props}>
      <BtnIcon>
        <IconArrowBack primary="#fff" />
      </BtnIcon>
    </BtnWrapper>
  );
};
// const BtnBack = props => {
//   return (
//     <BtnWrapper {...props}>
//       <Icon inverse path={mdiArrowLeftThick} size="45px" />
//     </BtnWrapper>
//   );
// };

export default BtnBack;
