import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'styles/default';

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.text.primary};

  & p {
    font-size: 16px;
    margin: 0.5rem 0;
  }
  & ul {
    font-size: 16px;
    margin: 0.5rem 0;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    padding-inline-start: 40px;
  }
  & ol {
    font-size: 16px;
    margin: 0.5rem 0;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    padding-inline-start: 40px;
  }
`;

// const ContentP = styled.p`
//   font-family: Montserrat;
//   font-size: 16px;
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   text-align: left;
//   color: ${colors.common.black};
//   color: ${colors.common.black};
//   margin: 0.5rem 0;
//   & span.insertSpan {
//     background: ${colors.template.highlightBlue};
//     color: #000000;
//   }
// `;

const DivText = ({ text }) => {
  return (
    <ContentDiv
      dangerouslySetInnerHTML={{
        __html: text,
      }}
    ></ContentDiv>
  );
};

export default DivText;
