import React, { useState } from 'react';
// import { useSelector } from 'react-redux';

import Grid from '@material-ui/core/Grid';

import { withFirebase } from 'api/Firebase';

import Loading from 'components/Loading';

/* UI lib components */
import {
  FormContent,
  FormHeader,
  FormFieldControl,
  FormFieldErrorText,
  FormFieldInput,
  FormFieldLabel,
} from 'elements/FormElements';

import BtnMain from 'elements/BtnMain';

const ChangePassword = ({ firebase, onSuccess, onCancel }) => {
  // const authUser = useSelector(state => state.sessionState.authUser);
  const [isWorking, setIsWorking] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [errors, setErrors] = useState({});
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');

  const handlePasswordResetForm = e => {
    //const { firebase, history } = this.props;
    // const { email, actionCode } = this.state;
    e.preventDefault();
    setIsWorking(true);

    const user = firebase.auth.currentUser;
    const credential = firebase.emailAuthProvider.credential(
      user.email,
      currentPassword
    );

    user
      .reauthenticateWithCredential(credential)
      .then(function() {
        firebase
          .doPasswordUpdate(newPassword)
          .then(() => {
            setIsWorking(false);
            onSuccess();
          })
          .catch(err => {
            setHasErrors(true);
            setErrors({
              code: err.code,
              message: err.message,
            });
            setIsWorking(false);
          });
      })
      .catch(function(err) {
        // An error happened.
        setHasErrors(true);
        setErrors({
          code: err.code,
          message: err.message,
        });
        setIsWorking(false);
      });
  };

  if (isWorking) {
    return <Loading />;
  }

  return (
    <form noValidate>
      <FormContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormHeader>Change Password</FormHeader>
          </Grid>
          <Grid item xs={12}>
            <FormFieldControl fullWidth>
              <FormFieldLabel>Current Password</FormFieldLabel>
              <FormFieldInput
                required
                fullWidth
                name="current-password"
                type="password"
                id="current-password"
                autoComplete="current-password"
                value={currentPassword}
                onChange={e => setCurrentPassword(e.target.value)}
              />
            </FormFieldControl>
            <FormFieldControl fullWidth>
              <FormFieldLabel>New Password</FormFieldLabel>
              <FormFieldInput
                required
                fullWidth
                name="password"
                type="password"
                id="password"
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
              />
            </FormFieldControl>

            {hasErrors && (
              <FormFieldErrorText>{errors.message}</FormFieldErrorText>
            )}
          </Grid>

          <Grid container item xs={12} justify="space-between">
            <BtnMain onClick={onCancel} label="Cancel"></BtnMain>
            <BtnMain
              onClick={handlePasswordResetForm}
              label="Save New Password"
            ></BtnMain>
          </Grid>
        </Grid>
      </FormContent>
    </form>
  );
};

export default withFirebase(ChangePassword);
