import React from 'react';
import styled from 'styled-components/macro';

import ContentLabelMedia from '../ContentLabelMedia';

const ContentListWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const BtnWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  margin: 0.5rem 1rem;
  cursor: pointer;
  border-radius: 4px;
  border-style: solid;
  border-width: 0;
  border-color: ${props => (props.fillColor ? props.fillColor : '#999999')};
  height: auto;
  color: ${props => (props.textColor ? props.textColor : '#fff')};
  background-color: ${props => (props.fillColor ? props.fillColor : '#999999')};

  padding: 0.5rem 1rem;

  /* margin-bottom: 25px; */
`;
const BtnLabel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const BtnLabelTitle = styled.div`
  display: flex;
  white-space: wrap;
  font-family: Montserrat;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-size: 1rem;
  text-align: left;
  line-height: 1;
  margin: 0;
`;
const BtnLabelInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 0.5rem;
`;
const BtnLabelMediaInfo = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-top: 0.5rem;
`;
const BtnLabelLength = styled.div`
  display: flex;
  white-space: wrap;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
  font-style: italic;
  text-align: left;
  font-size: 0.8rem;
  line-height: 1;
  margin: 0;
`;
const BtnLabelAction = styled.a`
  color: ${props => props.color};
  display: flex;
  white-space: wrap;
  font-family: Montserrat;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-decoration: none;
  font-style: normal;
  text-align: left;
  font-size: 0.8rem;
  line-height: 1;
  margin: 0;
  &:hover {
    text-decoration: underline;
  }
`;

const ContentList = ({ items, backColor, hoverColor, textColor }) => {
  return (
    <ContentListWrapper>
      {items.map(item => {
        return (
          <BtnWrapper
            key={item.uid}
            fillColor={backColor}
            textColor={textColor}
            hoverColor={hoverColor}
          >
            <BtnLabel>
              {item.media ? (
                <BtnLabelMediaInfo>
                  <ContentLabelMedia media={item.media} />
                  <BtnLabelTitle>{item.title}</BtnLabelTitle>
                </BtnLabelMediaInfo>
              ) : (
                <BtnLabelTitle>{item.title}</BtnLabelTitle>
              )}

              <BtnLabelInfo>
                <BtnLabelLength>{item.length}</BtnLabelLength>
                <BtnLabelAction
                  color={textColor}
                  target="_blank"
                  href={item.url}
                >
                  Read More...
                </BtnLabelAction>
              </BtnLabelInfo>
            </BtnLabel>
          </BtnWrapper>
        );
      })}
    </ContentListWrapper>
  );
};

export default ContentList;
