import React from 'react';

import {
  Title,
  Subtitle,
  Description,
  HeaderArea,
  CopyArea,
} from 'elements/WorkzoneText';
const ResultsHeader = ({ screen }) => {
  return (
    <>
      <HeaderArea>
        <Title>{screen.header}</Title>
        {screen.subheader && <Subtitle>{screen.subheader}</Subtitle>}
      </HeaderArea>

      <CopyArea>
        <Description
          dangerouslySetInnerHTML={{
            __html: screen.copy,
          }}
        />
      </CopyArea>
    </>
  );
};

export default ResultsHeader;
