import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { colors } from 'styles/default';

const BtnLink = styled(NavLink)`
  border-radius: 0 5px 5px 0;
  width: 130px;
  height: 32px;
  background-color: transparent;
  font-size: 13px;
  text-align: left;
  padding-left: 20px;
  border: none;
  border-left-style: solid;
  border-left-color: #ffffff;
  border-left-width: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  font-family: 'Montserrat';

  text-align: left;
  color: ${colors.text.primary};
  text-decoration: none;
  :hover {
    border-left-style: solid;
    border-left-color: ${colors.primary.dark};
    border-left-width: '10px';
    background-color: ${colors.member.sidebarHover};
  }
`;
const BtnLinkDesktop = styled(NavLink)`
  font-family: 'Montserrat';

  text-align: left;
  color: ${colors.text.primary};
  text-decoration: none;
  border-radius: 0 5px 5px 0;
  width: 220px;
  height: 38px;
  background-color: transparent;
  font-size: 16px;
  text-align: left;
  padding-left: 58px;
  border: none;
  border-left-style: solid;
  border-left-color: #ffffff;
  border-left-width: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  :hover {
    border-left-style: solid;
    border-left-color: ${colors.primary.dark};
    border-left-width: 20px;
    background-color: ${colors.member.sidebarHover};
  }
`;

const Button = styled.button`
  border-radius: 0 5px 5px 0;
  width: ${props => (props.isdesktop ? '220px' : '130px')};
  height: ${props => (props.isdesktop ? '38px' : '32px')};
  background-color: transparent;
  font-size: ${props => (props.isdesktop ? '16px' : '13px')};
  text-align: left;
  padding-left: ${props => (props.isdesktop ? '58px' : '20px')};

  border: none;
  border-left-style: solid;
  border-left-color: #ffffff;
  border-left-width: ${props => (props.isdesktop ? '20px' : '10px')};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 20px;
  :hover {
    border-left-style: solid;
    border-left-color: ${colors.primary.dark};
    border-left-width: ${props => (props.isdesktop ? '20px' : '10px')};
    background-color: ${colors.member.sidebarHover};
  }
`;

// const ButtonLink = styled(NavLink).attrs(props => ({
//   // we can define static props
//   type: 'password',
//   bwidth: props.isDesktop ? '220px' : '130px',
//   bheight: props.isDesktop ? '38px' : '32px',
//   padleft: props.isDesktop ? '58px' : '20px',
//   fsize: props.isDesktop ? '16px' : '13px',
//   borderleftwidth: props.isDesktop ? '20px' : '10px',
// }))`
//   border-radius: 0 5px 5px 0;
//   width: ${props => props.bwidth};
//   height: ${props => props.bheight};
//   background-color: transparent;
//   font-size: ${props => props.fsize};
//   text-align: left;
//   padding-left: ${props => props.padleft};
//   background-color: #ffffff;
//   border: none;
//   border-left-style: solid;
//   border-left-color: #ffffff;
//   border-left-width: ${props => props.borderleftwidth};
//   text-decoration: none;
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   margin-bottom: 20px;
//   :hover {
//     border-left-style: solid;
//     border-left-color: ${colors.primary.dark};
//     border-left-width: ${props => props.borderleftwidth};
//     background-color: ${colors.member.sidebarHover};
//   }
// `;

const SidebarButtonLabel = styled.div`
  font-family: 'Montserrat';
  font-size: ${props => (props.isdesktop ? '16px' : '13px')};
  text-align: left;
  color: ${colors.text.primary};
  text-decoration: none;
`;

const SidebarButtonLink = ({ children, isDesktop, ...props }) => {
  if (isDesktop) {
    return (
      <BtnLinkDesktop
        activeStyle={{
          borderLeftColor: `${colors.primary.dark}`,
        }}
        {...props}
      >
        {children}
      </BtnLinkDesktop>
    );
  }
  return (
    <BtnLink
      activeStyle={{
        borderLeftColor: `${colors.primary.dark}`,
      }}
      {...props}
    >
      <SidebarButtonLabel {...props}> {children}</SidebarButtonLabel>
    </BtnLink>
  );
};

const SidebarButton = ({ children, isDesktop, ...props }) => {
  return (
    <Button isdesktop={isDesktop} {...props}>
      <SidebarButtonLabel isdesktop={isDesktop} {...props}>
        {children}
      </SidebarButtonLabel>
    </Button>
  );
};
export { SidebarButtonLink, SidebarButton };
