import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CssBaseline from '@material-ui/core/CssBaseline';
// Material helpers
import { ThemeProvider } from '@material-ui/styles';
import { theme } from 'styles';

import { withAuthentication } from 'sessions';
// import useWindowSize from 'hooks/useWindowSize';
import { screenResize } from 'store/actions/app';
import Routes from 'routes';

// import * as PATHS from 'routes/paths';
// import { Landing, Member } from 'routes';
import useWindowDimensions from 'hooks/useWindowSize';
// import useScript from 'hooks/useScript';
// import AnalyticsListener from 'modules/AnalyticsListener';

// import ScrollToTop from 'utils/ScrollToTop';

const App = () => {
  const dispatch = useDispatch();
  //const windowSize = useWindowSize();
  const windowDimensions = useWindowDimensions();
  console.log(`windowDimensions=> ${windowDimensions.width}`);
  dispatch(screenResize(windowDimensions));
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeProvider>
  );
};
export default withAuthentication(App);
