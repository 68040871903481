import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { withFirebase } from 'api/Firebase';
import { withRouter } from 'react-router-dom';
import { mediaMinDevice } from 'styles/responsive';
import * as _ from 'lodash';
import styled from 'styled-components/macro';
import { colors } from 'styles/default';

import {
  HeaderArea,
  CopyArea,
  Title,
  Description,
  ContentRow,
  ContentArea,
} from 'elements/WorkzoneText';

import { activitySet } from 'store/actions/activity';
import ActivityCard from 'components/ActivityCard';
import * as PATHS from 'routes/paths';
import WorkzoneCard, { WorkzoneCardContent } from 'components/WorkzoneCard';
import Loading from 'components/Loading';
// const WorkzoneCardContainerHolder = styled(WorkzoneCardContainer`
// flex;
// `;

const Divider = styled.div`
  width: 100%;
  height: 4px;
  background-color: ${colors.workzoneProject.dividerColor};
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  ${mediaMinDevice.deviceS} {
    justify-content: space-between;
  }
`;

const CardHolder = styled.div`
  flex: 0 0;
`;
const CardWrapperTop = styled(CardHolder)`
  margin-bottom: 2rem;
`;
const CardWrapperBottom = styled(CardHolder)`
  margin-top: 2rem;
  margin-bottom: 0;
`;
const WorkzoneProject = ({ firebase, history }) => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.sessionState.authUser.uid);
  const projectId = useSelector(state => state.projectState.projectId);
  const resultId = useSelector(state => state.projectState.resultId);
  //const projectData = useSelector(state => state.projectState.data);
  const [project, setProject] = useState();
  const [isProjectComplete, setIsProjectComplete] = useState(false);

  const mainProjectDocRef = useRef(
    firebase.fstore.collection('projects').doc(projectId)
  );
  const memberProjectDocRef = useRef(
    firebase.fstore
      .collection('members')
      .doc(userId)
      .collection('projects')
      .doc(projectId)
  );
  useEffect(() => {
    const getMainProjectData = async () => {
      const pdataRef = mainProjectDocRef.current;
      const pdata = await pdataRef.get();

      setProject(pdata.data());
      const mProjectRef = memberProjectDocRef.current;
      const mProject = await mProjectRef.get();
      const mProjectData = mProject.data();

      if (mProjectData.status === 'complete') {
        setIsProjectComplete(true);
      } else {
        setIsProjectComplete(false);
      }
    };
    getMainProjectData();
  }, [mainProjectDocRef, memberProjectDocRef]);
  const [memberActivitys, setMemberActivitys] = useState({});
  const [docState, setDocState] = useState({
    isLoading: true,
    activitysDone: false,
    activityComponents: null,
    resultComponents: null,
    projectDone: false,
  });

  useMemo(() => {
    const fetchCollection = async () => {
      const mainProjectDocCurrentColl = mainProjectDocRef.current.collection(
        'activitys'
      );
      const mainQuerySnapshot = await mainProjectDocCurrentColl
        .orderBy('order')
        .get();
      const mainActDocs = mainQuerySnapshot.docs.map(function(
        documentSnapshot
      ) {
        return documentSnapshot.data();
      });

      const resultComponents = mainActDocs.filter(
        act => act.type === 'results'
      );
      //console.log(`resultComponents=> ${JSON.stringify(resultComponents)}`);
      const activityComponents = mainActDocs.filter(
        act => act.type !== 'results'
      );

      const activityColRef = memberProjectDocRef.current.collection(
        'activitys'
      );
      const querySnapshot = await activityColRef.get();
      const memberActDocs = querySnapshot.docs.map(function(documentSnapshot) {
        return documentSnapshot.data();
      });

      const incompleteActIndex = _.findIndex(memberActDocs, function(act) {
        return act.type !== 'results' && act.completed === false;
      });
      const resultsReady = incompleteActIndex === -1;

      const memberActivityStatus = memberActDocs.reduce((obj, item) => {
        obj[item.uid] = item;
        return obj;
      }, {});
      // console.log(
      //   `memberActivityStatus+++ ${JSON.stringify(memberActivityStatus)}`
      // );
      setMemberActivitys(memberActivityStatus);

      // console.log(`dataLength=> ${docs.length}`);

      //const resultComponents = docs.filter(act => act.type === 'results');
      //console.log(`resultComponents=> ${JSON.stringify(resultComponents)}`);
      //const activityComponents = docs.filter(act => act.type !== 'results');
      //console.log(`activityComponents=> ${JSON.stringify(activityComponents)}`);
      // const actProgress = activityComponents.map(item => {
      //   const { uid, name, completed } = item;
      //   return { uid, name, completed };
      // });
      // console.log(`actProgress+++ ${JSON.stringify(actProgress)}`);
      // const incompleteActIndex = _.findIndex(activityComponents, function(act) {
      //   return act.completed === false;
      // });
      // const resultsReady = incompleteActIndex === -1;

      setDocState({
        isLoading: false,
        activitysDone: resultsReady,
        resultComponents,
        activityComponents,
      });
    };
    if (!isProjectComplete) {
      fetchCollection();
    }
  }, [memberProjectDocRef, isProjectComplete]);

  const handleActChange = (actid, actData) => {
    console.log(
      `handleActChange__actid->${actid} name=>${actData.name} type=${actData.type} resultId => ${resultId}`
    );

    const { name, type } = actData;

    const newPath =
      actData.type === 'results'
        ? PATHS.WORKZONE_PROJECT_RESULTS
        : PATHS.WORKZONE_PROJECT_ACTIVITY;

    dispatch(activitySet({ actid, type, name })).then(() => {
      history.push(newPath);
    });

    // if (actData.type === 'results') {
    //   history.push(PATHS.WORKZONE_PROJECT_RESULTS);
    // } else {
    //   dispatch(setActivity(actid)).then(() => {
    //     history.push(PATHS.WORKZONE_PROJECT_ACTIVITY);
    //   });
    // }
  };

  if (!projectId) {
    return <Redirect to={PATHS.MEMBER_WORKZONES} />;
  }

  if (isProjectComplete) {
    return <Redirect to={PATHS.WORKZONE_PROJECT_RESULTS_BUILDER} />;
  }
  if (!project) {
    return (
      <WorkzoneCard>
        <WorkzoneCardContent item>
          <Loading />
        </WorkzoneCardContent>
      </WorkzoneCard>
    );
  }
  return (
    <WorkzoneCard>
      <WorkzoneCardContent item>
        <HeaderArea>
          <Title>{project.title}</Title>
        </HeaderArea>
        <CopyArea>
          <Description
            dangerouslySetInnerHTML={{
              __html: project.directions,
            }}
          />
        </CopyArea>
        <ContentArea>
          {docState.isLoading ? (
            <Loading />
          ) : (
            <ContentRow container direction="column">
              <CardContainer>
                {docState.activityComponents.map(doc => (
                  <CardWrapperTop key={doc.uid}>
                    <ActivityCard
                      id={doc.uid}
                      data={doc}
                      memberData={memberActivitys[doc.uid]}
                      cardType={doc.type}
                      isActive={true}
                      onHandleClick={handleActChange}
                    />
                  </CardWrapperTop>
                ))}
              </CardContainer>

              <Divider />

              <CardContainer>
                {docState.resultComponents.map(doc => (
                  <CardWrapperBottom key={doc.uid}>
                    <ActivityCard
                      id={doc.uid}
                      data={doc}
                      memberData={memberActivitys[doc.uid]}
                      cardType="results"
                      isActive={docState.activitysDone}
                      onHandleClick={handleActChange}
                    />
                  </CardWrapperBottom>
                ))}
              </CardContainer>
            </ContentRow>
          )}
        </ContentArea>
      </WorkzoneCardContent>
      {/* {activityId && (
        <Activity id={activityId} collRef={collRef} outputs={outputs} />
      )} */}
    </WorkzoneCard>
  );
};

export default withRouter(withFirebase(WorkzoneProject));
