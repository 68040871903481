import { GET_PRODUCT_LIST } from 'store/actions/types';

const INITIAL_STATE = {
  products: [],
};

const applyProducts = (state, action) => ({
  ...state,
  projects: action.products,
});

function productsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PRODUCT_LIST: {
      return applyProducts(state, action);
    }

    default:
      return state;
  }
}

export default productsReducer;
