import React, { useState, useEffect, useCallback } from 'react';
// import styled from 'styled-components/macro';
import { Grid } from '@material-ui/core';

import FormField from './FormField';

// const QuizContainer = styled.div`
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
// `;
// const FormNavigation = styled.div`
//   justify-content: center;
//   display: flex;
//   flex-direction: column;
//   flex: 0 0 auto;
// `;
// const QuizContainer = styled.div`
//   overflow: hidden;
//   /* justify-content: center; */
//   display: flex;
//   flex-direction: column;
//   margin-top: 1rem;
//   width: 800px;
//   flex: 1 0 auto;
// `;

const BuzzQuizForm = ({ inputs, values, handleChange, handleSave }) => {
  const [step, setStep] = useState(1);
  const [localVars, setLocalVars] = useState();
  const [currentObject, setCurrentObject] = useState();
  const [currentInitialValue, setCurrentInitialValue] = useState();
  useEffect(() => {
    // console.log(`localVars = ${JSON.stringify(localVars)}`);
    // console.log(`step ==== ${step}`);
    const currentObjectArray = inputs.filter(
      item => parseInt(item.order) === step
    );
    // console.log(`currentObjectArray ==== ${currentObjectArray.length}`);
    let currentObject;

    if (currentObjectArray.length > 1) {
      const cbranch = localVars['branch']
        ? localVars['branch'].value
        : 'Consumers';
      //console.log(`CBRANCH ==== ${cbranch}`);
      currentObject = currentObjectArray.find(
        item => item.branch.toLowerCase() === cbranch.toLowerCase()
      );
    } else if (currentObjectArray.length === 1) {
      currentObject = currentObjectArray[0];
    } else {
      currentObject = {
        branch: 'end',
        type: 'lastSlide',
        label: 'ERRRRROORROOROROROROR',
        activityOutputType: 'lastSlide',
      };
    }
    //console.log(`branch.id = ${currentObject.branch}`);
    //console.log(`activityOutputType = ${currentObject.activityOutputType}`);
    let initVal;
    if (currentObject.type === 'response') {
      //console.log(`Type = ${currentObject.type}`);
      //console.log(`id = ${currentObject.id}`);
      initVal = localVars ? localVars[currentObject.id].value : 'missing';
    } else {
      initVal = values[currentObject.id] ? values[currentObject.id].value : '';
    }

    //console.log(`initVal = ${JSON.stringify(initVal)}`);
    // window.scrollTo({
    //   behavior: 'smooth',
    //   top: 0,
    // });
    //window.scrollTo(0, 0);
    setCurrentObject(currentObject);
    setCurrentInitialValue(initVal);
  }, [inputs, localVars, step, values]);

  const triggerNext = e => {
    //console.log(`trigger currentObject.id ${currentObject.id}`);
    if (currentObject.id === 'lastSlide') {
      handleSave(e);
    } else {
      forwardAnimate();
    }
  };
  const triggerPrevious = e => {
    //console.log(`trigger currentObject.id ${currentObject.id}`);

    backAnimate();
  };
  const forwardAnimate = useCallback(() => {
    const nextStep = step + 1;
    setStep(nextStep);
  }, [step]);
  const backAnimate = useCallback(() => {
    const nextStep = step - 1;
    setStep(nextStep);
  }, [step]);

  const handleLocalField = obj => {
    //const { activityType, inputId, inputName, outputType, uid } = doc;
    // console.log(`handleLocalField_____ ${JSON.stringify(obj)}`);
    setLocalVars({
      ...localVars,
      ...obj,
    });
  };
  return (
    <Grid item xs={12}>
      {/* <FormNavigation>
          <IconButton disabled={step === 1} onClick={backAnimate}>
            <Icon path={mdiChevronLeft} />
          </IconButton>
        </FormNavigation> */}
      {/* <FormContainer> */}
      {currentObject && (
        <FormField
          step={step}
          animate={true}
          doc={currentObject}
          type={currentObject.type}
          initialValue={currentInitialValue}
          localVars={localVars}
          handleLocalFieldChange={handleLocalField}
          handleFieldChange={handleChange}
          handleTriggerPrevious={triggerPrevious}
          handleTriggerNext={triggerNext}
        />
      )}

      {/* {inputs.map((doc, index) => (
          <FormField
            animate={index === 0}
            key={doc.uid}
            doc={doc}
            initialValue={values[doc.id] ? values[doc.id].value : ''}
            handleFieldChange={handleChange}
          />
        ))} */}
      {/* </FormContainer> */}
      {/* <FormNavigation>
          <IconButton onClick={forwardAnimate}>
            <Icon path={mdiChevronRight} />
          </IconButton>
        </FormNavigation> */}

      {/* <button onClick={doAnimate}>Animate</button> */}
    </Grid>
  );
};

export default BuzzQuizForm;
