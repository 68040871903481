import styled from 'styled-components/macro';
import { breakpoints, workzoneCard, colors } from 'styles/default';
import { Grid } from '@material-ui/core';
import { mediaMinDevice } from 'styles/responsive';

const Head1 = styled.h1`
  font-family: Montserrat;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.primary.dark};
  width: 100%;

  line-height: 1.2;
  font-size: 1.5rem;
`;

export const Title = styled(Head1)`
  text-transform: uppercase;
  margin: 0;
  ${mediaMinDevice.deviceS} {
    font-size: 1.7rem;
  }
  ${mediaMinDevice.deviceM} {
    font-size: 2rem;
  }
`;
export const ResultsHeader = styled.h1`
  font-family: Montserrat;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.primary.dark};
  width: 100%;
  text-transform: uppercase;
  margin: 0;
`;
export const Name = styled(Head1)`
  text-transform: uppercase;
  margin: 0;
  ${mediaMinDevice.deviceS} {
    font-size: 1.7rem;
  }
  ${mediaMinDevice.deviceM} {
    font-size: 2rem;
  }
`;
const Head3 = styled.h3`
  font-family: Montserrat;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  margin: 0;
  font-weight: normal;
  color: ${colors.primary.dark};
`;

export const Subtitle = styled(Head3)`
  font-size: 1.2rem;
  ${mediaMinDevice.deviceS} {
    font-size: 1.4rem;
  }
`;
const DescriptionText = styled.div`
  font-family: Montserrat;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.text.primary};
  font-size: 1rem;
  white-space: normal;
`;

export const Description = styled(DescriptionText)`
  & ul {
    margin-left: 0;
    padding-left: 3rem;
    /* margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 3rem; */
  }

  & ol {
    margin-left: 0;
    padding-left: 3rem;
    /* margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 3rem; */
  }
  & p {
    font-size: 1rem;
    line-height: 1.5;
    margin-top: 1rem;
  }
  & p:first-child {
    margin-top: 0;
  }
  &&& li {
    font-size: 1rem;
    line-height: 1.5;
  }
`;
export const Tips = styled(Description)`
  margin-top: 1rem;
`;

const HeadArea = styled.div`
  display: flex;
  box-sizing: border-box;
  flex-grow: 0;
  max-width: 100%;
  flex-basis: 100%;
  width: 100%;
  padding: 0;
  border: 0;
  position: relative;
`;
export const HeaderArea = styled(HeadArea)`
  flex-direction: column;
  margin: ${workzoneCard.headerArea.margin};
`;

export const CopyArea = styled(HeadArea)`
  flex-direction: column;
  margin: ${workzoneCard.copyArea.margin};
`;
export const ContentArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: ${workzoneCard.contentArea['xs'].padding};
  margin: ${workzoneCard.contentArea['xs'].margin};
  ${mediaMinDevice.deviceS} {
    margin: ${workzoneCard.contentArea['sm'].margin};
  }
  ${mediaMinDevice.deviceM} {
    margin: ${workzoneCard.contentArea['md'].margin};
  }
`;

export const FooterArea = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  margin: ${workzoneCard.footerArea.margin};
`;
export const ContentRow = styled(Grid)`
  > .MuiGrid-item:first-child {
    margin-top: 0;
    padding: 0;
    ${mediaMinDevice.deviceM} {
      padding: 0 1rem 0 0;
    }
    ${mediaMinDevice.deviceL} {
      padding: 0 2.5rem 0 0;
    }
  }
  > .MuiGrid-item:last-child {
    margin-top: 0;
    padding: 2rem 0 0 0;
    ${mediaMinDevice.deviceM} {
      padding: 0 0 0 1rem;
    }
    ${mediaMinDevice.deviceL} {
      padding: 0 0 0 2.5rem;
    }
  }
  > .MuiGrid-item:only-child {
    margin-top: 0;
    padding: 0;
  }
`;

export const ContentCol = styled(Grid)`
  > .MuiFormControl-root:first-child {
    margin-top: 0;
  }
`;

// const ActivityRow = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   box-sizing: border-box;
//   justify-content: space-between;
//   width: calc(100% + 5rem);
//   margin: 3rem -2.5rem 0 -2.5rem;

//   > .MuiGrid-item {
//     padding: 1rem 2.5rem 0 2.5rem;
//   }
// `;

export const ActivityActionArea = styled(HeadArea)`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 1.5rem 0 0 0;
`;

// const ActivityHeader = styled(Grid)`
//   margin-bottom: 30px;
// `;
//  const ActivityActionGridItem = styled(Grid)`
//   &&&& {
//     margin-top: 1rem;
//   }
// `;

// const ActivityGrid = styled(Grid)`
//   margin-bottom: ${props => (props.mb ? props.mb : '2rem')};
// `;
// const ResultsGrid = styled(Grid)``;

// const ResultsRow = styled.div`
//   display: flex;
//   width: 100%;
//   flex-direction: row;
//   justify-content: space-between;
//   align-items: flex-end;
//   margin: 0;
// `;

// const ContentContainer = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   box-sizing: border-box;
//   margin: 3rem 0 0 0;
//   width: 100%;
// `;

// const ResultsSubheader = styled.h3`
//   font-family: Montserrat;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1;

//   font-size: 21px;
//   font-weight: normal;
//   color: ${colors.primary.dark};
//   margin: 0.5rem 0;
// `;
// const ResultsDescription = styled.div`
//   font-family: Montserrat;
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   text-align: left;
//   color: ${colors.text.primary};
//   font-size: 16px;
//   & ul {
//     margin-block-start: 0.5em;
//     margin-block-end: 0.5em;
//     padding-inline-start: 40px;
//   }

//   & ol {
//     margin-block-start: 0.5em;
//     margin-block-end: 0.5em;
//     padding-inline-start: 40px;
//   }
//   & h3 {
//     margin: 0 0 21px 0;
//     font-size: 21px;
//     font-weight: normal;
//     color: ${colors.primary.dark};
//   }
//   & p {
//     font-size: 16px;
//     margin-top: 0.5rem;
//     margin-bottom: 0.5rem;
//     font-size: 16px;
//   }

//   &&& ul {
//     margin-top: 0;
//   }
//   &&& li {
//     font-size: 16px !important;
//   }
// `;

// const ActivityName = styled.h1`
//   font-family: Montserrat;
//   font-weight: bold;
//   font-style: normal;
//   font-stretch: normal;
//   letter-spacing: normal;
//   text-align: left;
//   color: ${colors.primary.dark};
//   width: 100%;
//   text-transform: uppercase;
//   margin: 0;
//   line-height: 1.2;
//   font-size: 1.5rem;
//   ${mediaMinDevice.deviceS} {
//     font-size: 1.7rem;
//   }
//   ${mediaMinDevice.deviceM} {
//     font-size: 2rem;
//   }
// `;
// const ActivitySubtitle = styled.h3`
//   font-family: Montserrat;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1;
//   margin: 0;
//   font-weight: normal;
//   color: ${colors.primary.dark};
//   font-size: 1.2rem;
//   ${mediaMinDevice.deviceS} {
//     font-size: 1.4rem;
//   }
// `;
// const ActivityDescription = styled.div`
//   font-family: Montserrat;
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.5;
//   letter-spacing: normal;
//   text-align: left;
//   color: ${colors.text.primary};
//   font-size: 1rem;
//   & ul {
//     margin-block-start: 0.5em;
//     margin-block-end: 0.5em;
//     padding-inline-start: 40px;
//   }

//   & ol {
//     margin-block-start: 0.5em;
//     margin-block-end: 0.5em;
//     padding-inline-start: 40px;
//   }
//   & h3 {
//     margin: 0 0 21px 0;
//     font-size: 21px;
//     font-weight: normal;
//     color: ${colors.primary.dark};
//   }
//   & p {
//     margin-top: 0.5rem;
//     margin-bottom: 0.5rem;
//     font-size: 16px;
//   }
//   &&& li {
//     font-size: 16px !important;
//   }
// `;
