import React from 'react';
import styled from 'styled-components/macro';

const BtnWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 15px 0 0;
  padding: 10px;
  border: none;
  outline: none;
`;

const SvgIconMinus = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g data-name="Layer 2">
      <g fill="#fff" data-name="Layer 1">
        <path stroke="none" d="M0 0h24v24H0z" />
        <path
          stroke="stroke"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={7.07}
          d="M4 12h16"
        />
      </g>
    </g>
  </svg>
);

const SvgIconPlus = props => (
  <svg viewBox="0 0 24 24" {...props}>
    <g data-name="Layer 2">
      <g fill="#fff" data-name="Layer 1">
        <path stroke="none" d="M0 0h24v24H0z" />
        <path
          stroke="stroke"
          strokeLinecap="round"
          strokeMiterlimit={10}
          strokeWidth={7.07}
          d="M4 12h16M12 4v16"
        />
      </g>
    </g>
  </svg>
);

const BtnPlusMinus = ({ handleClick, actionColor, isOpen }) => {
  return (
    <BtnWrapper onClick={handleClick}>
      {isOpen ? (
        <SvgIconMinus width="24px" height="24px" stroke={actionColor} />
      ) : (
        <SvgIconPlus width="24px" height="24px" stroke={actionColor} />
      )}
    </BtnWrapper>
  );
};

export default BtnPlusMinus;
