export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const RETURN_ACTION = '/action';
export const PASSWORD_FORGET = '/pw-forget';
export const MEMBER = '/member';
export const MEMBER_DETAILS = '/member/:id';
export const MEMBER_ACCOUNT = '/member/account';
export const MEMBER_STORE = '/member/marketplace';
export const MEMBER_MARKETPLACE = '/member/marketplace';
export const MEMBER_CHECKOUT = '/member/checkout';
export const MEMBER_WORKZONES = '/member/workzones';
export const MEMBER_WORKZONE_PROJECT = '/member/workzone/project';
export const MEMBER_WORKZONE_PROJECT_ACTIVITY =
  '/member/workzone/project/activity';
export const WORKZONE = '/workzone/project';
export const WORKZONE_PROJECT = '/workzone/project';
export const WORKZONE_PROJECT_ACTIVITY = '/workzone/project/activity';
export const WORKZONE_PROJECT_RESULTS = '/workzone/project/results';
export const WORKZONE_PROJECT_RESULTS_BUILDER =
  '/workzone/project/results-builder';
