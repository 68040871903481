import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { makeStyles } from '@material-ui/core/styles';

import FormControl from '@material-ui/core/FormControl';
// import { InputBase, NativeSelect } from '@material-ui/core';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// import MenuItem from '@material-ui/core/MenuItem';
import TextFieldBase from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
// import SelectBase from '@material-ui/core/Select';
import { colors } from 'styles/default';
import { FormHelperText } from '@material-ui/core';

import useCharMaxLimit from 'hooks/useCharMaxLimit';

import CharsMaxHelper from 'components/CharsMaxHelper';
// const FieldSelect = styled(NativeSelect)`
//   & .MuiInput-underline:after & {
//     border: none;
//   }
//   &&&& .MuiInput-underline:before {
//     content: none;
//   }
//   &&& .MuiNativeSelect-select:focus {
//     outline: none;
//     outline-offset: 0;
//     background-color: ${colors.textArea.focusBack};
//   }
// `;

const HelperText = styled(FormHelperText)`
  text-align: right;
  font-style: italic;
  color: #cdcdcd;
`;

const SimpleFieldSelectControl = styled(FormControl)`
  padding: 0;
  margin-top: 2rem;
`;
const SimpleFieldControl = styled(FormControl)`
  margin-top: 2rem;
`;
// const SelectInput = styled(InputBase)`
//   height: auto;
//   max-width: 100%;
//   font-family: 'Roboto';
//   border-radius: 12px;
//   border: solid 2.2px ${colors.primary.dark};
//   line-height: 1.2;
//   font-size: 1rem;
//   padding: 1rem;
//   &&&& .MuiInput-underline:before {
//     content: none;
//   }
// `;

const TextField = styled(TextFieldBase)`
  font-family: 'Roboto';

  border-radius: 12px;
  border: solid 2.2px ${colors.primary.dark};
  padding: 0;
  line-height: 1.2;
  font-size: 1rem;
  height: auto;
  max-width: 100%;
  resize: none;

  &:focus {
    outline: none;
    outline-offset: 0;
    background-color: ${colors.textArea.focusBack};
  }
`;
const Input = styled(TextareaAutosize)`
  font-family: 'Roboto';

  border-radius: 12px;
  border: solid 2.2px ${colors.primary.dark};
  padding: 1rem;
  line-height: 1.2;
  font-size: 1rem;
  height: auto;
  max-width: 100%;
  resize: none;
  &:focus {
    outline: none;
    outline-offset: 0;
    background-color: ${colors.textArea.focusBack};
  }
  &&& .MuiInput-underline:before {
    border-bottom: none;
  }

  && .MuiInput-underline:hover {
    border-bottom: none;
  }
  && .MuiInput-underline:after {
    border-bottom: none;
  }
`;
// const Select = styled.select`
//   font-family: 'Roboto';
//   border-radius: 12px;
//   border: solid 2.2px ${colors.primary.dark};
//   line-height: 1.2;
//   font-size: 1rem;
//   padding: 1rem;
//   &&&&& {
//     margin-top: 0;
//   }

//   &&& .MuiInput-underline:before {
//     border-bottom: none;
//   }

//   && .MuiInput-underline:hover {
//     border-bottom: none;
//   }
//   && .MuiInput-underline:after {
//     border-bottom: none;
//   }
// `;
const ILabel = styled.label`
  padding: 0 2rem 0.5rem 2rem;
  width: 100%;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.text.primary};
`;
// const TextField = styled(MuiTextField)`
//   &&&& {
//     padding-bottom: 10px;
//   }
// `;

const useStyles = makeStyles({
  outlinedRoot: {
    '&:hover $notchedOutline': {
      borderWidth: 0,
    },
    '&$focused $notchedOutline': {
      borderWidth: 0,
    },
  },
  notchedOutline: { borderWidth: 0 }, // a style rule
  focused: { borderWidth: 0 }, // a nested style rule
});
const SimpleTextField = ({
  doc,
  initialValue,
  inputValue,
  onChange,
  onBlur,
}) => {
  const {
    hasCharsMax,
    charsMaxWarnValue,
    chars,
    charsLeft,
    charsMax,
  } = useCharMaxLimit(inputValue, doc);

  const classes = useStyles();
  const InputProps = {
    classes: {
      root: classes.outlinedRoot,
      notchedOutline: classes.notchedOutline,
      focused: classes.focused,
    },
  };
  if (doc.type === 'select' || doc.type === 'select-imgs') {
    //console.log(`doc.examples=> ${doc.examples}`);
    const menuitems = JSON.parse(doc.examples);
    //console.log(`${doc.id} menuitems=> ${menuitems}`);
    return (
      <SimpleFieldSelectControl fullWidth>
        <ILabel htmlFor={doc.id}>{doc.label}</ILabel>
        <Autocomplete
          freeSolo
          disableClearable
          value={inputValue}
          onChange={onChange}
          onBlur={onBlur}
          id={doc.id}
          maxLength={charsMax ? charsMax : 100}
          options={menuitems.map(option => option.label)}
          renderInput={params => (
            <TextField
              {...params}
              variant="outlined"
              fullWidth
              InputProps={{
                ...params.InputProps,
                type: 'search',
                ...InputProps,
              }}
            />
          )}
        />
        {/* <FieldSelect
          value={inputValue}
          onChange={onChange}
          onBlur={onBlur}
          id={doc.id}
          input={<SelectInput />}
        >
          {menuitems.map((item, index) => (
            <option key={index} value={item.label}>
              {item.label}
            </option>
          ))}
        </FieldSelect> */}
      </SimpleFieldSelectControl>
    );
  }

  return (
    <>
      <SimpleFieldControl fullWidth>
        <ILabel htmlFor={doc.id}>{doc.label}</ILabel>
        <Input
          key={doc.uid}
          id={doc.id}
          value={inputValue ? inputValue : initialValue}
          onChange={onChange}
          rowsMax={doc.type === 'textarea' ? 4 : 2}
          rows={doc.type === 'textarea' ? 1 : 1}
          onBlur={onBlur}
          maxLength={charsMax ? charsMax : 100}
        />
        <CharsMaxHelper
          hasMax={hasCharsMax}
          max={charsMax}
          chars={chars}
          warnValue={charsMaxWarnValue}
        />
      </SimpleFieldControl>
    </>
  );
};

export default SimpleTextField;

//  <TextareaAutosize
//    key={doc.uid}
//    id={doc.id}
//    fullWidth
//    value={inputValue ? inputValue : initialValue}
//    onChange={onChange}
//    type={doc.type === 'select' ? 'select' : 'textarea'}
//    multiline={doc.type === 'textarea' ? true : true}
//    rowsMax={doc.type === 'textarea' ? 1 : 1}
//    rows={doc.type === 'textarea' ? 1 : 1}
//    onBlur={onBlur}
//  />;
