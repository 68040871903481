import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { withFirebase } from 'api/Firebase';
import {
  FormContent,
  FormHeader,
  FormSubheader,
  FormFieldControl,
  FormFieldErrorText,
  FormFieldInput,
  FormFieldLabel,
} from 'elements/FormElements';

import BtnMain from 'elements/BtnMain';

// import * as PATHS from 'routes/paths';
import LandingFormLinks from 'components/LandingFormLinks';
// const SignLinks = props => {
//   return (
//     <Grid container justify="space-between">
//       <Grid item>
//         <FormLinkButton component={BtnLink} to={PATHS.SIGN_IN} variant="body2">
//           Already have an account? Sign in.
//         </FormLinkButton>
//       </Grid>
//       <Grid item>
//         <FormLinkButton component={BtnLink} to={PATHS.SIGN_UP} variant="body2">
//           Don't have an account? Sign Up.
//         </FormLinkButton>
//       </Grid>
//     </Grid>
//   );
// };

const PasswordForget = props => {
  const [error, setError] = useState(null);
  const [email, setEmail] = useState('');
  const [sentEmail, setSentEmail] = useState(false);

  // useEffect(() => {
  //   const getChargesFun = firebase.funcs.httpsCallable('stripeGetCharges');
  //   const getCharges = async () => {
  //     const res = await getChargesFun();
  //     console.log(`charges=> ${JSON.stringify(res.data.data)}`);
  //     setCharges(res.data.data);
  //   };
  //   getCharges();
  // }, [firebase.funcs]);
  async function onSubmit(e) {
    e.preventDefault();
    const onPasswordReset = props.firebase.funcs.httpsCallable(
      'onPasswordReset'
    );
    try {
      console.log(`res=>>>>>>>>>>>>>>> ${email}`);

      const res = await onPasswordReset({ email: email });
      console.log(`res=>>>>>>>>>>>>>>> ${res}`);
      setSentEmail(true);
      // props.firebase
      //   .doPasswordReset(email)
      //   .then(() => {
      //     setSentEmail(true);
      //     // this.setState({ ...INITIAL_STATE });
      //   })
      //   .catch(error => {
      //     setError(error);
      //   });
    } catch (error) {
      setError(error);
      alert(error.message);
    }
  }
  return (
    <form noValidate>
      <FormContent>
        {sentEmail ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormHeader>Password Reset</FormHeader>
            </Grid>
            <Grid item xs={12}>
              <FormSubheader>Check your email inbox to continue.</FormSubheader>
            </Grid>
            <Grid item xs={12}>
              <LandingFormLinks showSignin={true} showSignup={true} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormHeader>Password Reset</FormHeader>
            </Grid>
            <Grid item xs={12}>
              <FormSubheader>Enter email to reset password.</FormSubheader>
            </Grid>

            <Grid item xs={12}>
              <FormFieldControl fullWidth>
                <FormFieldLabel>Email Address</FormFieldLabel>
                <FormFieldInput
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  error={error && error.code !== 'auth/wrong-password'}
                />
              </FormFieldControl>
              {error && error.code !== 'auth/wrong-password' && (
                <FormFieldErrorText>{error.message}</FormFieldErrorText>
              )}
            </Grid>
            <Grid item xs={12}>
              <BtnMain
                onClick={onSubmit}
                label="Send Reset Email"
                fullWidth
              ></BtnMain>
            </Grid>
            <Grid item xs={12}>
              <LandingFormLinks showSignin={true} showSignup={true} />
            </Grid>
          </Grid>
        )}
      </FormContent>
    </form>
  );
};

export default withRouter(withFirebase(PasswordForget));
