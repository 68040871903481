import React, { useEffect, useRef, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
// import { useSelector } from 'react-redux';
// import { compose } from 'redux';

import styled from 'styled-components/macro';
import { mediaMinDevice } from 'styles/responsive';
import { withAuthorization } from 'sessions';
import { withFirebase } from 'api/Firebase';

import * as PATHS from 'routes/paths';
import { colors } from 'styles/default';
import IconButton from '@material-ui/core/IconButton';

import { projectDataSet } from 'store/actions/projectPlatform';
import BtnBack from 'elements/BtnBack';
import BtnHelp from 'elements/BtnHelp';
import Icon from 'elements/Icon';
import { mdiClose } from '@mdi/js';
import {
  WorkzoneProject,
  WorkzoneProjectActivity,
  WorkzoneProjectResults,
  WorkzoneProjectResultsBuilder,
} from 'scenes/Workzone';
import DialogModal from 'components/DialogModal';
import useModal from 'hooks/useModal';
// const MainArea = styled.main`
//   position: relative;
//   width: 100vw;
//   height: 100vh;
//   overflow: hidden;
//   /* background-image: url('/imgs/header.png');
//   background-position: top; */
// `;

// const ContentWrapper = styled.div`
//   position: relative;
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   height: 100%;
//   background-color: ${colors.member.backColor};
//   z-index: 40;
//   overflow-y: auto;
// `;

const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  width: 100vw;

  background-color: ${colors.member.backColor};
  overflow-y: auto;
`;
const WorkzoneHeader = styled.div`
  position: fixed;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.primary.dark};
  flex: 0 0 auto;
  padding: 0.5rem 1rem;
  ${mediaMinDevice.deviceM} {
    padding: 1rem 1rem;
  }
  z-index: 98;
  box-shadow: 0px 5px 5px 0px #ccc;
`;
const Content = styled.div`
  border-radius: 0;
  margin-left: 0;
  background-color: ${colors.member.backColor};
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: 0;
  margin-top: 52px;
  ${mediaMinDevice.deviceM} {
    margin-top: 80px;
  }
`;

// const CloseIcon = styled(Icon)`
//   color: ${colors.common.white};
//   width: 1rem;
//   height: auto;
// `;
// const Msg = styled.div`
//   height: 4rem;
//   width: 100%;
//   font-size: 2rem;
// `;

// const MyCustomMessage = (id, message) => {
//   console.log(
//     `key => ${JSON.stringify(id)} message=${JSON.stringify(
//       id.message
//     )} message=${JSON.stringify(message)}}`
//   );
//   return <Msg>{id.message}</Msg>;
// };

// const MySnackBar = ({ id, message }) => {
//   return (
//     <SnackBarArea>
//       <SnackBarMessageArea>{message}</SnackBarMessageArea>
//       <SnackBarCloseArea>
//         <CloseBtn aria-label="close">
//           <CloseIcon path={mdiClose} size="1.8rem" color="#fff" />
//         </CloseBtn>
//       </SnackBarCloseArea>
//     </SnackBarArea>
//   );
// };

const Workzone = ({ firebase, history, location }) => {
  //console.log(`WORKZONEEEEEE`);
  const [hasHelp] = useState(false);

  const [itemModalOpen, setItemModalOpen, toggleModal] = useModal();
  const dispatch = useDispatch();
  const userId = useSelector(state => state.sessionState.authUser.uid);
  const projectId = useSelector(state => state.projectState.projectId);
  const projectStatus = useSelector(state =>
    state.projectState.data ? state.projectState.data.status : 'current'
  );
  const projectDocRef = useRef(
    firebase.fstore
      .collection('members')
      .doc(userId)
      .collection('projects')
      .doc(projectId)
  );

  const navBack = () => {
    //console.log(`location=${location.pathname}`);

    let path = '';

    switch (location.pathname) {
      case PATHS.WORKZONE_PROJECT:
        path = PATHS.MEMBER_WORKZONES;
        break;
      case PATHS.WORKZONE_PROJECT_ACTIVITY:
        path = PATHS.WORKZONE_PROJECT;

        break;
      case PATHS.WORKZONE_PROJECT_RESULTS:
        path = PATHS.WORKZONE_PROJECT;
        break;
      case PATHS.WORKZONE_PROJECT_RESULTS_BUILDER:
        if (projectStatus === 'complete') {
          path = PATHS.MEMBER_WORKZONES;
        } else {
          path = PATHS.WORKZONE_PROJECT_RESULTS;
        }

        break;
      default:
        path = '';
    }

    if (path === '') {
      history.goBack();
    } else {
      const location = {
        pathname: path,
        state: {},
      };
      history.push(location);
    }
  };
  // useEffect(() => {
  //   if (projectId) {
  //     dispatch(resetProject()).then(() => {
  //       console.log(`reset}`);
  //     });
  //   }
  // }, [dispatch, projectId]);

  useEffect(() => {
    const unsubscribe = projectDocRef.current.onSnapshot(function(doc) {
      const projectData = doc.data();
      //console.log(`projectStatus=> ${projectData.status}`);
      dispatch(projectDataSet(projectData)).then(data => {
        //console.log(`data => ${data.status}`);
      });
    });

    return () => unsubscribe();
  }, [dispatch, projectDocRef]);

  // add action to all snackbars
  const notistackRef = React.createRef();
  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  };
  return (
    <SnackbarProvider
      ref={notistackRef}
      hideIconVariant={true}
      action={key => (
        <IconButton onClick={onClickDismiss(key)}>
          <Icon path={mdiClose} color="#fff" />
        </IconButton>
      )}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <MainContent>
        <DialogModal
          title="Item Modal"
          isOpen={itemModalOpen}
          actionClose={() => setItemModalOpen(false)}
          actionCancel={() => {}}
          disableBackDrop={true}
        >
          gkgkgkgkg
        </DialogModal>
        <WorkzoneHeader>
          <BtnBack onClick={navBack} />
          {hasHelp && <BtnHelp onClick={toggleModal} />}

          {/* <Switch>
            <Route path={PATHS.MEMBER_MARKETPLACE} component={PopperCart} />
            <Route component={PopperEmpty} />
          </Switch> */}
        </WorkzoneHeader>
        <Content>
          <Switch>
            <Route
              exact
              path={PATHS.WORKZONE_PROJECT_RESULTS_BUILDER}
              component={WorkzoneProjectResultsBuilder}
            />

            <Route
              path={PATHS.WORKZONE_PROJECT_RESULTS}
              component={WorkzoneProjectResults}
            />
            <Route
              path={PATHS.WORKZONE_PROJECT_ACTIVITY}
              component={WorkzoneProjectActivity}
            />
            <Route path={PATHS.WORKZONE_PROJECT} component={WorkzoneProject} />
          </Switch>
        </Content>
      </MainContent>
    </SnackbarProvider>
  );
};

const condition = authUser => !!authUser;
export default withAuthorization(condition)(withFirebase(Workzone));
