import React from 'react';
import { LogoJumpStartArtboard } from 'components/SvgLogos';
import BtnSmall from 'elements/BtnSmall';
import { colors } from 'styles/default';
import CardSwiper from 'components/CardSwiper';
import { CardMarketplaceCard } from 'components/CardBacks';
import {
  ProductLogoArea,
  ProductCardItem,
  ProductCardBack,
  ProductCardContent,
  ProductCardContentArea,
  ProductCardHeader,
  ProductTitle,
  ProductPrice,
  ProductCardFooter,
  ProductCardSlot,
  ProductCardSlotImage,
} from 'elements/ProjectCardElements';
const ProductItem = ({
  inCart,
  item,
  inProjects,
  onAddToCartClicked,
  onCheckWorkzoneClicked,
  onCheckCart,
}) => {
  const isDisabled = item.status === 'pre' ? true : false;
  //console.log(`slides=> ${slides}`);

  return (
    <ProductCardItem>
      <ProductCardContent>
        <ProductCardHeader>
          <ProductLogoArea>
            <LogoJumpStartArtboard
              height="28px"
              width="200px"
              preserveAspectRatio="xMinYMin meet"
              secondaryclr={
                isDisabled
                  ? `${colors.logos.disabled.secondaryColor}`
                  : `${colors.logos.jumpstart.secondaryColor}`
              }
              primaryclr={
                isDisabled
                  ? `${colors.logos.disabled.primaryColor}`
                  : `${colors.logos.jumpstart.primaryColor}`
              }
            />
          </ProductLogoArea>

          <ProductTitle disabled={isDisabled}>{item.name}</ProductTitle>
          <ProductPrice disabled={isDisabled}>${item.price / 100}</ProductPrice>
        </ProductCardHeader>
        {item.status === 'pre' ? (
          <ProductCardSlot>
            <ProductCardSlotImage>
              <img
                src={require(`assets/marketplace/storeCard99.png`)}
                alt="Coming Soons"
              />
            </ProductCardSlotImage>
          </ProductCardSlot>
        ) : (
          <ProductCardContentArea>
            {item.marketSlides && (
              <CardSwiper
                isDisabled={isDisabled}
                text={item.marketText}
                slides={item.marketSlides}
              />
            )}
          </ProductCardContentArea>
        )}

        {/* <ProductCardContent>
        <div dangerouslySetInnerHTML={{ __html: item.storeDescription }} />
      </ProductCardContent> */}
        <ProductCardFooter>
          {inCart && (
            <BtnSmall
              label="Check Cart"
              textColor={colors.product.btnTextColor}
              fillColor={colors.product.btnBackColor}
              onClick={onCheckCart}
            />
          )}
          {!inCart && inProjects && (
            <BtnSmall
              label="Check Work Zone"
              textColor={colors.product.btnTextColor}
              fillColor={colors.product.btnBackColor}
              onClick={onCheckWorkzoneClicked}
            />
          )}
          {!inCart && !inProjects && item.status === 'pre' && (
            <BtnSmall
              label="Coming Soon"
              textColor="#000000"
              fillColor="#cccccc"
              disabled
            />
          )}
          {!inCart && !inProjects && item.status !== 'pre' && (
            <BtnSmall
              onClick={onAddToCartClicked}
              label="Add to Cart"
              textColor={colors.product.btnTextColor}
              fillColor={colors.product.btnBackColor}
            />
          )}
        </ProductCardFooter>
      </ProductCardContent>
      <ProductCardBack isDisabled={isDisabled} />
    </ProductCardItem>
  );
};
export default ProductItem;
//       <Panel
//         size="reg"
//         width="270px"
//         height="370px"
//         fill={isDisabled ? '#bbbbbb' : '#76cbe2'}
//         available={!inProjects}
//       >
//         <ProductCardHeader>
//           <ProjectType>
//             <LogoJumpStartArtboard
//               height="80%"
//               width="80%"
//               secondary={isDisabled ? '#cccccc' : '#3b6fb4'}
//               primary={isDisabled ? '#000000' : '#1d4798'}
//             />
//           </ProjectType>
//           <ProjectTitle disabled={isDisabled}>{item.name}</ProjectTitle>
//           <ProjectPrice disabled={isDisabled}>${item.price / 100}</ProjectPrice>
//         </ProductCardHeader>
//         {item.status === 'pre' ? (
//           <ProductCardSlot>
//             <CardSlotImage>
//               <img
//                 src={require(`assets/marketplace/storeCard99.png`)}
//                 alt="Coming Soons"
//               />
//             </CardSlotImage>
//           </ProductCardSlot>
//         ) : (
//           <ProductCardContent>
//             {item.marketText && (
//               <ProjectCopy
//                 dangerouslySetInnerHTML={{
//                   __html: item.marketText,
//                 }}
//               />
//             )}
//             {item.marketSlides && (
//               <CardSwiper isDisabled={isDisabled} slides={item.marketSlides} />
//             )}
//           </ProductCardContent>
//         )}

//         {/* <ProductCardContent>
//         <div dangerouslySetInnerHTML={{ __html: item.storeDescription }} />
//       </ProductCardContent> */}
//         <ProductCardFooter>
//           {inCart && (
//             <BtnSmall
//               label="Check Cart"
//               textColor={colors.product.btnTextColor}
//               fillColor={colors.product.btnBackColor}
//               onClick={onCheckCart}
//             />
//           )}
//           {!inCart && inProjects && (
//             <BtnSmall
//               label="Check Work Zone"
//               textColor={colors.product.btnTextColor}
//               fillColor={colors.product.btnBackColor}
//               onClick={onCheckWorkzoneClicked}
//             />
//           )}
//           {!inCart && !inProjects && item.status === 'pre' && (
//             <BtnSmall
//               label="Coming Soon"
//               textColor="#000000"
//               fillColor="#cccccc"
//               disabled
//             />
//           )}
//           {!inCart && !inProjects && item.status !== 'pre' && (
//             <BtnSmall
//               onClick={onAddToCartClicked}
//               label="Add to Cart"
//               textColor={colors.product.btnTextColor}
//               fillColor={colors.product.btnBackColor}
//             />
//           )}
//         </ProductCardFooter>
//       </Panel>
//     </>
//   );
// };
// export default ProductItem;
