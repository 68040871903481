import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { styled } from '@material-ui/styles';
import styled, { css } from 'styled-components/macro';

import { Grid, Paper } from '@material-ui/core';
// import LogoStacked from 'elements/LogoStacked';
// import Login from 'components/Login';

import { mediaMinDevice } from 'styles/responsive';
import SignIn from './SignIn';
import SignUp from './SignUp';
import PasswordForget from './PasswordForget';
import ReturnAction from './ReturnAction';
import * as PATHS from 'routes/paths';

// import buildDate from 'utils/buildDate';

import preval from 'preval.macro';
import LogoCorner from 'assets/logos/logo-e.png';
import { LogoEa } from 'components/SvgLogos';
const buildDate = preval`module.exports = new Date().toISOString()`;

const MainContainer = styled(Grid)`
  height: 100vh;
  width: 100vw;
  background: transparent url('./imgs/backgroundpattern.png') no-repeat center
    center fixed;
  background-size: cover;
  position: relative;
`;

const MainArea = styled.main`
  background: transparent url('./imgs/backgroundpattern.png') no-repeat center
    center fixed;
  background-size: cover;
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const MainGrid = styled(Grid)`
  height: 100vh;
`;

const ImageGrid = styled(Grid)`
  background-image: url('./imgs/backgroundpattern.png');
  background-repeat: no-repeat;
  background-color: transparent;
  background-size: cover;
  background-position: center;
`;

// const QContainer = styled(Grid)`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;

//   background: transparent;
// `;

// const BoxShadow = css`
//   box-shadow: 14px 14px 11px 0px rgba(0, 0, 0, 0.2);
// `;
// const BoxShadowNone = css`
//   box-shadow: none;
// `;

const ColumnOpaque = styled(({ screenSize, ...other }) => <Grid {...other} />)`
  background: #ffffff;

  display: flex;
  flex-direction: column;

  align-items: center;
  box-shadow: none;
  ${mediaMinDevice.deviceM} {
    justify-content: space-between;
    box-shadow: 14px 14px 11px 0px rgba(0, 0, 0, 0.2);
  }
  ${'' /* ${props => (props.screenSize === 'xl' ? BoxShadow : BoxShadowNone)} */}
`;

const FormArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  align-items: center;
  box-shadow: none;
  ${mediaMinDevice.deviceM} {
    width: 50%;
    justify-content: space-between;
    box-shadow: 14px 14px 11px 0px rgba(0, 0, 0, 0.2);
  }
`;

// const ColumnOpaque = styled(Grid)`
//   background: #ffffff;
//   height: 100vh;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   ${props => (props.screenSize === 'xl' ? BoxShadow : BoxShadowNone)}
// `;
const BuildBox = styled.div`
  color: #cccccc;
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  text-align: right;
`;
const LandingFormTopRow = styled.div`
  display: flex;
  align-items: flex-start;

  justify-content: center;
  /* height: 100px; */
  /* padding: 1rem;
  ${mediaMinDevice.deviceM} {
    height: 200px;
    padding: 1.5rem;
  } */
`;

const LandingFormBottomRow = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  bottom: 0;
  /* min-height: 150px; */
`;

const Landing = () => {
  return (
    <MainGrid container component="main">
      <Grid
        item
        xs={12}
        sm={10}
        md={8}
        lg={6}
        component={Paper}
        elevation={6}
        square
      >
        <BuildBox>{buildDate}</BuildBox>

        <Switch>
          {/* <Route component={Members} path="/admin/members" /> */}
          <Route component={ReturnAction} path={PATHS.RETURN_ACTION} />
          <Route component={PasswordForget} path={PATHS.PASSWORD_FORGET} />
          <Route component={SignUp} path={PATHS.SIGN_UP} />
          <Route component={SignIn} />
          {/* <Route component={Error} /> */}
        </Switch>
      </Grid>
      <ImageGrid item xs={false} sm={2} md={4} lg={6} />
    </MainGrid>
  );
};
export default Landing;
// export default function Landing() {
//   return (
//     <MainArea>
//       <FormArea>
//         <BuildBox>{buildDate}</BuildBox>
//         <LandingFormTopRow>
//           <LogoEa width="100%" height="100%" />
//           {/* <img src={LogoCorner} alt="logo" /> */}
//         </LandingFormTopRow>
//         <Switch>
//           {/* <Route component={Members} path="/admin/members" /> */}
//           <Route component={ReturnAction} path={PATHS.RETURN_ACTION} />
//           <Route component={PasswordForget} path={PATHS.PASSWORD_FORGET} />
//           <Route component={SignUp} path={PATHS.SIGN_UP} />
//           <Route component={SignIn} />
//           {/* <Route component={Error} /> */}
//         </Switch>
//       </FormArea>
//     </MainArea>
//   );
// }
//   return (
//     <MainContainer
//       component="main"
//       container
//       direction="row"
//       justify="space-between"
//       alignItems="stretch"
//     >
//       <ColumnOpaque item xs={12} md={6}>
//         <BuildBox>{buildDate}</BuildBox>
//         <LandingFormTopRow>
//           <LogoEa width="100%" height="100%" />
//           {/* <img src={LogoCorner} alt="logo" /> */}
//         </LandingFormTopRow>
//         <Switch>
//           {/* <Route component={Members} path="/admin/members" /> */}
//           <Route component={ReturnAction} path={PATHS.RETURN_ACTION} />
//           <Route component={PasswordForget} path={PATHS.PASSWORD_FORGET} />
//           <Route component={SignUp} path={PATHS.SIGN_UP} />
//           <Route component={SignIn} />
//           {/* <Route component={Error} /> */}
//         </Switch>
//         {/* <LandingFormBottomRow>
//           <StickyBox>{buildDate}</StickyBox>
//         </LandingFormBottomRow> */}
//       </ColumnOpaque>

//       <Grid item xs={false} md={6}></Grid>
//     </MainContainer>
//   );
// }
