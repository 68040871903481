import React from 'react';

const EmailIconEnvelope = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 114 80" {...props}>
      <rect
        width="105.45"
        height="71.7"
        x="4"
        y="4"
        rx="14.57"
        fill="fill"
        stroke="stroke"
        strokeMiterlimit="10"
        strokeWidth="6"
      />
      <path
        d="M52 25.93L12.14 61.85a8.14 8.14 0 005.45 14.2H96.7a7.47 7.47 0 005-13L60.51 25.93a6.33 6.33 0 00-8.51 0z"
        fill="fill"
        stroke="stroke"
        strokeMiterlimit="10"
        strokeWidth="6"
      />
      <path
        d="M61.32 48.53l37.91-27.91a9 9 0 00-5.35-16.3H18.14a8.36 8.36 0 00-5 15.09l39.61 29.12a7.25 7.25 0 008.57 0z"
        fill="fill"
        stroke="stroke"
        strokeMiterlimit="10"
        strokeWidth="6"
      />
    </svg>
  );
};

export default EmailIconEnvelope;
