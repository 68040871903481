import React from 'react';
import styled from 'styled-components/macro';
import { PanelBackSmall, PanelBack } from './PanelBacks';
// fill="#76cbe2"
// const PanelWrapper = styled.div`
//   position: relative;
//   width: ${props => (props.width ? props.width : '270px')};
//   height: ${props => (props.height ? props.height : '366px')};
//   padding: 0;
//   margin: 0;
// `;
const PanelWrapper = styled.div`
  position: relative;
  width: ${props => (props.width ? props.width : '270px')};
  height: ${props => (props.height ? props.height : '366px')};
  padding: 0;
  margin: 0;
`;
const PanelBackground = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0;
  z-index: 10;
`;

const PanelForeground = styled.div`
  position: absolute;
  top: 10px;
  left: 28px;
  right: 28px;
  bottom: 22px;
  height: calc(${props => props.panelHeight} - 32px);
  width: calc(${props => props.panelWidth} - 56px);

  z-index: 20;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: center; */
  /* height: 178px;
  width: 215px; */
`;

const Panel = ({ size, width, height, fill = '#76cbe2', children }) => (
  <PanelWrapper width={width} height={height}>
    <PanelForeground panelWidth={width} panelHeight={height}>
      {children}
    </PanelForeground>
    <PanelBackground width={width} height={height}>
      {size === 'small' ? (
        <PanelBackSmall fill={fill} />
      ) : (
        <PanelBack fill={fill} />
      )}
    </PanelBackground>
  </PanelWrapper>
);

export default Panel;
