import React from 'react';
import { Link as BtnLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { FormLinkButton } from 'elements/FormElements';
import * as PATHS from 'routes/paths';

const LandingFormLinks = ({
  showSignin = false,
  showSignup = false,
  showPasswordForget = false,
}) => {
  return (
    <Grid container justify="space-between">
      {showSignin && (
        <Grid item>
          <FormLinkButton
            component={BtnLink}
            to={PATHS.SIGN_IN}
            variant="body2"
          >
            Already have an account? Sign in.
          </FormLinkButton>
        </Grid>
      )}

      {showPasswordForget && (
        <Grid item>
          <FormLinkButton
            component={BtnLink}
            to={PATHS.PASSWORD_FORGET}
            variant="body2"
          >
            Forgot password?
          </FormLinkButton>
        </Grid>
      )}

      {showSignup && (
        <Grid item>
          <FormLinkButton
            component={BtnLink}
            to={PATHS.SIGN_UP}
            variant="body2"
          >
            Don't have an account? Sign Up.
          </FormLinkButton>
        </Grid>
      )}
    </Grid>
  );
};

export default LandingFormLinks;
