import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'styles/default';

// const TextBase = styled.div`
//   display: flex;
//   justify-content: flex-end;
//   padding: 0 12px;
//   border-bottom: 1px solid #dadde1;
// `;

// const Cta = styled.button`
//   flex: 0 0 auto;
//   margin-left: 10px;
//   color: #4b4f56;
//   font-family: inherit;
//   line-height: 26px;
//   padding: 0 10px;
//   transition: 200ms cubic-bezier(0.08, 0.52, 0.52, 1) background-color,
//     200ms cubic-bezier(0.08, 0.52, 0.52, 1) box-shadow,
//     200ms cubic-bezier(0.08, 0.52, 0.52, 1) transform;
//   border: 1px solid;
//   border-radius: 2px;
//   box-sizing: content-box;
//   font-size: 12px;
//   font-weight: bold;
//   justify-content: center;
//   padding: 0 8px;
//   position: relative;
//   text-align: center;
//   text-shadow: none;
//   vertical-align: middle;
//   :hover {
//     background-color: #eff1f3;
//     border-color: #bec3c9;
//     background-color: #f5f6f7;
//     border-color: #ccd0d5;
//     color: #4b4f56;
//   }
// `;
// const ContentTextD = styled.div`
//   font-family: Montserrat;
//   & > p {
//     font-size: 16px;
//     font-weight: normal;
//     font-style: normal;
//     font-stretch: normal;
//     line-height: 1.2;
//     letter-spacing: normal;
//     text-align: left;
//     color: ${colors.common.black};
//   }

//   & span.insertSpan {
//     background: ${colors.template.highlightBlue};
//     color: #000000;
//   }
// `;
const ContentDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 12px;
`;

const ContentP = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.common.black};
  color: ${colors.common.black};
  & span.insertSpan {
    background: ${colors.template.highlightBlue};
    color: #000000;
  }
`;

// const ContentText = styled.div`
//   flex: 1 1 auto;
//   span.outputSpan {
//     background: ${highlightYellow};
//     color: #000000;
//   }
//   span.insertSpan {
//     background: ${highlightBlue};
//     color: #000000;
//   }
// `;

const ContentText = ({ text }) => {
  return (
    <ContentDiv>
      <ContentP
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </ContentDiv>
  );
};

export default ContentText;
