import React from 'react';

const SvgComponent = props => (
  <svg viewBox="0 0 10.5 10.5" width="1em" height="1em" {...props}>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <rect x={0.5} y={0.5} width={9.5} height={9.5} rx={1.45} fill="#fff" />
        <path d="M8.55 1a.94.94 0 01.95 1v6.6a.94.94 0 01-.95.95H2a.94.94 0 01-1-1V2a.94.94 0 011-1h6.6m0-1H2a2 2 0 00-2 2v6.6a2 2 0 002 1.9h6.6a2 2 0 001.9-1.95V2a2 2 0 00-1.95-2z" />
        <path d="M5.1 5.83a1.65 1.65 0 01.32-.5c.13-.14.3-.3.51-.49a3.76 3.76 0 00.57-.58 1 1 0 00.19-.6.9.9 0 00-.37-.76 1.66 1.66 0 00-1-.29 2.12 2.12 0 00-1.76.77l-.71-.5a2.65 2.65 0 011.05-.81 3.67 3.67 0 011.49-.28 2.76 2.76 0 011.69.46 1.49 1.49 0 01.63 1.27 1.75 1.75 0 01-.13.7 2.12 2.12 0 01-.32.51 8.3 8.3 0 01-.5.48 3.58 3.58 0 00-.59.63 1.13 1.13 0 00-.17.67H5a1.59 1.59 0 01.1-.68zM5 8.53a.63.63 0 010-.88.62.62 0 01.46-.18.61.61 0 01.45.18.64.64 0 01.17.44.61.61 0 01-.62.62.62.62 0 01-.46-.18z" />
      </g>
    </g>
  </svg>
);

export default SvgComponent;
