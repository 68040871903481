import React from 'react';
import styled, { css } from 'styled-components/macro';
// import SquareBottomLeft from './shapes/SquareBottomLeft';
import BottomLeftCorner from './shapes/BottomLeftCorner';
import SideBox from './shapes/SideBox';
import CornerBox from './shapes/CornerBox';
import RoundCornerTopRight from './shapes/RoundCornerTopRight';
import BottomLeftRound from './shapes/BottomLeftRound';
// import useDimensions from 'react-use-dimensions';

const Wrapper = styled.div`
  position: relative;
  height: auto;
  width: auto;
  margin: 0;
`;
const BottomLeft = styled.div`
  position: absolute;
  top: ${props => props.y};
  z-index: -10;
  transition: top 0.5s ease;
`;
const BottomRight = styled.div`
  position: absolute;
  top: ${props => props.y};
  right: 0;
  z-index: -10;
  transition: top 0.5s ease;
`;
const TopRight = styled.div`
  position: absolute;
  right: 0;
  top: -15px;
  z-index: -11;
  & > svg {
    transition: height 0.5s ease;
  }
`;
const SideLeft = styled.div`
  position: absolute;
  left: 0;
  top: 60px;
  height: ${props => (props.height ? `${props.height - 100}px` : '200px')};
  & > svg {
    transition: height 0.5s ease;
  }

  z-index: -12;
`;
const SideRight = styled.div`
  position: absolute;
  right: 0;
  top: 60px;
  height: ${props => (props.height ? `${props.height - 100}px` : '200px')};
  & > svg {
    transition: height 0.5s ease;
  }
  z-index: -13;
`;
const ChildArea = styled.div`
  position: relative;
  margin: 15px 15px 15px 0;
  ${props =>
    props.type === 'rssr' &&
    css`
      margin: 0 0.5rem 0 0;
    `};
  ${props =>
    props.type === 'srrs' &&
    css`
      margin: 0 0 0 0.5rem;
    `};
`;

const CardWrapper = ({ type, fill, contentHeight, children }) => {
  // const [ref, { height }] = useDimensions();

  // console.log(`height=====${height}`);

  // console.log(`Contentheight=====${contentHeight}`);
  return (
    <Wrapper type={type}>
      <ChildArea type={type}>{children}</ChildArea>
      {type === 'side-left' && (
        <SideLeft y={25} width="200px" height={contentHeight}>
          <SideBox width="200px" height={contentHeight} fill={fill} />
        </SideLeft>
      )}
      {type === 'side-right' && (
        <SideRight y={25} width="200px" height={contentHeight}>
          <SideBox width="200px" height={contentHeight} fill={fill} />
        </SideRight>
      )}
      {type === 'top-right-round' && (
        <TopRight
          x={0}
          y={0}
          width="200px"
          height={contentHeight < 155 ? '100px' : '200px'}
        >
          <RoundCornerTopRight
            width="200px"
            height={contentHeight < 155 ? '100px' : '200px'}
            fill={fill}
          />
        </TopRight>
      )}
      {type === 'bottom-left-angle' && (
        <BottomLeft
          y={contentHeight > 0 ? `${contentHeight - 24}px` : '30px'}
          width="250px"
          height={contentHeight > 0 ? '152px' : '100px'}
        >
          <BottomLeftCorner
            width="250px"
            height={contentHeight > 0 ? '152px' : '100px'}
            fill={fill}
          />
        </BottomLeft>
      )}

      {type === 'bottom-left-round' && (
        <BottomLeft
          y={contentHeight > 0 ? `${contentHeight - 50}px` : '30px'}
          width="254px"
          height={contentHeight > 0 ? '180px' : '100px'}
        >
          <BottomLeftRound
            width="200px"
            height={contentHeight < 155 ? '100px' : '180px'}
            fill={fill}
          />
        </BottomLeft>
      )}
      {type === 'bottom-right-square' && (
        <BottomRight
          y={contentHeight > 0 ? `${contentHeight - 50}px` : '30px'}
          width="254px"
          height={contentHeight > 0 ? '180px' : '100px'}
        >
          <CornerBox
            width="200px"
            height={contentHeight < 155 ? '100px' : '180px'}
            fill={fill}
          />
        </BottomRight>
      )}
    </Wrapper>
  );
};

export default CardWrapper;
