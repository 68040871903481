import React from 'react';
import styled from 'styled-components/macro';
import Loading from 'components/Loading';
const CardLoaderArea = styled.div`
  &&& {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 200px;
  }
`;
const LoaderArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const CardLoader = () => {
  return (
    <CardLoaderArea>
      <LoaderArea>
        <Loading />
      </LoaderArea>
    </CardLoaderArea>
  );
};
export default CardLoader;
