import React from 'react';
import styled from 'styled-components/macro';
import { FormHelperText } from '@material-ui/core';
const CharsMaxTextArea = styled.div`
  position: absolute;
  bottom: -1rem;
  right: 0;
  > p {
    color: ${props => (props.warn ? '#ff0000' : '#cdcdcd')};
  }
`;

const CharsMaxText = styled(FormHelperText)`
  text-align: right;
  font-style: italic;
`;
const CharsMaxHelper = ({ hasMax, max, chars, warnValue }) => {
  if (!hasMax) {
    return null;
  }

  return (
    <CharsMaxTextArea warn={chars >= max ? true : false}>
      {chars >= warnValue && (
        <CharsMaxText>
          {chars} / {max}
        </CharsMaxText>
      )}
    </CharsMaxTextArea>
  );
};

export default CharsMaxHelper;
