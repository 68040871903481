import React from 'react';
import styled from 'styled-components/macro';

const MediaCaption = styled.div``;

const MediaCaptionText = styled.div`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
`;

const Img = styled.img`
  max-width: 100%;
  object-fit: cover; /*magic*/
  /* object-fit: scale-down; magic */
  /* border-bottom: 1px solid #dddfe2; */
`;

const MediaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0 0.5rem 0 0;
  /* padding: 2rem;
  min-height: 220px; */
`;

const Media = styled.div`
  width: 50px;
  height: ${props => (props.height ? props.height : '100px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentLabelMedia = ({ media }) => {
  console.log(`media ${media}`);
  return (
    <MediaWrapper>
      {media.type === 'placeholder' ? (
        <MediaCaption>
          <MediaCaptionText>{media.caption}</MediaCaptionText>
        </MediaCaption>
      ) : (
        <Media height={media.height}>
          <Img src={media.fileurl} alt={media.caption} />
        </Media>
      )}
    </MediaWrapper>
  );
};
export default ContentLabelMedia;
