import React, { useState } from 'react';
import { Checkbox, Grid } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import firebase from "api/firebase";
import { LogoEa } from 'components/SvgLogos';
import {
  FormElement,
  FormContent,
  FormHeader,
  FormSubheader,
  FormFieldControl,
  FormFieldErrorText,
  FormFieldInput,
  FormFieldLabel,
  FormLogoHeader,
} from 'elements/FormElements';

import BtnMain from 'elements/BtnMain';
import LandingFormLinks from 'components/LandingFormLinks';

import { withFirebase } from 'api/Firebase';
import * as PATHS from 'routes/paths';

const ERROR_CODE_ACCOUNT_EXISTS = 'auth/email-already-in-use';

const TERMS_LINK =
  'https://excelerateamerica-3585258.hs-sites.com/exceleratebiz-blog-privacy-policy/terms-and-conditions-0';
const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this E-Mail address already exists.
  Try to login with this account instead. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

const SignUp = props => {
  const screenSize = useSelector(state => state.app.screenSize);
  const [errors, setErrors] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [company, setCompany] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [checkboxes, setCheckboxes] = useState({ isChecked: false });
  //const firebase = useContext(FirebaseContext);
  // const onSubmit = e => {
  //   e.preventDefault();
  //   props.firebase
  //     .doCreateMemberWithEmailAndPassword(email, password)
  //     .then(user => {
  //       console.log(`${user}`);
  //     })
  //     .catch(errors => {
  //       setErrors(errors);
  //     });
  // };
  const handleChange = name => event => {
    setCheckboxes({ ...checkboxes, [name]: event.target.checked });
  };
  async function onRegister(e) {
    // const { username, email, passwordOne, isAdmin } = this.state;
    // const roles = {};
    e.preventDefault();
    const initials = `${firstName.charAt(0).toUpperCase()}${lastName
      .charAt(0)
      .toUpperCase()}`;
    const dateRegister = new Date();

    try {
      const userCred = await props.firebase.doCreateUserWithEmailAndPassword(
        email,
        password
      );

      const { user } = userCred;
      await props.firebase
        .member(user.uid)
        .set({
          firstName,
          lastName,
          email,
          company,
          initials,
          dateRegister,
          showJoyride: true,
          uid: user.uid,
        })
        .then(() => {
          // props.firebase.setUserId(user.uid);
          props.firebase.sendAnalyticsLogEvent('sign_up', { method: 'email' });
          props.history.push(PATHS.MEMBER);
        })
        .catch(error => {
          if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
            error.message = ERROR_MSG_ACCOUNT_EXISTS;
          }
          setErrors(error);
        });
    } catch (error) {
      setErrors(error);
      alert(error.message);
    }
  }

  // .then(authUser => {
  //   // Create a user in your Firebase realtime database
  //   return props.firebase.member(authUser.user.uid).set({
  //     firstName,
  //     lastName,
  //     email,
  //     company,
  //     initials,
  //     dateRegister,
  //     uid: authUser.user.uid,
  //   });
  // })
  // .then(authUser => {
  //   // Create a user in your Firebase realtime database
  //   return props.firebase.user(authUser.user.uid).set({
  //     firstName,
  //     lastName,
  //     email,
  //     companyName,
  //     initials,
  //     dateRegister,
  //   });
  // })
  //       .then((authUser) => {
  //         //const { firstName, lastName, email, company, uid } = authUser;
  //         props.firebase.setUserId(authUser.user.uid);
  //         props.firebase.sendAnalyticsLogEvent('sign_up', { method: 'email' });
  //         props.history.push(PATHS.MEMBER);
  //       })
  //       .catch(error => {
  //         if (error.code === ERROR_CODE_ACCOUNT_EXISTS) {
  //           error.message = ERROR_MSG_ACCOUNT_EXISTS;
  //         }
  //         setErrors(error);
  //       });

  //     // register(email, password);
  //     // await firebase.addQuote(quote);
  //   } catch (error) {
  //     setErrors(error);
  //     alert(error.message);
  //   }
  // }
  // async function onRegister(e) {
  //   e.preventDefault();
  //   console.log(`props=${props.irebase}`);
  //   try {
  //     await props.firebase
  //       .register(email, password)
  //       .then(user => {
  //         props.history.replace("/dashboard");
  //       })
  //       .catch(errors => {
  //         setErrors(errors);
  //       });
  //     // register(email, password);
  //     // await firebase.addQuote(quote);
  //   } catch (error) {
  //     setErrors(error);
  //     alert(error.message);
  //   }
  // }

  return (
    <form noValidate onSubmit={onRegister}>
      <FormContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormLogoHeader>
              <LogoEa width="100%" height="100%" />
            </FormLogoHeader>
          </Grid>
          <Grid item xs={12}>
            <FormHeader>Welcome!</FormHeader>
          </Grid>
          <Grid item xs={12}>
            <FormSubheader>Sign up for an account.</FormSubheader>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormFieldControl fullWidth>
              <FormFieldLabel>First Name</FormFieldLabel>
              <FormFieldInput
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
                autoFocus
              />
            </FormFieldControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormFieldControl fullWidth>
              <FormFieldLabel>Last Name</FormFieldLabel>
              <FormFieldInput
                required
                fullWidth
                id="lastName"
                name="lastName"
                autoComplete="lname"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </FormFieldControl>
          </Grid>
          <Grid item xs={12}>
            <FormFieldControl fullWidth>
              <FormFieldLabel>Company Name</FormFieldLabel>
              <FormFieldInput
                fullWidth
                id="company"
                name="company"
                autoComplete="company"
                value={company}
                onChange={e => setCompany(e.target.value)}
              />
            </FormFieldControl>
          </Grid>
          <Grid item xs={12}>
            <FormFieldControl fullWidth>
              <FormFieldLabel>Email Address</FormFieldLabel>
              <FormFieldInput
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </FormFieldControl>
          </Grid>
          <Grid item xs={12}>
            <FormFieldControl fullWidth>
              <FormFieldLabel>Password</FormFieldLabel>
              <FormFieldInput
                required
                fullWidth
                name="password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </FormFieldControl>
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              checked={checkboxes.isChecked}
              onChange={handleChange('isChecked')}
              value="isChecked"
              color="primary"
            />
            <FormFieldLabel>
              I agree to the{' '}
              <a href={TERMS_LINK} target="_blank" rel="noopener noreferrer">
                terms & conditions
              </a>
              .
            </FormFieldLabel>
          </Grid>
          {/* <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.isChecked}
                  onChange={handleChange('isChecked')}
                  value="isChecked"
                  color="primary"
                />
              }
            />
            I agree to the <a href={TERMS_LINK}>terms & conditions</a>.
          </Grid> */}
          {errors && (
            <Grid item xs={12}>
              <FormFieldErrorText id="component-error-text">
                {errors.message}
              </FormFieldErrorText>
            </Grid>
          )}
          <Grid item xs={12}>
            <BtnMain
              type="submit"
              fullWidth
              label="Sign Up"
              disabled={!checkboxes.isChecked}
            />
          </Grid>
          {/* <Grid item xs={12}>
              <FormSubmitButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!checkboxes.isChecked}
              >
                Sign Up
              </FormSubmitButton>
            </Grid> */}

          <Grid item xs={12}>
            <LandingFormLinks showSignin={true} />
          </Grid>
        </Grid>
      </FormContent>
    </form>
  );
};

export default withRouter(withFirebase(SignUp));
