import React, { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { colors } from 'styles/default';
import { hexToRGBa } from 'utils/colors';
import { CircularProgress } from '@material-ui/core';

const DownloadProgress = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -15px;
  margin-left: -15px;
`;

const BtnWrapper = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.fullWidth ? '100%' : 'auto')};
  margin: 0;
  cursor: pointer;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => (props.fillColor ? props.fillColor : '#999999')};
  min-height: 30px;
  color: ${props => (props.textColor ? props.textColor : '#fff')};
  background-color: ${props => (props.fillColor ? props.fillColor : '#999999')};
  &:hover {
    color: ${props => (props.textColor ? props.textColor : '#fff')};
    background-color: ${props =>
      props.hoverColor ? props.hoverColor : '#999999'};
  }
  &:disabled {
    color: #fff;
    background-color: #999999;
    cursor: not-allowed;
  }
  &:focus {
    outline-color: ${colors.btnMain.text};
  }
  padding: 0 1rem;
  margin-right: ${props => (props.isBack ? '20px' : '0')};

  /* margin-bottom: 25px; */
`;
const BtnLabel = styled.div`
  display: flex;
  white-space: nowrap;

  font-family: Montserrat;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  font-size: ${props => (props.size === 'lg' ? '1.2rem' : '1rem')};
  line-height: ${props => (props.size === 'lg' ? 1.2 : 1)};
  margin: 0;
`;

const BtnDownload = ({
  label,
  textColor = `${colors.btnMain.text}`,
  fillColor = `${colors.btnMain.fill}`,
  size = `sm`,
  building = false,
  ...props
}) => {
  const [txtClr, setTxtClr] = useState();
  const [fillClr, setFillClr] = useState();
  const [hoverClr, setHoverClr] = useState();

  useMemo(() => {
    const tcolor = hexToRGBa(textColor, 1);
    const fcolor = hexToRGBa(fillColor, 0.28);
    const hcolor = hexToRGBa(fillColor, 0.6);
    setTxtClr(tcolor);
    setFillClr(fcolor);
    setHoverClr(hcolor);
  }, [textColor, fillColor]);

  //console.log(`textColor=>${textColor} fillColor=> ${fillColor}`);
  // const tcolor = hexToRGBa(textColor, 1);
  // const fcolor = hexToRGBa(fillColor, 0.25);
  // const hcolor = hexToRGBa(fillColor, 0.6);
  return (
    <BtnWrapper
      textColor={txtClr}
      fillColor={fillClr}
      hoverColor={hoverClr}
      isBack={label === 'back'}
      {...props}
    >
      <BtnLabel size={size}>{label}</BtnLabel>
      {building && <DownloadProgress size={30} />}
    </BtnWrapper>
  );
};

export default BtnDownload;
