import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  AlignmentType,
  HeadingLevel,
  Media,
  TextWrappingType,
  HorizontalPositionAlign,
  HorizontalPositionRelativeFrom,
  VerticalPositionAlign,
  VerticalPositionRelativeFrom,
  TextWrappingSide,
} from 'docx';
// import logo from 'assets/logos/EA_Logo-Results.png';
import * as _ from 'lodash';

export const fileToBase64 = (filename, filepath) => {
  return new Promise(resolve => {
    var file = new File([filename], filepath);
    var reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function(event) {
      resolve(event.target.result);
    };

    // Convert data to base64
    reader.readAsArrayBuffer(file);
    // reader.readAsDataURL(file);
  });
};
export const fileToBase = file => {
  return new Promise(resolve => {
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function(event) {
      resolve(xhr.response);
    };
    xhr.open('GET', file);
    xhr.send();
  });
};
const createTemplatesParagraph = template => {
  //console.log(`template ${template.name}`);
  if (!template.doc) {
    return;
  }
  return template.doc.map(item => {
    //console.log(`item.text ${item.text}`);
    const itemText =
      item.variant === 'firstcap' ? _.capitalize(item.text) : item.text;

    if (item.type === 'templateHeader') {
      return new Paragraph({
        children: [
          new TextRun({
            text: itemText,
            font: {
              name: 'Arial',
            },
            size: 22,
            bold: true,
          }),
        ],
        spacing: {
          before: 480,
        },
        style: item.style,
      });
    }

    if (item.type === 'contentHeader') {
      return new Paragraph({
        children: [
          new TextRun({
            text: itemText,
            font: {
              name: 'Arial',
            },
            size: 22,
          }),
        ],
        spacing: {
          before: 240,
        },
        style: item.style,
      });
    }

    if (item.type === 'contentBullet') {
      return new Paragraph({
        children: [
          new TextRun({
            text: itemText,
            font: {
              name: 'Arial',
            },
            size: 22,
          }),
        ],
        bullet: {
          level: 0,
        },
      });
    }
    if (item.label) {
      return new Paragraph({
        children: [
          new TextRun({
            text: `${item.label}: ${itemText}`,
            font: {
              name: 'Arial',
            },
            size: 22,
          }),
        ],
        style: item.style,
      });
    }

    return new Paragraph({
      children: [
        new TextRun({
          text: itemText,
          font: {
            name: 'Arial',
          },
          size: 22,
        }),
      ],
      style: item.style,
    });
  });
};

const createDocSections = (item, doc) => {
  if (item.type === 'paraBreakBefore') {
    return new Paragraph({
      text: item.text,
      style: item.style,
      spacing: {
        before: 240,
      },
    });
  }
  if (item.type === 'paraBreakAfter') {
    return new Paragraph({
      text: item.text,
      style: item.style,
      spacing: {
        after: 240,
      },
    });
  }

  if (item.type === 'paraItalic') {
    return new Paragraph({
      children: [
        new TextRun({
          text: item.text,
          font: {
            name: 'Arial',
          },
          size: 22,
          italic: true,
        }),
      ],
      style: item.style,
    });
  }
  if (item.type === 'paraAllCaps') {
    return new Paragraph({
      children: [
        new TextRun({
          text: item.text,
          font: {
            name: 'Arial',
          },
          size: 22,
          allCaps: true,
        }),
      ],
      style: item.style,
    });
  }
  if (item.type === 'bulletLink') {
    return new Paragraph({
      children: [
        new TextRun({
          text: doc.createHyperlink(item.url, item.text),
          font: {
            name: 'Arial',
          },
          size: 22,
        }),
      ],
      bullet: {
        level: 0,
      },
    });
  }
  // if (item.style === 'contentBullet') {
  //   return new Paragraph({
  //     children: [
  //       new TextRun({
  //         text: item.text,
  //         font: {
  //           name: 'Arial',
  //         },
  //         size: 22,
  //       }),
  //     ],
  //     bullet: {
  //       level: 0,
  //     },
  //   });
  // }
  return new Paragraph({
    text: item.text,
    font: {
      name: 'Arial',
    },
    size: 22,
    style: item.style,
  });
};
const createFooterParagraph = items => {
  const textlines = items.map(item => {
    if (item.type === 'italic') {
      return new TextRun({
        text: item.text,
        font: {
          name: 'Arial',
        },
        size: 22,
        italic: true,
      }).break();
    } else {
      return new TextRun({
        text: item.text,
        font: {
          name: 'Arial',
        },
        size: 22,
      }).break();
    }
  });
  return new Paragraph({
    children: [...textlines],
    spacing: {
      before: 960,
    },
    style: 'normalPara',
  });
};

export const createDocx = async (sectionItem, imageurl) => {
  //const imageData = await fileToBase(logo);
  const imageData = await fileToBase(imageurl);
  // console.log(`imageDAta =${imageData}`);

  const doc = new Document({
    styles: {
      paragraphStyles: [
        {
          id: 'Heading1',
          name: 'Heading 1',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 28,
            bold: true,
            color: '000000',
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
          },
        },
        {
          id: 'Heading2',
          name: 'Heading 2',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 24,
            italics: true,
          },
          paragraph: {
            alignment: AlignmentType.CENTER,
            spacing: {
              after: 960,
            },
          },
        },
        {
          id: 'normalPara',
          name: 'Normal Para',
          basedOn: 'Normal',
          next: 'Normal',
          quickFormat: true,
          paragraph: {
            font: 'Arial',
            size: 22,
          },
          run: {
            font: 'Arial',
            size: 22,
            color: '000000',
          },
        },
        {
          id: 'ListParagraph',
          name: 'List Paragraph',
          basedOn: 'Normal',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 22,
            color: '000000',
          },
          paragraph: {
            alignment: AlignmentType.LEFT,
          },
        },
        {
          id: 'normalParaBulletLink',
          name: 'Normal Para Bullet link',
          basedOn: 'ListParagraph',
          quickFormat: true,
          run: {
            font: 'Arial',
            size: 22,
            color: '000000',
          },
          paragraph: {
            alignment: AlignmentType.LEFT,
          },
        },
        {
          id: 'contentHeader',
          name: 'Content Header',
          basedOn: 'normalPara',
          next: 'Normal',
          quickFormat: true,
          paragraph: {
            font: 'Arial',
            size: 22,
          },
          run: {
            font: 'Arial',
            size: 22,
            color: '000000',
          },
        },
        {
          id: 'templateHeader',
          name: 'Teamplate Header',
          basedOn: 'normalPara',
          next: 'Normal',
          quickFormat: true,
          paragraph: {
            font: 'Arial',
            size: 22,
          },
          run: {
            font: 'Arial',
            size: 22,
            color: '000000',
          },
        },
      ],
    },
  });
  //const imageData = await fileToBase64('ea-logo.png', logo);
  const image = Media.addImage(doc, imageData, 200, 200, {
    floating: {
      horizontalPosition: {
        relative: HorizontalPositionRelativeFrom.PAGE,
        align: HorizontalPositionAlign.CENTER,
      },
      verticalPosition: {
        relative: VerticalPositionRelativeFrom.PAGE,
        align: VerticalPositionAlign.TOP,
      },
      wrap: {
        type: TextWrappingType.TOP_AND_BOTTOM,
        side: TextWrappingSide.BOTH_SIDES,
      },
      // wrap: {
      //   type: TextWrappingType.NONE,
      // },
    },
  });

  // const logoHeader = new Paragraph({ text: image, spacing: { after: 960 } });
  // const pageTitle = new Paragraph().heading1().center();
  // const pageTitleText = new TextRun(sectionItem.title)
  //   .font('Arial')
  //   .size(24)
  //   .bold();
  // pageTitle.addRun(pageTitleText);
  // const sectionTitle = new Paragraph().heading2().center();
  // const sectionTitleText = new TextRun(sectionItem.section)
  //   .font('Arial')
  //   .size(24);
  // sectionTitle.addRun(sectionTitleText);

  doc.addSection({
    properties: {
      top: 700,
      right: 1400,
      bottom: 700,
      left: 1400,
    },
    // footers: {
    //   default: new Footer({
    //     children: [
    //       new Paragraph({
    //         text: '1',
    //         style: 'normalPara',
    //         alignment: AlignmentType.RIGHT,
    //       }),
    //     ],
    //   }),
    // },
    children: [
      new Paragraph(image, {
        alignment: AlignmentType.CENTER,
        spacing: { after: 960 },
      }),
      new Paragraph({
        text: sectionItem.title,
        heading: HeadingLevel.HEADING_1,
        alignment: AlignmentType.CENTER,
      }),
      new Paragraph({
        text: sectionItem.section,
        heading: HeadingLevel.HEADING_2,
        alignment: AlignmentType.CENTER,
      }),
      ...sectionItem.directions
        .map(para => {
          return createDocSections(para, doc);
        })
        .reduce((prev, curr) => prev.concat(curr), []),
      ...sectionItem.tips
        .map(item => createDocSections(item, doc))
        .reduce((prev, curr) => prev.concat(curr), []),

      ...sectionItem.templates
        .map(item => createTemplatesParagraph(item))
        .reduce((prev, curr) => prev.concat(curr), []),

      createFooterParagraph(sectionItem.footer),
    ],
  });

  const content = await Packer.toBlob(doc);
  return content;

  // saveDocumentToFile(doc, `${section}.docx`);

  //return doc;
};

export default createDocx;
