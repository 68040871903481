import React from 'react';
import styled from 'styled-components/macro';

const ContentSubtitleWrapper = styled.div`
  position: relative;
  display: flex;
`;

const Subtitle = styled.p`
  font-family: Montserrat;
  font-weight: normal;
  font-style: italic;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: ${props => (props.align ? props.align : 'cener')};
  font-size: 0.8rem;
  padding: 0 1rem;
  color: ${props => (props.textColor ? props.textColor : '#000')};
`;

const ContentSubtitle = ({ textColor, item }) => {
  return (
    <ContentSubtitleWrapper>
      <Subtitle textColor={textColor} align={item.align}>
        {item.text}
      </Subtitle>
    </ContentSubtitleWrapper>
  );
};
export default ContentSubtitle;
