import React from 'react';
import styled from 'styled-components/macro';
// background-color: #f5f6f7;
const Banner = styled.div`
  border-top: 1px solid #dddfe2;
  overflow: hidden;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0.5rem 0;
  > img {
    max-width: 100%;
    object-fit: scale-down; /*magic*/
    border-bottom: 1px solid #dddfe2;
  }
`;

const FbBanner = ({ children }) => {
  return <Banner>{children}</Banner>;
};

export default FbBanner;
