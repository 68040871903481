export const blogs2 = [
  {
    uid: 'BLOG020001',
    order: 1,
    category: 'blog',
    theme: {
      type: 'random',
      colorCode: 'bluedark',
    },
    collapsable: false,
    header: 'Blog',
    content: {
      title: {
        text: 'Is Your Business Healthy? 10 Ways to Find Out.',
        align: 'left',
      },
      subtitle: {
        text: '5 min',
        align: 'left',
      },
      copy: {
        text:
          "<p>Our goal at Excelerate America is to help your company achieve long-term success. In order to do that, you must have a healthy organization that is built for the long run.</p><br /><p>So today I'm sharing 10 Characteristics of a Healthy Organization.</p>",
        align: 'left',
      },
      media: {
        caption: 'Is business healthy',
        type: 'img',
        fileurl:
          'https://firebasestorage.googleapis.com/v0/b/exceleratebiz-app.appspot.com/o/dashboard%2Fis-your-biz-healthy.jpg?alt=media&token=506b58ab-90a4-48ad-bcb7-19ab4cbf4888',
        height: '150px',
      },
      cta: {
        label: 'Read More',
        url:
          'https://blog.excelerateamerica.com/exceleratebiz/10-characteristics-of-a-healthy-organization',
      },
    },
  },
  {
    uid: 'BLOG020002',
    order: 2,
    category: 'blog',
    theme: {
      type: 'random',
      colorCode: 'bluedark',
    },
    collapsable: false,
    header: 'Blog',
    content: {
      title: {
        text: 'The Secret to Wow-worthy Transactions',
        align: 'left',
      },
      subtitle: {
        text: '7 min',
        align: 'left',
      },
      copy: {
        text:
          '<p>Technology within the retail space has primarily served to ensure transnational moments were quick and efficient. Now, retailers have an unparalleled opportunity to go a step further and leverage data-driven and digital technologies to elevate the customer experience in more meaningful ways.</p>',
        align: 'left',
      },
      media: {
        caption: 'wow worthy',
        type: 'img',
        fileurl:
          'https://firebasestorage.googleapis.com/v0/b/exceleratebiz-app.appspot.com/o/dashboard%2Fsecret-to-wow-worthy-transactions.png?alt=media&token=1b06ad23-6592-4c65-9214-0f47d4a5417f',
        height: '150px',
      },
      cta: {
        label: 'Read More',
        url:
          'https://blog.excelerateamerica.com/exceleratebiz/how-data-driven-intelligence-is-delivering-the-wow-experience',
      },
    },
  },
  {
    uid: 'BLOG020003',
    order: 3,
    category: 'blog',
    theme: {
      type: 'random',
      colorCode: 'bluedark',
    },
    collapsable: false,
    header: 'Blog',
    content: {
      title: {
        text: '3 Ways to Build A Gratitude Attitude',
        align: 'left',
      },
      subtitle: {
        text: '3 min',
        align: 'left',
      },

      copy: {
        text:
          '<p>Gratitude. It’s not something we talk about as fundamentally important in business especially when comparing to finance, marketing, or sales.</p><br /><p>However, without gratitude, we would have no sustained growth. The ability to be grateful is key to life balance and happiness, and this transcends into business. When you show gratitude you feel positive and inspired.</p>',
        align: 'left',
      },
      media: {
        caption: 'wow worthy',
        type: 'img',
        fileurl:
          'https://firebasestorage.googleapis.com/v0/b/exceleratebiz-app.appspot.com/o/dashboard%2F3-ways-to-build-gratitude.jpg?alt=media&token=55b08e70-e260-49e0-a9a6-0304b9a7f8a9',
        height: '150px',
      },

      cta: {
        label: 'Read More',
        url:
          'https://blog.excelerateamerica.com/exceleratebiz/3-ways-to-build-a-gratitude-attitude-in-your-business',
      },
    },
  },
  {
    uid: 'BLOG020004',
    order: 4,
    category: 'blog',
    theme: {
      type: 'random',
      colorCode: 'bluedark',
    },
    collapsable: false,
    header: 'Blog',
    content: {
      title: {
        text: 'Are Emotional Hijacks Holding You Back?',
        align: 'left',
      },
      subtitle: {
        text: '2 min',
        align: 'left',
      },
      copy: {
        text:
          '<p>Have you ever been addressed in a meeting unexpectedly? All of a sudden, it feels like the room temperature rose 10 degrees, and your upper lip is visibly sweating.</p><br /><p>That can result in an embarrassing moment that will keep you up at night, but the scientific name is fight or flight.</p>',
        align: 'left',
      },
      media: {
        caption: 'emotional hijinks',
        type: 'img',
        fileurl:
          'https://firebasestorage.googleapis.com/v0/b/exceleratebiz-app.appspot.com/o/dashboard%2Femotional-hijinks.jpg?alt=media&token=698e5f16-1c2d-4fa4-9fee-d724bdb2d291',
        height: '150px',
      },
      cta: {
        label: 'Read More',
        url:
          'https://blog.excelerateamerica.com/exceleratebiz/are-emotional-hijacks-holding-you-back',
      },
    },
  },
];

export const blogs1 = [
  {
    uid: 'BLOG00001111111',
    order: 1,
    category: 'blog',
    theme: {
      type: 'random',
      colorCode: 'bluedark',
    },
    collapsable: false,
    header: 'Blog',
    content: {
      title: {
        text: 'Business Setbacks - Tony’s Story',
        align: 'left',
      },
      subtitle: {
        text: '6 min',
        align: 'left',
      },
      copy: {
        text:
          "<p>There has been much discussion lately about the mind-body-spirit connection and how it has become further backed by science. It was a theory initially considered more hippie-dippie, but it's increasingly accepted as essential to our future.</p>",
        align: 'left',
      },
      media: {
        caption: 'Business Setbacks',
        type: 'img',
        fileurl:
          'https://firebasestorage.googleapis.com/v0/b/exceleratebiz-app.appspot.com/o/dashboard%2FBusiness-Setbacks.jpg?alt=media&token=b05e28fb-4e39-49b8-9bd1-c892ffe6635e',
        height: '150px',
      },
      cta: {
        label: 'Read More',
        url:
          'https://blog.excelerateamerica.com/exceleratebiz/how-i-got-through-my-biggest-business-setbakc',
      },
    },
  },
  {
    uid: 'BLOG00001111112',
    order: 2,
    category: 'blog',
    theme: {
      type: 'side-right',
      colorCode: 'bluedark',
    },
    collapsable: false,
    header: 'Blog',
    content: {
      title: {
        text: '6 Steps to Winning Instagram',
        align: 'left',
      },
      subtitle: {
        text: '3 min',
        align: 'left',
      },
      copy: {
        text:
          '<p>Over the past three months, I have embarked on a journey to build my freelance work and grow my brand utilizing Instagram (we love side hustlers here at Excelerate America). I chose Instagram because I’m a visual person, I make visual art, and I found that it was the best platform to showcase the skills I have.</p>',
        align: 'left',
      },
      media: {
        caption: '6 steps to winning instragram',
        type: 'img',
        fileurl:
          'https://firebasestorage.googleapis.com/v0/b/exceleratebiz-app.appspot.com/o/dashboard%2F6-steps-to-winning-instagram.png?alt=media&token=3fcaf55b-5f11-489f-af11-86464a9a76c8',
        height: '320px',
      },
      cta: {
        label: 'Read More',
        url:
          'https://blog.excelerateamerica.com/exceleratebiz/6-things-ive-learned-starting-to-grow-my-brand-on-instagram',
      },
    },
  },
  {
    uid: 'BLOG00001111113',
    order: 3,
    category: 'blog',
    theme: {
      type: 'side-right',
      colorCode: 'bluedark',
    },
    collapsable: false,
    header: 'Blog',
    content: {
      title: {
        text: 'Calling all Wallet Feminists!',
        align: 'left',
      },
      subtitle: {
        text: '4 min',
        align: 'left',
      },

      copy: {
        text:
          "<p>It's a stark fact made even more startling considering that we're currently celebrating National Women in Business Month.</p><br /><p>It's also a fact that Vanessa Bruce and Anna Palmer couldn't get out of their minds and one that ultimately inspired them to found Dough.</p>",
        align: 'left',
      },
      media: {
        caption: 'feminist wallet',
        type: 'img',
        fileurl:
          'https://firebasestorage.googleapis.com/v0/b/exceleratebiz-app.appspot.com/o/dashboard%2Fwallet-feminists.jpg?alt=media&token=bedd9548-69ea-42e8-bfcc-a864d228dfb6',
        height: '320px',
      },
      cta: {
        label: 'Read More',
        url:
          'https://blog.excelerateamerica.com/exceleratebiz/tenacity-tales-16-calling-all-wallet-feminists',
      },
    },
  },
  {
    uid: 'BLOG00001111114',
    order: 4,
    category: 'blog',
    theme: {
      type: 'side-right',
      colorCode: 'bluedark',
    },
    collapsable: false,
    header: 'Blog',
    content: {
      title: {
        text: '3 Local Resources Your Biz Should Be Using',
        align: 'left',
      },
      subtitle: {
        text: '3 min',
        align: 'left',
      },
      copy: {
        text:
          '<p>I think entrepreneurs or small business owners are some of the busiest, nose-to-the-grindstone people I know.</p><br /><p>Yet with that incredible work ethic can come some negatives. The first one that comes to mind is that a lot of business owners can start to feel a little isolated. And that can result in possibly not knowing about some really cool resources that are available to you right in your hometown.</p>',
        align: 'left',
      },
      media: {
        caption: 'local resources',
        type: 'img',
        fileurl:
          'https://firebasestorage.googleapis.com/v0/b/exceleratebiz-app.appspot.com/o/dashboard%2Flocal-resources.jpg?alt=media&token=dcceb62d-2af4-463c-a587-2586e9625f0d',
        height: '150px',
      },
      cta: {
        label: 'Read More',
        url:
          'https://blog.excelerateamerica.com/exceleratebiz/the-top-3-local-resources-your-small-business-should-be-using',
      },
    },
  },
];
export const baseCards = [
  {
    uid: 'base001',
    order: 1,
    category: 'project',
    collapsable: false,
    theme: {
      type: 'random',
      colorCode: 'bluelight',
    },
    header: '30 Day Challenge',
    content: {
      title: {
        text: 'Master Email Marketing',
        align: 'left',
      },
      subtitle: {
        text: 'Starting Feb. 10th',
        align: 'left',
      },
      copy: {
        text:
          'Fun fact: Email marketing is up to 40 times more effective than social media.',
        align: 'left',
      },
      cta: {
        label: 'Register Today',
        url: 'http://bit.ly/Email30Day',
      },
      media: {
        caption: 'Insta',
        type: 'img',
        fileurl:
          'https://firebasestorage.googleapis.com/v0/b/exceleratebiz-app.appspot.com/o/dashboard%2Finsta3.png?alt=media&token=3034c027-9f58-4b8c-a8cd-3866d84d86b7',
        height: '200px',
      },
    },
  },
  {
    uid: 'base002',
    order: 1,
    category: 'external',
    collapsable: false,
    theme: {
      type: 'random',
      colorCode: 'gold',
    },
    header: 'Headlines',
    content: {
      title: {
        text: 'Her Job Requires 7 Apps. She Works Retail.',
        align: 'left',
      },
      subtitle: {
        text: 'Courtesy: The New York Times',
        align: 'left',
      },

      copy: {
        text:
          'As brick-and-mortar stores scramble to justify their continued existence, they’re trying to be all things to all customers, to blend instant gratification and infinite selection. And it falls upon the workers on the front lines to make it all happen.',
        align: 'left',
      },
      cta: {
        label: 'Read More',
        url:
          'https://www.nytimes.com/2019/12/26/nyregion/old-navy-workers.html',
      },
      media: {
        caption: 'Healine image',
        type: 'img',
        fileurl:
          'https://firebasestorage.googleapis.com/v0/b/exceleratebiz-app.appspot.com/o/dashboard%2Fexternal-headline-image.jpg?alt=media&token=5451aff4-f516-42ff-b4b3-c8dea242be20',
        height: '150px',
      },
    },
  },
  {
    uid: 'base004',
    order: 1,
    category: 'inspo',
    collapsable: false,
    theme: {
      type: 'random',
      colorCode: 'red',
    },
    header: 'Word of the Day',
    content: {
      title: {
        text: 'Alpha Pup',
        align: 'center',
      },
      subtitle: {
        text: 'Noun',
        align: 'left',
      },
      copy: {
        text:
          'A trendsetting young person and important target for marketing to a specific age group.',
        align: 'left',
      },
      media: {
        caption: 'inspo',
        type: 'img',
        fileurl:
          'https://firebasestorage.googleapis.com/v0/b/exceleratebiz-app.appspot.com/o/dashboard%2FWord-of-the-day_Alpha-Pup.jpg?alt=media&token=bc2f8670-df5b-4526-a392-940f18611f6a',
        height: '150px',
      },
    },
  },
  {
    uid: 'base009',
    order: 2,
    category: 'inspo',
    collapsable: false,
    theme: {
      type: 'random',
      colorCode: 'red',
    },
    header: 'What We’re Listening To',
    content: {
      title: {
        text: 'EA Hype Jams',
        align: 'center',
      },
      subtitle: {
        text: 'Spotify Playlist',
        align: 'left',
      },
      copy: {
        text: 'A selection of favorite songs to get motivated and moving.',
        align: 'left',
      },
      cta: {
        label: 'Listen',
        url:
          'https://open.spotify.com/playlist/0eO9fj5c3FgauncXflTESS?si=m7YCdO5PT1uQKNU-nON7WQ&utm_campaign=EA%20Content%20&utm_source=hs_email&utm_medium=email&_hsenc=p2ANqtz-_PYlX4php_0a1JQ-ku_Xf39xTgpxkq_LmkIeT56x9QUlL_L4sb53eq5HUljrgLTlof_ssr',
      },
      media: {
        caption: 'Playlist',
        type: 'img',
        fileurl:
          'https://firebasestorage.googleapis.com/v0/b/exceleratebiz-app.appspot.com/o/dashboard%2FSpotify-Playlist.jpg?alt=media&token=6f2960e0-a7e6-4743-a889-4708ba83452b',
        height: '150px',
      },
    },
  },
  {
    uid: 'base015',
    order: 4,
    category: 'inspo',
    collapsable: false,
    theme: {
      type: 'random',
      colorCode: 'red',
    },
    header: 'What We’re Listening To',
    content: {
      title: {
        text: 'Second Life',
        align: 'center',
      },
      subtitle: {
        text: 'Podcast',
        align: 'left',
      },
      copy: {
        text:
          'Leslie on our team lives for this podcast that “chronicles career changes that can come at any age.”',
        align: 'left',
      },
      cta: {
        label: 'Listen',
        url: 'https://www.secondlifepod.com/',
      },

      media: {
        caption: 'Playlist',
        type: 'img',
        fileurl:
          'https://firebasestorage.googleapis.com/v0/b/exceleratebiz-app.appspot.com/o/dashboard%2Fsecond-life-podcast.jpg?alt=media&token=a8720ba6-cb04-4506-b561-057ce53277e3',
        height: '200px',
      },
    },
  },
];
