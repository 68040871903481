import { RESULT_OUTPUTS_RESET, RESULT_OUTPUTS_SET } from 'store/actions/types';
const INITIAL_STATE = {};

const applySetResultOutputs = (state, action) => ({
  ...state,
  ...action.outputs,
});

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case RESULT_OUTPUTS_RESET:
      return INITIAL_STATE;
    case RESULT_OUTPUTS_SET: {
      return applySetResultOutputs(state, action);
    }

    default:
      return state;
  }
}
