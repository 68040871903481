import React from 'react';

const CornerBox = props => (
  <svg viewBox="0 0 200 180" {...props} preserveAspectRatio="xMaxYMin slice">
    <path
      fill="fill"
      opacity={0.58}
      d="M200 0v180H0V0z"
      data-name="SvgSideBox"
    />
  </svg>
);

export default CornerBox;
