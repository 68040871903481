import React from 'react';
// import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
// import { Avatar } from '@material-ui/core';
// import { colors } from 'styles/default';
// import { ReactComponent as Basket } from 'assets/svg/basket.svg';
// import { ReactComponent as BasketFilled } from 'assets/svg/basketFilled.svg';
// import Icon from 'elements/Icon';
// import { mdiBasket, mdiClose } from '@mdi/js';
import IconCartBasket from './IconCartBasket';
import IconCartBasketEmpty from './IconCartBasketEmpty';

// const BasketCircleBtn = styled.button`
//   position: relative;
//   outline: none;
//   border-radius: 50%;
//   width: 65px;
//   height: 65px;
//   background-color: ${colors.primary.dark};
//   color: ${colors.primary.contrastText};
//   z-index: 80;
//   button > * {
//     pointer-events: none;
//   }
//   transition: transform 300ms ease-in-out;
//   &:hover {
//     transform: scale(1.2);
//   }
// `;
const BasketCircleBtn = styled.button`
  position: relative;
  outline: none;
  width: 72px;
  height: 72px;
  border: none;
  padding: 0;
  margin: 0;
  z-index: 80;
  background: transparent;
  button > * {
    pointer-events: none;
  }
  transition: transform 300ms ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
`;
const BasketCircle = styled.div`
  position: relative;
  outline: none;
  width: 72px;
  height: 72px;
  border: none;
  padding: 0;
  margin: 0;
  z-index: 80;
  background: transparent;

  z-index: 80;
`;

// const BasketLayer = styled.div`
//   position: absolute;
//   width: 45px;
//   height: 45px;
//   top: 10px;
//   left: 10px;
//   z-index: 81;
//   > svg {
//     fill: #ffffff;
//   }
// `;
// const NumberLayer = styled.div`
//   position: absolute;
//   width: 45px;
//   height: 25px;
//   top: 28px;
//   left: 10px;
//   right: 10px;
//   z-index: 82;
// `;

// const CartItemTotal = styled.div`
//   color: ${colors.primary.dark};
//   font-size: 16px;
//   text-align: center;
// `;
const CartBasket = ({ totalItems }) => {
  return (
    <BasketCircle>
      {totalItems > 0 ? (
        <IconCartBasket totalItems={totalItems} />
      ) : (
        <IconCartBasketEmpty />
      )}
    </BasketCircle>
  );
};
const CartBasketBtn = ({ handleClick, totalItems }) => {
  return (
    <BasketCircleBtn onClick={handleClick}>
      {totalItems > 0 ? (
        <IconCartBasket totalItems={totalItems} />
      ) : (
        <IconCartBasketEmpty />
      )}
    </BasketCircleBtn>
  );
};
// const CartBasket = ({ totalItems }) => {
//   return (
//     <BasketCircle>
//       <BasketLayer>
//         {totalItems > 0 ? <BasketFilled /> : <Basket />}
//       </BasketLayer>
//       {totalItems > 0 && (
//         <NumberLayer>
//           <CartItemTotal>{totalItems}</CartItemTotal>
//         </NumberLayer>
//       )}
//     </BasketCircle>
//   );
// };
// const CartBasketBtn = ({ handleClick, totalItems }) => {
//   return (
//     <BasketCircleBtn onClick={handleClick}>
//       <BasketLayer>
//         {totalItems > 0 ? <BasketFilled /> : <Basket />}
//       </BasketLayer>

//       {totalItems > 0 && (
//         <NumberLayer>
//           <CartItemTotal>{totalItems}</CartItemTotal>
//         </NumberLayer>
//       )}
//     </BasketCircleBtn>
//   );
// };
export { CartBasket, CartBasketBtn };
