import React from 'react';
import styled from 'styled-components/macro';
// import { Transition } from 'react-transition-group';
const QuizQuestionFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  height: 200px;
  min-height: 200px;
  background-color: #99cc11;
`;

const QuestionFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const LabelArea = styled.div`
  display: flex;

  font-size: 1rem;
  box-sizing: border-box;
  font-weight: 700;
  line-height: 1.1875em;
  justify-content: center;
  align-items: center;
`;

const QuizResponseField = ({ doc, inputValue, onContinue }) => {
  const nickname = inputValue;
  return (
    <QuizQuestionFieldContainer onClick={onContinue}>
      <QuestionFieldWrapper>
        <LabelArea>
          {doc.label} {nickname}
        </LabelArea>
      </QuestionFieldWrapper>
    </QuizQuestionFieldContainer>
  );
  // return (
  //   <QuizQuestionFieldContainer onClick={onContinue}>
  //     <QuestionFieldWrapper>
  //       <LabelArea>{eval('`' + doc.label + '`')}</LabelArea>
  //     </QuestionFieldWrapper>
  //   </QuizQuestionFieldContainer>
  // );
};

export default QuizResponseField;
