import { useState, useEffect, useCallback } from 'react';
import _ from 'lodash';

// import { stateSchema, validationSchema } from 'constants/product';

function customizer(objValue, srcValue) {
  if (objValue) {
    // console.log(`srcValur => ${srcValue}`);

    // objValue.value =
    //   typeof srcValue === 'boolean' ? srcValue.toString() : srcValue;
    objValue.value = typeof srcValue === 'boolean' ? srcValue : srcValue;
    return objValue;
  }
}

const useFormComplex = (
  stateSchema,
  validationSchema,
  initialValues,
  callback
) => {
  const [inputs, setInputs] = useState();
  const [formReady, setFormReady] = useState(false);
  //const [isDirty, setIsDirty] = useState(true);

  // Disable button in initial render.
  useEffect(() => {
    //console.log(`initialValues=> ${JSON.stringify(initialValues)}`);
    const stateKeys = Object.keys(stateSchema);
    // const dataKeys = Object.keys(initialValues);
    //console.log(`dataKeys= ${dataKeys.length} stateKeys=${stateKeys.length}`);
    const cleanData = _.pick(initialValues, stateKeys);
    //console.log(`cleanData=>${JSON.stringify(cleanData)}`);
    const fixedstate = _.mergeWith(stateSchema, cleanData, customizer);
    //console.log(`fixedstate=> ${JSON.stringify(fixedstate)}`);
    // setInputs(inputs => ({
    //   ...inputs,
    //   ...state,
    // }));
    setInputs({ ...fixedstate });
    setFormReady(true);
  }, [initialValues, stateSchema]);

  //   useEffect(() => {
  //     if (isDirty) {
  //       setDisable(validateState());
  //     }
  //   }, [isDirty, validateState]);

  // const validateState = useCallback(() => {
  //   const hasErrorInState = Object.keys(validationSchema).some(key => {
  //     const isInputFieldRequired = validationSchema[key].required;
  //     const stateValue = inputs[key].value; // state value
  //     const stateError = inputs[key].error; // state error
  //     console.log(
  //       `valid> ${key} stateValue-${stateValue} stateError-${stateError}`
  //     );
  //     return (isInputFieldRequired && !stateValue) || stateError;
  //   });
  //   console.log(`validateState - ${hasErrorInState}`);
  //   return hasErrorInState;
  // }, [inputs, validationSchema]);

  const handleInputChange = useCallback(
    event => {
      //setIsDirty(true);
      event.persist();
      const name = event.target.name;
      const value = event.target.value;
      console.log(`name= ${name} value=${value}`);
      let error = '';
      if (validationSchema[name].required) {
        if (!value) {
          error = 'This is required field.';
        }
      }
      setInputs(inputs => ({
        ...inputs,
        [name]: { value, error },
      }));
      // setInputs(inputs => ({
      //   ...inputs,
      //   [event.target.name]: event.target.value,
      // }));
    },
    [validationSchema]
  );
  const handleCheckboxChange = useCallback(
    (event, name) => {
      //setIsDirty(true);

      const value = event.target.checked;
      console.log(`name= ${name} value=${value}`);
      let error = '';
      if (validationSchema[name].required) {
        if (!value) {
          error = 'This is required field.';
        }
      }
      setInputs(inputs => ({
        ...inputs,
        [name]: { value, error },
      }));
    },
    [validationSchema]
  );
  const handleFieldChange = useCallback(
    (name, value) => {
      //setIsDirty(true);
      let error = '';
      if (validationSchema[name].required) {
        if (!value) {
          error = 'This is required field.';
        }
      }
      setInputs(inputs => ({
        ...inputs,
        [name]: { value, error },
      }));
    },
    [validationSchema]
  );

  const handleSubmit = event => {
    if (event) event.preventDefault();
    //if (!validateState()) {
    const keyvals = Object.keys(inputs).reduce(
      (acc, key) => ({
        ...acc,
        [key]: inputs[key].value,
        // [key]: _.isBoolean(inputs[key].value)
        //   ? _.toString(inputs[key].value)
        //   : inputs[key].value,
      }),
      {}
    );
    console.log(`onsubmit=${JSON.stringify(keyvals)}`);
    callback(keyvals);
    //}
    // callback();
  };
  return {
    handleSubmit,
    handleInputChange,
    handleFieldChange,
    handleCheckboxChange,
    inputs,
    formReady,
  };
};
export default useFormComplex;
