import React, { useMemo, useState } from 'react';
import styled from 'styled-components/macro';
import { colors } from 'styles/default';
import { hexToRGBa } from 'utils/colors';
import ArrowPrev from 'assets/icons/ArrowPrev';
import ArrowNext from 'assets/icons/ArrowNext';
const BtnWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => (props.width ? props.width : '100')};
  height: ${props => (props.height ? props.height : '100%')};
  margin: 0;
  cursor: pointer;
  color: ${props => (props.textColor ? props.textColor : '#fff')};
  background-color: ${props => (props.fillColor ? props.fillColor : '#fff')};
  &:hover {
    > svg {
      stroke: ${props =>
        props.textColorHover ? props.textColorHover : '#fff'};
    }

    background-color: ${props =>
      props.hoverColor ? props.hoverColor : '#999999'};
  }
  &:disabled {
    background-color: transparent;
    cursor: not-allowed;
    > svg {
      stroke: ${props => (props.textColorDis ? props.textColorDis : '#fff')};
    }
  }
  &:focus {
    outline-color: none;
  }
  padding: 0;

  /* margin-bottom: 25px; */
`;

const BtnSlideNav = ({
  width,
  height,
  type,
  isDisabled,
  textColor = `${colors.primary.dark}`,
  fillColor = '#ffffff',
  ...props
}) => {
  const [txtClr, setTxtClr] = useState();
  const [txtClrDis, setTxtClrDis] = useState();
  const [txtClrHover, setTxtClrHover] = useState();
  const [fillClr, setFillClr] = useState();
  const [hoverClr, setHoverClr] = useState();

  useMemo(() => {
    const tcolor = hexToRGBa(textColor, 1);
    const tcolordis = hexToRGBa(textColor, 0.2);
    const tcolorhover = hexToRGBa(fillColor, 1);
    const fcolor = hexToRGBa(fillColor, 1);
    const hcolor = hexToRGBa(textColor, 0.2);
    setTxtClr(tcolor);
    setFillClr(fcolor);
    setHoverClr(hcolor);
    setTxtClrDis(tcolordis);
    setTxtClrHover(tcolorhover);
  }, [textColor, fillColor]);

  //console.log(`textColor=>${textColor} fillColor=> ${fillColor}`);
  // const tcolor = hexToRGBa(textColor, 1);
  // const fcolor = hexToRGBa(fillColor, 0.25);
  // const hcolor = hexToRGBa(fillColor, 0.6);
  return (
    <BtnWrapper
      textColor={txtClr}
      textColorHover={txtClrHover}
      textColorDis={txtClrDis}
      fillColor={fillClr}
      hoverColor={hoverClr}
      disabled={isDisabled}
      {...props}
    >
      {type === 'prev' ? (
        <ArrowPrev
          width="20px"
          height="20px"
          stroke={isDisabled ? txtClrDis : txtClr}
        />
      ) : (
        <ArrowNext
          width="20px"
          height="20px"
          stroke={isDisabled ? txtClrDis : txtClr}
        />
      )}
    </BtnWrapper>
  );
};

export default BtnSlideNav;
