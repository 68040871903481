export const white = '#FFFFFF';

export const grey = {
  50: '#FAFAFA',
  100: '#F5F5F5',
  200: '#EEEEEE',
  300: '#E0E0E0',
  400: '#BDBDBD',
  500: '#9E9E9E',
  600: '#757575',
  700: '#616161',
  800: '#424242',
  900: '#212121',
};

export const black = '#000000';

export const blue = '#0767DB';

export const green = '#357a38';

export const orange = '#FFB822';

export const red = '#ED4740';

export const primary = {
  main: '#01008c',
  light: 'rgb(51, 51, 163)',
  dark: '#282965',
  contrastText: '#fff',
};

// export const formButton = {
//   activeBackColor: '#76cbe2',
//   activeTextColor: primary.dark,
//   hoverBackColor: '#ffc260',
//   hoverTextColor: primary.dark,
// };

export const blk = `rgba(0, 0, 0, 1)`;
export const blkLight = `rgba(0, 0, 0, 0.87)`;
export const blkLighter = `rgba(0, 0, 0, 0.23)`;
export const blkLightest = `rgba(0, 0, 0, 0.12)`;

export const defaultTextColor = blkLight;
export const defaultBackColor = white;
export const defaultBorderColor = blkLighter;
export const highlightYellow = '#ffff00';
export const highlightGreen = '#c6ff00';
export const highlightBlue = '#cfe2f3';
