import React from 'react';
import styled from 'styled-components/macro';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import { colors } from 'styles/default';
const Input = styled(TextareaAutosize)`
  &&&&&& {
    border-radius: 12px;
    border: solid 3.4px ${colors.primary.dark};
    padding: 1rem;
    line-height: 1.2;
    font-size: ${props => (props.fsize ? props.fsize : '1rem')};
    height: auto;
    resize: none;
    width: 100%;
    &:focus {
      outline: none;
      outline-offset: 0;
      background-color: ${colors.textArea.focusBack};
    }
  }
`;

const TextAreaAutoSize = ({ fsize, ...props }) => {
  return <Input fsize={fsize} {...props} />;
};

export default TextAreaAutoSize;
