const stateSchema = {
  firstName: { value: '', error: '' },
  lastName: { value: '', error: '' },
  initials: { value: '', error: '' },
  company: { value: '', error: '' },
  showJoyride: { value: '', error: '' },
};

const validationSchema = {
  firstName: { required: true },
  lastName: { required: true },
  initials: { required: false },
  company: { required: true },
  showJoyride: { required: false },
};

const initialState = {
  firstName: '',
  lastName: '',
  initials: '',
  company: '',
  showJoyride: true,
};

export { initialState, stateSchema, validationSchema };
