import { rgba } from 'polished';

const actCardConstants = {
  OPACITY_BACK: '0.16',
  OPACITY_SHAPE: '0.17',
  WIDTH: 259,
  HEIGHT: 153,
  CORNER_RADIUS: '20px',
};

export const globals = {
  margin: '2rem',
  padding: '2rem',
  borderRadius: '2rem',
  spacing: 4,
};
export const style = {
  borderRadius: '0.8rem',
  overlay: rgba('black', 0.54),
  boxShadow: `0 2px 4px 0 ${rgba('black', 0.1)}`,
  fontFamily: 'Montserrat',
  padding: 'calc(1rem + 10px)',
  paddingSide: '2.4rem',
  paddingTop: '2.4rem',
  radius: '6px',
};
export const colors = {
  primary: {
    main: '#01008c',
    light: '#76CBE2',
    dark: '#282964',
    contrastText: '#fff',
  },
  secondary: {
    main: '#FFC260',
    light: 'rgb(255, 206, 127)',
    dark: 'rgb(178, 135, 67)',
    contrastText: 'rgba(0, 0, 0, 0.87)',
  },
  background: { paper: '#fff', default: '#fafafa' },
  text: {
    primary: 'rgba(0, 0, 0, 1)',
    secondary: 'rgba(0, 0, 0, 0.54)',
    disabled: 'rgba(0, 0, 0, 0.38)',
    hint: 'rgba(0, 0, 0, 0.38)',
    error: '#ff0000',
  },
  cardFields: {
    productName: '#345ca4',
  },

  logos: {
    disabled: {
      primaryColor: '#000000',
      secondaryColor: '#cccccc',
    },
    jumpstart: {
      primaryColor: '#1d4798',
      secondaryColor: '#3b6fb4',
    },
  },
  workzoneProject: {
    dividerColor: '#282964',
  },
  marketPlace: {
    disabledTextColor: '#000000',
    primaryTextColor: '#345ca4',
    secondaryTextColor: '#000000',
    primaryColor: '#76cbe2',
    disabledColor: '#bbbbbb',
  },
  cart: {
    textColor: '#345ca4',
    dividerColor: '#76cbe2',
  },
  card: {
    backgroundColor: '#fff',
    boxShadowColor: 'rgba(0, 0, 0, 0.19)',
  },

  divider: 'rgba(0, 0, 0, 0.12)',
  common: { black: '#000', white: '#fff', grey: '#999' },
  product: {
    btnBackColor: '#345ca4',
    btnTextColor: '#1d4798',
  },
  textArea: {
    focusBack: 'rgba(118,203, 226, 0.25)',
  },
  btnMain: {
    fill: '#345ca4',
    text: '#1d4798',
  },
  toggleBtn: {
    text: '#1d4798',
    fill: 'rgba(52,92,164,.25)',
    border: 'rgba(52,92,164,0.25)',
    activeText: '#1d4798',
    activeFill: 'rgba(255,255,255,1)',
    activeBorder: '#1d4798',
  },
  btnResult: {
    fill: '#fff',
    active: `rgba(118, 203, 226, .25)`,
    text: 'rgba(40,41, 100, 1)',
    hover: 'rgba(40,41, 100, .25)',
  },
  member: {
    backColor: 'rgba(118, 203,226, 0)',
    sidebarColor: 'rgba(255,255,255,1)',
    sidebarHover: `rgba(118, 203, 226, .2)`,
  },
  template: {
    blueLighter: 'rgba(118,203, 226, 0.25)',
    highlightGreen: '#c6ff00',
    highlightBlue: '#cfe2f3',
    highlightYellow: '#ffff00',
  },
  formButton: {
    activeBackColor: '#76cbe2',
    activeTextColor: '#282964',
    hoverBackColor: '#ffc260',
    hoverTextColor: '#ffffff',
  },
  dialog: {
    container: rgba(242, 160, 30, 0.2),
  },
  bots: {
    main: '#272964',
    bota: '#76cbe2',
    botb: '#f2a01e',
    botc: '#e82127',
    greymd: '#e6e7e8',
    greydk: '#d0d2d3',
    greeylt: '#f2f2f2',
    pinka: '#e7c7df',
    purplea: '#6c63ff',
  },
  // actCards: {
  //   simpleForm: {
  //     textColor: '#fff',
  //     fillColor: 'rgba(229,64,37,1)',
  //   },
  //   madLib: {
  //     textColor: 'rgba(242,160, 30, 1)', //'#f2a01e',
  //     fillColor: 'rgba(242,160, 30, 1)',
  //   },
  //   buzzQuiz: {
  //     textColor: 'rgba(242,160, 30, 1)', //'#f2a01e',
  //     fillColor: 'rgba(242,160, 30, 1)',
  //   },
  //   convoBot: {
  //     textColor: 'rgba(29,71, 152, 1)',
  //     fillColor: 'rgba(29,71, 152, 1)',
  //   },
  //   results: {
  //     textColor: 'rgba(102,102, 102, 1)',
  //     fillColor: 'rgba(102,102, 102, 1)',
  //   },
  // },
};

export const buzzQuiz = {};

export const workzoneCard = {
  root: {
    padding: '2rem',
    margin: '2rem auto',
  },
  paragraph: {
    margin: '1.5rem 0 0 0',
  },
  header: {
    margin: '1.5rem 0 0 0',
  },
  headerArea: {
    padding: '0',
    margin: '0',
  },
  copyArea: {
    padding: '0',
    margin: '1.5rem 0 0 0',
  },
  contentArea: {
    xs: {
      padding: '0',
      margin: '1rem 0 0 0',
    },
    sm: {
      padding: '0',
      margin: '2rem 0 0 0',
    },
    md: {
      padding: '0',
      margin: '3rem 0 0 0',
    },
  },
  footerArea: {
    padding: '0',
    margin: '1.5rem 0 0 0',
  },
};

export const actCard = {
  constants: {
    OPACITY_BACK: actCardConstants.OPACITY_BACK,
    OPACITY_SHAPE: actCardConstants.OPACITY_SHAPE,
    WIDTH: actCardConstants.WIDTH,
    HEIGHT: actCardConstants.HEIGHT,
    CORNER_RADIUS: actCardConstants.CORNER_RADIUS,
  },
  type: {
    madLib: {
      boxRadius: `0 ${actCardConstants.CORNER_RADIUS} ${actCardConstants.CORNER_RADIUS} ${actCardConstants.CORNER_RADIUS}`,
      inset: `0 0 0 0 round 0 ${actCardConstants.CORNER_RADIUS} ${actCardConstants.CORNER_RADIUS} ${actCardConstants.CORNER_RADIUS}`,
    },
    buzzQuiz: {
      boxRadius: `${actCardConstants.CORNER_RADIUS} 0 ${actCardConstants.CORNER_RADIUS} ${actCardConstants.CORNER_RADIUS}`,
      inset: `0 0 0 0 round ${actCardConstants.CORNER_RADIUS} 0 ${actCardConstants.CORNER_RADIUS} ${actCardConstants.CORNER_RADIUS}`,
    },
    convoBot: {
      boxRadius: `${actCardConstants.CORNER_RADIUS} ${actCardConstants.CORNER_RADIUS} 0 ${actCardConstants.CORNER_RADIUS}`,
      inset: `0 0 0 0 round ${actCardConstants.CORNER_RADIUS} ${actCardConstants.CORNER_RADIUS} 0 ${actCardConstants.CORNER_RADIUS}`,
    },
    simpleForm: {
      boxRadius: `${actCardConstants.CORNER_RADIUS} ${actCardConstants.CORNER_RADIUS} ${actCardConstants.CORNER_RADIUS} 0`,
      inset: `0 0 0 0 round ${actCardConstants.CORNER_RADIUS} ${actCardConstants.CORNER_RADIUS} ${actCardConstants.CORNER_RADIUS} 0`,
    },
    results: {
      boxRadius: `${actCardConstants.CORNER_RADIUS} 0`,
      inset: `0 0 0 0 round ${actCardConstants.CORNER_RADIUS} 0 ${actCardConstants.CORNER_RADIUS} 0`,
    },

    v6: {
      boxradius: '0 25px',
    },
    v7: {
      boxradius: '0',
    },
    v8: {
      boxradius: '25px',
    },
  },
  status: {
    completed: {
      label: 'completed',
      textColor: '#ffffff',
      fillColor: '#E54025',
    },
    continue: {
      label: 'continue',
      textColor: '#F2A01E', //'#f2a01e',
      fillColor: '#F2A01E', //'rgba(242,160, 30, 1)',
    },
    ready: {
      label: 'start',
      textColor: '#1D4798', //rgba(29,71, 152, 1)',
      fillColor: '#1D4798', //'rgba(29,71, 152, 1)',
    },
    locked: {
      label: 'Not Ready',
      textColor: '#666666',
      fillColor: '#666666', //rgba(102,102, 102, 1)',
    },
  },
};

// text: {
//     primary: 'rgba(0, 0, 0, 0.87)',
//     secondary: 'rgba(0, 0, 0, 0.54)',
//     disabled: 'rgba(0, 0, 0, 0.38)',
//     hint: 'rgba(0, 0, 0, 0.38)',
//   },
export const fontSize = {
  userAvatar: '36px',
  sidebarButton: '20px',
  // cardTitle: '1.8rem',
  // cardInfo: '1.4rem',
  // cardSubTitle: '1.2rem',
};

// export const colors = {
//   blk: '#25253F',
//   lightBlk: rgba('#25253F', 0.5),
//   lighterBlk: rgba('#25253F', 0.2),
//   lightest: rgba(244, 245, 238, 1),
//   pageBack: '#ffffff',
//   greenLight: '#65ff87',
//   greenMain: '#11D357',
//   greenDark: '#00a027',
//   blueLight: '#917AFF',
//   blueMain: '#554DF3',
//   blueDark: '#0023BF',
//   blueDarker: '#25253F',
//   blueLighter: '#36364E',
//   blueLightest: rgba('#554DF3', 0.1),
//   blueNormal: '#554DF3',
//   primaryGreen: '#23d160',
//   greenNormal: '#11D357',
//   fontNormal: '#fff',
//   shadowColor: '#002231',
//   primary: '#113B55',
//   accent1: '#469BF6',
//   accent2: '#FFB600',
//   button1: '#1EB273',
//   button2: '#469BF6',
//   almostBlack: rgba('black', 0.87),
//   lightBlack: rgba('black', 0.54),
//   lighterBlack: rgba('black', 0.37),
//   lightWhite: rgba('white', 0.7),
//   bodyBg: '#f3f4f6',
//   border: '#ccd3db',
//   alert: '#e0454a',
//   warning: '#FC6C2B',
//   weatherIcons: '#AEB7BF',
//   alwaysOnLight: '#2E3133',
//   alwaysOnLightFaded: rgba('#2E3133', 0.37),
//   dark: '#657786',
// };

export const fontWeight = {
  thin: 100,
  extraLight: 200,
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
  black: 900,
};

export const gradient = ['#1EB273', '#FFC105', '#E0454A'];
export const fontType = {
  finePrint: '11px',
  copy: '14px',
  title: '2rem',
  subTitle: '18px',
  answerLetter: '36px',
  answerCopy: '18px',
  feedbackCopy: '14px',
  questionCopy: '14px',
};
export const fonts = [
  {
    family: 'Montserrat',
    name: 'Montserrat-Light',
    weight: 300,
    style: 'normal',
  },
  {
    family: 'Montserrat',
    name: 'Montserrat-LightItalic',
    weight: 300,
    style: 'italic',
  },
  {
    family: 'Montserrat',
    name: 'Montserrat-Regular',
    weight: 400,
    style: 'normal',
  },
  {
    family: 'Montserrat',
    name: 'Montserrat-Italic',
    weight: 400,
    style: 'italic',
  },
  {
    family: 'Montserrat',
    name: 'Montserrat-Semibold',
    weight: 500,
    style: 'normal',
  },
  {
    family: 'Montserrat',
    name: 'Montserrat-SemiboldItalic',
    weight: 500,
    style: 'italic',
  },
  {
    family: 'Montserrat',
    name: 'Montserrat-Bold',
    weight: 700,
    style: 'normal',
  },
  {
    family: 'Montserrat',
    name: 'Montserrat-BoldItalic',
    weight: 700,
    style: 'italic',
  },
  {
    family: 'Roboto',
    name: 'Roboto-Light',
    weight: 300,
    style: 'normal',
  },
  {
    family: 'Roboto',
    name: 'Roboto-LightItalic',
    weight: 300,
    style: 'italic',
  },
  {
    family: 'Roboto',
    name: 'Roboto-Regular',
    weight: 400,
    style: 'normal',
  },
  {
    family: 'Roboto',
    name: 'Roboto-Italic',
    weight: 400,
    style: 'italic',
  },
  {
    family: 'Roboto',
    name: 'Roboto-Medium',
    weight: 500,
    style: 'normal',
  },
  {
    family: 'Roboto',
    name: 'Roboto-MediumItalic',
    weight: 500,
    style: 'italic',
  },
  {
    family: 'Roboto',
    name: 'Roboto-Bold',
    weight: 700,
    style: 'normal',
  },
  {
    family: 'Roboto',
    name: 'Roboto-BoldItalic',
    weight: 700,
    style: 'italic',
  },
];

export const fontFace = fonts
  .map(
    font => `
      @font-face {
        font-family: ${font.family};
        src: url('./fonts/${font.family}/${font.name}.ttf') format('ttf'),
        font-weight: ${font.weight};
        font-style: ${font.style};
        font-display: swap;
      }
    `
  )
  .join('');
// export default {
//   style,
//   colors,
//   fontWeight,
//   gradient,
//   fontSize,
// };
