export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const REMOVE_ALL_ITEMS = 'REMOVE_ALL_ITEMS';
export const UPDATE_CART = 'UPDATE_CART';
export const EMPTY_CART = 'EMPTY_CART';

export const PROJECT_SET = 'PROJECT_SET';
export const PROJECT_DATA_SET = 'PROJECT_DATA_SET';
export const PROJECT_STATUS_SET = 'PROJECT_STATUS_SET';
export const PROJECT_RESET = 'PROJECT_RESET';

// export const SET_PROJECT_ACTIVITY = 'SET_PROJECT_ACTIVITY';
// export const SET_PROJECT_CLIPBOARD = 'SET_PROJECT_CLIPBOARD';
// export const SET_PROJECT_WITH_OUTPUTS = 'SET_PROJECT_WITH_OUTPUTS';

export const RESULT_OUTPUTS_RESET = 'RESULT_OUTPUTS_RESET';
export const RESULT_OUTPUTS_SET = 'RESULT_OUTPUTS_SET';

export const ACTIVITY_ID_SET = 'ACTIVITY_ID_SET';
export const ACTIVITY_SET = 'ACTIVITY_SET';
export const ACTIVITY_RESET = 'ACTIVITY_RESET';
