import * as React from "react";

function SvgLogoEa(props) {
  return (
    <svg viewBox="0 0 200 260" {...props}>
      <path
        fill="#f6a31c"
        fillRule="evenodd"
        d="M138.19 116.21l-20.14 20.11-20.14 20.11-7.38-27.47-7.37-27.47 27.52 7.36 27.51 7.36z"
      />
      <path
        fill="#e93e25"
        fillRule="evenodd"
        d="M83.16 101.49l27.52 7.36 27.51 7.36-7.37-27.47-7.37-27.47-20.14 20.11-20.15 20.11z"
      />
      <path
        fill="#ec1c24"
        fillRule="evenodd"
        d="M178.48 75.99L158.33 96.1l-20.14 20.11-7.37-27.47-7.37-27.47 27.51 7.36 27.52 7.36z"
      />
      <path
        fill="#f6a31c"
        fillRule="evenodd"
        d="M159.72 198.03l-20.15 20.11-20.14 20.11-7.37-27.47-7.37-27.47 27.51 7.36 27.52 7.36z"
      />
      <path
        fill="#e93e25"
        fillRule="evenodd"
        d="M104.69 183.31l27.51 7.36 27.52 7.36-7.38-27.47-7.37-27.48-20.14 20.11-20.14 20.12z"
      />
      <path
        fill="#ec1c24"
        fillRule="evenodd"
        d="M200 157.81l-20.14 20.11-20.14 20.11-7.38-27.47-7.37-27.48 27.52 7.36 27.51 7.37z"
      />
      <path
        fill="#1d459c"
        fillRule="evenodd"
        d="M55.03 95.17l-7.37-27.48-7.38-27.47 27.52 7.36 27.51 7.37-20.14 20.11-20.14 20.11z"
      />
      <path
        fill="#24368d"
        fillRule="evenodd"
        d="M80.57 0L60.43 20.11 40.28 40.22l27.52 7.36 27.51 7.37-7.37-27.48L80.57 0z"
      />
      <path
        fill="#1b2368"
        fillRule="evenodd"
        d="M95.31 54.95l-7.37-27.48L80.57 0l27.51 7.36 27.52 7.36-20.14 20.11-20.15 20.12z"
      />
      <path
        fill="#2b4ea1"
        fillRule="evenodd"
        d="M40.28 40.22L20.14 60.33 0 80.44l27.52 7.37 27.51 7.36-7.37-27.48-7.38-27.47z"
      />
      <path
        fill="#2a54a4"
        fillRule="evenodd"
        d="M14.74 135.39l-7.37-27.47L0 80.44l27.52 7.37 27.51 7.36-20.14 20.11-20.15 20.11z"
      />
      <path
        fill="#365ca9"
        fillRule="evenodd"
        d="M55.03 95.17l-20.14 20.11-20.14 20.11 27.51 7.36 27.52 7.36-7.38-27.47-7.37-27.47z"
      />
      <path
        fill="#3a6fb6"
        fillRule="evenodd"
        d="M29.49 190.33l-7.37-27.47-7.37-27.47 27.51 7.36 27.52 7.36-20.15 20.11-20.14 20.11z"
      />
      <path
        fill="#4993cf"
        fillRule="evenodd"
        d="M69.78 150.11l-20.15 20.11-20.14 20.11 27.52 7.36 27.51 7.37-7.37-27.48-7.37-27.47z"
      />
      <path
        fill="#43c1f0"
        fillRule="evenodd"
        d="M44.24 245.28l-7.38-27.47-7.37-27.48 27.52 7.36 27.51 7.37-20.14 20.11-20.14 20.11z"
      />
      <path
        fill="#76cee5"
        fillRule="evenodd"
        d="M84.52 205.06l-20.14 20.11-20.14 20.11 27.51 7.36L99.27 260l-7.38-27.47-7.37-27.47z"
      />
    </svg>
  );
}

export default SvgLogoEa;
