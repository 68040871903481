import React, { useState, useEffect } from 'react';
import * as _ from 'lodash';
import { withFirebase } from 'api/Firebase';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
// import { deviceWidth, mediaMinDevice } from 'styles/responsive';
// import styled from 'styled-components';
// import MemberScreen from 'screens/MemberScreen';
// import DashboardItem from './DashboardItem';
import { SceneInnerHeader } from 'elements/Headers';
import { GridContainer, GridBase } from 'elements/LayoutElements';

// import { FrameA, CardA, CardB, CardC, CardD } from 'components/DashCards';
// import DashCardFrame from 'components/DashCardFrame';
// import { DashCard } from 'components/DashCard';
// import Masonry from 'modules/Masonry';

// import DashCardShadow from 'components/DashCardShadow';
// import Masonary from 'components/Masonary';
import { CSSGrid, measureItems, makeResponsive } from 'react-stonecutter';

import CardGrid from './CardGrid';
import { blogs1, blogs2, baseCards } from './data';
import ProjectSummaryCard from 'components/ProjectSummaryCard';

import * as PATHS from 'routes/paths';
// const LogoArea = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 1rem;
// `;
import { mediaMinDevice, mediaMinPadding } from 'styles/responsive';
const Mason = makeResponsive(
  measureItems(CSSGrid, { measureImages: true, background: true }),
  {
    maxWidth: 1500,
  }
);
const MasonMd = makeResponsive(
  measureItems(CSSGrid, { measureImages: true, background: true }),
  {
    maxWidth: 960,
    minPadding: mediaMinPadding.md,
  }
);
const MasonSm = makeResponsive(
  measureItems(CSSGrid, { measureImages: true, background: true }),
  {
    maxWidth: 768,
    minPadding: mediaMinPadding.sm,
  }
);
const MasonXs = makeResponsive(
  measureItems(CSSGrid, { measureImages: true, background: true }),
  {
    maxWidth: 425,
    minPadding: mediaMinPadding.xs,
  }
);

// const BoxFlex = styled.div`
//   display: flex;
//   align-items: 'center';
//   justify-content: ${props =>
//     props.screenSize === 'sm' ? 'center' : 'flex-start'};
// `;

// const StyledMason = styled(Mason)`
//   margin-bottom: 3rem;
// `;
// const StyledMasonXs = styled(MasonXs)`
//   margin-bottom: 1rem;
// `;
// const StyledMasonSm = styled(MasonSm)`
//   margin-bottom: 1rem;
// `;
// const StyledMasonMd = styled(MasonMd)`
//   margin-bottom: 1rem;
// `;
// const StyledMasonMobile = styled(MasonMobile)`
//   margin-bottom: 1rem;
// `;

const GridBaseTopCard = styled(GridBase)`
  margin-bottom: 1rem;

  max-width: 1100px;
    margin-right: 0;
         ${mediaMinDevice.deviceM} {
    margin-right: 1rem;
  }
     ${mediaMinDevice.deviceM} {
    margin-right: 2rem;
  }

  /* width: ${props => `${props.screenWidth}px`}; */
`;

// const GridBaseBottom = styled(GridBase)`
//   margin-bottom: 3rem;
// `;

const Dashboard = ({ history, firebase }) => {
  const screenSize = useSelector(state => state.app.screenSize);
  const screenWidth = useSelector(state => state.app.screenWidth);
  const [cardList, setCardList] = useState([]);
  //const [cardBaseList] = useState(baseCards);
  const gridwidth = screenWidth - 240;
  // const NewMason = makeResponsive(
  //   measureItems(CSSGrid, { measureImages: true, background: true }),
  //   {
  //     maxWidth: gridwidth,
  //   }
  // );
  const handleSummaryClick = viewObj => {
    const view = _.get(viewObj, 'statusContent.cta.url');
    console.log(`view=> ${view}`);
    if (view === 'marketplace') {
      history.push(PATHS.MEMBER_MARKETPLACE);
    } else if (view === 'workzones') {
      history.push(PATHS.MEMBER_WORKZONES);
    } else {
      console.log(`view-${view}`);
    }
  };

  // const handleSummaryClick = useCallback(
  //   view => {
  //     if (view === 'marketplace') {
  //       history.push(PATHS.MEMBER_WORKZONES);
  //     } else if (view === 'workzones') {
  //       history.push(PATHS.MEMBER_WORKZONES);
  //     } else {
  //       console.log(`view-${view}`);
  //     }
  //   },
  //   [history]
  // );

  useEffect(() => {
    // const comboList = _.concat(blogsSideRight, blogsTopRight, miscRight);
    firebase.analytics.logEvent('dashboard');
    const comboList = _.concat(blogs1, blogs2, baseCards);
    //setCardList(comboList);
    setCardList(_.shuffle(comboList));

    //return comboList;
    //setCardList(miscRight);
  }, [firebase.analytics]);

  return (
    <GridContainer container>
      <GridBaseTopCard item xs={12}>
        <ProjectSummaryCard type="card" onSummaryClick={handleSummaryClick} />
      </GridBaseTopCard>
      <GridBase item xs={12}>
        <SceneInnerHeader screenSize={screenSize}>
          Here are the latest updates for you.
        </SceneInnerHeader>
      </GridBase>
      <CardGrid screenSize={screenSize} cardList={cardList} />
    </GridContainer>
  );
};

export default withFirebase(Dashboard);
