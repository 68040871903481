import React from 'react';
import styled from 'styled-components/macro';

import BtnPlusMinus from './BtnPlusMinus';

const CardHeaderWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex: 0 0 auto;
  width: 100%;
  height: 80px;
`;

const CardHeaderAction = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
`;
const CardHeaderBack = styled.div`
  padding: 0.5rem;
  margin: 25px 25px 0.5rem 0;
  background-color: ${props => (props.backColor ? props.backColor : '#ccc')};
`;
const CardHeaderText = styled.div`
  display: flex;
  /* white-space: nowrap; */
  font-family: Montserrat;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: left;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1;
  color: ${props => (props.color ? props.color : '#000')};
`;

const CardHeader = ({
  backColor,
  textColor,
  actionColor,
  isOpen,
  handleClick,
  collapsable,
  children,
}) => {
  return (
    <CardHeaderWrapper>
      <CardHeaderBack backColor={backColor}>
        <CardHeaderText color={textColor}>{children}</CardHeaderText>
      </CardHeaderBack>
      <CardHeaderAction>
        {collapsable && (
          <BtnPlusMinus
            handleClick={handleClick}
            actionColor={actionColor}
            isOpen={isOpen}
          />
        )}
      </CardHeaderAction>
    </CardHeaderWrapper>
  );
};

export default CardHeader;
