import React from 'react';
import styled from 'styled-components/macro';
import { globals, colors } from 'styles/default';
import { deviceWidth, mediaMinDevice } from 'styles/responsive';
import Grid from '@material-ui/core/Grid';

export const WorkzoneCardContent = styled(({ ...other }) => (
  <Grid
    container
    direction="column"
    justify="center"
    alignItems="center"
    {...other}
  />
))``;

// const Container = styled.div`
//   width: 100%;
//   max-width: ${deviceWidth.deviceXL};
//   box-sizing: border-box;
//   margin: 0 auto;
//   padding: 0 1rem;
//   ${mediaMinDevice.deviceS} {
//     padding: 0 1.5rem;
//   }
// `;

const Container = styled(Grid)`
  width: 100%;
  max-width: ${deviceWidth.deviceXL};
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 1rem;
  ${mediaMinDevice.deviceS} {
    padding: 0 1.5rem;
  }
`;
const Card = styled.div`
  display: block;
  position: relative;
  /* flex-direction: column;
  align-items: center;
  justify-content: flex-start; */
  /* border-radius:  28px; */

  background-color: ${colors.card.backgroundColor};
  /* width: calc(100% - 4rem); */
  /* max-width: 1024px; */
  padding: 0;
  margin: 1rem auto;
  border-radius: none;

  box-shadow: none;

  ${mediaMinDevice.deviceM} {
    padding: ${globals.padding};
    margin: 2rem auto;
    border-radius: ${globals.borderRadius};
    box-shadow: 0 0 14px 8px ${colors.card.boxShadowColor};
  }
`;

export const WorkzoneCard = ({ children }) => {
  return (
    <Container>
      <Card>{children}</Card>
    </Container>
  );
};
