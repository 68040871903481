import React, { useState, useMemo } from 'react';
import styled from 'styled-components/macro';
// import ActCardBack from './ActCardBackV03';
import ActCardBtn from './ActCardBtn';

import { actCard } from 'styles/default';
import { hexToRGBa } from 'utils/colors';

import {
  BackConvoBot,
  BackSimpleForm,
  BackMadLib,
  BackBuzzQuiz,
  BackResults,
} from './ActCardBacks';
const ActCard = styled.div`
  position: relative;
  box-shadow: 6px 6px 10px 3px rgba(0, 0, 0, 0.25);
  padding: 0;
  border-radius: ${props => (props.borderRad ? props.borderRad : '0 0 0 0')};
  /* border-radius: 0 25px 25px 25px; */
  height: ${props => (props.height ? props.height : '185px')};
  width: ${props => (props.width ? props.width : '313px')};
  margin: 0;
  color: ${props => (props.textColor ? props.textColor : '#fff')};
background-color: #fff;
  /* background-color: ${props =>
    props.fillColor ? props.fillColor : '#fff'}; */

  /* '#e54025'; */
`;
const ActCardBackLayer = styled.div`
  position: absolute;
  height: ${props => (props.height ? props.height : '185px')};
  width: ${props => (props.width ? props.width : '313px')};
  padding: 0;
  z-index: 10;
  clip-path: inset(
    ${props => (props.inset ? props.inset : '0 0 0 0 round 0 0 0 0')}
  );
  /* clip-path: inset(0 0 0 0 round 0 25px 25px 25px); */
`;

const ActCardForeground = styled.div`
  position: absolute;
  z-index: 20;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-content: center;
  /* align-items: center; */
  height: 100%;
  width: 100%;
  padding: 1rem;
`;

const ActCardTitle = styled.div`
  font-family: Montserrat;
  font-size: 25px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${props => (props.textColor ? props.textColor : '#fff')};
  flex: 0 0;
  /* color: #fff; */
  /* //margin: 25px; */
`;

const ActCardStatusButtonArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border: none;
  flex: 1 0;
`;
const ActCardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background: transparent;
  border: none;
  flex: 3 0;
`;

const ActivityCard = ({ id, data, memberData, isActive, onHandleClick }) => {
  //console.log(`actvityButoon ${JSON.stringify(data)}`);
  const width = actCard.constants.WIDTH; //259;
  const height = actCard.constants.HEIGHT; //153;
  const [actCardType] = useState(actCard.type[data.type]);
  const [actCardStatus, setActCardStatus] = useState();

  useMemo(() => {
    let status;
    if (memberData.completed) {
      status = 'completed';
    } else if (memberData.started) {
      status = 'continue';
    } else {
      status = isActive ? 'ready' : 'locked';
    }
    // console.log(`isActive ${isActive} status=${status}`);
    // const textColor = actCard.type[data.type].textColor;
    // const fillColor = colors.actCards[data.type].fillColor;
    setActCardStatus(actCard.status[status]);
  }, [memberData.completed, memberData.started, isActive]);

  // useMemo(() => {
  //   setActCardType(actCard.type[data.type]);
  // }, [data.type]);
  // const [actStatus, setActStatus] = useState();
  // useEffect(() => {
  //   if (data.dateCompleted) {
  //     return setActStatus('complete');
  //   }
  //   if (data.dateStarted) {
  //     return setActStatus('started');
  //   }

  //   return setActStatus('ready');
  // }, [data]);
  if (actCardType && actCardStatus) {
    // console.log(
    //   `type=>${data.type} ${JSON.stringify(
    //     actCardStatus
    //   )} actCardType=> ${JSON.stringify(actCardType)}`
    // );
    return (
      <ActCard
        width={`${width}px`}
        height={`${height}px`}
        fillColor={hexToRGBa(actCardStatus.fillColor, 0.4)}
        borderRad={actCardType.boxRadius}
      >
        <ActCardForeground>
          <ActCardTitle textColor={actCardStatus.textColor}>
            {data.name}
          </ActCardTitle>
          <ActCardContent />
          <ActCardStatusButtonArea>
            <ActCardBtn
              label={actCardStatus.label}
              textColor={actCardStatus.textColor}
              fillColor={actCardStatus.fillColor}
              disabled={!isActive}
              onClick={e => onHandleClick(id, data)}
            />
          </ActCardStatusButtonArea>
        </ActCardForeground>
        <ActCardBackLayer
          inset={actCardType.inset}
          width={`${width}px`}
          height={`${height}px`}
        >
          {data.type === 'simpleForm' && (
            <BackSimpleForm
              width={width}
              height={height}
              fill={actCardStatus.fillColor}
            />
          )}
          {data.type === 'madLib' && (
            <BackMadLib
              width={width}
              height={height}
              fill={actCardStatus.fillColor}
            />
          )}
          {data.type === 'convoBot' && (
            <BackConvoBot
              width={width}
              height={height}
              fill={actCardStatus.fillColor}
            />
          )}
          {data.type === 'buzzQuiz' && (
            <BackBuzzQuiz
              width={width}
              height={height}
              fill={actCardStatus.fillColor}
            />
          )}
          {data.type === 'results' && (
            <BackResults
              width={width}
              height={height}
              fill={actCardStatus.fillColor}
            />
          )}
        </ActCardBackLayer>
      </ActCard>
    );
  }
  return <ActCard />;
};

export default ActivityCard;
