import React, { useState, useEffect, useLayoutEffect } from 'react';
import styled from 'styled-components/macro';
import BtnMain from 'elements/BtnMain';
import { Grid } from '@material-ui/core';
import MadLibForm from './MadLibForm';
import MadLibTemplate from './MadLibTemplate';
import { mediaMinDevice } from 'styles/responsive';

import { WorkzoneCardContent } from 'components/WorkzoneCard';
//import ProjectPlatformOutput from 'screens/ProjectPlatformOutput';
import {
  Name,
  Subtitle,
  Description,
  Tips,
  CopyArea,
  HeaderArea,
  ContentArea,
  ContentRow,
  FooterArea,
} from 'elements/WorkzoneText';

// import { MadLibStoryBots, MadLibFormBots } from 'elements/SvgElements';
import { MadLibBotsForm, MadLibBotsStory } from 'components/Bots';
const StickyArt = styled.div`
  position: sticky;
  max-height: 60vh;
  top: 0;
  padding: 0 0 1rem 0;
  ${mediaMinDevice.deviceS} {
    top: 40vh;
  }
  ${mediaMinDevice.deviceM} {
    top: 40vh;
  }
  ${mediaMinDevice.deviceL} {
    padding: 0 0 1rem 0;
    top: 40vh;
  }
`;

const BotGrid = styled(Grid)``;

const StickyArtStory = styled.div`
  position: sticky;
  top: 0;
  padding: 0 0 1rem 0;
  ${mediaMinDevice.deviceS} {
    top: 40vh;
  }
  ${mediaMinDevice.deviceM} {
    top: 40vh;
  }
  ${mediaMinDevice.deviceL} {
    padding: 0 0 1rem 0;
    top: 40vh;
  }
  /* padding-left: 40px; */
`;
const TabGroup = styled.div`
  /* border: solid 1px #000; */
  width: 100%;
`;
const TabPanelContent = styled.div`
  width: 100%;
`;
// const TabPanelFooter = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin-top: 1rem;
// `;

const TabPanelFooter = styled(FooterArea)`
  justify-content: space-between;
  align-items: center;
`;

// function ScrollToTopOnChange() {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return null;
// }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <TabPanelContent
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {children}
    </TabPanelContent>
  );
}
const MadLib = ({
  ready,
  submitting,
  actSaved,
  fields,
  values,
  outputs,
  handleFormChange,
  handleSave,
  handleEnd,
  actCompleted,
  mainActData,
  globals,
}) => {
  const [tabValue, setTabValue] = React.useState(0);
  //const gridRef = useRef(null);
  const handleClickSave = e => {
    e.preventDefault();
    setTabValue(1);
    handleSave(e);
  };
  const handleClickBack = e => {
    e.preventDefault();
    setTabValue(0);
  };
  const [formComplete, setFormComplete] = useState(false);
  useEffect(() => {
    if (!values || !fields) {
      setFormComplete(false);
    } else {
      const formDone = Object.keys(values).length >= Object.keys(fields).length;
      // console.log(
      //   `values=>${Object.keys(values).length} ___ fields=>${
      //     Object.keys(fields).length
      //   }`
      // );
      setFormComplete(formDone);
    }
  }, [values, fields]);

  // useEffect(() => {
  //   console.log(`tabValue=> ${tabValue}`);
  //   console.log(`____scrollY_____${window.scrollY}`);
  //   const element = forwardRef.current;

  //   setTimeout(() => {
  //     element.scrollTo({
  //       behavior: 'smooth',
  //       top: 0,
  //     });
  //   }, 100);
  // }, [forwardRef, tabValue]);

  useLayoutEffect(() => {
    console.log(`useses`);
    window.scrollTo(0, 0);
  }, [tabValue]);

  return (
    <WorkzoneCardContent>
      <HeaderArea>
        <Name>{mainActData.name}</Name>
        <Subtitle>{mainActData.subtitle}</Subtitle>
      </HeaderArea>
      <CopyArea>
        <Description
          dangerouslySetInnerHTML={{
            __html: mainActData.description,
          }}
        />
        <Tips
          dangerouslySetInnerHTML={{
            __html: mainActData.tips,
          }}
        />
      </CopyArea>
      {/* <ActivityGrid container item xs={12}>
        <ActivityName>{mainActData.name}</ActivityName>
        <ActivitySubtitle>{mainActData.subtitle}</ActivitySubtitle>
        <ActivityDescription
          dangerouslySetInnerHTML={{
            __html: mainActData.description,
          }}
        />
        <ActivityDescription
          dangerouslySetInnerHTML={{
            __html: mainActData.tips,
          }}
        />
      </ActivityGrid> */}

      <ContentArea>
        <TabGroup>
          <TabPanel value={tabValue} index={0}>
            {ready && (
              <ContentRow
                container
                direction="row"
                justify="space-between"
                spacing={0}
              >
                <Grid item xs={12} md={6}>
                  <MadLibForm
                    inputs={fields}
                    values={values}
                    handleChange={handleFormChange}
                  />
                </Grid>
                <BotGrid item xs={12} md={6}>
                  <StickyArt>
                    <MadLibBotsForm
                      width="100%"
                      height="100%"
                      preserveAspectRatio="xMidYMin"
                      main="#272964"
                      bota="#76cbe2"
                      botb="#f2a01e"
                      botc="#e82127"
                      greymd="#e6e7e8"
                      greydk="#d0d2d3"
                      greylt="#f2f2f2"
                      pinka="#e7c7df"
                      purplea="#6c63ff"
                      bluea="#76cbe2"
                      golda="#f2a01e"
                      reda="#e82127"
                    />
                  </StickyArt>
                </BotGrid>
              </ContentRow>
            )}

            <TabPanelFooter>
              <BtnMain
                label="Next"
                variant="contained"
                disabled={submitting || !formComplete}
                onClick={handleClickSave}
              />
            </TabPanelFooter>
          </TabPanel>
          <TabPanel value={tabValue} index={1}>
            <ContentRow
              container
              direction="row"
              justify="space-between"
              spacing={0}
            >
              <Grid item xs={12} md={4}>
                <StickyArtStory>
                  <MadLibBotsStory
                    width="100%"
                    height="50vh"
                    preserveAspectRatio="xMidYMid meet"
                    main="#272964"
                    bota="#76cbe2"
                    botb="#f2a01e"
                    botc="#e82127"
                    greymd="#e6e7e8"
                    greydk="#d0d2d3"
                    greylt="#f2f2f2"
                    pinka="#e7c7df"
                    purplea="#6c63ff"
                    bluea="#76cbe2"
                    golda="#f2a01e"
                    reda="#e82127"
                  />
                </StickyArtStory>
              </Grid>

              <Grid item xs={12} md={8}>
                {((values && actSaved) || (values && actCompleted)) && (
                  <MadLibTemplate values={values} outputs={outputs} />
                )}
              </Grid>
            </ContentRow>

            {fields && (
              <TabPanelFooter>
                <BtnMain label="back" onClick={handleClickBack} />
                <BtnMain
                  label="Save & End Activity"
                  disabled={submitting || !actSaved}
                  onClick={handleEnd}
                />
              </TabPanelFooter>
            )}
          </TabPanel>
        </TabGroup>
      </ContentArea>
    </WorkzoneCardContent>
  );
};

export default MadLib;
