export const colors = {
  bluedark: {
    primary: '#282964',
    secondary: '#355ca3',
    headerText: '#272963',
    titleText: '#272963',
    copyText: '#000000',
  },
  bluelight: {
    primary: '#77cae1',
    secondary: '#77cae1',
    headerText: '#272963',
    titleText: '#272963',
    copyText: '#000000',
  },
  gold: {
    primary: '#f09f21',
    secondary: '#f0b14c',
    headerText: '#f09f21',
    titleText: '#272963',
    copyText: '#000000',
  },
  red: {
    primary: '#e62529',
    secondary: '#e62529',
    headerText: '#ffffff',
    titleText: '#272963',
    copyText: '#000000',
  },
};
