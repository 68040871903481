import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { colors } from 'styles/default';
import { mediaMinDevice } from 'styles/responsive';
import Slide from '@material-ui/core/Slide';
// import IconButton from '@material-ui/core/IconButton';
// import BtnMain from 'elements/BtnMain';
import Icon from 'elements/Icon';
import { mdiClose } from '@mdi/js';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const CloseIcon = styled(Icon)`
  transition: transform 300ms ease-in-out;
  color: ${colors.common.white};
  width: 1.8rem;
  height: auto;
`;

const CloseBtn = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  flex: 0 0 auto;

  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  &:hover ${CloseIcon} {
    transform: scale(1.2);
  }
`;

const ModalTitle = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  color: ${colors.common.white};
  font-size: 1.3rem;
  text-align: center;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;

  text-transform: uppercase;
  ${mediaMinDevice.deviceS} {
    font-size: 1.5rem;
  }
`;
const ModalContent = styled(DialogContent)`
  padding: 0 1.5rem;
`;

export const DialogModalContent = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.2;
  color: ${colors.common.white};
  font-size: 1.3rem;
  text-align: center;
  ${mediaMinDevice.deviceS} {
    font-size: 1.5rem;
  }
`;
export const DialogModalContentHtml = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.2;
  text-align: left;
  color: ${colors.common.white};
  > p {
    font-size: 1.2rem;
    margin: 0 0 0.5rem 0;
    ${mediaMinDevice.deviceS} {
      margin: 0 0 1rem 0;
      font-size: 1.3rem;
    }
  }
`;

const DialogHeaderArea = styled.div`
  flex: 0 0 auto;
  margin: 0;
  padding: 1rem;
`;

const ModalHeader = styled(({ children, onClose, ...other }) => (
  <DialogHeaderArea>
    <ModalTitle>{children}</ModalTitle>
    {onClose ? (
      <CloseBtn aria-label="close" onClick={onClose}>
        <CloseIcon path={mdiClose} size="1.8rem" color="#fff" />
      </CloseBtn>
    ) : null}
  </DialogHeaderArea>
))``;
const ModalActions = styled(DialogActions)`
  justify-content: space-between;
`;

const Modal = styled(Dialog)`
  & .MuiDialog-paper {
    padding: 1rem;
    background-color: ${colors.primary.dark};
    border-radius: 18px;
    padding: 1rem;
    position: relative;
    max-width: 600px;
    width: 100%;
  }
  & .MuiDialog-container {
    /* background-color: rgba(230, 37, 41, 0.2); */
  }
  & .MuiDialog-paperFullScreen {
  }
  & .MuiDialog-paperFullWidth {
    /* ${mediaMinDevice.deviceS} {
      height: calc(100% - 65px);
    } */
  }
`;
const ModalBtn = styled(Button)`
  background-color: ${colors.common.white};
  border-color: ${colors.primary.dark};
  border-width: 2px;
  border-style: solid;
  border-radius: 5;
  color: ${colors.primary.dark};
  outline: 'none';
  line-height: 1;
  padding: 0.5rem;
  font-weight: 'bold';
  text-transform: 'uppercase';
  & .MuiButton-label {
    color: ${colors.primary.dark};
  }
  &:hover {
    background-color: ${colors.primary.dark};
    border-color: ${colors.common.white};
    & .MuiButton-label {
      color: #fff;
    }
  }
`;

export const DialogModal = ({
  isOpen,
  children,
  title,
  actionCancel,
  actionClose,
  actionOk,
  labelCancel = 'Cancel',
  labelOk = 'Ok',
  disableBackDrop = false,
}) => {
  const screenSize = useSelector(state => state.app.screenSize);
  return (
    <Modal
      TransitionComponent={Transition}
      keepMounted
      // fullScreen={
      //   screenSize === 'xs' ? true : screenSize === 'sm' ? true : false
      // }
      disableBackdropClick={disableBackDrop}
      fullScreen={screenSize === 'xs' ? true : false}
      maxWidth="lg"
      fullWidth={true}
      open={isOpen}
      onClose={actionClose}
      aria-labelledby="responsive-dialog-title"
    >
      <ModalHeader id="responsive-dialog-title" onClose={actionClose}>
        {title}
      </ModalHeader>
      <ModalContent>{children}</ModalContent>
      <ModalActions>
        <ModalBtn autoFocus onClick={actionCancel}>
          {labelCancel}
        </ModalBtn>
        <ModalBtn onClick={actionOk} autoFocus>
          {labelOk}
        </ModalBtn>
      </ModalActions>
    </Modal>
  );
};
