import React from 'react';

function SvgMadLibBotsStory(props) {
  return (
    <svg viewBox="0 0 191.26 509.9" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <g data-name="Layer 2">
            <g data-name="MadLib Bots-Story">
              <path
                fill="none"
                stroke={props.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.389}
                d="M124.754 8.943l58.389-7.75 6.92 52.144-58.388 7.749z"
              />
              <g
                style={{
                  isolation: 'isolate',
                }}
                fontSize={8.028}
                fill={props.main}
                fontFamily="Montserrat-Bold,Montserrat"
                fontWeight={700}
              >
                <text
                  transform="matrix(1.07 -.14 .13 .99 136.68 37.88)"
                  style={{
                    isolation: 'isolate',
                  }}
                >
                  {'HOOR'}
                </text>
                <text
                  transform="matrix(1.07 -.14 .13 .99 164.48 34.24)"
                  style={{
                    isolation: 'isolate',
                  }}
                  letterSpacing="-.042em"
                >
                  {'A'}
                </text>
                <text
                  transform="matrix(1.07 -.14 .13 .99 170.69 33.43)"
                  style={{
                    isolation: 'isolate',
                  }}
                  letterSpacing=".019em"
                >
                  {'Y'}
                </text>
                <text
                  transform="matrix(1.07 -.14 .13 .99 176.69 32.65)"
                  style={{
                    isolation: 'isolate',
                  }}
                >
                  {'!'}
                </text>
              </g>
              <path
                d="M114.06 111.93s27.6-13.1 29.9-42.1l-6.3-2.6s-4.1 24.3-26.5 37.8"
                fill={props.greymd}
              />
              <path
                d="M114.06 111.93s27.6-13.1 29.9-42.1l-6.3-2.6s-4.1 24.3-26.5 37.8"
                fill="none"
                stroke={props.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.376}
              />
              <path
                fill="none"
                stroke={props.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.09}
                d="M120.06 108.13l-4.2-6M125.26 103.63l-4.7-5.4M130.06 99.43l-5.1-5.2M140.06 83.53l-6.1-3.5M133.76 94.53l-5.6-4.7M137.56 88.93l-6-4.4M142.36 76.43l-6.1-2.5"
              />
              <path
                d="M137.56 56c-1.5 6.3-2.1 12.7 3 13.9s8.1-4.4 9.6-10.7l-4 .4c-.8 3.1-1.7 5.9-4.3 5.3s-1.9-3.1-1.1-6.2z"
                fill={props.greymd}
              />
              <path
                d="M137.56 56c-1.5 6.3-2.1 12.7 3 13.9s8.1-4.4 9.6-10.7l-4 .4c-.8 3.1-1.7 5.9-4.3 5.3s-1.9-3.1-1.1-6.2z"
                fill="none"
                stroke={props.main}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.766}
              />
              <g>
                <path
                  d="M45.66 113.53s-26.6-14.9-27.1-44l6.5-2.2s2.5 24.5 23.9 39.4"
                  fill={props.greymd}
                />
                <path
                  d="M45.66 113.53s-26.6-14.9-27.1-44l6.5-2.2s2.5 24.5 23.9 39.4"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.376}
                />
                <path
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.09}
                  d="M39.86 109.43l4.6-5.8M35.06 104.53l4.9-5.1M30.46 100.03l5.5-4.8M21.56 83.53l6.3-3.1M27.06 94.83l6-4.2M23.66 89.03l6.3-3.9M19.66 76.33l6.3-2.2"
                />
                <path
                  d="M25.66 56.13c1.1 6.4 1.2 12.8-3.9 13.7s-7.8-4.9-8.9-11.3l3.9.6c.6 3.2 1.3 6 3.9 5.6s2.1-2.9 1.5-6.1z"
                  fill={props.greymd}
                />
                <path
                  d="M25.66 56.13c1.1 6.4 1.2 12.8-3.9 13.7s-7.8-4.9-8.9-11.3l3.9.6c.6 3.2 1.3 6 3.9 5.6s2.1-2.9 1.5-6.1z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.766}
                />
              </g>
              <g>
                <path
                  d="M112.16 162.23h-61.4a3.15 3.15 0 01-3.2-3.12v-57.78a3.16 3.16 0 013.12-3.2h61.58a3.17 3.17 0 013.2 3.12V159a3.3 3.3 0 01-3.3 3.23z"
                  fill={props.greymd}
                />
                <path
                  d="M111.06 162.23h-61.5a3.15 3.15 0 01-3.2-3.12v-57.78a3.16 3.16 0 013.12-3.2h61.58a3.17 3.17 0 013.2 3.12V159a3.11 3.11 0 01-3 3.24z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.475}
                />
                <g
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path
                    fill={props.greymd}
                    strokeWidth={2.049}
                    d="M66.86 93.63h29.2v4.5h-29.2z"
                  />
                  <path
                    fill="none"
                    strokeWidth={1.09}
                    d="M70.96 93.63v4.1M75.36 93.63v4.1M79.66 93.93v4.1M83.76 93.93v4.1M92.16 93.63v4.1M87.96 93.93v4.1"
                  />
                </g>
                <path
                  d="M95.76 127.63h-30.1a1.1 1.1 0 01-1.1-1.1v-14.1a1.12 1.12 0 011.1-1.1h30.1a1.12 1.12 0 011.1 1.1v14.1a1.11 1.11 0 01-1.1 1.1z"
                  style={{
                    isolation: 'isolate',
                  }}
                  fill={props.botc}
                  opacity={0.3}
                />
                <path
                  d="M95.76 127.23h-30.1a1.1 1.1 0 01-1.1-1.1V112a1.12 1.12 0 011.1-1.1h30.1a1.12 1.12 0 011.1 1.1v14.1a1.11 1.11 0 01-1.08 1.14z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.376}
                />
                <g
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.376}
                >
                  <path d="M65.46 134.33v12.8M71.86 134.33v12.8M78.06 134.33v12.8M83.86 134.33v12.8M95.96 134.33v12.8M89.96 134.33v12.8" />
                </g>
              </g>
              <g>
                <path fill={props.greymd} d="M86.46 163.43h20.9v9.4h-20.9z" />
                <path
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.752}
                  d="M86.46 163.43h20.9v8.9h-20.9z"
                />
                <path
                  d="M109 176.63c0-4.7-5.4-8.5-12-8.5s-12 3.8-12 8.5z"
                  fill={props.greymd}
                />
                <path
                  d="M108.86 176.63c0-4.7-5.4-8.5-12-8.5s-12 3.8-12 8.5z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.609}
                />
                <g>
                  <path fill={props.greymd} d="M55.36 163.43h20.9v9.4h-20.9z" />
                  <path
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.545}
                    d="M55.36 163.43h20.9v8.9h-20.9z"
                  />
                  <path
                    d="M77.86 176.63c0-4.7-5.4-8.5-12-8.5s-12 3.8-12 8.5z"
                    fill={props.greymd}
                  />
                  <path
                    d="M77.76 176.63c0-4.7-5.4-8.5-12-8.5s-12 3.8-12 8.5z"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.609}
                  />
                </g>
              </g>
              <g>
                <path
                  d="M46 71.63a6.5 6.5 0 010-13M114.76 58.53a6.5 6.5 0 110 13"
                  fill={props.greydk}
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.376}
                />
                <path
                  d="M81.46 37s.6-10.2 10.5-12.3"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.376}
                />
                <circle cx={99.46} cy={22.93} r={5.8} fill={props.greymd} />
                <circle
                  cx={98.96}
                  cy={22.93}
                  r={5.8}
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.376}
                />
                <path
                  d="M111.26 93.43h-61.7a3.12 3.12 0 01-3.1-3.1v-53a3.12 3.12 0 013.1-3.1h61.7a3.12 3.12 0 013.1 3.1v53a3.12 3.12 0 01-3.1 3.1z"
                  fill={props.greymd}
                />
                <path
                  d="M111.26 93.43h-61.7a3.12 3.12 0 01-3.1-3.1v-53a3.12 3.12 0 013.1-3.1h61.7a3.12 3.12 0 013.1 3.1v53a3.12 3.12 0 01-3.1 3.1z"
                  fill="none"
                  stroke={props.main}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2.376}
                />
                <g>
                  <circle
                    cx={61.96}
                    cy={73.73}
                    r={5.9}
                    style={{
                      isolation: 'isolate',
                    }}
                    fill={props.botc}
                    opacity={0.3}
                  />
                  <circle
                    cx={99.46}
                    cy={73.73}
                    r={5.9}
                    style={{
                      isolation: 'isolate',
                    }}
                    fill={props.botc}
                    opacity={0.3}
                  />
                  <path
                    d="M87.86 67.93c.2 3-2.9 5.6-6.8 5.8s-7.2-2-7.4-5"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={3.168}
                  />
                  <circle cx={65.66} cy={58.53} r={3.7} fill={props.main} />
                  <path
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.635}
                    d="M95.36 56.03l-4.9 3.7 6 1.3"
                  />
                </g>
              </g>
              <g>
                <g data-name="Legs">
                  <g data-name="LegRight">
                    <path
                      fill={props.greymd}
                      d="M88.66 323.73h23.7v10.7h-23.7z"
                    />
                    <path
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3.118}
                      d="M88.66 323.73h23.7v10.1h-23.7z"
                    />
                    <path
                      d="M114.16 338.63c0-5.3-6.1-9.6-13.6-9.6s-13.6 4.3-13.6 9.6z"
                      fill={props.greymd}
                    />
                    <path
                      d="M114.06 338.63c0-5.3-6.1-9.6-13.6-9.6s-13.6 4.3-13.6 9.6z"
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2.956}
                    />
                  </g>
                  <g data-name="LegLeft">
                    <path
                      fill={props.greymd}
                      d="M53.46 323.73h23.7v10.7h-23.7z"
                    />
                    <path
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2.883}
                      d="M53.46 323.73h23.7v10.1h-23.7z"
                    />
                    <path
                      d="M79 338.63c0-5.3-6.1-9.6-13.6-9.6s-13.6 4.3-13.6 9.6z"
                      fill={props.greymd}
                    />
                    <path
                      d="M78.86 338.63c0-5.3-6.1-9.6-13.6-9.6s-13.6 4.3-13.6 9.6z"
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2.956}
                    />
                  </g>
                </g>
                <g data-name="ArmRight">
                  <path
                    d="M120 265.33s31.3-14.8 33.9-47.7l-7.1-2.9s-4.6 27.5-30 42.8"
                    fill={props.greymd}
                  />
                  <path
                    d="M120 265.33s31.3-14.8 33.9-47.7l-7.1-2.9s-4.6 27.5-30 42.8"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.692}
                  />
                  <path
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.236}
                    d="M126.76 261.03l-4.8-6.8M132.66 255.93l-5.3-6.1M138.06 251.13l-5.8-5.8M149.36 233.13l-6.9-3.9M142.26 245.63l-6.3-5.3M146.56 239.33l-6.8-5M152.06 225.13l-7-2.8"
                  />
                  <path
                    d="M146.56 202c-1.7 7.1-2.4 14.4 3.4 15.7s9.2-5 10.9-12.1l-4.5.5c-.9 3.5-1.9 6.7-4.9 6-2.8-.7-2.2-3.5-1.2-7z"
                    fill={props.greymd}
                  />
                  <path
                    d="M146.56 202c-1.7 7.1-2.4 14.4 3.4 15.7s9.2-5 10.9-12.1l-4.5.5c-.9 3.5-1.9 6.7-4.9 6-2.8-.7-2.2-3.5-1.2-7z"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.001}
                  />
                </g>
                <g data-name="ArmLeft">
                  <path
                    d="M47 256.53s-30.1 16.9-30.7 49.9l7.4 2.5s2.8-27.8 27.1-44.6"
                    fill={props.greymd}
                  />
                  <path
                    d="M47 256.53s-30.1 16.9-30.7 49.9l7.4 2.5s2.8-27.8 27.1-44.6"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.692}
                  />
                  <path
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.236}
                    d="M40.46 261.13l5.2 6.6M34.96 266.73l5.6 5.7M29.76 271.83l6.2 5.4M19.66 290.53l7.2 3.5M25.96 277.73l6.7 4.7M22.06 284.23l7.1 4.5M17.56 298.63l7.1 2.5"
                  />
                  <path
                    d="M24.36 321.53c1.2-7.3 1.4-14.5-4.4-15.5s-8.8 5.6-10.1 12.8l4.4-.7c.7-3.6 1.5-6.8 4.4-6.3s2.4 3.3 1.7 6.9z"
                    fill={props.greymd}
                  />
                  <path
                    d="M24.36 321.53c1.2-7.3 1.4-14.5-4.4-15.5s-8.8 5.6-10.1 12.8l4.4-.7c.7-3.6 1.5-6.8 4.4-6.3s2.4 3.3 1.7 6.9z"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.001}
                  />
                </g>
                <g data-name="Body">
                  <path
                    data-name="BodyBack"
                    d="M117.76 322.33h-69.5a3.59 3.59 0 01-3.6-3.58v-65.42a3.59 3.59 0 013.58-3.6H118a3.6 3.6 0 013.6 3.58v65.42a3.75 3.75 0 01-3.84 3.6z"
                    fill={props.greymd}
                  />
                  <path
                    data-name="BodyPath"
                    d="M116.56 322.33h-69.7a3.59 3.59 0 01-3.6-3.58v-65.42a3.59 3.59 0 013.58-3.6h69.72a3.6 3.6 0 013.6 3.58v65.42a3.52 3.52 0 01-3.44 3.6z"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.805}
                  />
                  <g
                    data-name="Neck"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path
                      fill={props.greymd}
                      strokeWidth={2.322}
                      d="M66.46 244.63h33.1v5.1h-33.1z"
                    />
                    <path
                      fill="none"
                      strokeWidth={1.236}
                      d="M71.16 244.63v4.6M76.06 244.63v4.6M80.96 244.93v4.7M85.66 244.93v4.7M95.16 244.63v4.6M90.36 244.93v4.7"
                    />
                  </g>
                  <g data-name="Chest">
                    <g data-name="ChestPlate">
                      <path
                        d="M99.26 283.13h-34.1a1.22 1.22 0 01-1.2-1.2v-16a1.22 1.22 0 011.2-1.2h34.1a1.22 1.22 0 011.2 1.2v16a1.29 1.29 0 01-1.2 1.2z"
                        style={{
                          isolation: 'isolate',
                        }}
                        fill={props.botb}
                        opacity={0.3}
                      />
                      <path
                        d="M99.26 282.63h-34.1a1.22 1.22 0 01-1.2-1.2v-16a1.22 1.22 0 011.2-1.2h34.1a1.22 1.22 0 011.2 1.2v16a1.22 1.22 0 01-1.2 1.2z"
                        fill="none"
                        stroke={props.main}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2.692}
                      />
                    </g>
                    <g
                      data-name="ChestGrate"
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2.692}
                    >
                      <path d="M64.86 290.73v14.5M72.16 290.73v14.5M79.16 290.73v14.5M85.76 290.73v14.5M99.46 290.73v14.5M92.66 290.73v14.5" />
                    </g>
                  </g>
                </g>
                <g data-name="Head">
                  <path
                    data-name="EarLeft"
                    d="M42.76 219.63a7.4 7.4 0 010-14.8"
                    fill={props.greydk}
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.692}
                  />
                  <path
                    data-name="EarRight"
                    d="M120.76 204.83a7.4 7.4 0 010 14.8"
                    fill={props.greydk}
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.692}
                  />
                  <path
                    data-name="HeadBack"
                    d="M116.76 244.33h-69.9a3.54 3.54 0 01-3.5-3.5v-60a3.54 3.54 0 013.5-3.5h69.9a3.54 3.54 0 013.5 3.5v60a3.48 3.48 0 01-3.46 3.5z"
                    fill={props.greymd}
                  />
                  <path
                    data-name="HeadPath"
                    d="M116.76 244.33h-69.9a3.54 3.54 0 01-3.5-3.5v-60a3.54 3.54 0 013.5-3.5h69.9a3.54 3.54 0 013.5 3.5v60a3.48 3.48 0 01-3.46 3.5z"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.692}
                  />
                  <g data-name="Antenna">
                    <circle
                      cx={103.36}
                      cy={164.53}
                      r={6.6}
                      fill={props.greymd}
                    />
                    <path
                      d="M83.06 180.43s.7-11.6 11.9-13.9"
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2.692}
                    />
                    <circle
                      cx={102.86}
                      cy={164.53}
                      r={6.6}
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2.692}
                    />
                  </g>
                  <g data-name="Face">
                    <circle
                      cx={60.96}
                      cy={222.03}
                      r={6.7}
                      style={{
                        isolation: 'isolate',
                      }}
                      fill={props.botb}
                      opacity={0.3}
                    />
                    <circle
                      cx={103.36}
                      cy={222.03}
                      r={6.7}
                      style={{
                        isolation: 'isolate',
                      }}
                      fill={props.botb}
                      opacity={0.3}
                    />
                    <g fill="none" stroke={props.main} strokeLinecap="round">
                      <path
                        d="M90.26 215.53c.2 3.4-3.3 6.3-7.7 6.6s-8.2-2.3-8.4-5.7"
                        strokeMiterlimit={10}
                        strokeWidth={3.589}
                      />
                      <path
                        strokeLinejoin="round"
                        strokeWidth={2.986}
                        d="M98.76 202.03l-5.5 4.2 6.8 1.4M66.46 202.03l5.6 4.2-6.8 1.4"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g>
                <g data-name="ArmLeft">
                  <path
                    d="M40.36 432.53s-32-17.9-32.5-52.8l7.8-2.6s3 29.5 28.7 47.3"
                    fill={props.greymd}
                  />
                  <path
                    d="M40.36 432.53s-32-17.9-32.5-52.8l7.8-2.6s3 29.5 28.7 47.3"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.852}
                  />
                  <path
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.309}
                    d="M33.46 427.53l5.5-7M27.66 421.63l5.9-6.1M22.16 416.23l6.6-5.8M11.46 396.43l7.6-3.8M18.16 410.03l7.1-5.1M14.06 403.03l7.6-4.7M9.26 387.73l7.5-2.5"
                  />
                  <path
                    d="M16.36 363.53c1.3 7.6 1.5 15.4-4.7 16.4s-9.3-5.9-10.6-13.5l4.7.8c.7 3.8 1.6 7.2 4.7 6.7s2.5-3.5 1.9-7.3z"
                    fill={props.greymd}
                  />
                  <path
                    d="M16.36 363.53c1.3 7.6 1.5 15.4-4.7 16.4s-9.3-5.9-10.6-13.5l4.7.8c.7 3.8 1.6 7.2 4.7 6.7s2.5-3.5 1.9-7.3z"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.119}
                  />
                </g>
                <g data-name="ArmRight">
                  <path
                    d="M115.86 434.13s32-17.9 32.5-52.8l-7.8-2.6s-3 29.5-28.7 47.3"
                    fill={props.greymd}
                  />
                  <path
                    d="M115.86 434.13s32-17.9 32.5-52.8l-7.8-2.6s-3 29.5-28.7 47.3"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.852}
                  />
                  <path
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.309}
                    d="M122.76 429.13l-5.4-6.9M128.56 423.33l-5.9-6.1M134.06 417.83l-6.5-5.7M144.76 398.13l-7.6-3.8M138.06 411.73l-7.1-5.2M142.16 404.73l-7.5-4.7M146.96 389.43l-7.5-2.6"
                  />
                  <path
                    d="M139.86 365.23c-1.3 7.6-1.5 15.4 4.7 16.4s9.3-5.9 10.6-13.5l-4.7.8c-.7 3.8-1.6 7.2-4.7 6.7s-2.5-3.5-1.9-7.3z"
                    fill={props.greymd}
                  />
                  <path
                    d="M139.86 365.23c-1.3 7.6-1.5 15.4 4.7 16.4s9.3-5.9 10.6-13.5l-4.7.8c-.7 3.8-1.6 7.2-4.7 6.7s-2.5-3.5-1.9-7.3z"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.119}
                  />
                </g>
                <g data-name="Body">
                  <path
                    data-name="BodyBack"
                    d="M116.06 491h-73.8a3.91 3.91 0 01-3.9-3.9v-69.3a3.91 3.91 0 013.9-3.9h73.8a3.91 3.91 0 013.9 3.9v69.3a3.84 3.84 0 01-3.78 3.9z"
                    fill={props.greymd}
                  />
                  <path
                    data-name="BodyPath"
                    d="M114.76 491H41a3.91 3.91 0 01-3.9-3.9v-69.3a3.91 3.91 0 013.9-3.9h73.8a3.91 3.91 0 013.9 3.9v69.3a3.84 3.84 0 01-3.78 3.9z"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.971}
                  />
                  <g
                    data-name="Neck"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path
                      fill={props.greymd}
                      strokeWidth={2.459}
                      d="M61.66 408.73h35v5.4h-35z"
                    />
                    <path
                      fill="none"
                      strokeWidth={1.309}
                      d="M66.66 408.73v4.9M71.96 408.73v4.9M77.06 409.03v4.9M81.96 409.03v4.9M92.06 408.73v4.9M87.06 409.03v4.9"
                    />
                  </g>
                  <g data-name="Chest">
                    <g data-name="ChestPlate">
                      <path
                        d="M96.36 449.53h-36.1a1.42 1.42 0 01-1.4-1.4v-16.9a1.43 1.43 0 011.4-1.4h36.1a1.43 1.43 0 011.4 1.4v16.9a1.37 1.37 0 01-1.34 1.4z"
                        style={{
                          isolation: 'isolate',
                        }}
                        fill={props.bota}
                        opacity={0.3}
                      />
                      <path
                        d="M96.36 449h-36.1a1.42 1.42 0 01-1.4-1.4v-16.9a1.43 1.43 0 011.4-1.4h36.1a1.43 1.43 0 011.4 1.4v16.9a1.37 1.37 0 01-1.34 1.4z"
                        fill="none"
                        stroke={props.main}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2.852}
                      />
                    </g>
                    <g
                      data-name="ChestGrate"
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2.852}
                    >
                      <path d="M60.06 457.53v15.4M67.66 457.53v15.4M75.06 457.53v15.4M82.06 457.53v15.4M96.66 457.53v15.4M89.46 457.53v15.4" />
                    </g>
                  </g>
                </g>
                <g data-name="Legs">
                  <g data-name="LegLeft">
                    <path
                      fill={props.greymd}
                      d="M85.16 492.53h25.1v11.3h-25.1z"
                    />
                    <path
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3.303}
                      d="M85.16 492.53h25.1v10.7h-25.1z"
                    />
                    <path
                      d="M112.26 508.33c0-5.6-6.4-10.2-14.3-10.2s-14.3 4.6-14.3 10.2z"
                      fill={props.greymd}
                    />
                    <path
                      d="M112.06 508.33c0-5.6-6.4-10.2-14.3-10.2s-14.3 4.6-14.3 10.2z"
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3.131}
                    />
                  </g>
                  <g data-name="LegRight">
                    <path
                      fill={props.greymd}
                      d="M47.86 492.53h25.1v11.3h-25.1z"
                    />
                    <path
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3.054}
                      d="M47.86 492.53h25.1v10.7h-25.1z"
                    />
                    <path
                      d="M74.86 508.33c0-5.6-6.4-10.2-14.3-10.2s-14.3 4.6-14.3 10.2z"
                      fill={props.greymd}
                    />
                    <path
                      d="M74.76 508.33c0-5.6-6.4-10.2-14.3-10.2s-14.3 4.6-14.3 10.2z"
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={3.131}
                    />
                  </g>
                </g>
                <g data-name="Head">
                  <path
                    data-name="HeadBack"
                    d="M116 408.43H41.86a3.69 3.69 0 01-3.7-3.68v-63.62a3.69 3.69 0 013.68-3.7H116a3.69 3.69 0 013.7 3.68v63.62a3.68 3.68 0 01-3.68 3.7z"
                    fill={props.greymd}
                  />
                  <path
                    data-name="HeadPath"
                    d="M115 408.43H40.86a3.69 3.69 0 01-3.7-3.68v-63.62a3.69 3.69 0 013.68-3.7H115a3.69 3.69 0 013.7 3.68v63.62a3.68 3.68 0 01-3.68 3.7z"
                    fill="none"
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.852}
                  />
                  <path
                    data-name="EarLeft"
                    d="M36.66 382.33a7.8 7.8 0 010-15.6"
                    fill={props.greydk}
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.852}
                  />
                  <path
                    data-name="EarRight"
                    d="M119.16 366.63a7.8 7.8 0 010 15.6"
                    fill={props.greydk}
                    stroke={props.main}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2.852}
                  />
                  <g data-name="Antenna">
                    <path
                      d="M79.16 340.73s.7-12.2 12.6-14.7"
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2.852}
                    />
                    <circle
                      cx={100.76}
                      cy={323.83}
                      r={6.9}
                      fill={props.greymd}
                    />
                    <circle
                      cx={100.16}
                      cy={323.83}
                      r={6.9}
                      fill="none"
                      stroke={props.main}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2.852}
                    />
                  </g>
                  <g data-name="Face">
                    <circle
                      cx={55.56}
                      cy={368.73}
                      r={7.1}
                      style={{
                        isolation: 'isolate',
                      }}
                      fill={props.bota}
                      opacity={0.3}
                    />
                    <circle
                      cx={100.26}
                      cy={369.43}
                      r={7.1}
                      style={{
                        isolation: 'isolate',
                      }}
                      fill={props.bota}
                      opacity={0.3}
                    />
                    <circle cx={64.56} cy={354.93} r={4.4} fill={props.main} />
                    <circle cx={89.46} cy={354.93} r={4.4} fill={props.main} />
                    <path
                      d="M78.76 370.63c6.3 0 12-6.2 12 .1s-5.7 9.5-12 9.5S68 377 68 370.73s4.46-.1 10.76-.1z"
                      fill={props.main}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default SvgMadLibBotsStory;
