import { combineReducers } from 'redux';
import productsReducer from './products';
import sessionReducer from './session';
import cart from './cart';
import cartitems from './cartitems';
import projectReducer from './projectReducer';
import appReducer from './appReducer';
import projectResults from './projectResults';
import activity from './activity';
const rootReducer = combineReducers({
  sessionState: sessionReducer,
  app: appReducer,
  products: productsReducer,
  cart,
  cartitems,
  projectState: projectReducer,
  projectResults,
  activity: activity,
});
export default rootReducer;
