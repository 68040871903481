import React from 'react';

const SvgPatternBottomRight = props => (
  <svg viewBox="0 0 401.23 333.27" {...props}>
    <defs>
      <pattern
        id="PatternBottomRight_svg__a"
        data-name="New Pattern 6"
        width={9}
        height={9}
        patternTransform="translate(-67.5 265.52)"
        patternUnits="userSpaceOnUse"
      >
        <path fill="none" d="M0 0h9v9H0z" />
        <path d="M4.5 3L3 6h3L4.5 3z" />
      </pattern>
    </defs>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path fill="#4990cc" d="M268.09 265.78h66.5v66.5" />
        <path fill="#76cbe2" d="M401.23 265.69v66.5h-66.5" />
        <path fill="#282965" d="M334.73 332.19h-66.5v-66.5" />
        <path fill="#4990cc" d="M268.23 265.69v66.5h-66.51" />
        <path fill="#f2a01e" d="M334.73 265.69v-66.5h66.5" />
        <path
          fill="url(#PatternBottomRight_svg__a)"
          d="M334.73 199.19v66.5h-66.5"
        />
        <path fill="#e82127" d="M334.73 332.19v-66.5h66.5" />
        <path fill="#f2a01e" d="M268.09 199.28v66.5h-66.5" />
        <path fill="#e54025" d="M268.09 265.78v-66.5h66.5" />
        <path fill="#4990cc" d="M334.59 199.28h-66.5v-66.5" />
        <path fill="#76cbe2" d="M334.59 199.28v-66.5h66.5" />
        <path fill="#1d4797" d="M401.09 132.78v66.5h-66.5" />
        <path
          fill="url(#PatternBottomRight_svg__a)"
          d="M334.4 132.77v-66.5h66.5v66.5h-66.5z"
        />
        <path fill="#282965" d="M401.02 0v66.5h-66.51" />
        <path fill="#e82127" d="M67.44 265.83v67.44H0v-67.44z" />
        <path
          fill="url(#PatternBottomRight_svg__a)"
          d="M67.44 333.27v-67.44h67.45"
        />
        <path fill="#1d4797" d="M202.33 265.83v67.44h-67.44v-67.44z" />
        <path fill="#f2a01e" d="M134.12 199.28v66.5h-66.5" />
        <path fill="#76cbe2" d="M200.88 266.48v-66.5h66.51" />
      </g>
    </g>
  </svg>
);

export default SvgPatternBottomRight;
