import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Landing from './Landing';
import Member from './Member';
import Workzone from './Workzone';
import * as PATHS from './paths';

export default () => (
  <BrowserRouter>
    <Switch>
      <Route path={PATHS.MEMBER} component={Member} />
      <Route path={PATHS.WORKZONE} component={Workzone} />
      <Route path={PATHS.LANDING} component={Landing} />
      {/* <Route component={Error} /> */}
    </Switch>
  </BrowserRouter>
);
