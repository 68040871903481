import styled from 'styled-components/macro';
import { Grid } from '@material-ui/core';

export const GridContainer = styled(Grid)`
  display: flex;
  padding: 0;
  flex-wrap: wrap;
  list-style: none;
`;
export const GridBase = styled(Grid)``;
