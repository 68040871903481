import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import SimpleFormBlur from './SimpleFormBlur';
import EmailTemplate from './EmailTemplate';
import BtnMain from 'elements/BtnMain';
// import ProjectPlatformOutput from 'screens/ProjectPlatformOutput';
import { WorkzoneCardContent } from 'components/WorkzoneCard';
import {
  Name,
  Tips,
  HeaderArea,
  CopyArea,
  ContentArea,
  Subtitle,
  Description,
  ContentRow,
  ContentCol,
  FooterArea,
} from 'elements/WorkzoneText';

const SimpleForm = ({
  ready,
  submitting,
  actSaved,
  fields,
  values,
  outputs,
  handleFormChange,
  handleSave,
  handleEnd,
  mainActData,
}) => {
  const [formComplete, setFormComplete] = useState(false);
  useEffect(() => {
    if (!values || !fields) {
      setFormComplete(false);
    } else {
      const formDone = Object.keys(values).length >= Object.keys(fields).length;
      // console.log(
      //   `values=>${Object.keys(values).length} ___ fields=>${
      //     Object.keys(fields).length
      //   }`
      // );
      setFormComplete(formDone);
    }
  }, [values, fields]);
  console.log(`_______${mainActData.name}`);
  return (
    <WorkzoneCardContent>
      <HeaderArea>
        <Name>{mainActData.name}</Name>
        <Subtitle>{mainActData.subtitle}</Subtitle>
      </HeaderArea>
      <CopyArea>
        <Description
          dangerouslySetInnerHTML={{
            __html: mainActData.description,
          }}
        />
        <Tips
          dangerouslySetInnerHTML={{
            __html: mainActData.tips,
          }}
        />
      </CopyArea>
      <ContentArea>
        <ContentRow
          container
          direction="row"
          justify="space-between"
          spacing={0}
        >
          <ContentCol item xs={12} md={6}>
            {ready && (
              <SimpleFormBlur
                inputs={fields}
                values={values}
                handleChange={handleFormChange}
              />
            )}
            {/* {formComplete && (
          <BtnMain
            label="Next"
            textColor="#1D4798"
            fillColor="#345CA4"
            variant="contained"
            disabled={submitting}
            onClick={handleSave}
          />
        )} */}
          </ContentCol>
          <Grid item xs={12} md={6}>
            {outputs && <EmailTemplate values={values} outputs={outputs} />}
          </Grid>
        </ContentRow>
      </ContentArea>
      <FooterArea>
        <BtnMain
          label="Save & End Activity"
          textColor="#1D4798"
          fillColor="#345CA4"
          disabled={!formComplete}
          onClick={handleEnd}
        />
      </FooterArea>
    </WorkzoneCardContent>
  );
};

export default SimpleForm;
