import React, { useState, useEffect, useRef, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withFirebase } from 'api/Firebase';

import {
  Name,
  Subtitle,
  Description,
  Tips,
  CopyArea,
  HeaderArea,
  ContentArea,
} from 'elements/WorkzoneText';

import BtnResults from 'elements/BtnResults';
// import ResultsHolder from './ResultsHolder';
import BtnMain from 'elements/BtnMain';
import ResultsContent from './ResultsContent';
import * as PATHS from 'routes/paths';

import WorkzoneCard, { WorkzoneCardContent } from 'components/WorkzoneCard';
// import { setProjectOutputs } from 'store/actions/projectPlatform';
import { resultOutputsSet } from 'store/actions/projectResults';

import { mediaMinDevice } from 'styles/responsive';
const ResultBtnsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  z-index: 80;
  ${mediaMinDevice.deviceM} {
    justify-content: space-between;
  }
`;
const ResultBtnDesktopContainer = styled.div`
  /* display: flex;
  align-self: flex-end; */
  position: absolute;
  top: 2rem;
  right: 2rem;
`;

const ResultsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;
const ResultsBuilderBtnContainer = styled.div`
  width: 100%;
  margin: 1rem 0 0 0;
`;
const WorkzoneProjectResults = ({ history, firebase }) => {
  const dispatch = useDispatch();
  const isDesktop = useSelector(state => state.app.isDesktop);
  const authId = useSelector(state => state.sessionState.authUser.uid);
  const projectId = useSelector(state => state.projectState.projectId);
  const resultId = useSelector(state => state.projectState.resultId);
  const projectData = useSelector(state => state.projectState.data);

  const [isProjectComplete, setIsProjectComplete] = useState(false);

  const [resultStart, setResultStart] = useState(false);
  const [activityData, setActivityData] = useState();
  const [sections, setSections] = useState();
  const [currentSection, setCurrentSection] = useState();
  const [templateData, setTemplateData] = useState();

  const memberProjectRef = useRef(
    firebase.fstore
      .collection('members')
      .doc(authId)
      .collection('projects')
      .doc(projectId)
  );
  const memberActivityRef = useRef(
    firebase.fstore
      .collection('members')
      .doc(authId)
      .collection('projects')
      .doc(projectId)
      .collection('activitys')
      .doc(resultId)
  );
  const activityRef = useRef(
    firebase.fstore
      .collection('projects')
      .doc(projectId)
      .collection('activitys')
      .doc(resultId)
  );
  const projectTemplatesRef = useRef(
    firebase.dbase.ref(`/projects/${projectId}/templates`)
  );

  useEffect(() => {
    const getMemberActivity = async () => {
      const currentRef = memberActivityRef.current;
      const snap = await currentRef.get();
      const docData = snap.data();

      const { started } = docData;

      if (!started) {
        await currentRef.update({
          started: true,
          dateStarted: firebase.fieldValue.serverTimestamp(),
          dateUpdated: firebase.fieldValue.serverTimestamp(),
        });
      } else {
        await currentRef.update({
          dateUpdated: firebase.fieldValue.serverTimestamp(),
        });
      }
    };
    getMemberActivity();
  }, [firebase.fieldValue]);

  useEffect(() => {
    const unsubscribe = memberProjectRef.current
      .collection('outputs')
      .where('projectOutputType', 'in', [
        'global',
        'ad',
        'first5',
        'email',
        'ongoingPosts',
        'missing',
      ])
      .onSnapshot(function(snapshot) {
        var data = snapshot.docs
          .map(function(documentSnapshot) {
            return documentSnapshot.data();
          })
          .reduce((obj, item) => {
            // console.log(`item.key ${item.id}`);
            obj[item.id] = item.value;
            return obj;
          }, {});
        dispatch(resultOutputsSet(data));
        //dispatch({ type: 'SET_PROJECT_OUTPUTS', outputs: data });
        //setOutputFields(data);
      });
    return () => unsubscribe();
  }, [dispatch, memberProjectRef]);

  useEffect(() => {
    if (projectData.status === 'complete') {
      setIsProjectComplete(true);
    } else {
      setIsProjectComplete(false);
    }
  }, [projectData.status]);

  useEffect(() => {
    const fetchSections = async () => {
      const actvityDocSnap = await activityRef.current.get();
      const activityDocData = actvityDocSnap.data();
      setActivityData(activityDocData);
      // const sectionCollection = activityRef.current.collection('sections');
      //const sectionGet = await sectionCollection.orderBy('order').get();
      //const sectionData = sectionGet.docs.map(docsnap => docsnap.data());
      //console.log(`current sectionData=> ${Object.keys(sectionData).length}`);

      const templateDataRef = projectTemplatesRef.current;
      const templates = await templateDataRef
        .orderByChild('order')
        .once('value');

      let sectionsArray = [];
      templates.forEach(childSnap => {
        sectionsArray.push(childSnap.val());
      });

      // let templateArray = [];
      // templates.forEach(childSnap => {
      //   const obj = {};
      //   obj[childSnap.key] = childSnap.val();

      //   templateArray.push(childSnap.val());
      // });
      //const sectionData = templateArray.reduce();
      const templateData = sectionsArray.reduce((obj, item) => {
        obj[item.type] = item;
        return obj;
      }, {});
      // console.log(`current templateData=> ${JSON.stringify(templateData)}`);
      // console.log(`current sectionsArray=> ${JSON.stringify(sectionsArray)}`);
      setTemplateData(templateData);
      setSections(sectionsArray);
      setResultStart(true);
    };
    if (!isProjectComplete) {
      fetchSections();
    }
  }, [isProjectComplete, activityRef, projectTemplatesRef]);
  // useEffect(() => {
  //   const fetchSections = async () => {
  //     const actvityDocSnap = await activityRef.current.get();
  //     const activityDocData = actvityDocSnap.data();
  //     setActivityData(activityDocData);
  //     const sectionCollection = activityRef.current.collection('sections');
  //     const sectionGet = await sectionCollection.orderBy('order').get();
  //     const sectionData = sectionGet.docs.map(docsnap => docsnap.data());
  //     console.log(`current sectionData=> ${Object.keys(sectionData).length}`);
  //     setSections(sectionData);
  //     setResultStart(true);
  //   };
  //   if (!isProjectComplete) {
  //     fetchSections();
  //   }
  // }, [isProjectComplete, activityRef]);

  // const handleSectionType = useCallback(type => {
  //   // console.log(`handleSectiontype=> ${type}`);
  //   setCurrentSection(type);
  // }, []);

  const changeSectionType = useCallback(
    type => {
      // console.log(`handleSectiontype=> ${type}`);
      if (currentSection === type) {
        setCurrentSection(type);
      } else {
        setCurrentSection(type);
      }
    },
    [currentSection]
  );
  const handleResetSectionType = useCallback(() => {
    // console.log(`handleSectiontype=> ${type}`);
    setCurrentSection('');
  }, []);
  const gotoBuilder = () => {
    history.push(`${PATHS.WORKZONE_PROJECT_RESULTS_BUILDER}`);
  };

  if (!projectId) {
    return <Redirect to={PATHS.MEMBER_WORKZONES} />;
  }
  if (isProjectComplete) {
    return <Redirect to={PATHS.WORKZONE_PROJECT_RESULTS_BUILDER} />;
  }
  return (
    <WorkzoneCard>
      {resultStart && (
        <WorkzoneCardContent>
          <HeaderArea>
            <Name>{activityData.name}</Name>
            {activityData.subtitle && (
              <Subtitle>{activityData.subtitle}</Subtitle>
            )}
          </HeaderArea>
          {isDesktop && (
            <ResultBtnDesktopContainer>
              <BtnMain label="Build My Package" onClick={gotoBuilder} />
            </ResultBtnDesktopContainer>
          )}

          <CopyArea>
            {isDesktop ? (
              <Description
                dangerouslySetInnerHTML={{
                  __html: activityData.description,
                }}
              />
            ) : (
              <Description
                dangerouslySetInnerHTML={{
                  __html: activityData.descriptionMobile,
                }}
              />
            )}

            {activityData.tips && (
              <Tips
                dangerouslySetInnerHTML={{
                  __html: activityData.tips,
                }}
              />
            )}
          </CopyArea>
          <ContentArea>
            <ResultBtnsContainer>
              {sections &&
                sections.map(sec => (
                  <BtnResults
                    currentType={currentSection ? currentSection : ''}
                    key={sec.order}
                    type={sec.type}
                    label={sec.name}
                    onClick={() => changeSectionType(sec.type)}
                  />
                ))}
            </ResultBtnsContainer>
            <ResultsContainer>
              {currentSection && (
                <ResultsContent
                  templateData={templateData}
                  projectRef={memberProjectRef.current}
                  sectionType={currentSection}
                  resetSectionType={handleResetSectionType}
                />
              )}
            </ResultsContainer>
            {!isDesktop && (
              <ResultsBuilderBtnContainer>
                <BtnMain
                  fullWidth
                  label="Build Package"
                  onClick={gotoBuilder}
                />
              </ResultsBuilderBtnContainer>
            )}
          </ContentArea>
        </WorkzoneCardContent>
      )}
    </WorkzoneCard>
  );
};

export default withRouter(withFirebase(WorkzoneProjectResults));

//       {resultStart && (
//         <WorkzoneCardContent>
//           <ResultsGrid>
//             <ResultsRow>
//               <Name>{activityData.name}</Name>
//               {isDesktop && (
//                 <BtnMain label="Build My Package" onClick={gotoBuilder} />
//               )}
//             </ResultsRow>
//             {activityData.subtitle && (
//               <ResultsRow>
//                 <ResultsSubheader>{activityData.subtitle}</ResultsSubheader>
//               </ResultsRow>
//             )}
//             {isDesktop ? (
//               <ResultsDescription
//                 dangerouslySetInnerHTML={{
//                   __html: activityData.description,
//                 }}
//               />
//             ) : (
//               <ResultsDescription
//                 dangerouslySetInnerHTML={{
//                   __html: activityData.descriptionMobile,
//                 }}
//               />
//             )}

//             {activityData.tips && (
//               <ResultsDescription
//                 dangerouslySetInnerHTML={{
//                   __html: activityData.tips,
//                 }}
//               />
//             )}
//           </ResultsGrid>
//           <ResultBtnsContainer>
//             {sections &&
//               sections.map(sec => (
//                 <BtnResults
//                   currentType={currentSection ? currentSection : ''}
//                   key={sec.order}
//                   type={sec.type}
//                   label={sec.name}
//                   onClick={() => changeSectionType(sec.type)}
//                 />
//               ))}
//           </ResultBtnsContainer>
//           <ResultsContainer>
//             {currentSection && (
//               <ResultsContent
//                 templateData={templateData}
//                 projectRef={memberProjectRef.current}
//                 sectionType={currentSection}
//                 resetSectionType={handleResetSectionType}
//               />
//             )}
//           </ResultsContainer>

//           {!isDesktop && (
//             <ResultsBuilderBtnContainer>
//               <BtnMain fullWidth label="Build Package" onClick={gotoBuilder} />
//             </ResultsBuilderBtnContainer>
//           )}
//         </WorkzoneCardContent>
//       )}
//     </WorkzoneCard>
//   );
// };

// export default withRouter(withFirebase(WorkzoneProjectResults));
