import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import * as _ from 'lodash';
import { Popover } from '@material-ui/core';
import { mdiCheckboxMarkedCircleOutline } from '@mdi/js';
import Icon from 'elements/Icon';
import { highlightYellow, highlightBlue } from 'styles/colors';

import { colors } from 'styles/default';
const TextAreaInput = styled(TextareaAutosize)`
  border-radius: 12px;
  border: solid 2px ${colors.primary.dark};
  padding: 16px;
  line-height: 16px;
  font-size: 16px;
  height: auto;
  max-width: 100%;
  resize: none;
  &:focus {
    outline: none;
    outline-offset: 0;
    background-color: ${colors.textArea.focusBack};
  }
`;

const BulletSpan = styled.span`
  color: #000000;
  display: inline-block;
  margin: 0 1rem;
  transform: scale(2);
`;

const OutputSpan = styled.span`
  color: #000000;

  background: ${props => (props.isTemp ? highlightBlue : highlightYellow)};
`;

const FieldPopoverWrap = styled.div`
  display: flex;
  background-color: transparent;
  color: #000000;
  padding: 8px;
  justify-content: center;
  border-radius: 12px;
`;
const StyledPopover = styled(Popover)`
  && .MuiPaper-root {
    background: #fff;
    border-radius: 12px;
  }
`;

// const StyledPopover = styled(({ color, ...other }) => <Button {...other} />)`
//   background: linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%);
//   border: 0;
//   color: white;
//   height: 48px;
//   padding: 0 30px;
//   box-shadow: 0 3px 5px 2px rgba(255, 105, 135, 0.3);

//   & .MuiButton-label {
//     color: ${props => props.color};
//   }
// `;
const PopoverBtnArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const PopoverBtn = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  height: 48px;
  border-radius: 0 12px 12px 0;
  margin-left: 12px;
  border: solid 2px #fff;
`;
const PopoverBtnLabel = styled.p`
  margin: 0 12px;
  font-size: 16px;
  line-height: 16px;
  color: ${colors.primary.dark};
`;
// const FieldInputVal = styled(InputBase)`
//   &&& .MuiInputBase-input {
//     white-space: nowrap;
//   }
// `;

const FieldPopover = ({ fval, handleChange, handleSave }) => {
  const handleOnChange = event => {
    handleChange(event.target.value);
  };
  return (
    <FieldPopoverWrap>
      <TextAreaInput
        placeholder={fval}
        value={fval}
        cols={50}
        onChange={handleOnChange}
      />
      <PopoverBtnArea>
        {/* <PopoverBtn onClick={handleClose}>
          <Icon path={mdiCancel} />
          <PopoverBtnLabel>cancel</PopoverBtnLabel>
        </PopoverBtn>  */}
        <PopoverBtn onClick={handleSave}>
          <Icon path={mdiCheckboxMarkedCircleOutline} />
          <PopoverBtnLabel>save</PopoverBtnLabel>
        </PopoverBtn>
      </PopoverBtnArea>
    </FieldPopoverWrap>
  );
};
const ResultContentBuilder = ({
  fieldId,
  fieldValue,
  handleValueChange,
  fieldType = 'content',
  fieldVariant = 'normal',
}) => {
  const [originalFieldValue, setOriginalFieldValue] = useState();
  const [newFieldValue, setNewFieldValue] = useState();

  useEffect(() => {
    let fieldVal;
    if (fieldVariant === 'firstcap') {
      fieldVal = _.capitalize(fieldValue);
    } else {
      fieldVal = fieldValue;
    }
    setOriginalFieldValue(fieldVal);
    setNewFieldValue(fieldVal);
  }, [fieldId, fieldType, fieldValue, fieldVariant]);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
    e.preventDefault();
  };

  const handleClose = e => {
    setNewFieldValue(originalFieldValue);
    setAnchorEl(null);
    e.preventDefault();
  };
  const onHandleSave = e => {
    e.preventDefault();
    console.log(`handleSave`);
    handleValueChange(fieldId, newFieldValue);
    setAnchorEl(null);
  };
  const onChangeValue = val => {
    setNewFieldValue(val);
  };

  const open = Boolean(anchorEl);

  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {fieldType === 'contentBullet' && <BulletSpan>•</BulletSpan>}

      <OutputSpan isTemp={false} aria-describedby={id} onClick={handleClick}>
        {newFieldValue}
      </OutputSpan>
      <StyledPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}

        // PaperProps={{
        //   style: {
        //     borderRadius: '#12px',
        //   },
        // }}
      >
        <FieldPopover
          fid={fieldId}
          fval={newFieldValue}
          handleClose={handleClose}
          handleChange={onChangeValue}
          handleSave={onHandleSave}
        />
      </StyledPopover>
    </>
  );
};

export default ResultContentBuilder;
