import React from 'react';
import { useSelector } from 'react-redux';
// import { Grid } from '@material-ui/core';
import styled from 'styled-components/macro';
// import { SceneHeader, SceneSubHeader } from 'elements/Headers';
import { colors } from 'styles/default';
// import { ReactComponent as HeaderTopLeft } from 'assets/svg/HeaderTopLeft.svg';
import { mediaMinDevice, mediaMinPadding } from 'styles/responsive';

import {
  PatternTopLeft,
  PatternTopRight,
  PatternBottomRight,
} from 'elements/UiPatterns';
// import SvgSubhead from 'elements/UiPatterns/SvgSubhead';
// import headerTopLeft from 'assets/imgs/HeaderTopLeft.png';
// import headerBottomRight from 'assets/imgs/HeaderBottomRight.png';
// import UiHeaderMobile from 'elements/UiHeaderMobile';

const MainWindow = styled.div`
  display: flex;
`;
const SideCol = styled.div`
  width: 240px;
  flex-grow: 0;
`;

const ContentWindow = styled.div`
  flex-grow: 1;
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: auto;
  /* height: 100%;
  overflow-x: hidden;
  overflow-y: auto; */
  /* background-image: url('/imgs/header.png');
  background-position: top left;
  background-repeat: no-repeat; */
`;
// const ContentHeaderPattern = styled.div`
//   position: absolute;
//   width: 100vw;
//   height: 120px;
//   object-fit: cover;
//   overflow: hidden;
//   > img {
//     margin-top: -65px;
//   }
// `;
const ContentHeaderPatternWeb = styled.div`
  position: absolute;
  width: 500px;
  top: -4px;
  z-index: 36;
`;
const ContentFooterPattern = styled.div`
  position: fixed;
  bottom: -16px;
  right: 0;
  width: 300px;
  z-index: 38;
`;
// const ContentFooterPatternLeft = styled.div`
//   position: fixed;
//   bottom: -16px;
//   left: 0;
//   width: 300px;
//   z-index: 38;
// `;
// const ContentHeaderPatternWeb = styled.div`
//   position: absolute;

//   object-fit: cover;
//   overflow: hidden;
//   > img {
//     margin-top: -75px;
//     margin-left: 165px;
//   }
//   z-index: 38;
// `;
// const ContentHeaderPatternMobile = styled.div`

//   object-fit: cover;
//   overflow: hidden;
//   > img {
//     margin-top: -10px;
//   }
//   z-index: 38;
// `;
const ContentHeaderPatternMobile = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  > svg {
    min-width: 220px;
    max-width: 300px;
  }
`;
// const ContentFooterPattern = styled.div`
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   object-fit: cover;
//   overflow: hidden;
//   > img {
//     margin-bottom: -80px;
//   }
//   z-index: 38;
// `;
// const ContentHeader = styled.div`
//   position: static;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-end;
//   align-items: center;
//   padding-right: 50px;
//   min-height: 120px;
//   > img {
//     margin-top: -60px;
//   }
//   z-index: 90;
// `;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0;
 /* margin-bottom: ${props => (props.isDesktop ? '100px' : '0')}; */
  /* margin-left: ${props => (props.isDesktop ? '240px' : '0')}; */
  background-color: ${colors.member.backColor};
  flex: 1 1 100%;
  padding: 0;
   /* padding: ${props => (props.isDesktop ? '0' : '0 36px')}; */
  z-index: 40;
  min-height: 100%;
  width: ${props => `${props.width}px`};
  margin: 140px 1rem 0 1rem;

  ${mediaMinDevice.deviceS} {
    margin: 140px 1rem 0 1rem;
  }
  /* ${mediaMinDevice.deviceM} {
     margin: 140px 24px 0 24px;
  } */
  ${mediaMinDevice.deviceM} {
    margin: 170px 1rem 0 272px;
  }

`;

const MemberScreen = ({ children }) => {
  const isDesktop = useSelector(state => state.app.isDesktop);
  const screenWidth = useSelector(state => state.app.screenWidth);
  const screenSize = useSelector(state => state.app.screenSize);
  return (
    <MainWindow>
      <SideCol></SideCol>
      <ContentWindow>{children}</ContentWindow>
      {isDesktop ? (
        <ContentHeaderPatternWeb>
          <PatternTopLeft width="100%" height="100%" />
        </ContentHeaderPatternWeb>
      ) : (
        <ContentHeaderPatternMobile width={screenWidth * 0.693}>
          <PatternTopRight width={screenWidth * 0.693} height="100%" />
        </ContentHeaderPatternMobile>
      )}
      {isDesktop && (
        <ContentFooterPattern>
          <PatternBottomRight width="100%" height="100%" />
        </ContentFooterPattern>
      )}
    </MainWindow>
  );
};
export default MemberScreen;
//     <Wrapper>
//       {/* <ContentHeader /> */}
//       {isDesktop ? (
//         <ContentHeaderPatternWeb>
//           <PatternTopLeft width="100%" height="100%" />
//         </ContentHeaderPatternWeb>
//       ) : (
//         <ContentHeaderPatternMobile width={screenWidth * 0.693}>
//           <PatternTopRight width={screenWidth * 0.693} height="100%" />
//         </ContentHeaderPatternMobile>
//       )}

//       {screenSize === 'xs' && (
//         <Content width={screenWidth - mediaMinPadding.xs}>{children}</Content>
//       )}
//       {screenSize === 'sm' && (
//         <Content width={screenWidth - mediaMinPadding.sm}>{children}</Content>
//       )}
//       {screenSize === 'md' && (
//         <Content width={screenWidth - mediaMinPadding.md}>{children}</Content>
//       )}

//       {screenSize === 'lg' && (
//         <Content width={screenWidth - mediaMinPadding.lg}>{children}</Content>
//       )}
//       {isDesktop && (
//         <ContentFooterPattern>
//           <PatternBottomRight width="100%" height="100%" />
//         </ContentFooterPattern>
//       )}
//     </Wrapper>
//   );
// };
