import React from 'react';

import { LogoJumpStartArtboard } from 'components/SvgLogos';
import BtnSmall from 'elements/BtnSmall';
import { colors } from 'styles/default';
import Panel from 'components/Panel';

import {
  ProjectType,
  ProjectTitle,
  ProjectCardHeader,
  ProjectCardContentArea,
  ProjectCardContentText,
  ProjectCardFooter,
} from 'elements/ProjectCardElements';

const CARD_WIDTH = 270; //'320px'
const CARD_HEIGHT = 210; //'380px'

// const ProductCardHeader = styled.div`
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: flex-start;
//   margin: 10px;
// `;

// const ProjectType = styled.div``;
// const ProjectTitle = styled.div`
//   font-family: Montserrat;
//   font-size: 18px;
//   font-weight: 700;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1;
//   letter-spacing: normal;
//   color: #345ca4;
//   white-space: nowrap;
// `;

// const ProductCardContent = styled.div`
//   display: flex;
//   flex: 1 0 auto;
//   margin: 0 0.9rem;
// `;
// const ProductCardContentText = styled.div`
//   display: flex;
//   flex-direction: column;
//   height: auto;

//   font-size: 1rem;
//   font-weight: 400;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1;
//   letter-spacing: normal;
//   color: #345ca4;
//   white-space: normal;
//   text-align: left;
// `;

// const ProductCardFooter = styled.div`
//   display: flex;
//   flex: 0 0 auto;
//   justify-content: center;
//   align-items: center;
//   margin: 0 0 10px 0;
// `;

const ProjectItem = ({
  project,
  handleContinueProject,
  handleLaunchProject,
}) => {
  const clickContinueProject = ev => {
    ev.preventDefault();
    handleContinueProject(project);
  };
  const clickLaunchProject = ev => {
    ev.preventDefault();
    handleLaunchProject(project);
  };

  return (
    <Panel size="small" width={`${CARD_WIDTH}px`} height={`${CARD_HEIGHT}px`}>
      <ProjectCardHeader>
        <ProjectType>
          <LogoJumpStartArtboard
            height="100%"
            width="100%"
            secondaryclr={colors.logos.jumpstart.secondaryColor}
            primaryclr={colors.logos.jumpstart.primaryColor}
          />
        </ProjectType>
        <ProjectTitle>{project.name}</ProjectTitle>
      </ProjectCardHeader>

      {!project.active && (
        <ProjectCardContentArea>
          <ProjectCardContentText
            dangerouslySetInnerHTML={{ __html: project.launchDescription }}
          />
        </ProjectCardContentArea>
      )}
      {project.active && (
        <ProjectCardContentArea>
          {project.status === 'complete' ? (
            <ProjectCardContentText
              dangerouslySetInnerHTML={{
                __html: project.completeDescription,
              }}
            />
          ) : (
            <ProjectCardContentText
              dangerouslySetInnerHTML={{
                __html: project.continueDescription,
              }}
            />
          )}
        </ProjectCardContentArea>
      )}

      {/* <ProductCardContent>
        <div dangerouslySetInnerHTML={{ __html: item.storeDescription }} />
      </ProductCardContent> */}
      <ProjectCardFooter>
        {!project.active && (
          <BtnSmall
            label="Start"
            textColor={colors.product.btnTextColor}
            fillColor={colors.product.btnBackColor}
            onClick={clickLaunchProject}
          />
        )}
        {project.active && (
          <BtnSmall
            label={project.status === 'complete' ? 'My Results' : 'Continue'}
            textColor={colors.product.btnTextColor}
            fillColor={colors.product.btnBackColor}
            onClick={clickContinueProject}
          />
        )}
      </ProjectCardFooter>
    </Panel>
  );
};
export default ProjectItem;
