import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { colors } from 'styles/default';
// import ScrollToTopMount from 'utils/ScrollToTopMount';
const LineHead = styled.div`
  font-family: Montserrat;
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.primary.dark};
  margin-bottom: 2rem;
`;

const TemplateArea = styled.div`
  margin: auto;
  display: flex;
  outline: 0;
  border-radius: 0;
  justify-content: center;
  background-color: ${colors.common.white};
`;
const TemplateCard = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  outline: 0;
  border-radius: 0;
  justify-content: center;
  background-color: ${colors.common.white};
  padding: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  white-space: pre-wrap;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  align-items: baseline;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.text.primary};
`;
const ContentQuote = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto 2rem auto;
`;
// font-family: inherit;
// font-size: 0.8rem;
// line-height: 1rem;
// height: 2rem;
const ContentInnerText = styled.span`
  white-space: pre-wrap;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  height: 60px;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.text.primary};
`;
const OutputArea = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  min-height: 60px;
  padding-left: 2px;
  padding-right: ${props => (props.nospace ? '0' : '6px')};
`;
const OutputAreaMain = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const ExtraText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.text.primary};
  margin-bottom: 26px;
  padding-left: 3px;
`;
// const OutputValue = styled.div`
//   text-align: center;
//   font-size: 0.8rem;
//   line-height: 1rem;
//   color: #333333;
//   font-weight: 800;
// `;
const OutputValue = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.text.primary};
`;

// const OutputDivider = styled.hr`
//   width: 100%;
//   height: 2px;
//   padding: 0;
//   margin: 0;
// `;
const OutputDivider = styled.div`
  width: 100%;
  height: 3px;
  padding: 0;
  margin: 0;

  border-bottom: solid 2px #000;
`;
const OutputLabel = styled.div`
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #999999;
  text-transform: lowercase;
  margin-bottom: 0.5rem;
`;
const ContentText = ({ children }) => {
  const words = children.toString().split(' ');
  const thewords = words.map((item, index) => (
    <ContentInnerText key={index}>{`${item} `}</ContentInnerText>
  ));
  return thewords;
};
// const FieldOutput = ({ globalState, fieldId }) => {
//   if (globalState.hasOwnProperty(fieldId)) {
//     return <OutputSpan>{globalState[fieldId].value} </OutputSpan>;
//   }
//   return <OutputSpan>{fieldId} </OutputSpan>;
// };
const FieldOutput = ({ actState, fieldId, extraTxt = '' }) => {
  let value, label;
  if (actState.hasOwnProperty(fieldId)) {
    value = `${actState[fieldId].value}`;
    label = actState[fieldId].label;
  } else {
    value = `missing}`;
    label = actState[fieldId].label;
  }
  return (
    <OutputArea nospace={extraTxt === '_' ? true : false}>
      <OutputAreaMain>
        <OutputValue>{value}</OutputValue>
        <OutputDivider />
        <OutputLabel>( {label} )</OutputLabel>
      </OutputAreaMain>

      {extraTxt && extraTxt !== '_' && <ExtraText>{extraTxt}</ExtraText>}
    </OutputArea>
  );
};
const MadLibTemplate = ({ values }) => {
  const [actState, setActState] = useState();

  useEffect(() => {
    setActState(values);
  }, [values]);
  // const arrayToObject = (array, keyField) =>
  //   array.reduce((obj, item) => {
  //     obj[item[keyField]] = item;
  //     return obj;
  //   }, {});

  //const globalState = arrayToObject(globals, 'id');
  //const actState = arrayToObject(outputs, 'id');
  return (
    <TemplateArea>
      {actState && (
        <TemplateCard>
          <LineHead>The Big Announcement</LineHead>
          <Content>
            <ContentText>Once upon a time, </ContentText>{' '}
            <FieldOutput actState={actState} fieldId="firstName" />{' '}
            <ContentText>opened </ContentText>
            <FieldOutput actState={actState} fieldId="businessName" />
            <ContentText> in </ContentText>
            <FieldOutput
              actState={actState}
              fieldId="yearBusinessEstablished"
            />
            <ContentText>
              and shouted to strangers walking down the sidewalk,
            </ContentText>
            <ContentText>“We are </ContentText>
            <FieldOutput actState={actState} fieldId="businessName" />{' '}
            <ContentText>and we will help</ContentText>{' '}
            <FieldOutput actState={actState} fieldId="audienceName" />
            <ContentText> with </ContentText>
            <FieldOutput actState={actState} fieldId="audienceProblem2" />{' '}
            <ContentText>until these doors close!”</ContentText>
          </Content>
          <Content>
            <ContentText>As </ContentText>
            <FieldOutput actState={actState} fieldId="firstName" />{' '}
            <ContentText>grabbed a </ContentText>
            <FieldOutput actState={actState} fieldId="noun1" />{' '}
            <ContentText>so the door didn’t immediately close,</ContentText>
            <ContentText> they thought, “I will write about </ContentText>
            <FieldOutput actState={actState} fieldId="contentTopic1" />{' '}
            <ContentText>
              and those strangers will go crazy for it and soon they will become
              customers.
            </ContentText>
            <ContentText>They will love the </ContentText>
            <FieldOutput actState={actState} fieldId="valueProp1post" />
            <ContentText> and the </ContentText>
            <FieldOutput
              actState={actState}
              fieldId="valueProp2post"
              extraTxt="."
            />
          </Content>
          <Content>
            <FieldOutput actState={actState} fieldId="businessName" />{' '}
            <FieldOutput actState={actState} fieldId="adverb1" />
            <ContentText> got to work spreading the</ContentText>
            <ContentText> news all over Facebook.</ContentText>
          </Content>
          <Content>
            <ContentText> First, they wrote a </ContentText>
            <FieldOutput actState={actState} fieldId="adjective2" />
            <ContentText> post: </ContentText>
          </Content>
          <ContentQuote>
            <ContentText>Hey, Facebook friends! We are </ContentText>
            <FieldOutput actState={actState} fieldId="businessName" />
            <ContentText> and we are on Facebook to share </ContentText>
            <FieldOutput
              actState={actState}
              fieldId="importantItem1post"
              extraTxt=","
            />
            <FieldOutput
              actState={actState}
              fieldId="importantItem2post"
              extraTxt=","
            />
            <ContentText> and </ContentText>
            <FieldOutput
              actState={actState}
              fieldId="importantItem3post"
              extraTxt=". "
            />
            <ContentText>
              Plus, we are always creating new things to help our customers with
            </ContentText>
            <FieldOutput
              actState={actState}
              fieldId="audienceProblem2"
              extraTxt="."
            />
            <ContentText>
              If that sounds like you, give us a call and we will
            </ContentText>
            <FieldOutput actState={actState} fieldId="verb2" extraTxt="." />
          </ContentQuote>
          <Content>
            <FieldOutput actState={actState} fieldId="firstName" />
            <ContentText> and </ContentText>
            <FieldOutput actState={actState} fieldId="businessName" />
            <ContentText>
              felt alive. Now that they had one valuable post on Facebook, even
              more strangers would check them out quickly.
            </ContentText>
            <FieldOutput
              actState={actState}
              fieldId="audienceProblem3"
              extraTxt="_"
            />
            <ContentText>?</ContentText>
            <ContentText>Solved!</ContentText>
          </Content>
        </TemplateCard>
      )}
    </TemplateArea>
  );
};

export default MadLibTemplate;
