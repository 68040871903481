import React from 'react';
import styled from 'styled-components/macro';
import { mediaMinDevice } from 'styles/responsive';
import Icon from 'elements/Icon';
import { mdiInformationVariant } from '@mdi/js';

// import { ReactComponent as BackBtn } from 'assets/svg/BackBtn.svg';
import { colors } from 'styles/default';

const BtnIcon = styled(Icon)`
  transition: transform 300ms ease-in-out;
  width: 65px;
  height: auto;
  /* height: 65px; */

  /* height: 100%; */
`;
const BtnWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
  margin: 0;
  padding: 0;
  background-color: ${colors.primary.dark};

  width: 36px;
  height: 36px;
  border: solid 2px #ffffff;

  ${mediaMinDevice.deviceM} {
    width: 48px;
    height: 48px;
    border: solid 4px #ffffff;
  }

  &:hover ${BtnIcon} {
    transform: scale(1.2);
  }
  outline: none;
  /* &:focus {
    outline: #fff solid 3px;
  } */
  /* margin-bottom: 25px; */
`;
const BtnHelp = props => {
  return (
    <BtnWrapper {...props}>
      <BtnIcon path={mdiInformationVariant} color="#fff" size="2rem" />
    </BtnWrapper>
  );
};
// const BtnBack = props => {
//   return (
//     <BtnWrapper {...props}>
//       <Icon inverse path={mdiArrowLeftThick} size="45px" />
//     </BtnWrapper>
//   );
// };

export default BtnHelp;
