import React from 'react';

const SvgTopRightCorner = props => (
  <svg viewBox="0 0 200 200" {...props} preserveAspectRatio="xMidYMin slice">
    <g data-name="Layer 2">
      <path
        d="M200 200H0V0h177.58A22.42 22.42 0 01200 22.42V200z"
        fill="fill"
        opacity={0.49}
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default SvgTopRightCorner;
