import React from 'react';
import styled from 'styled-components/macro';
import { Avatar } from '@material-ui/core';
import { colors, fontWeight } from 'styles/default';
import Icon from 'elements/Icon';
import { mdiBackburger } from '@mdi/js';

const IconHam = styled(Icon)`
  margin-top: 40px;
`;
const SidebarArea = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: ${({ isDesktop }) => (isDesktop ? '240px' : '130px')};
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
  transition: transform 0.3s ease-in-out;
  background-color: #ffffff;
  border-radius: 0 42px 0 0;
  box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.24);
  z-index: 55;
  height: 100vh;
`;
const SidebarTopArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const UserAvatar = styled(Avatar)`
  && {
    margin-top: 50px;
    width: ${props => (props.isDesktop ? '80px' : '65px')};
    height: ${props => (props.isDesktop ? '80px' : '65px')};
    background-color: ${colors.primary.main};
    color: ${colors.primary.contrastText};
    font-size: ${props => (props.isDesktop ? '36px' : '28px')};
    font-weight: ${fontWeight.light};
    font-family: 'Montserrat';
  }
`;
const UserWelcome = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const UserWelcomeText = styled.div`
  text-align: center;
  font-size: 24pt;
  line-height: 1;
  color: ${colors.text.primary};
  font-weight: ${fontWeight.bold};
  font-family: 'Montserrat';
`;

const SidebarMenuArea = styled.div`
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const Sidebar = ({ isDesktop, authUser, open, setOpen, children }) => {
  return (
    <SidebarArea isDesktop={isDesktop} open={open}>
      <SidebarTopArea>
        <IconHam
          path={mdiBackburger}
          size={3}
          color={`${colors.primary.main}`}
          onClick={() => setOpen(!open)}
        />
        <UserAvatar isDesktop={isDesktop}>{authUser.initials}</UserAvatar>
        {isDesktop && (
          <UserWelcome>
            <UserWelcomeText>Hello</UserWelcomeText>
            <UserWelcomeText>{authUser.firstName}!</UserWelcomeText>
          </UserWelcome>
        )}
      </SidebarTopArea>
      <SidebarMenuArea>{children}</SidebarMenuArea>
    </SidebarArea>
  );
};

export default Sidebar;
