import { ADD_ITEM, REMOVE_ITEM, REMOVE_ALL_ITEMS } from 'store/actions/types';
const INITIAL_STATE = {};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case REMOVE_ALL_ITEMS:
      return INITIAL_STATE;
    case ADD_ITEM:
      return {
        ...state,
        [action.item.uid]: { ...action.item },
      };
    // return [...state, { ...action.item, orderQty: 1 }];
    // return [...state, { ...action.item, orderQty: 1 }];
    case REMOVE_ITEM:
      //const foundItem = state[action.itemId];

      // In a immutable way - Avoid using the delete keyword
      return Object.keys(state)
        .filter(key => key !== action.itemId)
        .reduce((result, item) => ({ ...result, [item]: state[item] }), {});
    default:
      return state;
  }
}
