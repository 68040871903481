import React from 'react';

const EmailTopBarIcons = props => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="EmailTopBarIcons"
      data-name="EmailTopBarIcons"
      viewBox="0 0 110 30"
      {...props}
    >
      <path
        d="M84 6.14l17.61 17.61M101.61 6.14L84 23.75"
        fill="none"
        stroke="#282964"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
      <path
        fill="none"
        stroke="#282964"
        strokeLinejoin="round"
        strokeWidth="4"
        d="M47 4h21v21H47z"
      />
      <path
        d="M8 23h27"
        fill="none"
        stroke="#282964"
        strokeWidth="4px"
        strokeLinecap="round"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

export default EmailTopBarIcons;
