import React from 'react';
import styled from 'styled-components/macro';
import ArrowNext from 'assets/icons/ArrowNext';
import ArrowPrev from 'assets/icons/ArrowPrev';
import { mediaMinDevice } from 'styles/responsive';
import { colors } from 'styles/default';

const BtnIcon = styled.div`
  transition: transform 300ms ease-in-out;
  width: 20px;
`;
const BtnWrapper = styled.button`
  margin: 0;
  padding: 0;
  background-color: ${colors.common.white};
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;

  &:hover ${BtnIcon} {
    transform: scale(0.9);
  }
  width: 45px;
  height: 45px;
  ${mediaMinDevice.deviceM} {
    width: 60px;
    height: 60px;
  }
  /* &:focus {
    outline: #fff solid 3px;
  } */
  /* margin-bottom: 25px; */
`;
const BtnNext = props => {
  return (
    <BtnWrapper {...props}>
      <BtnIcon>
        {props.disabled ? (
          <ArrowNext stroke={colors.common.grey} />
        ) : (
          <ArrowNext stroke={colors.primary.dark} />
        )}
      </BtnIcon>
    </BtnWrapper>
  );
};
const BtnPrev = props => {
  return (
    <BtnWrapper {...props}>
      <BtnIcon>
        {props.disabled ? (
          <ArrowPrev stroke={colors.common.grey} />
        ) : (
          <ArrowPrev stroke={colors.primary.dark} />
        )}
      </BtnIcon>
    </BtnWrapper>
  );
};

export { BtnNext, BtnPrev };
