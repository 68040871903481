import { UPDATE_CART, EMPTY_CART } from 'store/actions/types';
const INITIAL_STATE = {
  items: {},
  totalItems: 0,
  subTotal: 0,
  taxAmount: 0,
  totalPrice: 0,
};
export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case EMPTY_CART:
      return INITIAL_STATE;
    case UPDATE_CART:
      return {
        ...state,
        items: action.cart.items,
        totalItems: action.cart.totalItems,
        subTotal: action.cart.subTotal,
        taxAmount: action.cart.taxAmount,
        totalPrice: action.cart.totalPrice,
      };
    default:
      return state;
  }
}
