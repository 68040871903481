/**
 * ACTION TYPES
 */
import { RESULT_OUTPUTS_RESET, RESULT_OUTPUTS_SET } from 'store/actions/types';

export const resultOutputsSet = outputs => ({
  type: RESULT_OUTPUTS_SET,
  outputs: outputs,
});

export const resultOutputsReset = () => dispatch => {
  return new Promise(function(resolve, reject) {
    dispatch({
      type: RESULT_OUTPUTS_RESET,
    });
    resolve();
  });
};
