/**
 * ACTION TYPES
 */
import {
  ADD_ITEM,
  REMOVE_ITEM,
  REMOVE_ALL_ITEMS,
  UPDATE_CART,
  EMPTY_CART,
} from 'store/actions/types';

const MI_SALES_TAX = 0.06;

export const addItem = item => dispatch => {
  //const { active, dateCreated, ...product } = item;
  const productData = {
    name: item.name,
    price: item.price,
    status: item.status,
    type: item.type,
    uid: item.uid,
  };

  new Promise(function(resolve, reject) {
    dispatch({
      type: ADD_ITEM,
      item: productData,
    });
    resolve(dispatch(updateTheCart()));
  });
};

export const removeItem = itemId => dispatch => {
  new Promise(function(resolve, reject) {
    dispatch({
      type: REMOVE_ITEM,
      itemId: itemId,
    });
    resolve(dispatch(updateTheCart()));
  });
};
// export const resetCartProducts = () => dispatch => {
//   new Promise(function(resolve, reject) {
//     dispatch({
//       type: REMOVE_ALL_ITEMS,
//     });
//     resolve(dispatch(_emptyCart()));
//   });
// };
export const resetCartProducts = () => dispatch => {
  dispatch(_emptyCart());
  dispatch(_removeAllProducts());

  return Promise.resolve();
};
// const _removeAllProducts = () => ({
//   type: REMOVE_ALL_ITEMS,
// });
function _emptyCart() {
  return function(dispatch, getState) {
    return dispatch({
      type: EMPTY_CART,
    });
  };
}
function _removeAllProducts() {
  return function(dispatch, getState) {
    return dispatch({
      type: REMOVE_ALL_ITEMS,
    });
  };
}
// const _emptyCart = () => ({
//   type: EMPTY_CART,
// });
export function updateTheCart() {
  return function(dispatch, getState) {
    if (!getState().cartitems.length < 1) {
      // You don’t have to return Promises, but it’s a handy convention
      // so the caller can always call .then() on async dispatch result.
      return dispatch({
        type: EMPTY_CART,
      });
    }
    const items = getState().cartitems;
    console.log(`______ssss${JSON.stringify(items)}`);

    const totalItems = Object.keys(items).length;
    console.log(`_____ totalItems ${totalItems}`);

    const subTotal = Object.keys(items).reduce((prev, curr) => {
      return prev + Number(items[curr].price);
    }, 0);
    console.log(`_____ subTotal ${subTotal}`);

    const subTotalRaw = subTotal / 100;
    const taxRaw = subTotalRaw * MI_SALES_TAX;
    const taxAdjust = Math.round(taxRaw * 100);
    const taxCost = taxAdjust / 100;
    const tax = Number(taxCost.toFixed(2));
    const totalAmount = Number(subTotalRaw) + Number(tax);
    const totalPriceRaw = totalAmount * 100;
    // const totalPrice = Object.keys(items).reduce((prev, curr) => {
    //    return prev + Number(items[curr].price);
    //  }, 0);

    return dispatch({
      type: UPDATE_CART,
      cart: {
        items,
        totalItems,
        subTotal,
        taxAmount: tax,
        totalPrice: totalPriceRaw,
      },
    });
  };
  // We can dispatch both plain object actions and other thunks,
  // which lets us compose the asynchronous actions in a single flow.
}

/**
 * ACTION CREATORS
 */

// export const getProductsInCart = products => ({
//   type: GET_PRODUCTS_IN_CART,
//   products,
// });
// export const addProductToCart = product => ({
//   type: ADD_PRODUCT_TO_CART,
//   product,
// });
// export const removeProductFromCart = product => ({
//   type: REMOVE_PRODUCT_FROM_CART,
//   product,
// });

/**
 * THUNKS
 */
// export function getProductsInCartThunk() {
//   return dispatch => {
//     const products = [];
//     database
//       .ref(`/`)
//       .once('value', snap => {
//         snap.forEach(data => {
//           let product = data.val();
//           products.push(product);
//         });
//       })
//       .then(() => dispatch(getProductsInCart(products)));
//   };
// }
/**
 * LISTENERS
 */
// export function watchTaskAddedEvent(dispatch) {
//   database.ref(`/`).on('child_added', snap => {
//     dispatch(addTask(snap.val()));
//   });
// }
// export function watchTaskRemovedEvent(dispatch) {
//   database.ref(`/`).on('child_removed', snap => {
//     dispatch(removeTask(snap.val()));
//   });
// }
