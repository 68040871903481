import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import LogoEa from './LogoEa';

// const rotate = keyframes`
//   from {
//     transform: rotate(0deg);
//   }
//   to {
//     transform: rotate(360deg);
//   }
// `;
// const fade = keyframes`
// 0% {
// fill:#61DAFB;
// }
// 50% {
//  fill:#999;
// }
// 100%{
//   fill:#61DAFB;
// }
// `;
const pulse = keyframes`
0% {
  transform: scale(0);
  opacity: 1;
  transform-origin: center;
}
100% {
  transform: scale(4.5);
  opacity: 0;
  transform-origin: center;
}
`;
const drop = keyframes`
0% {
transform: rotate(0deg) translateY(-900px);
  opacity: 0;
  transform-origin: center;
}
25% {
transform: rotate(180deg) translateY(-600px);
  opacity: 0.5;
}
50% {
transform: rotate(360deg) translateY(-300px);
  opacity: 1;
}
75% {
transform: rotate(180deg) transform: translateY(-150px);
  opacity: 1;
}
100% {
    transform: rotate(0deg) translateY(0);
  opacity: 1;

}
`;

const StyledLogo = styled(LogoEa)`
  height: 10rem;
  width: 10rem;
  display: block;
  margin: auto;
  .tri05 {
    animation: ${drop} 1 2s linear;
  }
  .tri11 {
    animation: ${pulse} infinite 4s linear;
    &:hover {
      animation-play-state: paused;
      cursor: pointer;
    }
  }
`;

const LogoEaAnim = () => {
  return <StyledLogo />;
};

export default LogoEaAnim;
