import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { withFirebase } from 'api/Firebase';
import SwipeableViews from 'react-swipeable-views';

import useDimensions from 'react-use-dimensions';

// import { setResultOutputs } from 'store/actions/projectResults';
// import allviews from './WindowResultContent/templates/data/allviews.json';
import { Dialog, DialogContent } from '@material-ui/core';
import { colors } from 'styles/default';
import { BtnNext, BtnPrev } from 'elements/NavBtns';
import ToggleEdit from 'elements/ToggleEdit';
import Icon from 'elements/Icon';
import { mdiClose } from '@mdi/js';
// import BtnMain from 'elements/BtnMain';

import WindowResultContent from './WindowResultContent';

const DialogScreenContent = styled(DialogContent)`
  margin: 1rem;
  padding: 0;
  border: solid 4px ${colors.primary.dark};
  background-color: ${colors.template.blueLighter};
`;

const DialogTopArea = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1rem;
`;
// const IconCloseArea = styled.div`
//   display: flex;
//   padding: 8px;
//   justify-content: flex-end;
//   align-items: center;
//   width: 100%;
// `;

const DialogAreaTitle = styled.div`
  font-family: Montserrat;
  font-size: 1.5rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.primary.dark};
  padding: 0;
`;

const DialogRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  outline: 0;
  padding: 0 1rem;
  border-radius: 0;
  background-color: #fff;
`;
const GroupAreaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  outline: 0;
  padding: 0;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
const GroupAreaTitle = styled.div`
  font-family: Montserrat;
  font-size: 30px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.primary.dark};
  padding: 20px 0;
`;

const GroupArea = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  outline: 0;
  padding: 0;
  border-radius: 0;

  background-color: #fff;
  margin-bottom: 1rem;
`;
const GroupRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  outline: 0;
  padding: 12px;
  border-radius: 0;
  background-color: #fff;
  margin-bottom: 1rem;
`;
const GroupRowCenter = styled(GroupRow)`
  padding: ${props =>
    props.pad === 'vert' ? '12px 0' : props.pad === 'horz' ? '0 12px' : '0'};
  justify-content: center;
`;
const ScreenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  /* height: 250px; */
`;
const ScreenNavigation = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
`;

const ScreenContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 4 1 auto;
`;
const ScreenWindow = styled.div`
  overflow: hidden;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  border: solid 5px ${colors.primary.dark};
  background-color: ${colors.template.blueLighter};
`;
const GroupPageNav = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;

const PageBtn = styled.button`
  border-radius: 50%;
  width: 36px;
  height: 36px;
  font-family: Montserrat;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.primary.dark};
  border-style: none;
  cursor: pointer;
  margin: 0 10px;
  padding: 0;
  &:hover {
    background-color: ${colors.template.blueLighter};
  }
  &:disabled {
    background-color: ${colors.template.blueLighter};
    cursor: default;
  }
`;
// const styles = {
//   slideContainer: {
//     height: '614px',
//     backgroundColor: '#00ff00',
//   },
//   slideStyle: {},
// };
const ResultsContent = ({
  projectRef,
  sectionType,
  templateData,
  resetSectionType,
}) => {
  // const dispatch = useDispatch();
  //const [clipboard, setClipboard] = useClippy();
  const isDesktop = useSelector(state => state.app.isDesktop);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  //const clipb = useSelector(state => state.projectState.clipboard);
  //const [outputFields, setOutputFields] = useState();
  const [viewReady, setViewReady] = useState(false);
  // const [sectionData, setSectionData] = useState();

  const [templateType, setTemplateType] = useState('');
  const [screenIndex, setScreenIndex] = useState(0);
  const [screenNumber, setScreenNumber] = useState();
  const [editMode, setEditMode] = useState(true);
  const [views, setViews] = useState();
  const [currentView, setCurrentView] = useState();
  const [ref, dimensions] = useDimensions();
  useEffect(() => {
    setViewReady(true);
    const getSectionData = async () => {
      const sectionData = templateData[sectionType];
      const { views } = sectionData;
      setViews(views);
      // console.log(`items.legth==== >${views.length}`);
      const view = views.find(obj => obj['order'] === 1);
      // console.log(`currentItem==== >${JSON.stringify(view)}`);
      setCurrentView(view);
      //setOutputReady(true);
      setViewReady(true);
    };

    getSectionData();
  }, [views, sectionType, templateData]);

  const addMissingOutputField = (id, val) => {
    projectRef
      .collection('outputs')
      .doc(id)
      .set({ id: id, value: val, projectOutputType: 'missing' });
  };
  const updateOutputField = (id, val) => {
    projectRef
      .collection('outputs')
      .doc(id)
      .update({ value: val })
      // .then(() => {
      //   setOutputFields({ ...outputFields, objectAfter });
      // })
      .then(() => console.log(`updatedOutputField!`))
      .catch(function(error) {
        // The document probably doesn't exist.
        if (error.code === 'not-found') {
          addMissingOutputField(id, val);
        }
        console.log(`Error updating document: ${JSON.stringify(error)}`);
        console.error('Error updating document: ', error);
      });
  };

  useEffect(() => {
    // console.log(
    //   `(sectionType) ${sectionType} !== ${templateType} (templateType)`
    // );
    if (sectionType !== templateType) {
      setScreenIndex(0);
      setScreenNumber(1);
      setTemplateType(sectionType);
      setIsDialogOpen(true);
    }
  }, [sectionType, templateType]);

  const setOutputFieldValue = (id, val) => {
    //console.log(`setOutputFieldValue====== ${id} val=> ${val}`);
    updateOutputField(id, val);
  };

  const handleEditModeToggle = e => {
    e.preventDefault();
    setEditMode(!editMode);
  };
  const screenNavigate = useCallback(
    (e, num) => {
      e.preventDefault();
      setViewReady(false);
      const nextStep = num;

      const view = views.find(obj => obj['order'] === nextStep);
      //console.log(`currentView==== >${JSON.stringify(view)}`);
      setScreenNumber(nextStep);
      setEditMode(true);
      setCurrentView(view);
      setViewReady(true);
    },
    [views]
  );
  const screenNavigateMobile = useCallback((e, num) => {
    e.preventDefault();

    const nextIndex = num - 1;
    console.log(`num==== >${num} nextIndex = ${nextIndex}`);
    setScreenNumber(num);
    setScreenIndex(nextIndex);
  }, []);

  const forwardAnimate = useCallback(
    e => {
      e.preventDefault();
      setViewReady(false);
      const nextStep = screenNumber + 1;

      const view = views.find(obj => obj['order'] === nextStep);
      //console.log(`currentView === >${JSON.stringify(view)}`);
      setScreenNumber(nextStep);
      setEditMode(true);
      setCurrentView(view);
      setViewReady(true);
    },
    [views, screenNumber]
  );

  const backAnimate = useCallback(
    e => {
      e.preventDefault();
      setViewReady(false);
      const nextStep = screenNumber - 1;
      const view = views.find(obj => obj['order'] === nextStep);
      //console.log(`currentView==== >${JSON.stringify(view)}`);
      setScreenNumber(nextStep);
      setEditMode(true);
      setCurrentView(view);
      setViewReady(true);
    },
    [screenNumber, views]
  );
  const handleChangeIndex = useCallback(
    index => {
      console.log(
        `onChangeINdex ${index}=  screenIndex = ${screenIndex} screenNumber = ${screenNumber}`
      );
      setScreenIndex(index);
      setScreenNumber(index + 1);
    },
    [screenNumber, screenIndex]
  );
  // const handleSwitching = useCallback(() => {
  //   console.log(`onSwitching= ${screenNumber}`);
  // }, [screenNumber]);

  const handleDialogClose = e => {
    if (e) {
      e.preventDefault();
    }

    if (isDialogOpen) {
      setIsDialogOpen(false);
      resetSectionType();
    }
  };

  // const copyToClipboard = useCallback(
  //   e => {
  //     e.preventDefault();

  //     const inputEl = document.createElement('textarea');
  //     document.body.appendChild(inputEl);
  //     inputEl.value = clipb;
  //     inputEl.select();
  //     document.execCommand('copy');
  //     document.body.removeChild(inputEl);
  //     //setClipboard(clipb);
  //   },
  //   [clipb]
  // );

  console.log(`height=${JSON.stringify(dimensions)}`);
  if (isDesktop) {
    if (views && sectionType) {
      return (
        <GroupAreaWrapper>
          {currentView && (
            <>
              <GroupAreaTitle>{currentView.name}</GroupAreaTitle>

              <GroupArea>
                <ScreenWrapper>
                  <ScreenNavigation>
                    <BtnPrev
                      disabled={screenNumber <= 1}
                      onClick={backAnimate}
                    />
                  </ScreenNavigation>
                  <ScreenContainer>
                    <GroupRow>
                      <ToggleEdit
                        editMode={editMode}
                        onClick={handleEditModeToggle}
                      />

                      {/* <BtnMain label="COPY" onClick={copyToClipboard} /> */}
                    </GroupRow>
                    <ScreenWindow>
                      {viewReady && (
                        <WindowResultContent
                          sectionType={sectionType}
                          view={currentView}
                          editMode={editMode}
                          setOutputFieldValue={setOutputFieldValue}
                        />
                      )}
                    </ScreenWindow>
                    <GroupRowCenter pad="vert">
                      <GroupPageNav>
                        {viewReady &&
                          views.map(item => (
                            <PageBtn
                              key={item.order}
                              disabled={item.order === screenNumber}
                              onClick={e => screenNavigate(e, item.order)}
                            >
                              {item.order}
                            </PageBtn>
                          ))}
                      </GroupPageNav>
                    </GroupRowCenter>
                  </ScreenContainer>

                  <ScreenNavigation>
                    <BtnNext
                      disabled={screenNumber >= views.length}
                      onClick={forwardAnimate}
                    />
                  </ScreenNavigation>
                </ScreenWrapper>
              </GroupArea>
            </>
          )}
        </GroupAreaWrapper>
      );
    }
  }
  if (!isDesktop) {
    if (views && sectionType) {
      return (
        <Dialog
          open={isDialogOpen}
          scroll="paper"
          fullScreen={true}
          onClose={handleDialogClose}
        >
          <DialogTopArea>
            <DialogAreaTitle>{currentView.name}</DialogAreaTitle>

            <Icon
              path={mdiClose}
              color={colors.primary.dark}
              onClick={handleDialogClose}
            />
          </DialogTopArea>

          <DialogRow>
            <ToggleEdit editMode={editMode} onClick={handleEditModeToggle} />
          </DialogRow>
          <DialogScreenContent ref={ref} dividers={true}>
            <SwipeableViews
              index={screenIndex}
              containerStyle={{ height: `${dimensions.height - 8}px` }}
              onChangeIndex={handleChangeIndex}
              // onSwitching={handleSwitching}
              enableMouseEvents
            >
              {views.map(item => {
                return (
                  <WindowResultContent
                    isDesktop={isDesktop}
                    key={item.order}
                    sectionType={sectionType}
                    view={item}
                    editMode={editMode}
                    setOutputFieldValue={setOutputFieldValue}
                  />
                );
              })}
            </SwipeableViews>
          </DialogScreenContent>
          <GroupRowCenter pad="none">
            <GroupPageNav>
              {viewReady &&
                views.map(item => (
                  <PageBtn
                    key={item.order}
                    disabled={item.order === screenNumber}
                    onClick={e => screenNavigateMobile(e, item.order)}
                  >
                    {item.order}
                  </PageBtn>
                ))}
            </GroupPageNav>
          </GroupRowCenter>
        </Dialog>
      );
    }
  }
  return <p>not ready</p>;
};

export default withFirebase(ResultsContent);
