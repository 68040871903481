import React from 'react';

const ArrowPrev = props => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 42" {...props}>
      <g data-name="ArrowPrev">
        <path
          fill="none"
          stroke="stroke"
          strokeLinejoin="round"
          strokeWidth="3.21"
          d="M18.83 1.05l-17.22 20 17.22 20"
          data-name="ArrowPrev"
        />
      </g>
    </svg>
  );
};

export default ArrowPrev;
