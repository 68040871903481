import React from 'react';

const SvgBottomLeftRound = props => (
  <svg
    viewBox="0 0 200.88 200.88"
    preserveAspectRatio="xMidYMax slice"
    {...props}
  >
    <g id="SvgBottomLeftRound" data-name="SvgBottomLeftRound">
      <path
        d="M0,0H175.86a25,25,0,0,1,25,25V200.88a0,0,0,0,1,0,0H22.52A22.52,22.52,0,0,1,0,178.37V0A0,0,0,0,1,0,0Z"
        transform="translate(200.88 200.88) rotate(180)"
        fill="fill"
        opacity={0.49}
      />
    </g>
  </svg>
);
export default SvgBottomLeftRound;
