import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'styles/default';
import { mediaMinDevice } from 'styles/responsive';
import { CardMarketplaceCard } from 'components/CardBacks';
// export const ProductItemCard = styled(Card)`
//   && {
//     background-color: #00ff00;
//   }
// `;

// const ProjectPrice = styled.div`
//   font-family: Montserrat;
//   font-size: 12px;
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.2;
//   letter-spacing: normal;
//   color: #345ca4;
// `;

const CARD_WIDTH_MOBILE = 280; //'320px'
const CARD_HEIGHT_MOBILE = 380;
const CARD_WIDTH = 340; //'320px'
const CARD_HEIGHT = 400; //'380px'
// const CARD_CONTENT_HEIGHT = CARD_HEIGHT - 40; //'380px'
// const CARD_CONTENT_WIDTH = CARD_WIDTH - 56; //'380px'

export const ProjectType = styled.div``;

export const ProductCardContentText = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;

  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #345ca4;
  white-space: normal;
  text-align: left;
`;
export const ProjectCardContentText = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;

  font-size: 1rem;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #345ca4;
  white-space: normal;
  text-align: left;
`;
export const ProductCardItem = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  margin-bottom: 1rem;
  width: ${CARD_WIDTH_MOBILE}px;
  height: ${CARD_HEIGHT_MOBILE}px;

  ${mediaMinDevice.deviceM} {
    margin-bottom: 2rem;
    width: ${CARD_WIDTH}px;
    height: ${CARD_HEIGHT}px;
  }
`;

export const CardBackDesign = styled.div`
  position: absolute;
  z-index: 22;
  top: 0;
  left: 0;
  > svg {
    object-fit: contain;
  }

  width: ${CARD_WIDTH_MOBILE}px;
  height: ${CARD_HEIGHT_MOBILE}px;

  ${mediaMinDevice.deviceM} {
    width: ${CARD_WIDTH}px;
    height: ${CARD_HEIGHT}px;
  }
`;

export const ProductCardBack = ({ isDisabled }) => {
  return (
    <CardBackDesign>
      <CardMarketplaceCard
        height="100%"
        width="100%"
        preserveAspectRatio="none"
        primaryclr={
          isDisabled
            ? `${colors.marketPlace.disabledColor}`
            : `${colors.marketPlace.primaryColor}`
        }
        backclr="#fff"
      />
    </CardBackDesign>
  );
};

export const ProductCardContent = styled.div`
  position: absolute;
  z-index: 24;
  top: 12px;
  left: 28px;

  /* width: 264px; */

  /* height: 340px; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: ${CARD_WIDTH_MOBILE - 56}px;
  height: ${CARD_HEIGHT_MOBILE - 40}px;

  ${mediaMinDevice.deviceM} {
    width: ${CARD_WIDTH - 56}px;
    height: ${CARD_HEIGHT - 40}px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ProjectCardHeader = styled(CardHeader)`
  margin: 0.5rem 0.5rem 0 0.5rem;
`;
export const ProductCardHeader = styled(CardHeader)`
  margin: 1rem 1rem 0 1rem;
`;
export const CardContentArea = styled.div`
  display: flex;
  flex-direction: column;
`;
export const ProjectCardContentArea = styled(CardContentArea)`
  padding: 0 0.5rem;
`;
export const ProductCardContentArea = styled(CardContentArea)`
  padding: 0;
`;

const CardFooterArea = styled.div`
  display: flex;
  flex: 0 0 auto;
  justify-content: center;
  align-items: center;
`;

export const ProductCardFooter = styled(CardFooterArea)`
  margin: 0 0 1rem 0;
`;
export const ProjectCardFooter = styled(CardFooterArea)`
  margin: 0 0 0.8rem 0;
`;

export const ProjectLogoArea = styled.div`
  width: 100%;
`;
export const ProductLogoArea = styled.div`
  width: 100%;
`;

export const ProjectTitle = styled.div`
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${props =>
    props.disabled
      ? colors.marketPlace.disabledTextColor
      : colors.marketPlace.primaryTextColor};
  white-space: nowrap;
`;
export const ProductTitle = styled.div`
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${props =>
    props.disabled
      ? colors.marketPlace.disabledTextColor
      : colors.marketPlace.primaryTextColor};
  white-space: nowrap;
`;
// const ProjectTitle = styled.div`
//   font-family: Montserrat;
//   font-size: 18px;
//   font-weight: 700;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1;
//   letter-spacing: normal;
//   color: #345ca4;
//   white-space: nowrap;
// `;

export const ProductPrice = styled.div`
  font-family: Montserrat;
  font-size: 0.9rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: ${props =>
    props.disabled
      ? colors.marketPlace.disabledTextColor
      : colors.marketPlace.primaryTextColor};
`;
// export const ProjectCopy = styled.div`
//   font-family: Montserrat;
//   font-size: 1rem;
//   font-weight: 400;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.1;
//   letter-spacing: normal;
//   text-align: center;
//   color: ${props => (props.disabled ? '#000000' : '#345ca4')};
//   margin: 0 5px;
// `;

// const ProductCardContent = styled.div`
//   display: flex;
//   flex: 1 0 auto;
//   margin: 0 0.9rem;
// `;

// const ProductCardFooter = styled.div`
//   display: flex;
//   flex: 0 0 auto;
//   justify-content: center;
//   align-items: center;
//   margin: 0 0 10px 0;
// `;

export const ProductCardSlot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: ${CARD_WIDTH_MOBILE - 56}px;
  /* height: ${CARD_HEIGHT_MOBILE - 40}px; */

  ${mediaMinDevice.deviceM} {
    width: ${CARD_WIDTH - 56}px;
    /* height: ${CARD_HEIGHT - 40}px; */
  }
  /* width: 244px;
  height: 204px;
  ${mediaMinDevice.deviceM} {
    width: 264px;
    height: 204px;
  } */
`;

export const ProductCardSlotImage = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  max-width: 100%;
  width: 160px;
  height: 160px;
  ${mediaMinDevice.deviceM} {
    width: 180px;
    height: 180px;
  }

  > img {
    object-fit: scale-down;
  }
`;
