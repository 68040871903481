import React from 'react';
// import { ReactComponent as CircleDots } from 'assets/svg/circleDots.svg';

//  transform={`translate(${props.width - 313} 0) scale(${props.width /
//     313} ${props.height / 185})`}

import { actCard } from 'styles/default';
const OPACITY_BACK = actCard.constants.OPACITY_BACK;
const OPACITY_SHAPE = actCard.constants.OPACITY_SHAPE;
const BackConvoBot = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 259 153" {...props}>
    <defs>
      <pattern
        id="ConvoPattern"
        width="10"
        height="10"
        data-name="New Pattern"
        patternTransform="translate(46.83 69.34)"
        patternUnits="userSpaceOnUse"
      >
        <path fill="none" d="M0 0h10v10H0z" />
        <path fill="#fff" d="M5 3.5l-1.73 3h3.46L5 3.5z" />
      </pattern>
    </defs>
    <g id="ActivityCard-ChatBot">
      <path
        id="backColor"
        fill="fill"
        d="M0 0h259.33v153.36H0z"
        opacity={OPACITY_BACK}
        transform="rotate(180 129.665 76.68)"
      />
      <path
        id="fillTriangle"
        d="M230.21 1.24l-53.69 53.69-53.7-53.69"
        fill="fill"
        opacity={OPACITY_SHAPE}
      />
      <path
        id="fillRect"
        d="M222.74 56.54h36.32v95.74h-36.32z"
        fill="fill"
        opacity={OPACITY_SHAPE}
        transform="rotate(180 240.895 104.41)"
      />
      <circle
        id="patternEliipse"
        cx="42.81"
        cy="88.73"
        r="28.06"
        fill="url(#ConvoPattern)"
      />
      <path
        id="patternRect"
        d="M194.67 1.24h22.28v95.74h-22.28z"
        fill="url(#ConvoPattern)"
      />
    </g>
  </svg>
);
const BackSimpleForm = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 259 153" {...props}>
    <defs>
      <pattern
        id="SimpleFormPattern"
        width="10"
        height="10"
        data-name="SimpleFormPattern"
        patternTransform="translate(46.29 69.34)"
        patternUnits="userSpaceOnUse"
      >
        <path fill="none" d="M0 0h10v10H0z" />
        <path fill="#fff" d="M5 3.5l-1.73 3h3.46L5 3.5z" />
      </pattern>
    </defs>
    <g id="ActivityCard-SimpleForm">
      <path
        id="backColor"
        fill="fill"
        opacity={OPACITY_BACK}
        d="M0 0h259.33v153.36H0z"
        transform="rotate(180 129.665 76.68)"
      />
      <path
        id="fillTriangleRight"
        d="M197.26 6.15l53.69 53.69-53.69 53.69"
        fill="fill"
        opacity={OPACITY_SHAPE}
      />
      <path
        id="patternBottomRightCorner"
        d="M257.64 133.69a18.76 18.76 0 01-18.76 18.77h-43.25v-21.3a49 49 0 0149-49h13"
        fill="url(#SimpleFormPattern)"
      />
      <path
        id="patternTopCorner"
        d="M0 65.1V21A20.55 20.55 0 0120.55.46h76.84v15.43A49.21 49.21 0 0148.17 65.1z"
        fill="url(#SimpleFormPattern)"
      />
      <path
        id="rectFill"
        d="M30.54 86.25h36.32v95.74H30.54z"
        fill="fill"
        opacity={OPACITY_SHAPE}
        transform="rotate(90 48.695 134.125)"
      />
    </g>
  </svg>
);
const BackMadLib = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 259 153" {...props}>
    <defs>
      <pattern
        id="MadLibPattern"
        width="10"
        height="10"
        data-name="MadLibPattern"
        patternTransform="translate(45.64 69.34)"
        patternUnits="userSpaceOnUse"
      >
        <path fill="none" d="M0 0h10v10H0z" />
        <path fill="#fff" d="M5 3.5l-1.73 3h3.46L5 3.5z" />
      </pattern>
    </defs>
    <g id="ActivityCard-MadLib">
      <path
        id="backColor"
        fill="fill"
        opacity={OPACITY_BACK}
        d="M.01 0h259.33v153.36H.01z"
        transform="rotate(180 129.68 76.68)"
      />
      <path
        id="patternTriangle"
        fill="url(#MadLibPattern)"
        d="M.01 39.99l53.7 53.69-53.7 53.69"
      />
      <path
        id="fillRoundCorner"
        d="M67 153v-12.66a46.85 46.85 0 00-46.73-47H0V153z"
        fill="fill"
        opacity={OPACITY_SHAPE}
      />
      <path
        id="fillRect"
        d="M172.58-37.14h48.7v123.8h-48.7z"
        fill="fill"
        opacity={OPACITY_SHAPE}
        transform="rotate(90 196.925 24.765)"
      />
      <circle
        id="patternEllipse"
        cx="198.1"
        cy="49.11"
        r="35.49"
        fill="url(#MadLibPattern)"
      />
    </g>
  </svg>
);
const BackBuzzQuiz = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 259 153" {...props}>
    <defs>
      <pattern
        id="BuzzQuizPattern"
        width="10"
        height="10"
        data-name="BuzzQuizPattern"
        patternTransform="translate(46.66 69.55)"
        patternUnits="userSpaceOnUse"
      >
        <path fill="none" d="M0 0h10v10H0z" />
        <path fill="#fff" d="M5 3.5l-1.73 3h3.46L5 3.5z" />
      </pattern>
    </defs>
    <g id="ActivityCard-BuzzQuiz">
      <path
        id="backColor"
        fill="fill"
        opacity={OPACITY_BACK}
        d="M0 0h259.33v153.36H0z"
        transform="rotate(180 129.665 76.68)"
      />
      <path
        id="fillTriangle"
        d="M218.44 72.63v63.55h-63.56"
        fill="fill"
        opacity={OPACITY_SHAPE}
      />
      <path
        id="patternCorner"
        fill="url(#BuzzQuizPattern)"
        d="M241.05 39h17.13v95.1a18.77 18.77 0 01-18.77 18.77H192V88a49 49 0 0149-49z"
      />
      <path
        id="fillRect"
        d="M190.37-16.51h51.17v84.19h-51.17z"
        fill="fill"
        opacity={OPACITY_SHAPE}
        transform="rotate(90 215.96 25.59)"
      />
      <circle
        id="fillEllipse"
        cx="53.36"
        cy="52"
        r="35.49"
        fill="fill"
        opacity={OPACITY_SHAPE}
      />
    </g>
  </svg>
);
const BackResults = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 260 153" {...props}>
    <defs>
      <pattern
        id="ResultsPattern"
        width="10"
        height="10"
        data-name="ResultsPattern"
        patternTransform="translate(46.49 69.34)"
        patternUnits="userSpaceOnUse"
      >
        <path fill="none" d="M0 0h10v10H0z" />
        <path fill="#fff" d="M5 3.5l-1.73 3h3.46L5 3.5z" />
      </pattern>
    </defs>
    <g id="ActivityCard-Results">
      <path
        id="backColor"
        fill="fill"
        opacity={OPACITY_BACK}
        d="M.29 0h259.33v153.36H.29z"
        transform="rotate(180 129.95 76.68)"
      />
      <path
        id="solidrect"
        fill="fill"
        opacity={OPACITY_SHAPE}
        d="M0 55.06h36.43v98.54H0z"
      />
      <path
        id="solidTriangle"
        fill="fill"
        opacity={OPACITY_SHAPE}
        d="M183.82.41H260v76.18"
      />
      <path
        id="pattern2"
        d="M2.48 89.06v60.4h64.59v-37.84A22.56 22.56 0 0044.5 89.06z"
        fill="url(#ResultsPattern)"
      />
      <path
        id="pattern"
        d="M246.28 38.68h13v95.44a18.83 18.83 0 01-18.83 18.83h-43.36V87.87a49.19 49.19 0 0149.19-49.19z"
        fill="url(#ResultsPattern)"
      />
    </g>
  </svg>
);
export { BackConvoBot, BackSimpleForm, BackMadLib, BackBuzzQuiz, BackResults };
