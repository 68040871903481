import React from 'react';

const SvgPatternTopLeft = props => (
  <svg viewBox="0 0 696 348.53" {...props}>
    <defs>
      <pattern
        id="PatternTopLeft_svg__a"
        data-name="New Pattern 6"
        width={9}
        height={9}
        patternTransform="translate(-84.9 236.53)"
        patternUnits="userSpaceOnUse"
      >
        <path fill="none" d="M0 0h9v9H0z" />
        <path d="M4.5 3L3 6h3L4.5 3z" />
      </pattern>
      <pattern
        id="PatternTopLeft_svg__b"
        data-name="New Pattern 6"
        width={9}
        height={9}
        patternTransform="matrix(1.16 0 0 1.16 -289.21 210.12)"
        patternUnits="userSpaceOnUse"
      >
        <path fill="none" d="M0 0h9v9H0z" />
        <path d="M4.5 3L3 6h3L4.5 3z" />
      </pattern>
      <pattern
        id="PatternTopLeft_svg__c"
        data-name="New Pattern 6"
        width={9}
        height={9}
        patternTransform="translate(-82.2 236.85)"
        patternUnits="userSpaceOnUse"
      >
        <path fill="none" d="M0 0h9v9H0z" />
        <path d="M4.5 3L3 6h3L4.5 3z" />
      </pattern>
      <pattern
        id="PatternTopLeft_svg__d"
        data-name="New Pattern 6"
        width={9}
        height={9}
        patternTransform="matrix(.98 0 0 .99 -75.83 237.22)"
        patternUnits="userSpaceOnUse"
      >
        <path fill="none" d="M0 0h9v9H0z" />
        <path d="M4.5 3L3 6h3L4.5 3z" />
      </pattern>
      <pattern
        id="PatternTopLeft_svg__e"
        data-name="New Pattern 6"
        width={9}
        height={9}
        patternTransform="translate(-84.44 236.08)"
        patternUnits="userSpaceOnUse"
      >
        <path fill="none" d="M0 0h9v9H0z" />
        <path d="M4.5 3L3 6h3L4.5 3z" />
      </pattern>
    </defs>
    <g data-name="Layer 2">
      <g data-name="Layer 1">
        <path
          fill="url(#PatternTopLeft_svg__a)"
          d="M277.82 209.14v69.82H208v-69.82h69.82z"
        />
        <path fill="#282965" d="M208 348.53v-69.82h69.82" />
        <path fill="#4990cc" d="M70 139.31h69.82v69.82" />
        <path fill="#76cbe2" d="M138 69.5h69.82v69.81" />
        <path fill="url(#PatternTopLeft_svg__b)" d="M207.82 139.31H138V69.5" />
        <path fill="#e54025" d="M70 139.31V69.5h69.82" />
        <path fill="#76cbe2" d="M70 69.82V0h69.82" />
        <path fill="#e82127" d="M0 139.31V69.5h69.82" />
        <path fill="#4990cc" d="M69.82 69.82H0V0" />
        <path fill="#e54025" d="M69.82 209.13H0v-69.82" />
        <path fill="#76cbe2" d="M0 139.31h69.82v69.82" />
        <path fill="#f2a01e" d="M277.82 69.5v69.81H208" />
        <path fill="#282965" d="M277.82 139.31V69.5h69.81" />
        <path fill="#f2a01e" d="M347.63 139.31V69.5h69.82" />
        <path fill="#76cbe2" d="M487.27 139.31h-69.82V69.5" />
        <path fill="#282965" d="M557.09 139.31V69.5h69.81" />
        <path fill="#1d4797" d="M557.09 69.5v69.81h-69.82" />
        <path fill="url(#PatternTopLeft_svg__c)" d="M347.43 69.82h-69.72V0" />
        <path fill="#282965" d="M277.71 0h69.72v69.82" />
        <path fill="#4990cc" d="M347.43 69.82V0h69.71" />
        <path fill="#76cbe2" d="M208 69.82V0h69.71" />
        <path
          fill="#e82127"
          d="M277.71 0v69.82H208M556.57 69.82V0h69.71v69.82z"
        />
        <path fill="url(#PatternTopLeft_svg__d)" d="M556.57 0v69.82h-69.71" />
        <path fill="#1d4797" d="M417.14 69.82V0h69.71v69.82z" />
        <path fill="#f2a01e" d="M696 0v69.82h-69.71" />
        <path fill="#76cbe2" d="M277.82 139.31v69.82H208" />
        <path fill="#1d4797" d="M208 209.13v-69.82h69.82" />
        <path fill="#e82127" d="M347.63 139.31v69.82h-69.81" />
        <path fill="#e54025" d="M417.45 139.31h69.82v69.82" />
        <path
          fill="url(#PatternTopLeft_svg__e)"
          d="M347.63 209.13v-69.82h69.82"
        />
      </g>
    </g>
  </svg>
);

export default SvgPatternTopLeft;
