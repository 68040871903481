import {
  ACTIVITY_ID_SET,
  ACTIVITY_SET,
  ACTIVITY_RESET,
} from 'store/actions/types';
const INITIAL_STATE = {
  actId: null,
  actName: null,
  actType: null,
};

const applySetActivityId = (state, action) => ({
  ...state,
  actId: action.actId,
  actName: null,
  actType: null,
});
const applySetActivity = (state, action) => ({
  ...state,
  actId: action.actId,
  actName: action.actName,
  actType: action.actType,
});
// const applySetActivityData = (state, action) => ({
//   ...state,
//   actName: action.actName,
//   actType: action.actType,
//   actData: action.actData,
// });

export default function(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIVITY_RESET:
      return INITIAL_STATE;
    case ACTIVITY_ID_SET: {
      return applySetActivityId(state, action);
    }
    case ACTIVITY_SET: {
      return applySetActivity(state, action);
    }
    // case SET_ACTIVITY_DATA: {
    //   return applySetActivityData(state, action);
    // }

    default:
      return state;
  }
}
