import React from 'react';
// import * as _ from 'lodash';
import { useSelector } from 'react-redux';
import ChatBot from 'react-simple-chatbot';
import styled, { ThemeProvider } from 'styled-components/macro';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import ButtonMain from 'elements/ButtonMain';
import {
  HeaderArea,
  Subtitle,
  Name,
  CopyArea,
  Description,
  Tips,
  ContentRow,
  ContentArea,
} from 'elements/WorkzoneText';
import { LuBotBotSingle } from 'components/Bots';

import { WorkzoneCardContent } from 'components/WorkzoneCard';
// import { ReactComponent as LuBotArt } from 'assets/svg/LuBot-Art.svg';
import { colors } from 'styles/default';

const LUBOT_COMMAND_PREFIX = '*';
const StickyArt = styled.div`
  position: sticky;
  display: flex;
  padding: 1rem;
  > svg {
    max-height: 440px;
  }

  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%; */
`;
// all available props
const theme = {
  background: `${colors.template.blueLighter}`,
  fontFamily: 'Roboto',
  headerBgColor: '#EF6C00',
  headerFontColor: '#fff',
  headerFontSize: '15px',
  botBubbleColor: '#ffffff',
  botFontColor: `${colors.primary.text}`,
  userBubbleColor: `${colors.primary.light}`,
  userFontColor: `${colors.primary.text}`,
};

function universalCheck(val) {
  const lowval = String(val)
    .substring(1)
    .toLowerCase();
  let checkval;
  switch (lowval) {
    case 'back':
      console.log(`BACK!!!!!!`);
      checkval = 'back';
      break;
    case 'skip':
      console.log(`SKIP!!!!!!`);
      checkval = 'skip';
      break;
    case 'stop':
      console.log(`STOP!!!!!!`);
      checkval = 'stop';
      break;
    default:
      checkval = lowval.charAt(0);
  }
  return checkval;
}
// function checkyesno(val, previousStep) {
//   const valstring = universalCheck(val);
//   console.log(`valstring=> ${valstring}`);
//   switch (valstring) {
//     case 'y':
//       return 'sound-good-isyes';

//     case 'n':
//       return 'sound-good-isno';
//     case 'back':
//       console.log(`previousStep=> ${JSON.stringify(previousStep)}`);
//       return `${previousStep.id}`;
//     default:
//       return 'sound-good-iswrong';
//   }
// }

function cancontinue(val, previousStep) {
  const valstring = universalCheck(val);
  // const valstring = String(val)
  //   .toLowerCase()
  //   .charAt(0);
  console.log(`valstring=> ${valstring}`);
  switch (valstring) {
    case 'y':
      return 'cancontinue-isyes';
    case 'n':
      return 'cancontinue-isno';
    case 'back':
      console.log(`previousStep=> ${JSON.stringify(previousStep)}`);
      return `${previousStep.id}`;
    default:
      return 'cancontinue-iswrong';
  }
}
// const ChangeValues2 = props => {
//   //console.log(`${props.steps.product1.value}`);
//   console.log(`_previousStep======${JSON.stringify(props.previousStep)} `);
//   const pStep = props.previousStep;

//   const meta = pStep['metadata'];
//   const val = pStep['value'];
//   const obj = {
//     [meta.id]: {
//       ...meta,
//       value: val,
//     },
//   };

//   console.log(`obj=> ${JSON.stringify(obj)}`);
//   console.log(`meta=> ${JSON.stringify(meta)}`);
//   const steps = props.steps;
//   const stepKeyValues = Object.keys(steps)
//     .filter(key => _.has(steps[key], 'metadata.id'))
//     .reduce((obj, key) => {
//       return {
//         ...obj,
//         [key]: {
//           id: key,
//           value: steps[key].value,
//           activityType: steps[key].metadata.activityType,
//           activityOutputType: steps[key].metadata.activityOutputType,
//           projectOutputType: steps[key].metadata.projectOutputType,
//         },
//       };
//     }, {});
//   console.log(`steps=> ${JSON.stringify(props.steps)}`);
//   props.handleChange(stepKeyValues);
//   return <CircularProgress />;
// };

const ChangeValues = props => {
  const { previousStep, step, triggerNextStep } = props;
  console.log(`_____________${step.id}___________`);
  // console.log(`previousStep=>${JSON.stringify(previousStep)}`);
  // console.log(`step=>${Object.keys(step)}`);
  // console.log(`steps=>${JSON.stringify(steps)}`);

  const valPrevious = previousStep.value;
  const valPreviousTrim = valPrevious
    ? valPrevious.toLowerCase().charAt(0)
    : 'x';
  // const valCurrent = step.value;
  // const valCurrentTrim = valCurrent ? valCurrent.toLowerCase().charAt(0) : 'x';
  const metaPrevious = previousStep.metadata;
  const metaCurrent = step.metadata;

  // console.log(
  //   `valPrevious=>${valPrevious} valPreviousTrim=${valPreviousTrim} valCurrent=${valCurrent} valCurrentTrim=>${valCurrentTrim}`
  // );
  let newTrigger;

  if (valPreviousTrim === LUBOT_COMMAND_PREFIX) {
    const univval = universalCheck(valPrevious);
    newTrigger = metaCurrent.hasOwnProperty(univval)
      ? metaCurrent[univval]
      : step.trigger;
    console.log(`newTrigger=> ${newTrigger}`);
    if (univval === 'skip') {
      const skipobj = {
        [metaPrevious.id]: {
          ...metaPrevious,
          value: metaPrevious.id,
        },
      };
      props.handleChange(skipobj);
    }

    //triggerNextStep({ trigger: newTrigger });
  } else {
    const obj = {
      [metaPrevious.id]: {
        ...metaPrevious,
        value: valPrevious,
      },
    };
    console.log(`obj=>${JSON.stringify(obj)}`);
    // props.handleChange(obj);
    newTrigger = step.trigger;
    props.handleChange(obj);
    //triggerNextStep();
  }

  triggerNextStep({ trigger: newTrigger });

  return <CircularProgress />;
};
const CheckValue = props => {
  const { previousStep, step, triggerNextStep } = props;
  console.log(`previousStepValue=> ${previousStep.value}`);
  const val = previousStep.value;
  const valtrim = val.toLowerCase().charAt(0);
  const meta = step.metadata;
  let newTrigger;

  if (valtrim === LUBOT_COMMAND_PREFIX) {
    const univval = universalCheck(val);
    newTrigger = meta.hasOwnProperty(univval) ? meta[univval] : step.trigger;
  } else {
    newTrigger = meta.hasOwnProperty(valtrim) ? meta[valtrim] : step.trigger;
  }
  triggerNextStep({ trigger: newTrigger });
  return <CircularProgress />;
};

const EndConvoBot = props => {
  return <ButtonMain onClick={props.handleEnd}>END</ButtonMain>;
};
const ConvoBot = ({ mainActData, handleFormChange, handleSave, handleEnd }) => {
  // const [answerObject, setAnswerObject] = useState({});
  const isDesktop = useSelector(state => state.app.isDesktop);
  // const [nickName, setNickName] = useState();
  // const [trackType, setTrackType] = useState();
  // const [botDone, setBotDone] = useState(false);

  // const handleValueAnswerChange = useCallback(
  //   valObj => {
  //     const combineObj = { ...answerObject, valObj };
  //     setAnswerObject(combineObj);
  //     props.handleFormChange(valObj);
  //   },
  //   [answerObject, props]
  // );

  // const handleValueAnswerChange = valObj => {
  //   // const updateObject = {
  //   //   ...answerObject,
  //   //   ...valObj,
  //   // };
  //   console.log(`handleValueAnswerChange____${valObj}`);
  //   //setAnswerObject(updateObject);
  //   props.handleFormChange(valObj);
  // };

  const handleAnswerChange = value => {
    console.log(`handleAnswerChange value=> ${JSON.stringify(value)}`);
    //console.log(`answerObject=> ${JSON.stringify(answerObject)}`);

    const activityOutputs = Object.keys(value)
      .filter(key => value[key].activityOutputType === 'convoBot')
      .reduce((obj, key) => {
        return {
          ...obj,
          [key]: {
            id: key,
            value: value[key].value,
          },
        };
      }, {});
    console.log(`activityOutputs=> ${JSON.stringify(activityOutputs)}`);
    // const newObj = { ...answerObject, value };
    // setAnswerObject(newObj);
    handleFormChange(value);
  };

  const handleBotEnd = ({ steps, values }) => {
    console.log(steps);
    console.log(values);
    console.log(`${values[0]}`);
    handleSave();
    // alert(`Chat handleEnd callback! Number: ${values[0]}`);
  };

  return (
    <WorkzoneCardContent>
      <HeaderArea>
        <Name>{mainActData.name}</Name>
        <Subtitle>{mainActData.subtitle}</Subtitle>
      </HeaderArea>
      <CopyArea>
        <Description
          dangerouslySetInnerHTML={{
            __html: mainActData.description,
          }}
        />
        <Tips
          dangerouslySetInnerHTML={{
            __html: mainActData.tips,
          }}
        />
      </CopyArea>
      <ContentArea>
        <ContentRow
          container
          direction={isDesktop ? 'row' : 'column-reverse'}
          wrap="nowrap"
          justify="space-between"
          spacing={0}
        >
          <Grid item xs={12} md={6}>
            <StickyArt>
              <LuBotBotSingle
                width="100%"
                height="100%"
                main="#272964"
                bota="#76cbe2"
                botb="#f2a01e"
                botc="#e82127"
                greymd="#e6e7e8"
                greydk="#d0d2d3"
                greylt="#f2f2f2"
                pinka="#e7c7df"
                purplea="#6c63ff"
                bluea="#76cbe2"
                golda="#f2a01e"
                reda="#e82127"
              />
            </StickyArt>
          </Grid>
          <Grid item xs={12} md={6}>
            <ThemeProvider theme={theme}>
              <ChatBot
                style={{
                  height: '100%',
                  boxShadow: 'none',
                  borderRadius: '25px',
                  borderWidth: '4px',
                  borderStyle: 'solid',
                  borderColor: `${colors.primary.dark}`,
                  zIndex: 44,
                }}
                inputStyle={{
                  borderTopWidth: '4px',
                  borderTopStyle: 'solid',
                  borderTopColor: `${colors.primary.dark}`,
                  padding: '16px 52px 32px 10px',
                }}
                enableMobileAutoFocus={true}
                hideSubmitButton={false}
                hideHeader={true}
                hideUserAvatar={true}
                hideBotAvatar={true}
                width="100%"
                customDelay={0}
                handleEnd={handleBotEnd}
                steps={[
                  {
                    id: 'q-your-name',
                    message:
                      'Hi! I’m Lu-Bot, but you can call me Lu. What can I call you?',
                    trigger: 'nickName',
                  },
                  {
                    id: 'nickName',
                    user: true,
                    trigger: 'nickName-response',
                  },
                  {
                    id: 'nickName-response',
                    message: ({ steps }) =>
                      `Nice to meet you, ${steps.nickName.value}. How about I ask you some questions so I can learn more about your business and help create new content for you?`,
                    trigger: 'sound-good-ask',
                  },

                  // {
                  //   id: 'nickName-response',
                  //   message: ({ previousValue, steps }) =>
                  //     `Nice to meet you, ${steps.nickName.value}. How about I ask you some questions so I can learn more about your business and help create new content for you?`,
                  //   trigger: 'sound-good-ask',
                  // },
                  {
                    id: 'sound-good-ask',
                    message: `It will be easy-peasy and maybe even fun. Sound good?`,
                    trigger: 'sound-good',
                  },

                  {
                    id: 'sound-good',
                    user: true,
                    trigger: 'componentSoundGood',
                    // trigger: ({ value, previousStep }) =>
                    //   checkyesno(value, previousStep),
                  },
                  {
                    id: 'componentSoundGood',
                    component: <CheckValue />,
                    waitAction: true,
                    metadata: {
                      y: 'sound-good-isyes',
                      n: 'sound-good-isno',
                      back: 'q-your-name',
                      skip: 'sound-good-isyes',
                    },
                    trigger: 'sound-good-iswrong',
                    replace: true,
                  },
                  {
                    id: 'sound-good-isyes',
                    message: 'Awesome.',
                    trigger: 'lets-get-to-it',
                  },
                  {
                    id: 'sound-good-isno',
                    message: 'No?',
                    trigger: 'sound-good-no',
                  },
                  {
                    id: 'sound-good-iswrong',
                    message: 'HUH?',
                    trigger: 'sound-good-ask',
                  },
                  // {
                  //   id: 'sound-good',
                  //   options: [
                  //     { value: 1, label: 'YES', trigger: 'lets-get-to-it' },
                  //     { value: 2, label: 'NO', trigger: 'sound-good-no' },
                  //   ],
                  // },
                  {
                    id: 'sound-good-no',
                    message: ({ steps }) =>
                      `I'm sorry ${steps.nickName.value}.  Can we please continue? I’m rarin’ to go here!`,
                    trigger: 'can-continue',
                  },
                  {
                    id: 'can-continue',
                    user: true,
                    trigger: ({ value, previousStep }) =>
                      cancontinue(value, previousStep),
                  },
                  {
                    id: 'cancontinue-isno',
                    message:
                      'Ok, well I look forward to chatting once you’re ready. You can click end to return to your main activities page',
                    trigger: 'end',
                  },

                  {
                    id: 'cancontinue-isyes',
                    message: 'Awesome.',
                    trigger: 'lets-get-to-it',
                  },
                  {
                    id: 'cancontinue-iswrong',
                    message: 'IsWRONG?',
                    trigger: 'sound-good-no',
                  },
                  {
                    id: 'lets-get-to-it',
                    message: 'Let’s get to it!',
                    trigger: 'product-or-service-ask',
                  },
                  {
                    id: 'product-or-service-ask',
                    message:
                      'First, is your business product or service-based? Please make your selection below. ',
                    trigger: 'track',
                  },
                  {
                    id: 'track',
                    options: [
                      {
                        value: 'product',
                        label: 'Product',
                        trigger: 'product',
                      },
                      {
                        value: 'service',
                        label: 'Service',
                        trigger: 'service',
                      },
                    ],
                  },

                  {
                    id: 'product',
                    message: ({ steps }) =>
                      `What’s the name of the ${steps.track.value} you offer? If you offer more than one, that’s great, but just list one for me here.`,
                    trigger: 'product1',
                  },
                  {
                    id: 'service',
                    message: 'What kind of service is it?',
                    trigger: 'product1',
                  },
                  {
                    id: 'product1-ask',
                    message: ({ steps }) => `${steps.track.value} name?`,
                    trigger: 'product1',
                  },
                  {
                    id: 'product1',
                    user: true,
                    metadata: {
                      id: 'product1',
                      activityType: 'convoBot',
                      activityOutputType: 'convoBot',
                      projectOutputType: 'ongoingPosts',
                    },
                    trigger: 'product1-set',
                  },
                  {
                    id: 'product1-set',
                    component: (
                      <ChangeValues handleChange={handleAnswerChange} />
                    ),
                    metadata: {
                      back: 'product-or-service-ask',
                      skip: 'product2-ask',
                    },
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    trigger: 'product1-response',
                  },
                  {
                    id: 'product1-response',
                    message: 'Excellent,',
                    trigger: 'product1benefit1-ask',
                  },

                  {
                    id: 'product1benefit1-ask',
                    message: ({ steps }) =>
                      `and what is the #1 benefit of this ${steps.track.value} for your customers?`,
                    trigger: 'product1benefit1',
                  },

                  {
                    id: 'product1benefit1',
                    user: true,
                    trigger: 'product1benefit1-set',
                    metadata: {
                      id: 'product1benefit1',
                      activityType: 'convoBot',
                      activityOutputType: 'convoBot',
                      projectOutputType: 'ongoingPosts',
                    },
                  },
                  {
                    id: 'product1benefit1-set',
                    component: (
                      <ChangeValues handleChange={handleAnswerChange} />
                    ),
                    metadata: {
                      back: 'product1-ask',
                      skip: 'product1benefit2-ask',
                    },
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    trigger: 'product1benefit1-response',
                  },
                  {
                    id: 'product1benefit1-response',
                    message: 'Got it.',
                    trigger: 'product1benefit2-ask',
                  },

                  {
                    id: 'product1benefit2-ask',
                    message: ({ steps }) =>
                      `And what is a second advantage of this ${steps.track.value}?`,
                    trigger: 'product1benefit2',
                  },

                  {
                    id: 'product1benefit2',
                    user: true,
                    trigger: 'product1benefit2-set',
                    metadata: {
                      id: 'product1benefit2',
                      activityType: 'convoBot',
                      activityOutputType: 'convoBot',
                      projectOutputType: 'ongoingPosts',
                    },
                  },

                  {
                    id: 'product1benefit2-set',
                    component: (
                      <ChangeValues handleChange={handleAnswerChange} />
                    ),
                    metadata: {
                      back: 'product1benefit1-ask',
                      skip: 'product2-ask',
                    },
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    trigger: 'product1benefit2-response',
                  },
                  {
                    id: 'product1benefit2-response',
                    message: ({ steps }) =>
                      `I’m loving hearing about what you do, ${steps.nickName.value}!`,
                    trigger: 'product2-ask',
                  },
                  // {
                  //   id: 'product1benefit2-response',
                  //   message: 'How cool!',
                  //   trigger: 'product2-ask',
                  // },
                  {
                    id: 'product2-ask',
                    message: `What’s the name of another product or service you offer?`,
                    trigger: 'product2',
                  },
                  {
                    id: 'product2',
                    user: true,
                    trigger: 'product2-set',
                    metadata: {
                      id: 'product2',
                      activityType: 'convoBot',
                      activityOutputType: 'convoBot',
                      projectOutputType: 'ongoingPosts',
                    },
                  },
                  {
                    id: 'product2-set',
                    component: (
                      <ChangeValues handleChange={handleAnswerChange} />
                    ),
                    metadata: {
                      back: 'product1benefit2-ask',
                      skip: 'product2benefit1-ask',
                    },
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    trigger: 'product2-response',
                  },
                  {
                    id: 'product2-response',
                    message: 'OK',
                    trigger: 'product2benefit1-ask',
                  },

                  {
                    id: 'product2benefit1-ask',
                    message: `What is the #1 feature of that?`,
                    trigger: 'product2benefit1',
                  },
                  {
                    id: 'product2benefit1',
                    user: true,
                    trigger: 'product2benefit1-set',
                    metadata: {
                      id: 'product2benefit1',
                      activityType: 'convoBot',
                      activityOutputType: 'convoBot',
                      projectOutputType: 'ongoingPosts',
                    },
                  },
                  {
                    id: 'product2benefit1-set',
                    component: (
                      <ChangeValues handleChange={handleAnswerChange} />
                    ),
                    metadata: {
                      back: 'product2-ask',
                      skip: 'newProduct-ask',
                    },
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    trigger: 'product2benefit1-response',
                  },
                  {
                    id: 'product2benefit1-response',
                    message: 'Looking good.',
                    trigger: 'newProduct-ask',
                  },

                  {
                    id: 'newProduct-ask',
                    message: `Now, tell me about a new product or service you are or will soon be offering. What is its name, or what will it be called?`,
                    trigger: 'newProduct',
                  },
                  {
                    id: 'newProduct',
                    user: true,
                    trigger: 'newProduct-set',
                    metadata: {
                      id: 'newProduct',
                      activityType: 'convoBot',
                      activityOutputType: 'convoBot',
                      projectOutputType: 'ongoingPosts',
                    },
                  },
                  {
                    id: 'newProduct-set',
                    component: (
                      <ChangeValues handleChange={handleAnswerChange} />
                    ),
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    metadata: {
                      back: 'product2-ask',
                      skip: '',
                    },
                    trigger: 'newProduct-response',
                  },
                  {
                    id: 'newProduct-response',
                    message: `Cool!`,
                    trigger: 'newProductFeature1-ask',
                  },

                  {
                    id: 'newProductFeature1-ask',
                    message: `What is one use for it?`,
                    trigger: 'newProductFeature1',
                  },
                  {
                    id: 'newProductFeature1',
                    user: true,
                    trigger: 'newProductFeature1-set',
                    metadata: {
                      id: 'newProductFeature1',
                      activityType: 'convoBot',
                      activityOutputType: 'convoBot',
                      projectOutputType: 'ongoingPosts',
                    },
                  },
                  {
                    id: 'newProductFeature1-set',
                    component: (
                      <ChangeValues handleChange={handleAnswerChange} />
                    ),
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    metadata: {
                      back: 'newProduct-ask',
                      skip: '',
                    },
                    trigger: 'newProductFeature1-response',
                  },
                  {
                    id: 'newProductFeature1-response',
                    message: `And,`,
                    trigger: 'newProductFeature2-ask',
                  },

                  {
                    id: 'newProductFeature2-ask',
                    message: `what else is cool about it?`,
                    trigger: 'newProductFeature2',
                  },
                  {
                    id: 'newProductFeature2',
                    user: true,
                    trigger: 'newProductFeature2-set',
                    metadata: {
                      id: 'newProductFeature2',
                      activityType: 'convoBot',
                      activityOutputType: 'convoBot',
                      projectOutputType: 'ongoingPosts',
                    },
                  },
                  {
                    id: 'newProductFeature2-set',
                    component: (
                      <ChangeValues handleChange={handleAnswerChange} />
                    ),
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    metadata: {
                      back: 'newProductFeature1-ask',
                      skip: '',
                    },
                    trigger: 'newProductFeature2-response',
                  },
                  {
                    id: 'newProductFeature2-response',
                    message: `Awesome. That’s all I need to know about your products and services!`,
                    trigger: 'companyContentSection',
                  },
                  {
                    id: 'companyContentSection',
                    message: `Let’s move on to content that your company makes and shares with customers.`,
                    trigger: 'hasContentTopic2-ask',
                  },
                  {
                    id: 'hasContentTopic2-ask',
                    message: `Have you or your business ever created an original content piece like a blog article or whitepaper?`,
                    trigger: 'hasContentTopic2',
                  },

                  {
                    id: 'hasContentTopic2',
                    user: true,
                    trigger: 'hasContentTopic2-set',
                    // trigger: ({ value, previousStep }) =>
                    //   checkyesno(value, previousStep),
                  },
                  {
                    id: 'hasContentTopic2-set',
                    component: <CheckValue />,
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    metadata: {
                      y: 'contentTopic2-ask',
                      n: 'hasContentTopic2-isno',
                      back: 'newProduct-ask',
                      skip: 'hasSale-ask',
                    },
                    trigger: 'hasContentTopic2-iswrong',
                  },
                  {
                    id: 'hasContentTopic2-iswrong',
                    message: 'HUH?',
                    trigger: 'hasContentTopic2-ask',
                  },
                  {
                    id: 'hasContentTopic2-isno',
                    message: 'Ok, no worries. We’ll move on!',
                    trigger: 'hasSale-ask',
                  },
                  {
                    id: 'contentTopic2-ask',
                    message: 'What’s the title of that piece?',
                    trigger: 'contentTopic2',
                  },
                  {
                    id: 'contentTopic2',
                    user: true,
                    trigger: 'contentTopic2-set',
                    metadata: {
                      id: 'contentTopic2',
                      activityType: 'convoBot',
                      activityOutputType: 'convoBot',
                      projectOutputType: 'ongoingPosts',
                    },
                  },
                  {
                    id: 'contentTopic2-set',
                    component: (
                      <ChangeValues handleChange={handleAnswerChange} />
                    ),
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    metadata: {
                      back: 'newProduct-ask',
                      skip: 'hasSale-ask',
                    },
                    trigger: 'contentTopic2-response',
                  },
                  {
                    id: 'contentTopic2-response',
                    message: `Now I just have a few more questions...`,
                    trigger: 'hasSale-ask',
                  },
                  {
                    id: 'hasSale-ask',
                    message: `Do you happen to have an upcoming sale or promotion planned?`,
                    trigger: 'hasSale',
                  },

                  {
                    id: 'hasSale',
                    user: true,
                    trigger: 'hasSale-set',
                    // trigger: ({ value, previousStep }) =>
                    //   checkyesno(value, previousStep),
                  },
                  {
                    id: 'hasSale-set',
                    component: <CheckValue />,
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    metadata: {
                      y: 'sale1name-ask',
                      n: 'hasSale-isno',
                      back: 'contentTopic2-ask',
                      skip: 'companyPersonalitySection',
                    },
                    trigger: 'hasSale-iswrong',
                  },
                  {
                    id: 'hasSale-iswrong',
                    message: 'HUH?',
                    trigger: 'hasSale-ask',
                  },
                  {
                    id: 'hasSale-isno',
                    message: 'Roger that!',
                    trigger: 'companyPersonalitySection',
                  },
                  {
                    id: 'sale1name-ask',
                    message:
                      'Cool. What’s the title of it? Or, what are you calling the promotion? For example: Black Friday Bonanza, Summer Savings, etc.',
                    trigger: 'sale1name',
                  },
                  {
                    id: 'sale1name',
                    user: true,
                    trigger: 'sale1name-set',
                    metadata: {
                      id: 'sale1name',
                      activityType: 'convoBot',
                      activityOutputType: 'convoBot',
                      projectOutputType: 'ongoingPosts',
                    },
                  },
                  {
                    id: 'sale1name-set',
                    component: (
                      <ChangeValues handleChange={handleAnswerChange} />
                    ),
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    metadata: {
                      back: 'hasSale-ask',
                      skip: 'companyPersonalitySection',
                    },
                    trigger: 'sale1name-response',
                  },
                  {
                    id: 'sale1name-response',
                    message: `Cool.`,
                    trigger: 'sale1details-ask',
                  },
                  {
                    id: 'sale1details-ask',
                    message:
                      'And what are the details of this sale? How long does it last, how much can customers save? Tell me more.',
                    trigger: 'sale1details',
                  },
                  {
                    id: 'sale1details',
                    user: true,
                    trigger: 'sale1details-set',
                    metadata: {
                      id: 'sale1details',
                      activityType: 'convoBot',
                      activityOutputType: 'convoBot',
                      projectOutputType: 'ongoingPosts',
                    },
                  },
                  {
                    id: 'sale1details-set',
                    component: (
                      <ChangeValues handleChange={handleAnswerChange} />
                    ),
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    metadata: {
                      back: 'sale1name-ask',
                      skip: 'companyPersonalitySection',
                    },
                    trigger: 'sale1details-response',
                  },
                  {
                    id: 'sale1details-response',
                    message: `Ok, we’re about to wrap things up.`,
                    trigger: 'companyPersonalitySection',
                  },
                  {
                    id: 'companyPersonalitySection',
                    message: `Before I go, I’d love to hear a bit about the personality of your business.`,
                    trigger: 'teamOrSolo-ask',
                  },
                  {
                    id: 'teamOrSolo-ask',
                    message:
                      'Do you have a team or is it just you right now? Please make you selection below. ',
                    trigger: 'teamOrSolo',
                  },
                  {
                    id: 'teamOrSolo',
                    options: [
                      {
                        value: 'team',
                        label: 'Team',
                        trigger: 'team-funFact1-ask',
                      },
                      {
                        value: 'solo',
                        label: 'Just You',
                        trigger: 'solo-funFact1-ask',
                      },
                    ],
                  },
                  {
                    id: 'solo-funFact1-ask',
                    message:
                      'What’s something you like to do in your spare time when you’re not working?',
                    trigger: 'teamFunFact1',
                  },

                  {
                    id: 'team-funFact1-ask',
                    message:
                      'What is your favorite team outing or activity to do together?',
                    trigger: 'teamFunFact1',
                  },

                  {
                    id: 'teamFunFact1',
                    user: true,
                    trigger: 'teamFunFact1-set',
                    metadata: {
                      id: 'teamFunFact1',
                      activityType: 'convoBot',
                      activityOutputType: 'convoBot',
                      projectOutputType: 'ongoingPosts',
                    },
                  },
                  {
                    id: 'teamFunFact1-set',
                    component: (
                      <ChangeValues handleChange={handleAnswerChange} />
                    ),
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    metadata: {
                      back: 'teamOrSolo-ask',
                      skip: 'almostDoneSection',
                    },
                    trigger: 'teamFunFact1-response',
                  },
                  {
                    id: 'teamFunFact1-response',
                    message: `That sounds fun!`,
                    trigger: ({ steps }) =>
                      steps.teamOrSolo === 'team'
                        ? 'team-funFact2-ask'
                        : 'solo-funFact2-ask',
                  },
                  {
                    id: 'solo-funFact2-ask',
                    message: 'What about your favorite food?',
                    trigger: 'teamFunFact2',
                  },

                  {
                    id: 'team-funFact2-ask',
                    message: 'What about your team’s favorite food?',
                    trigger: 'teamFunFact2',
                  },

                  {
                    id: 'teamFunFact2',
                    user: true,
                    trigger: 'teamFunFact2-set',
                    metadata: {
                      id: 'teamFunFact2',
                      activityType: 'convoBot',
                      activityOutputType: 'convoBot',
                      projectOutputType: 'ongoingPosts',
                    },
                  },
                  {
                    id: 'teamFunFact2-set',
                    component: (
                      <ChangeValues handleChange={handleAnswerChange} />
                    ),
                    waitAction: true,
                    asMessage: true,
                    replace: true,
                    metadata: {
                      back: 'teamOrSolo-ask',
                      skip: 'almostDoneSection',
                    },
                    trigger: 'teamFunFact2-response',
                  },
                  {
                    id: 'teamFunFact2-response',
                    message: `Yum! I wish I had tastebuds!`,
                    trigger: ({ steps }) =>
                      steps.teamOrSolo === 'team'
                        ? 'almostDoneSection'
                        : 'almostDoneSection',
                  },
                  {
                    id: 'almostDoneSection',
                    message: ({ steps }) =>
                      `Alright, that’s all for now! I had a blast chatting with you, ${steps.nickName.value}.`,
                    trigger: 'end-ask',
                  },
                  {
                    id: 'end-ask',
                    message: `I’ve saved your data and am stoked to input what you’ve shared with me into some amazing content for you. Bye!`,
                    trigger: 'end',
                  },
                  {
                    id: 'end',
                    component: <EndConvoBot handleEnd={handleEnd} />,
                    end: true,
                  },
                ]}
              />
            </ThemeProvider>
          </Grid>
        </ContentRow>
      </ContentArea>
    </WorkzoneCardContent>
  );
};

export default ConvoBot;
