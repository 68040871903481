import React, { useState, useEffect, useRef } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { compose } from 'redux';
import { Button } from '@material-ui/core';
import Joyride, { ACTIONS, STATUS } from 'react-joyride';
import styled from 'styled-components/macro';
import { hotjar } from 'react-hotjar';
import {
  PatternTopLeft,
  PatternTopRight,
  PatternBottomRight,
} from 'elements/UiPatterns';
// import SideCart from 'modules/SideCart';
// import PopperCart from 'components/PopperCart';
// import PopperEmpty from 'components/PopperEmpty';
// import Navigation from 'components/Navigation';
import Icon from 'elements/Icon';
import { mdiClose } from '@mdi/js';
import { withAuthorization } from 'sessions';
import { withFirebase } from 'api/Firebase';
import { projectReset } from 'store/actions/projectPlatform';
import * as PATHS from 'routes/paths';
// import { colors } from 'styles/default';

import { SidebarButton, SidebarButtonLink } from 'elements/SidebarButton';
import MemberScreen from 'screens/MemberScreen';
// import headerPattern from 'assets/imgs/header.png';

import Account from './Account';
import Dashboard from './Dashboard';
import Marketplace from './Marketplace';
import Workzones from './Workzones';

// import Projects from './Projects';
import { Sidebar, SidebarDesktop } from 'components/Sidebar';
import { mediaMinDevice } from 'styles/responsive';
// import Coimport
import { colors } from 'styles/default';
const MainArea = styled.main`
  box-sizing: border-box;
  display: block;
  position: relative;
  /* max-width: 100%; */
  height: 100%;
`;
const HeadSpace = styled.div`
  height: 140px;
  ${mediaMinDevice.deviceM} {
    height: 170px;
  }
`;

// const JoyBtn = styled.button`
//   background-color: ${colors.common.white};
//   border: 2;
//   border-radius: 5;
//   color: ${colors.primary.dark};
//   outline: none;
//   line-height: 1;
//   padding: 8px;
//   font-weight: bold;
//   text-transform: uppercase;
//   -webkit-appearance: none;
//   cursor: pointer;
// `;

const Area = styled.div`
  display: flex;
  box-sizing: border-box;
`;
const AsideArea = styled.div`
  flex-shrink: 0;
  box-sizing: border-box;

  ${mediaMinDevice.deviceM} {
  }
`;
const ContentArea = styled.div`
  flex-grow: 1;
  padding: 0 0.5rem;
  ${mediaMinDevice.deviceM} {
    padding: 0 2rem;
  }
`;
const ContentHeaderPatternWeb = styled.div`
  position: absolute;
  width: 500px;
  top: -4px;
  z-index: -36;
`;
const ContentFooterPattern = styled.div`
  position: fixed;
  bottom: -16px;
  right: 0;
  width: 300px;
  z-index: -38;
`;
const ContentHeaderPatternMobile = styled.div`
  position: absolute;
  top: -5px;
  right: 0;
  > svg {
    min-width: 220px;
    max-width: 300px;
  }
  z-index: -38;
`;
const TooltipBody = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  visibility: visible;
  margin: 0;
  border: 0;
  background-color: ${colors.primary.dark};
  border-radius: 5px;
  box-sizing: border-box;
  color: ${colors.common.white};
  width: 100%;

  position: relative;
  z-index: 1000;

  padding: 0.5rem;
  min-width: 290px;
  max-width: 300px;

  ${mediaMinDevice.deviceM} {
    padding: 1rem;
    min-width: 290px;
    max-width: 600px;
  }
`;

const JoyBtn = styled.button`
  background-color: ${colors.common.white};

  border: 2px;
  border-radius: 5px;
  color: ${colors.primary.dark};
  outline: none;
  line-height: 1;
  padding: 8px;
  font-weight: bold;
  text-transform: uppercase;
  -webkit-appearance: none;
  cursor: pointer;
  font-size: 0.8rem;

  ${mediaMinDevice.deviceM} {
    font-size: 1rem;
  }
`;
const JoyBtnNext = styled(JoyBtn)``;
const JoyBtnBack = styled(JoyBtn)`
  margin-left: auto;
  margin-right: 5px;
`;
const JoyBtnClose = styled(JoyBtn)`
  background-color: transparent;
  color: ${colors.common.white};
  height: 20;
  padding: 15;
  position: absolute;
  right: 0;
  top: 0;
  width: 20px;
`;

const TooltipInner = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  ${mediaMinDevice.deviceM} {
    width: 100%;
  }
`;
const TooltipFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
`;

const TooltipContent = styled.div`
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.2;
  color: ${colors.common.white};

  text-align: center;
  padding: 0.5rem;
  font-size: 1rem;
  ${mediaMinDevice.deviceM} {
    padding: 1rem;
    font-size: 1.5rem;
  }
`;

const TooltipTitle = styled.h2`
  color: ${colors.common.white};

  margin: 0;

  text-transform: uppercase;
  text-align: center;
  font-size: 1.1rem;
  padding: 0 0.5rem 0.5rem 0.5rem;
  ${mediaMinDevice.deviceM} {
    font-size: 1.6rem;
    padding: 0 1rem 1rem 1rem;
  }
`;

const CloseIcon = styled(Icon)`
  transition: transform 300ms ease-in-out;
  color: ${colors.common.white};
`;

const CloseBtn = styled.button`
  position: absolute;

  flex: 0 0 auto;

  overflow: visible;

  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  font-size: 1rem;
  top: 0.5rem;
  right: 0.5rem;
  ${mediaMinDevice.deviceM} {
    font-size: 1.5rem;
    top: 1rem;
    right: 1rem;
  }

  &:hover ${CloseIcon} {
    transform: scale(1.2);
  }
`;
const Tooltip = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  size,
}) => {
  const nextLabel = index > 0 ? `NEXT ${index + 1} / ${size}` : 'NEXT';
  return (
    <TooltipBody {...tooltipProps}>
      <CloseBtn {...closeProps}>
        <CloseIcon path={mdiClose} color="#fff" />
      </CloseBtn>

      {step.content}
      <TooltipFooter>
        {index > 0 && <JoyBtnBack {...backProps}>BACK</JoyBtnBack>}
        {continuous && <JoyBtnNext {...primaryProps}>{nextLabel}</JoyBtnNext>}
        {!continuous && <JoyBtnNext {...closeProps}>CLOSE</JoyBtnNext>}
      </TooltipFooter>
    </TooltipBody>
  );
};
const Member = ({ history, firebase }) => {
  // const buttonReset = {
  //   backgroundColor: `${colors.common.white}`,
  //   border: 2,
  //   borderRadius: 5,
  //   color: `${colors.primary.dark}`,
  //   outline: 'none',
  //   lineHeight: 1,
  //   padding: 8,
  //   WebkitAppearance: 'none',
  //   fontWeight: 'bold',
  //   textTransform: 'uppercase',
  // };
  const stylesForJoy = {
    options: {
      arrowColor: `${colors.primary.dark}`,
      backgroundColor: `${colors.primary.dark}`,
      borderRadius: '18px',
      overlayColor: 'rgba(0, 0, 0, 0.4)',
      primaryColor: `${colors.common.white}`,
      textColor: `${colors.common.white}`,
      width: '100%',
      zIndex: 1000,
    },
  };
  // const stylesForJoy = {
  //   options: {
  //     arrowColor: `${colors.primary.dark}`,
  //     backgroundColor: `${colors.primary.dark}`,
  //     borderRadius: '18px',
  //     overlayColor: 'rgba(0, 0, 0, 0.4)',
  //     primaryColor: `${colors.common.white}`,
  //     textColor: `${colors.common.white}`,
  //     width: '100%',
  //     zIndex: 1000,
  //   },
  //   tooltip: {
  //     backgroundColor: `${colors.primary.dark}`,
  //     borderRadius: 18,
  //     boxSizing: 'border-box',
  //     color: `${colors.common.white}`,
  //     fontSize: 24,
  //     padding: 15,
  //     position: 'relative',
  //     maxWidth: '600px',
  //     width: '100%',
  //   },
  //   buttonNext: {
  //     ...buttonReset,
  //   },
  //   buttonBack: {
  //     ...buttonReset,
  //     color: `${colors.primary.dark}`,
  //     marginLeft: 'auto',
  //     marginRight: 5,
  //   },
  //   buttonClose: {
  //     ...buttonReset,
  //     backgroundColor: 'transparent',
  //     color: `${colors.common.white}`,
  //     height: 20,
  //     padding: 15,
  //     position: 'absolute',
  //     right: 0,
  //     top: 0,
  //     width: 20,
  //   },
  //   buttonSkip: {
  //     ...buttonReset,
  //   },
  // };
  const dispatch = useDispatch();
  const location = useLocation();
  const authUser = useSelector(state => state.sessionState.authUser);
  const checkJoyRide = useSelector(
    state => state.sessionState.authUser.showJoyride
  );
  const isDesktop = useSelector(state => state.app.isDesktop);
  const screenWidth = useSelector(state => state.app.screenWidth);
  // const [joyHelpers, setJoyHelpers] = useState();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [joyRun, setJoyRun] = useState();
  const memberDocRef = useRef(firebase.member(authUser.uid));

  useEffect(() => {
    const { pathname } = location;
    console.log(`newpath=>${pathname}`);
    firebase.sendAnalyticsLogEvent('screen_view', { pathname: pathname });
  }, [firebase, location]);

  useEffect(() => {
    //const showJoyRide = authUser.showJoyride ? authUser.showJoyride : true;

    console.log(`  checkJoyRide=${checkJoyRide}`);
    if (location.pathname === PATHS.MEMBER && checkJoyRide) {
      setJoyRun(true);
      setSidebarOpen(true);
    } else {
      setJoyRun(false);
      setSidebarOpen(false);
    }

    if (location.pathname === PATHS.MEMBER_MARKETPLACE) {
    }
  }, [location, authUser, checkJoyRide]);

  const [steps] = useState([
    {
      content: (
        <TooltipInner>
          <TooltipTitle>Welcome!</TooltipTitle>
          <TooltipContent>
            Get to know your way around your Excelerate America Member App with
            a quick tour.
          </TooltipContent>
        </TooltipInner>
      ),
      locale: { skip: <strong aria-label="skip">SKIP</strong> },
      placement: 'center',
      styles: {
        options: {
          padding: 0,
        },
      },
      target: 'body',
    },
    {
      content: (
        <TooltipInner>
          <TooltipTitle>Dashboard</TooltipTitle>
          <TooltipContent>
            Your main space and where you can access news, updates, blogs, and
            more.
          </TooltipContent>
        </TooltipInner>
      ),
      spotlightPadding: 0,
      target: '.joy0',
      locale: { skip: <strong aria-label="skip">SKIP</strong> },
    },
    {
      content: (
        <TooltipInner>
          <TooltipTitle>Marketplace</TooltipTitle>
          <TooltipContent>
            Discover and shop projects designed to empower your entrepreneurial
            journey.
          </TooltipContent>
        </TooltipInner>
      ),
      spotlightPadding: 0,
      target: '.joy1',
      locale: { skip: <strong aria-label="skip">SKIP</strong> },
    },
    {
      content: (
        <TooltipInner>
          <TooltipTitle>Work Zone</TooltipTitle>
          <TooltipContent>
            Access your project purchases and get to work.
          </TooltipContent>
        </TooltipInner>
      ),
      spotlightPadding: 0,
      target: '.joy2',
      locale: { skip: <strong aria-label="skip">SKIP</strong> },
    },
    {
      content: (
        <TooltipInner>
          <TooltipTitle>Account Profile</TooltipTitle>
          <TooltipContent>
            Update your info and change your password.
          </TooltipContent>
        </TooltipInner>
      ),
      spotlightPadding: 10,
      target: '.joy4',
    },
  ]);
  // const [isCartOpen, setIsCartOpen] = useState(false);
  //const [open, setOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const handleSidebarAction = () => {
    history.push(PATHS.MEMBER_ACCOUNT);
  };

  const handleSignOut = () => {
    dispatch(projectReset()).then(() => {
      firebase.doSignOut();
    });
  };
  // const getStoreHelpers = helpers => {
  //   setJoyHelpers(helpers);
  // };

  const handleJoyrideCallback = data => {
    const { action, status, type } = data;

    //const { status, type, action } = data;
    // const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    // const dataActions = [ACTIONS.CLOSE];

    // if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
    //   // Update state to advance the tour
    //   this.setState({ stepIndex: index + (action === ACTIONS.PREV ? -1 : 1) });
    // } else
    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) ||
      [ACTIONS.CLOSE].includes(action)
    ) {
      // Need to set our running state to false, so we can restart if we click start again.

      const dateUpdated = new Date();
      const memberRef = memberDocRef.current;

      memberRef
        .update({
          showJoyride: false,
          dateUpdated,
        })
        .then(() => {
          setJoyRun(false);
          firebase.sendAnalyticsLogEvent('exit_joyride');
        })
        .catch(error => {
          setJoyRun(false);
          throw error;
        });

      // this.setState({ run: false });
    }

    // if (finishedStatuses.includes(status)) {
    //   setJoyRun(false);
    // }
    // if (dataActions.includes(action)) {
    //   setJoyRun(false);
    // }

    // tslint:disable:no-console
    console.groupCollapsed(type);
    console.log(data);
    console.groupEnd();

    // tslint:enable:no-console
  };

  useEffect(() => {
    hotjar.initialize('1661665', 6);
  }, []);

  // const [setCurrentPath] = useState(location.pathname);

  // useEffect(() => {
  //   const { pathname } = location;
  //   console.log('New path:', pathname);
  //   setCurrentPath(pathname);
  // }, [location, location.pathname, setCurrentPath]);
  // const handleCartOpen = e => {
  //   if (e) {
  //     e.preventDefault();
  //   }
  //   if (!isCartOpen) {
  //     setIsCartOpen(true);
  //   }
  // };
  // const handleCartClose = e => {
  //   if (e) {
  //     e.preventDefault();
  //   }

  //   if (isCartOpen) {
  //     setIsCartOpen(false);
  //   }
  // };
  const sidebarContent = (
    <div>
      <SidebarButtonLink
        isDesktop={isDesktop}
        className="joy0"
        exact
        to={PATHS.MEMBER}
      >
        Dashboard
      </SidebarButtonLink>
      <SidebarButtonLink
        isDesktop={isDesktop}
        className="joy1"
        to={PATHS.MEMBER_MARKETPLACE}
      >
        Marketplace
      </SidebarButtonLink>
      <SidebarButtonLink
        isDesktop={isDesktop}
        to={PATHS.MEMBER_WORKZONES}
        className="joy2"
      >
        Work Zone
      </SidebarButtonLink>
      <SidebarButton isDesktop={isDesktop} onClick={handleSignOut}>
        Sign Out
      </SidebarButton>
    </div>
  );

  return (
    <Area>
      <Joyride
        tooltipComponent={Tooltip}
        // beaconComponent={BeaconComponent as React.ElementType<BeaconRenderProps>} for TS

        callback={handleJoyrideCallback}
        continuous={true}
        disableOverlayClose={true}
        // getHelpers={getStoreHelpers}
        run={joyRun}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={false}
        steps={steps}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          skip: 'Skip',
        }}
        styles={stylesForJoy}
      />
      <AsideArea>
        {isDesktop && (
          <SidebarDesktop
            isDesktop={true}
            handleAction={handleSidebarAction}
            authUser={authUser}
          >
            {sidebarContent}
          </SidebarDesktop>
        )}
      </AsideArea>
      <ContentArea>
        {!isDesktop && (
          <Sidebar
            isDesktop={false}
            handleAction={handleSidebarAction}
            authUser={authUser}
            isOpen={sidebarOpen}
            handleMenuToggle={toggleSidebar}
          >
            {sidebarContent}
          </Sidebar>
        )}
        <HeadSpace></HeadSpace>
        <Switch>
          <Route path={PATHS.MEMBER_ACCOUNT} component={Account} />
          <Route path={PATHS.MEMBER_MARKETPLACE} component={Marketplace} />
          <Route path={PATHS.MEMBER_WORKZONES} component={Workzones} />
          <Route path={PATHS.MEMBER} component={Dashboard} />
          {/* <Route path={PATHS.MEMBER_PROJECTS} component={Projects} /> */}
        </Switch>
      </ContentArea>
      {isDesktop ? (
        <ContentHeaderPatternWeb>
          <PatternTopLeft width="100%" height="100%" />
        </ContentHeaderPatternWeb>
      ) : (
        <ContentHeaderPatternMobile width={screenWidth * 0.693}>
          <PatternTopRight width={screenWidth * 0.693} height="100%" />
        </ContentHeaderPatternMobile>
      )}
      {isDesktop && (
        <ContentFooterPattern>
          <PatternBottomRight width="100%" height="100%" />
        </ContentFooterPattern>
      )}
    </Area>
  );
};

const condition = authUser => !!authUser;
export default compose(withFirebase, withAuthorization(condition))(Member);
