/**
 * ACTION TYPES
 */
import {
  PROJECT_SET,
  PROJECT_RESET,
  PROJECT_DATA_SET,
  PROJECT_STATUS_SET,
  ACTIVITY_RESET,
} from 'store/actions/types';

export const projectSet = (pid, name, status, resultId) => dispatch => {
  return new Promise(function(resolve, reject) {
    dispatch({
      type: PROJECT_SET,
      projectId: pid,
      projectName: name,
      projectStatus: status,
      resultId: resultId,
    });
    resolve(pid);
  });
};

export const projectDataSet = data => dispatch => {
  return new Promise(function(resolve, reject) {
    dispatch({
      type: PROJECT_DATA_SET,
      data: data,
    });
    resolve(data);
  });
};
export const projectStatusSet = status => dispatch => {
  return new Promise(function(resolve, reject) {
    dispatch({
      type: PROJECT_STATUS_SET,
      projectStatus: status,
    });
    resolve(status);
  });
};

export const projectReset = () => dispatch => {
  dispatch(_projectReset());
  dispatch(_activityReset());

  return Promise.resolve();
};

function _projectReset() {
  return function(dispatch, getState) {
    return dispatch({
      type: PROJECT_RESET,
    });
  };
}
function _activityReset() {
  return function(dispatch, getState) {
    return dispatch({
      type: ACTIVITY_RESET,
    });
  };
}

// export const setProjectWithOutputs = (pid, name, outputs) => dispatch => {
//   return new Promise(function(resolve, reject) {
//     dispatch({
//       type: SET_PROJECT_WITH_OUTPUTS,
//       projectId: pid,
//       projectName: name,
//       outputs: outputs,
//     });
//     resolve(pid);
//   });
// };
// export const setProjectActivity = (actid, name) => dispatch => {
//   return new Promise(function(resolve, reject) {
//     dispatch({
//       type: SET_PROJECT_ACTIVITY,
//       activityId: actid,
//       activityName: name,
//     });
//     resolve(actid);
//   });
// };
// const setProject = (pid) => {
//     return (dispatch, getState) => {
//         return fetch(/* some request */).then(response => {
//             dispatch ({type: 'RESPONSE_RECEIVED', payload: response})
//             return response;
//         }
//     }
// }
// export const setProject = projectId => ({
//   type: SET_PROJECT,
//   projectId: projectId,
// });

// export const setProjectOutputs = outputs => ({
//   type: SET_PROJECT_OUTPUTS,
//   outputs: outputs,
// });
// export const setProjectClipboard = value => ({
//   type: SET_PROJECT_CLIPBOARD,
//   clipboard: value,
// });
