import React from 'react';
import styled from 'styled-components/macro';
import * as _ from 'lodash';
// import ResultFieldChange from 'components/ResultFieldChange';
import ResultContentBuilder from 'components/ResultContentBuilder';
// import UserTempField from 'components/UserTempField';
// import _ from 'lodash';
import { colors } from 'styles/default';

const BlockText = styled.p`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.common.black};
  margin: 0.5rem 0;
`;
const ParaText = styled.span`
  color: #000000;
`;
const UserParaText = styled.span`
  /* background: #cfe2f3; */
  color: ${colors.text.primary};
`;
// const InsertSpan = styled.span`
//   background: #cfe2f3;
//   color: #000000;
// `;

const InsertSpan = styled.span`
  color: ${colors.text.primary};
`;
const ContentBase = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  /* margin-top: 10px; */
`;
const BulletSpan = styled.span`
  color: #000000;
  display: inline-block;
  margin: 0 1rem;
  transform: scale(2);
`;

const OutputSpan = styled.span`
  color: #000000;
`;
const OutputDiv = styled.span`
  color: #000000;
`;
const UserFieldDiv = ({ value, type, variant }) => {
  console.log(`type=> ${type} value=>${value}`);
  const text = variant === 'firstcap' ? _.capitalize(value) : value;
  if (type === 'contentBullet') {
    return (
      <OutputDiv>
        <BulletSpan>•</BulletSpan>
        {text}
      </OutputDiv>
    );
  }
  return <OutputDiv>{text}</OutputDiv>;
};
const ContentArea = ({ editBlocks, tempValues, outputs }) => {
  return (
    <ContentBase>
      {editBlocks.map((blocks, index) => {
        return (
          <BlockText key={index}>
            {blocks['block'].map((item, index) => {
              if (item.component === 'ParaText') {
                return <ParaText key={item.order}>{item.text}</ParaText>;
              }

              if (item.component === 'InsertSpan') {
                return <InsertSpan key={item.order}>{item.text}</InsertSpan>;
              }
              if (item.component === 'UserTempField') {
                return (
                  <UserParaText key={item.order}>{item.text}</UserParaText>
                );
              }
              return (
                <UserFieldDiv
                  key={`${item.order}-${index}`}
                  type={item.type}
                  variant={_.get(item, 'variant', null)}
                  value={
                    outputs[item.outputField]
                      ? outputs[item.outputField]
                      : item.outputField
                  }
                />
              );
            })}
          </BlockText>
        );
      })}
    </ContentBase>
  );
};

export default ContentArea;
