import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

// import { useDispatch } from 'react-redux';
import DivText from 'components/DivText';
// import useClippy from 'use-clippy';
import {
  GroupCardHeader,
  GroupCardHeadline,
  GroupCardBanner,
  GroupCardFooter,
} from 'components/GroupCard';
import _ from 'lodash';
import { colors } from 'styles/default';
import { mediaMinDevice } from 'styles/responsive';
import ContentArea from 'components/ContentArea';
import EditArea from 'components/EditArea';
// import Activity from 'screens/Activity';
// import { setProjectClipboard } from 'store/actions/projectPlatform';

import TemplateImageBanner from 'components/TemplateImageBanner';

const CardMedia = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 320px;
  background-color: #ccc;
  padding: 2rem;
`;
const CardMediaCaption = styled.div``;

const CardMediaCaptionText = styled.div`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.04;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
`;

const PostContent = styled.div`
  background-color: #fff;
`;
// const TabsHolder = styled.div`
//   background-color: #cccccc;
//   display: flexbox;
// `;
const PostWrapper = styled.div`

  display: flex;
  flex-direction: column;

  align-items: center;
  outline: 0;
  border-radius: 0;
  width: 100%;
  min-width: 300px;
  max-width: 600px;
  background-color: transparent;
  padding: 0;
  margin: 0 auto;
  max-height: 100%;
  ${mediaMinDevice.deviceM} {
    padding: 1rem 0;
    justify-content: center;
  }
  /* padding: ${props => (props.isDesktop ? '0 12px' : '0 0')};
  margin-bottom: ${props => (props.isDesktop ? '20px' : '0')}; */
`;
const PostsActionBar = styled.div`
  width: 100%;
   min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  outline: 0;
  padding: 0 1rem;
  border-radius: 0;
  height: 65px;
  /* height: ${props => (props.isDesktop ? '65px' : '1rem')}; */
  max-width: 600px;
  background-color: transparent;
  /* background-color: ${colors.template.blueLighter}; */
`;

const Post = ({ item, outputs, editMode, changeOutputValue, isDesktop }) => {
  // const outputFields = useSelector(state => state.projectState.outputs);
  //const [clipb, setClipboard] = useClippy();
  // const dispatch = useDispatch();
  const [itemContent, setItemContent] = useState();
  // const [tempClipboard, setTempClipboard] = useState();
  const [tempValues, setTempValues] = useState(item.extras ? item.extras : {});
  // const [itemFieldValues] = useState(item.fields);

  // const templateEval = tempstring => {
  //   //console.log(`tempstring=>${tempstring}`);
  //   const compiled = _.template(tempstring);
  //   return compiled(item.fieldValues);
  // };
  const templateEval = tempstring => {
    //console.log(`tempstring=>${tempstring}`);
    const compiled = _.template(tempstring);
    return compiled(outputs);
  };
  const handleTempValues = (id, val) => {
    const updateObj = { [id]: val };
    //console.log(`handleTempValues ___${JSON.stringify(updateObj)}`);
    setTempValues({ ...tempValues, ...updateObj });
  };

  // useEffect(() => {
  //   const extras = item.extras ? item.extras : {};
  //   const allFields = { ...outputs, ...extras };

  //   const compiled = _.template(item.content);
  //   const mainContent = compiled(allFields);

  //   setItemContent(mainContent);
  //   // dispatch(setProjectClipboard(mainContent));
  // }, [item.content, item.extras, item.fieldValues, outputs]);
  //  const templateData = sectionsArray.reduce((obj, item) => {
  //       obj[item.type] = item;
  //       return obj;
  //     }, {});
  useEffect(() => {
    const extras = item.extras ? item.extras : {};
    const fields = item.fields ? item.fields : [];
    let allFields;
    if (fields.length > 0) {
      const itemFields = fields
        .map(key => {
          const itemObj = _.has(outputs, key)
            ? _.pick(outputs, key)
            : { [key]: key };
          // const fieldObj = { [key]: value };
          return itemObj;
        })
        .reduce((result, field) => {
          return { ...result, ...field };
        }, {});
      //console.log(`.... ${JSON.stringify(itemFields)}`);
      // .reduce((obj, item) => {
      //   obj[item.id] = item;
      //   return obj;
      // }, {});
      allFields = { ...itemFields, ...extras };
    } else {
      allFields = { ...outputs, ...extras };
    }
    //console.log(`^^^^^allFields___ ${JSON.stringify(allFields)}`);

    let blocks;
    if (Array.isArray(item.content)) {
      blocks = item.content;
    } else {
      blocks = [item.content];
    }

    const contentBlocks = blocks.map(str => {
      const compiled = _.template(str);
      const mainContent = compiled(allFields);
      return `<p>${mainContent}</p>`;
    });
    const contentBlocksString = _.join(contentBlocks, '');

    setItemContent(contentBlocksString);
    // dispatch(setProjectClipboard(contentBlocksString));
  }, [item.fields, item.content, item.extras, outputs]);
  // useEffect(() => {
  //   const compiled = _.template(item.content);
  //   const clip = compiled(item.fieldValues);
  //   dispatch(setProjectClipboard(clip));
  // }, [dispatch, item.content, item.fieldValues, outputs]);

  return (
    <PostWrapper>
      <PostContent>
        <GroupCardHeader
          type={item.type}
          title={outputs.businessName}
          order={item.order}
          subtitle={outputs.businessName}
          // subtitle={<Subtitle name={fieldValues.businessName} />}
        />

        {item.headline && (
          <GroupCardHeadline text={templateEval(item.headline)} />
        )}

        {!editMode ? (
          <ContentArea
            editBlocks={item.blocks}
            tempValues={tempValues}
            outputs={outputs}
          />
        ) : (
          <EditArea
            editBlocks={item.blocks}
            tempValues={tempValues}
            handleTempValues={handleTempValues}
            outputs={outputs}
            changeOutputValue={changeOutputValue}
          />
        )}
        {item.media &&
          (item.media.type === 'placeholder' ? (
            <CardMedia>
              <CardMediaCaption>
                <CardMediaCaptionText>
                  {item.media.caption}
                </CardMediaCaptionText>
              </CardMediaCaption>

              {/* <img
                src={require(`assets/${item.type}/${item.media.filename}`)}
                alt={item.media.caption}
              /> */}
            </CardMedia>
          ) : (
            <GroupCardBanner>
              <TemplateImageBanner item={item} />
              {/* <img
                src={require(`assets/Results${item.type}/${item.media.filename}`)}
                alt={item.media.caption}
              /> */}
            </GroupCardBanner>
          ))}
        {item.img && (
          <GroupCardBanner>
            <img
              src={require(`assets/${item.type}/${item.img}`)}
              alt="Fb Post"
            />
          </GroupCardBanner>
        )}
      </PostContent>
      {isDesktop && <PostsActionBar />}

      <GroupCardFooter />
    </PostWrapper>
  );
};

export default Post;
