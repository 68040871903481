import React from 'react';

// import { Grid } from '@material-ui/core';

// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'elements/Icon';
import { mdiCloseCircle } from '@mdi/js';

import styled from 'styled-components/macro';
import { colors } from 'styles/default';
// import RemoveCircle from '@mdi/js/RemoveCircle';

const IconRemoveBtn = styled(IconButton)`
  padding: 6px;
`;
// const CartDivider = styled.div`
//   margin: 20px 0;
//   width: 100%;
//   height: 1px;
//   background-color: ${colors.cart.dividerColor};
// `;
const CartItemText = styled.p`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.cart.textColor};
`;

const CartItemRow = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  border-top: solid 1px ${colors.primary.light};
`;

const RowBtn = styled.div`
  display: flex;
  flex-basis: 30px;
  margin-right: 8px;
`;
const RowText = styled.div`
  display: flex;
  flex: 2 0;
`;
const RowPrice = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: 1 1;
  width: 100%;
`;
const formatPrice = price => {
  return price / 100;
};
const CartItem = ({ item, onRemoveCartItem }) => (
  <CartItemRow>
    {onRemoveCartItem && (
      <RowBtn>
        <IconRemoveBtn
          edge="end"
          aria-label="remove"
          onClick={onRemoveCartItem}
        >
          <Icon color={colors.cart.textColor} path={mdiCloseCircle} />
        </IconRemoveBtn>
      </RowBtn>
    )}

    <RowText>
      <CartItemText>
        {item.type}: {item.name}
      </CartItemText>
    </RowText>
    <RowPrice>
      <CartItemText>${formatPrice(item.price)}</CartItemText>
    </RowPrice>
  </CartItemRow>
);
// const CartItem = ({ item, onRemoveCartItem }) => (
//   <>
//     <ListItem dense alignItems="flex-start">
//       <ListItemText
//         primary={`${item.name} - $${formatPrice(item.price)}`}
//         secondary={`${item.type} - ${item.uid}`}
//       />
//       <ListItemSecondaryAction>
//         <IconButton edge="end" aria-label="remove" onClick={onRemoveCartItem}>
//           <Icon path={mdiCloseCircle} />
//         </IconButton>
//       </ListItemSecondaryAction>
//     </ListItem>
//     <CartDivider />
//   </>
// );

export default CartItem;
