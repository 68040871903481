import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import * as _ from 'lodash';

import { withFirebase } from 'api/Firebase';
import { useSelector } from 'react-redux';
import WorkzoneCard, { WorkzoneCardContent } from 'components/WorkzoneCard';

// import ProgressWorking from 'components/ProgressWorking';
import SimpleForm from './SimpleForm';
import Loading from 'components/Loading';
import MadLib from './MadLib';
import BuzzQuiz from './BuzzQuiz';
import ConvoBot from './ConvoBot';
import * as PATHS from 'routes/paths';
// import { globals } from 'styles/default';
// import { setProjectOutputs } from 'store/actions/projectPlatform';
const ActivityContent = ({
  history,
  firebase,
  activityId,
  activityType,
  completed,
  mainActivityData,
}) => {
  const userId = useSelector(state => state.sessionState.authUser.uid);
  const projectId = useSelector(state => state.projectState.projectId);
  // const [working, setWorking] = useState(true);
  // const [actType, setActType] = useState();
  const [inputsReady, setInputsReady] = useState(false);
  const [activityLoaded, setActivityLoaded] = useState(false);
  const [inputs, setInputs] = useState();
  const [submitting, setSubmitting] = useState(false);
  const [actDone] = useState(completed);
  const [actSave, setActSave] = useState(false);
  // const [nextAct, setNextAct] = useState(false);
  const [values, setValues] = useState();
  const [outputs, setOutputs] = useState();
  const [globals] = useState();
  // const [globalFields, setGlobalFields] = useState();
  const [tempValues, setTempValues] = useState({});

  const fstoreRef = useRef(firebase.fstore);

  const activityFieldsRef = useRef(
    firebase.fstore
      .collection('projects')
      .doc(projectId)
      .collection('activitys')
      .doc(activityId)
      .collection('fields')
  );

  const memberOutputsRef = useRef(
    firebase.fstore
      .collection('members')
      .doc(userId)
      .collection('projects')
      .doc(projectId)
      .collection('outputs')
  );

  const memberActivityRef = useRef(
    firebase.fstore
      .collection('members')
      .doc(userId)
      .collection('projects')
      .doc(projectId)
      .collection('activitys')
      .doc(activityId)
  );

  useEffect(() => {
    const fetchInputs = async () => {
      //console.log(`fetchInputs`);
      const globalFields = [];
      const fieldsRef = activityFieldsRef.current.orderBy('order');
      const fieldsSnapshot = await fieldsRef.get();

      const inputs = fieldsSnapshot.docs.map(docsnap => {
        if (docsnap.data().activityOutputType === 'global') {
          //console.log(`globalField id= ${docsnap.data().id}`);
          globalFields.push(docsnap.data().id);
        }
        return docsnap.data();
      });

      const projectOutputsRef = memberOutputsRef.current;
      const outputcollection = await projectOutputsRef.get();
      const isEmpty = outputcollection.empty ? true : false;

      if (isEmpty) {
        setInputs(inputs);
        setInputsReady(true);
        setOutputs({});
        setValues({});
        // setWorking(false);
        setActivityLoaded(true);
      } else {
        const outputsGlobalSnap = await projectOutputsRef
          .where('activityOutputType', '==', 'global')
          .get();

        const globalOutputData = outputsGlobalSnap.docs
          .map(docsnap => docsnap.data())
          .reduce((obj, item) => {
            obj[item.id] = item;
            return obj;
          }, {});

        const globalActOutputsWithData = [];
        await globalFields.forEach(key => {
          if (globalOutputData[key]) {
            // console.log(
            //   `hasKey =${key} data=${JSON.stringify(globalOutputData[key])}`
            // );
            globalActOutputsWithData.push(globalOutputData[key]);
          }
        });
        let globalActivityOutputs;

        if (globalActOutputsWithData.length > 0) {
          globalActivityOutputs = globalActOutputsWithData.reduce(
            (obj, item) => {
              //console.log(`reduce item.id ${item.id}`);
              obj[item.id] = item;
              return obj;
            },
            {}
          );
        } else {
          globalActivityOutputs = {};
        }

        const outputsActivitySnap = await projectOutputsRef
          .where('activityOutputType', '==', activityType)
          .get();

        const activityOutputData = outputsActivitySnap.docs
          .map(docsnap => docsnap.data())
          .reduce((obj, item) => {
            obj[item.id] = item;
            return obj;
          }, {});

        const combinedOutputs = {
          ...globalActivityOutputs,
          ...activityOutputData,
        };
        //console.log(`combinedOutputs  ${JSON.stringify(combinedOutputs)}`);
        setInputs(inputs);
        setInputsReady(true);
        setOutputs(combinedOutputs);
        setValues(combinedOutputs);
        // setWorking(false);
        setActivityLoaded(true);
      }
    };
    fetchInputs();
  }, [activityFieldsRef, memberOutputsRef, activityType]);

  const handleTempValues = obj => {
    //const { activityType, inputId, inputName, outputType, uid } = doc;

    //console.log(`_objggg____ ${JSON.stringify(obj)}`);
    //console.log(`tempvalues=> ${JSON.stringify(tempValues)}`);

    const mergedTempValues = _.merge(tempValues, obj);

    //const newValues = { ...tempValues, obj };
    // console.log(
    //   `**total fields answered ************_____ ${
    //     Object.keys(mergedTempValues).length
    //   }`
    // );
    setTempValues(mergedTempValues);
  };
  const handleFormChange = obj => {
    //const { activityType, inputId, inputName, outputType, uid } = doc;

    //console.log(`**************_____ ${JSON.stringify(obj)}`);
    const newValues = { ...values, ...obj };
    // console.log(
    //   `**total fields answered ************_____ ${
    //     Object.keys(newValues).length
    //   }`
    // );
    setValues(newValues);
  };

  const submitOutputValuesAndSaveActivity = async () => {
    const fstore = fstoreRef.current;
    const batch = fstore.batch();
    const memberOutputCollection = memberOutputsRef.current;
    // const outputsRef = firebase.fstore
    //   .collection('members')
    //   .doc(userId)
    //   .collection('projects')
    //   .doc(projectId)
    //   .collection('outputs');

    Object.keys(values).forEach(key => {
      //console.log(`key=> ${key}`);
      const docref = memberOutputCollection.doc(key);
      const objVal = values[key];
      const newVal = _.trim(objVal.value);
      console.log(`___new Val => ${newVal}`);
      const upVal = { ...objVal, value: newVal };

      batch.set(docref, upVal);
    });
    const memberActivityDoc = memberActivityRef.current;

    // const actRef = firebase.fstore
    //   .collection('members')
    //   .doc(userId)
    //   .collection('projects')
    //   .doc(projectId)
    //   .collection('activitys')
    //   .doc(activityId);

    batch.update(memberActivityDoc, {
      dateCompleted: firebase.fieldValue.serverTimestamp(),
      completed: true,
    });
    batch
      .commit()
      .then(() => {
        // setNextAct(true);

        setSubmitting(false);
        // setWorking(false);
      })
      .then(() => {
        history.push(PATHS.WORKZONE_PROJECT);
      });
  };

  const submitTempValues = () => {
    const fstore = fstoreRef.current;
    const batch = fstore.batch();
    const memberOutputCollection = memberOutputsRef.current;
    // const batch = firebase.fstore.batch();
    // const outputsRef = firebase.fstore
    //   .collection('members')
    //   .doc(userId)
    //   .collection('projects')
    //   .doc(projectId)
    //   .collection('outputs');

    Object.keys(tempValues).forEach(key => {
      //console.log(`key=>${key}`);
      const docref = memberOutputCollection.doc(key);
      batch.set(docref, tempValues[key], { merge: true });
    });

    batch.commit().then(() => {
      setActSave(true);
      setSubmitting(false);
      // setWorking(false);
    });
  };

  const submitOutputValues = () => {
    const fstore = fstoreRef.current;
    const batch = fstore.batch();
    const memberOutputCollection = memberOutputsRef.current;
    // const outputsRef = firebase.fstore
    //   .collection('members')
    //   .doc(userId)
    //   .collection('projects')
    //   .doc(projectId)
    //   .collection('outputs');

    Object.keys(values).forEach(key => {
      //console.log(`key=>${key}`);
      const docref = memberOutputCollection.doc(key);
      batch.set(docref, values[key]);
    });

    batch.commit().then(() => {
      memberOutputCollection.get().then(() => {
        setOutputs(values);

        setActSave(true);
        setSubmitting(false);
        // setWorking(false);
      });
    });
  };

  const saveAndCompleteActivity = () => {
    const { name, type, uid } = mainActivityData;
    const docRef = memberActivityRef.current;
    // const docref = firebase.fstore
    //   .collection('members')
    //   .doc(userId)
    //   .collection('projects')
    //   .doc(projectId)
    //   .collection('activitys')
    //   .doc(activityId);

    docRef
      .update({
        dateCompleted: firebase.fieldValue.serverTimestamp(),
        completed: true,
      })
      .then(() => {
        // setNextAct(true);

        firebase.sendAnalyticsLogEvent('activity_completed', {
          uid: uid,
          name: name,
          type: type,
        });
        setSubmitting(false);
        // setWorking(false);
      })
      .then(() => {
        history.push(PATHS.WORKZONE_PROJECT);
      });
  };
  // const handleAutoSave = () => {
  //   setSubmitting(true);
  //   setWorking(true);
  //   submitOutputValues();
  //   // Object.keys(values).map();
  // };

  const handleSaveTempValues = () => {
    setSubmitting(true);
    // setWorking(true);
    submitTempValues();
    // Object.keys(values).map();
  };
  const handleSave = event => {
    event.preventDefault();
    setSubmitting(true);
    // setWorking(true);
    submitOutputValues();
    // Object.keys(values).map();
  };
  // const handleActSave = event => {
  //   event.preventDefault();
  //   setWorking(true);
  //   setSubmitting(true);
  //   saveAndCompleteActivity();
  // };
  const handleActDone = event => {
    event.preventDefault();
    // setWorking(true);
    setSubmitting(true);
    saveAndCompleteActivity();
    // Object.keys(values).map();
  };
  if (!activityLoaded) {
    return (
      <WorkzoneCard>
        <WorkzoneCardContent>
          {' '}
          <Loading />
        </WorkzoneCardContent>
      </WorkzoneCard>
    );
  }
  return (
    <WorkzoneCard>
      {activityType === 'simpleForm' && (
        <SimpleForm
          mainActData={mainActivityData}
          ready={inputsReady}
          actSaved={actSave}
          submitting={submitting}
          fields={inputs}
          values={values}
          outputs={outputs}
          handleFormChange={handleFormChange}
          handleSave={handleSave}
          handleEnd={submitOutputValuesAndSaveActivity}
        />
      )}

      {activityType === 'madLib' && (
        <MadLib
          globals={globals}
          mainActData={mainActivityData}
          actCompleted={actDone}
          ready={inputsReady}
          actSaved={actSave}
          submitting={submitting}
          outputs={outputs}
          fields={inputs}
          values={values}
          handleFormChange={handleFormChange}
          handleSave={handleSave}
          handleEnd={handleActDone}
        />
      )}

      {activityType === 'buzzQuiz' && (
        <BuzzQuiz
          mainActData={mainActivityData}
          ready={inputsReady}
          actSaved={actSave}
          submitting={submitting}
          fields={inputs}
          values={values}
          handleFormChange={handleFormChange}
          handleSave={submitOutputValuesAndSaveActivity}
          handleEnd={handleActDone}
        />
      )}

      {activityType === 'convoBot' && (
        <ConvoBot
          mainActData={mainActivityData}
          ready={inputsReady}
          actSaved={actSave}
          submitting={submitting}
          fields={inputs}
          handleFormChange={handleTempValues}
          handleSave={handleSaveTempValues}
          handleEnd={handleActDone}
        />
      )}
    </WorkzoneCard>
  );
};
// if (activityType === 'simpleForm') {
//   return (
//     <SimpleForm
//       mainActData={mainActivityData}
//       ready={inputsReady}
//       actSaved={actSave}
//       submitting={submitting}
//       fields={inputs}
//       values={values}
//       outputs={outputs}
//       handleFormChange={handleFormChange}
//       handleSave={handleSave}
//       handleEnd={submitOutputValuesAndSaveActivity}
//     />
//   );
// }

// if (activityType === 'madLib') {
//   return (
//     <MadLib
//       globals={globals}
//       mainActData={mainActivityData}
//       actCompleted={actDone}
//       ready={inputsReady}
//       actSaved={actSave}
//       submitting={submitting}
//       outputs={outputs}
//       fields={inputs}
//       values={values}
//       handleFormChange={handleFormChange}
//       handleSave={handleSave}
//       handleEnd={handleActDone}
//     />
//   );
// }

// if (activityType === 'buzzQuiz') {
//   return (
//     <BuzzQuiz
//       mainActData={mainActivityData}
//       ready={inputsReady}
//       actSaved={actSave}
//       submitting={submitting}
//       fields={inputs}
//       values={values}
//       handleFormChange={handleFormChange}
//       handleSave={submitOutputValuesAndSaveActivity}
//       handleEnd={handleActDone}
//     />
//   );
// }

// if (activityType === 'convoBot') {
//   return (
//     <ConvoBot
//       mainActData={mainActivityData}
//       ready={inputsReady}
//       actSaved={actSave}
//       submitting={submitting}
//       fields={inputs}
//       handleFormChange={handleTempValues}
//       handleSave={handleSaveTempValues}
//       handleEnd={handleActDone}
//     />
//   );
// }

//   return (
//     <WorkzoneCard>
//       <Loading />
//     </WorkzoneCard>
//   );
// };

export default withRouter(withFirebase(ActivityContent));
