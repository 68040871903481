import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
// import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { colors } from 'styles/default';
import IconThumbUp from 'assets/icons/IconThumbUp';
import { GroupCardHeader } from 'components/GroupCard';
//import BackPattern from 'assets/imgs/backgroundpattern.png';
import DivText from 'components/DivText';
import EditArea from 'components/EditArea';
import { highlightYellow, highlightBlue } from 'styles/colors';
// import { setProjectClipboard } from 'store/actions/projectPlatform';
// import TemplateImageBanner from 'components/TemplateImageBanner';
// const CardMedia = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background-color: #ccc;
//   padding: 2rem;
//   min-height: 220px;
// `;
const CardMediaCaption = styled.div``;
// const CardMediaCaption = styled.div`
//   position: absolute;
//   z-index: 100;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
// `;

const CardMediaCaptionText = styled.div`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
`;

const AdCard = styled.div`
  background-color: #fff;
`;
// const TabsHolder = styled.div`
//   background-color: #cccccc;
//   display: flexbox;
// `;
const AdWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  outline: 0;
  padding: 0 12px;
  border-radius: 0;
  min-width: 300px;
  max-width: 600px;
  margin: auto;
  background-color: transparent;
  margin-bottom: ${props => (props.isDesktop ? '20px' : '1rem')};
`;
const PostsActionBar = styled.div`
  width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  outline: 0;
  padding: 0 1rem;
  border-radius: 0;
  height: ${props => (props.isDesktop ? '65px' : '1rem')};
  max-width: 600px;
  background-color: transparent;
  /* background-color: ${colors.template.blueLighter}; */
`;

const MediaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ccc;
  border-top: 1px solid #dddfe2;
  padding: 0;
  margin: 0;
  min-height: 300px;
  /* padding: 2rem;
  min-height: 220px; */
`;
const MediaImg = styled.img`
  max-width: 100%;
  object-fit: scale-down; /*magic*/
  border-bottom: 1px solid #dddfe2;
`;

// const MediaCaptionText = styled.div`
//   font-family: Montserrat;
//   font-size: 24px;
//   font-weight: normal;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 2;
//   letter-spacing: normal;
//   text-align: center;
//   color: #fff;
//   text-transform: uppercase;
// `;
// const AdBanner = styled.div`
//   border-top: 1px solid #dddfe2;
//   overflow: hidden;
//   display: flex;
//   justify-content: flex-end;
//   align-items: center;
//   padding: 0;
//   margin: 0;
//   > img {
//     max-width: 100%;
//     object-fit: scale-down; /*magic*/
//     border-bottom: 1px solid #dddfe2;
//   }
// `;
const AdFooter = styled.div`
  background-color: #f2f2f2;
  padding: 12px;
  display: flex;
  justify-content: space-between;
`;
const AdFooterContent = styled.div`
  display: flex;
  flex-direction: column;
`;
const AdFooterCompany = styled.div`
  display: flex;
  color: #808080;
  line-height: 1.2;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  font-family: Montserrat;
`;
const AdCta = styled.div`
  /* height: 36px;
  width: 131px; */
  border-radius: 0.47px;
  border: 1px solid #808080;
  padding: 2px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const AdCtaIcon = styled(IconThumbUp)`
  margin-right: 10px;
`;
const AdCtaLabel = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: center;
  color: #808080;
`;

const FooterHeadline = styled.p`
  margin: 0;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #808080;
  span.outputSpan {
    background: ${highlightYellow};
    color: #000000;
  }
  span.insertSpan {
    background: ${highlightBlue};
    color: #000000;
  }
`;

const MyTemplatetImageBanner = ({ templateType, media }) => {
  //console.log(`media ${media}`);
  return (
    <MediaWrapper>
      {media.type === 'placeholder' ? (
        <CardMediaCaption>
          <CardMediaCaptionText>{media.caption}</CardMediaCaptionText>
        </CardMediaCaption>
      ) : (
        <MediaImg
          src={require(`assets/ResultArchive/${templateType}/${media.filename}`)}
          alt={media.caption}
        />
      )}
    </MediaWrapper>
  );
};

const Ad = ({
  item,
  order,
  outputs,
  editMode,
  changeOutputValue,
  isDesktop,
}) => {
  // const dispatch = useDispatch();
  // const [itemText] = useState({ html: item.text });
  const [itemContent, setItemContent] = useState();

  const [tempValues, setTempValues] = useState(item.extras ? item.extras : {});
  // const [itemFieldValues] = useState(item.fields);

  const templateEval = tempstring => {
    //console.log(`tempstring=>${tempstring}`);
    const compiled = _.template(tempstring);
    return compiled(outputs);
  };
  const handleTempValues = (id, val) => {
    const updateObj = { [id]: val };
    //console.log(`handleTempValues ___${JSON.stringify(updateObj)}`);
    setTempValues({ ...tempValues, ...updateObj });
  };

  // useEffect(() => {
  //   const extras = item.extras ? item.extras : {};
  //   const allFields = { ...outputs, ...extras };

  //   const compiled = _.template(item.content);
  //   const mainContent = compiled(allFields);
  //   setItemContent(mainContent);
  //   //dispatch(setProjectClipboard(mainContent));
  // }, [item.content, item.extras, outputs]);

  useEffect(() => {
    const extras = item.extras ? item.extras : {};
    const allFields = { ...outputs, ...extras };

    let blocks;
    if (Array.isArray(item.content)) {
      blocks = item.content;
    } else {
      blocks = [item.content];
    }

    const contentBlocks = blocks.map(str => {
      const compiled = _.template(str);
      const mainContent = compiled(allFields);
      return `<p>${mainContent}</p>`;
    });
    const contentBlocksString = _.join(contentBlocks, '');

    setItemContent(contentBlocksString);
    // dispatch(setProjectClipboard(contentBlocksString));
  }, [item.content, item.extras, outputs]);
  // const handleTempValues = (id, val) => {
  //   const updateObj = { [id]: val };
  //   console.log(`${JSON.stringify(updateObj)}`);
  //   setTempValues({ ...tempValues, ...updateObj });
  // };
  // useEffect(() => {
  //   const compiled = _.template(item.content);
  //   const clip = compiled(item.fieldValues);
  //   dispatch(setProjectClipboard(clip));
  // }, [dispatch, item.content, item.fieldValues]);

  return (
    <AdWrapper isDesktop={isDesktop}>
      <PostsActionBar isDesktop={isDesktop}></PostsActionBar>

      <AdCard>
        <GroupCardHeader
          type={item.type}
          title={outputs.businessName}
          order={item.order}
          subtitle="Sponsored"
          // subtitle={<Subtitle name={fieldValues.businessName} />}
        />
        {!editMode ? (
          <>{itemContent && <DivText text={itemContent} />}</>
        ) : (
          <EditArea
            editBlocks={item.blocks}
            tempValues={tempValues}
            handleTempValues={handleTempValues}
            outputs={outputs}
            changeOutputValue={changeOutputValue}
          />
        )}
        {item.media && (
          <MyTemplatetImageBanner templateType={item.type} media={item.media} />
        )}

        {/* {item.media &&
          (item.media.type === 'placeholder' ? (
            <CardMedia>
              <CardMediaCaption>
                <CardMediaCaptionText>
                  {item.media.caption}
                </CardMediaCaptionText>
              </CardMediaCaption>
            </CardMedia>
          ) : (
            <AdBanner>
              <TemplateImageBanner item={item} />
            </AdBanner>
          ))} */}
        <AdFooter>
          <AdFooterContent>
            <AdFooterCompany>{outputs.businessName}</AdFooterCompany>
            {item.headline && (
              <FooterHeadline
                dangerouslySetInnerHTML={{
                  __html: templateEval(item.headline),
                }}
              />
            )}
          </AdFooterContent>
          <AdCta>
            {item.ctaicon === 'thumbUp' && (
              <AdCtaIcon fill="#808080" height="20" />
            )}

            <AdCtaLabel>{item.cta}</AdCtaLabel>
          </AdCta>
        </AdFooter>
      </AdCard>
      <PostsActionBar></PostsActionBar>
    </AdWrapper>
  );
};

export default Ad;
