import React from 'react';
import { Elements, StripeProvider } from 'react-stripe-elements';
import CheckoutForm from './CheckoutForm';

const CheckoutFormWrapper = () => {
  return (
    <StripeProvider apiKey="pk_test_bSY2PrXZza1Z89oN4DcXbISb00tMi2vZ3X">
      <Elements
        options={{
          locale: 'en',
          fonts: {
            cssSrc: 'https://fonts.googleapis.com/css?family=Roboto',
          },
        }}
      >
        <CheckoutForm />
      </Elements>
    </StripeProvider>
  );
};
export default CheckoutFormWrapper;
