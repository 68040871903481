import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Grid, FormControl } from '@material-ui/core';

import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  injectStripe,
} from 'react-stripe-elements';
import BtnMain from 'elements/BtnMain';
import { withFirebase } from 'api/Firebase';
import { resetCartProducts } from 'store/actions/cart';
import * as PATHS from 'routes/paths';
import ProgressWorking from 'components/ProgressWorking';

import { colors, style } from 'styles/default';
const ILabel = styled.label`
  padding: 0;
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 5400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.btnMain.text};
  margin-bottom: 0.5rem;
`;
const ErrorText = styled.p`
  padding: 0;
  width: 100%;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 5400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.text.error};
  margin-bottom: 0.5rem;
`;
const CcFormControl = styled(FormControl)`
  padding: 0.5rem;
  border-radius: ${style.borderRadius};
  border-width: 1px;
  border-style: solid;
  border-color: ${props =>
    props.error ? `${colors.text.error}` : `${colors.btnMain.text}`};
`;
const createOptions = () => {
  return {
    style: {
      base: {
        fontSize: '1rem',
        color: '#1d4798',
        fontFamily: 'Roboto',
        letterSpacing: '0.025em',
        textAlign: 'left',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#c23d4b',
      },
    },
  };
};

const CheckoutForm = ({ stripe, firebase, history }) => {
  const [working, setWorking] = useState(false);

  const [errors, setErrors] = useState('');
  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  const { items, totalPrice } = cart;

  const products = Object.keys(items).map(key => items[key]);

  const submit = async ev => {
    ev.preventDefault();
    setWorking(true);

    const { source, error } = await stripe.createSource({ type: 'card' });
    if (error) {
      // Inform the customer that there was an error.
      setWorking(false);
      setErrors(error);
    } else {
      // Send the token to your server.
      console.log(`${source}`);
      sourceHandler(source);
      //chargeHandler(source);
    }
  };

  const handleChange = ({ error }) => {
    if (error) {
      setErrors(error);
    }
  };
  //const getChargesFun = props.firebase.funcs.httpsCallable('stripeGetCharges');
  // const getCharges = async ev => {
  //   ev.preventDefault();
  //   const res = await getChargesFun();
  //   console.log(res);
  //   alert('Success! get charges customer');
  // };

  const attachFun = firebase.funcs.httpsCallable('stripeAttachSource');
  const chargeFun = firebase.funcs.httpsCallable('stripeCreateCharge');

  const sourceHandler = async source => {
    console.log(source.id);
    await attachFun({ source: source.id });
    try {
      const res = await chargeFun({
        source: source.id,
        amount: totalPrice,
        description: 'test description',
        products,
      });
      console.log(`res=>>>>>>>>>>>>>>> ${res}`);
      setWorking(false);
      dispatch(resetCartProducts()).then(history.push(PATHS.MEMBER_WORKZONES));
    } catch (error) {
      setErrors(error);
    }
    //alert('Success! source attached to customer Success! charged customer');
  };

  // const chargeHandler = async source => {
  //   const res = await chargeFun({ source: source.id, amount: 2500 });
  //   console.log(res);
  //   alert('Success! charged customer');
  // };

  return (
    <Grid container spacing={2}>
      <ProgressWorking open={working} />
      <Grid item xs={12}>
        <CcFormControl
          fullWidth
          error={errors && errors.code === 'incomplete_number' ? true : false}
        >
          <ILabel>Credit Card Number</ILabel>
          <CardNumberElement {...createOptions()} onChange={handleChange} />
        </CcFormControl>
      </Grid>
      <Grid item xs={6}>
        <CcFormControl
          fullWidth
          error={errors && errors.code === 'incomplete_expiry' ? true : false}
        >
          <ILabel>Exp. Date</ILabel>
          <CardExpiryElement {...createOptions()} onChange={handleChange} />
        </CcFormControl>
      </Grid>
      <Grid item xs={6}>
        <CcFormControl
          fullWidth
          error={errors && errors.code === 'incomplete_cvc' ? true : false}
        >
          <ILabel>CVC</ILabel>
          <CardCvcElement {...createOptions()} onChange={handleChange} />
        </CcFormControl>
      </Grid>
      {errors && (
        <Grid item xs={12}>
          <ErrorText>{errors.message}</ErrorText>
        </Grid>
      )}
      <Grid item xs={12}>
        <BtnMain fullWidth label="Place Order" onClick={submit} />
      </Grid>
    </Grid>
  );
};

export default compose(withRouter, injectStripe, withFirebase)(CheckoutForm);
