import * as React from 'react';

function SvgIconArrowBack(props) {
  return (
    <svg viewBox="0 0 50 50" {...props}>
      <path
        data-name="ArrowBack"
        d="M46.31 19.71H32.24V9a2 2 0 00-2-2 1.81 1.81 0 00-1 .27L3 23.24A2.09 2.09 0 002.26 26a2 2 0 00.71.75l26.36 16A1.89 1.89 0 0032 42a2.05 2.05 0 00.26-1V30.29h14.05A1.73 1.73 0 0048 28.53v-7.06a1.73 1.73 0 00-1.69-1.76z"
        fill={props.primary}
      />
    </svg>
  );
}

export default SvgIconArrowBack;
