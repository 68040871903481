import React from 'react';
import styled from 'styled-components/macro';
import { colors } from 'styles/default';

const ToggleButton = styled.button`
  box-sizing: border-box;
  margin: 0 12px 0 0;
  padding: 0;
  cursor: pointer;
  display: flex;
  color: ${colors.btnResult.text};
  background-color: ${colors.toggleBtn.activeFill};
  border-style: solid;
  border-width: 1px;
  border-radius: 4px 0 0 4px;
  border-color: ${colors.toggleBtn.activeFill};
  width: 210px;
  height: 100%;
  &:focus {
    outline: none;
  }
`;
const ToggleSide = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 30px;
  border-style: solid;
  border-width: 1px;
  color: ${colors.toggleBtn.text};
`;
const ToggleLeftSide = styled(ToggleSide)`
  border-radius: 4px 0 0 4px;
  border-color: ${props =>
    props.isActive ? colors.toggleBtn.activeBorder : colors.toggleBtn.border};
  background-color: ${props =>
    props.isActive ? colors.toggleBtn.activeFill : colors.toggleBtn.fill};
`;
const ToggleRightSide = styled(ToggleSide)`
  border-radius: 0 4px 4px 0;
  border-color: ${props =>
    props.isActive ? colors.toggleBtn.activeBorder : colors.toggleBtn.border};
  background-color: ${props =>
    props.isActive ? colors.toggleBtn.activeFill : colors.toggleBtn.fill};
`;
const BtnLabel = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 1;
`;

const ToggleEdit = ({ editMode, ...props }) => {
  return (
    <ToggleButton {...props}>
      <ToggleLeftSide isActive={editMode}>
        <BtnLabel>EDIT</BtnLabel>
      </ToggleLeftSide>
      <ToggleRightSide isActive={!editMode}>
        <BtnLabel>PREVIEW</BtnLabel>
      </ToggleRightSide>
    </ToggleButton>
  );
};

export default ToggleEdit;
