import React from 'react';
import MdiIcon from '@mdi/react';
import { colors } from 'styles/default';

// const defColor = `${colors.text.primary}`;
// const invColor = `${colors.primary.textContrast}`;

const Icon = ({
  inverse = false,
  color = colors.text.primary,
  size = '1.5rem',
  ...other
}) => {
  const colorText = inverse ? colors.primary.contrastText : color;
  return <MdiIcon size={size} color={colorText} {...other} />;
};

export default Icon;
