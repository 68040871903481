import React, { useState, useEffect, useRef } from 'react';
import { withFirebase } from 'api/Firebase';

import styled from 'styled-components/macro';

import { colors } from 'styles/default';
import Loading from 'components/Loading';
const CAROUSEL_HEIGHT = 150; // '190px'
const SlotContent = styled.div`
  display: flex;
  flex-direction: column;
  height: ${CAROUSEL_HEIGHT}px;
  padding: 0;
  align-content: center;
  justify-content: center;
`;
const SlotImage = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;

  > img {
    height: 80px;
    width: 80px;
    object-fit: scale-down;
  }
`;

const SlotText = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Montserrat;
  color: ${colors.marketPlace.secondaryTextColor};
  line-height: 1.1;
  margin: 1rem 0 0 0;
  > p {
    font-weight: 500;
    font-size: 1rem;
    text-align: center;
  }
`;
const CardContent = ({ firebase, slide }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [errors, setErrors] = useState();
  const [url, setUrl] = useState();
  const storageRef = useRef(firebase.bucket.ref('ui/marketplace'));

  useEffect(() => {
    const getImageUrl = async () => {
      const bucketRef = storageRef.current;

      try {
        const result = await bucketRef.child(slide.filename).getDownloadURL();
        setUrl(result);
        setErrors();
        setIsLoading(false);
      } catch (error) {
        let errorstring;
        switch (error.code) {
          case 'storage/object-not-found':
            // File doesn't exist

            errorstring = "File doesn't exist";

            break;

          case 'storage/unauthorized':
            errorstring = "User doesn't have permission to access the object";
            break;

          case 'storage/canceled':
            errorstring = 'User canceled the upload';
            break;
          case 'storage/unknown':
            errorstring = 'Unknown error occurred, inspect the server response';
            break;
          default:
            errorstring = '';
        }
        setUrl();
        setErrors(errorstring);
        setIsLoading(false);
      }
    };
    getImageUrl();
  }, [slide.filename, slide.fileref]);
  if (isLoading) {
    return (
      <SlotContent>
        <Loading />
      </SlotContent>
    );
  }
  if (url && !isLoading) {
    return (
      <SlotContent>
        <SlotImage>
          <img src={url} alt={`slideimg${slide.order}`} />
        </SlotImage>
        <SlotText dangerouslySetInnerHTML={{ __html: slide.text }} />
      </SlotContent>
    );
  }
  if (errors && !isLoading) {
    return <SlotContent>{errors}</SlotContent>;
  }

  return (
    <SlotContent>
      <Loading />
    </SlotContent>
  );
};
export default withFirebase(CardContent);
