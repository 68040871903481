import React from 'react';

import styled from 'styled-components/macro';
import { useSelector } from 'react-redux';
import BtnMain from 'elements/BtnMain';
import { colors } from 'styles/default';
import { mediaMinDevice } from 'styles/responsive';

import LogoJumpstart from 'assets/logos/LogoJumpstart';
import DashCardShadow from 'components/DashCardShadow';

const CardFieldHeader = styled.h3`
  font-family: 'Montserrat';
  text-align: left;
  color: ${colors.primary.dark};

  line-height: 1.2;
  font-weight: bold;
  padding: 0;
  margin: 0;
  font-size: 1.8rem;
  ${mediaMinDevice.deviceS} {
    font-size: 2rem;
  }
  ${mediaMinDevice.deviceM} {
    font-size: 2.2rem;
  }
  ${mediaMinDevice.deviceL} {
    font-size: 2.6rem;
  }
`;
const CardFieldCopy = styled.div`
  font-family: 'Montserrat';
  text-align: left;
  color: ${colors.primary.dark};
  font-size: ${props => (props.screenSize === 'sm' ? '18px' : '24px')};
  font-weight: 300;
  line-height: 1.2;
  padding: 0;
  margin: 0;
  font-size: 1.1rem;
  ${mediaMinDevice.deviceM} {
    font-size: 1.5rem;
  }
`;

const InnerCardContent = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  /* margin: 2rem 0; */
`;

const CardTypeArea = styled.div`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  /* margin: 2rem 0; */
`;
const CardMiddle = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  width: 100%;
  align-items: flex-start;
  margin: 1rem 0 0 0;

  ${mediaMinDevice.deviceM} {
    margin: 1.5rem 0 0 0;
  }
`;
const CardFooter = styled.div`
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  align-items: flex-start;
  margin: ${props => (props.screenSize === 'sm' ? '12px 0 0 0' : '24px 0 0 0')};
`;
const JumpStartLogo = styled(LogoJumpstart)`
  margin-right: 10px;
  margin-bottom: 3px;
`;
//  font-family: 'Montserrat-ExtraLight';
const ProductName = styled.p`
  font-family: 'Montserrat';
  font-weight: 200;
  font-size: 18px;
  line-height: 24px;
  color: ${colors.cardFields.productName};
  margin-bottom: 3px;
`;

const StatusCard = ({ data, onCtaClick }) => {
  const screenSize = useSelector(state => state.app.screenSize);
  console.log(`screenSize=${screenSize}`);
  return (
    <DashCardShadow>
      <InnerCardContent>
        <CardTypeArea>
          <JumpStartLogo height="24px" />
          <ProductName>{data.dynamicProject.name}</ProductName>
        </CardTypeArea>

        <CardMiddle screenSize={screenSize}>
          <CardFieldHeader screenSize={screenSize}>
            {data.statusContent.header}
          </CardFieldHeader>
          <CardFieldCopy screenSize={screenSize}>
            {data.statusContent.copy}
          </CardFieldCopy>
        </CardMiddle>
        {data.statusContent.cta && (
          <CardFooter screenSize={screenSize}>
            <BtnMain
              label={data.statusContent.cta.label}
              textColor="#282964"
              fillColor="#355ca3"
              onClick={onCtaClick}
            />
          </CardFooter>
        )}
      </InnerCardContent>
    </DashCardShadow>
  );
};

export default StatusCard;
