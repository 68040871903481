import React from 'react';
import styled from 'styled-components/macro';
import { formatDistance, addDays } from 'date-fns';
import { colors } from 'styles/default';
import Icon from 'elements/Icon';
import { mdiDotsHorizontal } from '@mdi/js';

import Avatar from '@material-ui/core/Avatar';
import { ReactComponent as IconEarth } from 'assets/template/icons/icon-earth.svg';

const HeaderBase = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: inherit;
  padding: 12px;
`;
const HeaderTools = styled.div`
  flex: 0 0 auto;
`;

const HeaderLogoArea = styled.div`
  flex: 0 0 auto;
`;
const HeaderTitlesArea = styled.div`
  flex: 1 1 auto;
`;
const Title = styled.div`
  cursor: pointer;
  direction: ltr;
  display: inline;
  font-family: system-ui, -apple-system, system-ui, '.SFNSText-Regular',
    sans-serif;
  font-size: 21px;
  font-weight: 600;
  line-height: 21px;
  overflow-wrap: break-word;
  text-decoration-color: rgb(56, 88, 152);
  text-decoration-line: none;
  text-decoration-style: solid;
  color: ${colors.primary.dark};
  cursor: pointer;
  text-decoration: none;
`;
const AdLogo = styled(Avatar)`
  margin: 10;
  color: #fff;
  background-color: ${colors.primary.dark};
  margin-right: 10px;
  width: 32px;
  height: 32px;
`;

const SubheaderArea = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 1rem;
`;
const Subheader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1;
  color: #616770;
`;
const SubheaderIcon = styled(IconEarth)`
  display: flex;
  height: 100%;
  margin-left: 0.3rem;
`;
// const CompanyName = styled.span`
//   color: #385898;
//   cursor: pointer;
//   text-decoration: none;
//   font-size: 14px;
//   line-height: 1.38;
//   font-weight: 600;
// `;
const SubtitleText = styled.div`
  color: rgb(97, 103, 112);
  cursor: pointer;
  direction: ltr;
  display: inline;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.08px;
  overflow-wrap: break-word;
`;
// const Subtitle = ({ name }) => {
//   return (
//     <SubtitleText>
//       Published by <CompanyName> {name}</CompanyName>
//     </SubtitleText>
//   );
// };

const GroupCardHeader = ({ title, type, subtitle, order }) => {
  const itemDate = formatDistance(addDays(new Date(), order), new Date(), {
    includeSeconds: true,
    addSuffix: true,
  });
  return (
    <HeaderBase>
      <HeaderLogoArea>
        <AdLogo />
      </HeaderLogoArea>
      <HeaderTitlesArea>
        <Title>{title}</Title>
        {type === 'fb-ads' && (
          <SubheaderArea>
            <Subheader>
              {type === 'fb-ads' && <SubtitleText>{subtitle}</SubtitleText>}
            </Subheader>
          </SubheaderArea>
        )}
        {type === 'first5' && (
          <SubheaderArea>
            <Subheader>
              <SubtitleText>Published {itemDate} •</SubtitleText>
            </Subheader>
            <SubheaderIcon />
          </SubheaderArea>
        )}
      </HeaderTitlesArea>
      <HeaderTools>
        {type !== 'fb-ads' && (
          <Icon path={mdiDotsHorizontal} color={colors.primary.dark} />
        )}
      </HeaderTools>
    </HeaderBase>
  );
};

export default GroupCardHeader;
