import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import { Card } from '@material-ui/core';

const ProgressWorking = ({ open }) => {
  return (
    <Dialog
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
      aria-labelledby="progress-working-modal"
      open={open}
    >
      <Card>
        <CircularProgress />
      </Card>
    </Dialog>
  );
};

export default ProgressWorking;
