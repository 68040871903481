import React, { useState, useLayoutEffect, useRef } from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import { hexToRGBa } from 'utils/colors';
import { colors } from './cardColors';
import BtnMain from 'elements/BtnMain';
import {
  CardWrapper,
  Card,
  CardContent,
  CardBottom,
  CardHeader,
} from './DashCardParts';
import {
  ContentTitle,
  ContentSubtitle,
  ContentCopy,
  ContentMedia,
  ContentList,
  ContentFooter,
} from './DashCardContent';
// import DashCardMedia from './DashCardMedia';
const cardShapes = ['side-right', 'top-right-round'];

const DashCard = ({ item, fullWidth }) => {
  const content = useRef(null);
  const [cardShape, setCardShape] = useState();
  const [contentHeight, setContentHeight] = useState('0px');
  // const [colorCodeObject, setColorCodeObject] = useState();
  const [borderColor, setBorderColor] = useState();
  const [itemBackColor, setItemBackColor] = useState();
  const [headerBackColor, setHeaderBackColor] = useState();
  const [headerTextColor, setHeaderTextColor] = useState();
  const [headerActionColor, setHeaderActionColor] = useState();
  const [titleTextColor, setTitleTextColor] = useState();
  const [copyTextColor, setCopyTextColor] = useState();

  const [colorSecondary, setColorSecondary] = useState();
  const [cardIsOpen, setCardIsOpen] = useState(true);
  const [cardContent] = useState(item.content);
  const [borderTheme, setBorderTheme] = useState();

  useLayoutEffect(() => {
    const { type, colorCode } = item.theme;
    let cardshape;
    if (type === 'random') {
      cardshape = _.sample(cardShapes);
    } else {
      cardshape = type;
    }
    setCardShape(cardshape);

    const colorObj = colors[colorCode];

    const { primary, secondary, headerText, titleText, copyText } = colorObj;
    setColorSecondary(secondary);
    setBorderColor(hexToRGBa(primary, 1));
    setHeaderBackColor(hexToRGBa(secondary, 0.4));
    setItemBackColor(hexToRGBa(secondary, 0.2));
    setHeaderTextColor(headerText);
    const actionStartColor = headerText === '#ffffff' ? primary : headerText;
    setHeaderActionColor(hexToRGBa(actionStartColor, 1));
    setTitleTextColor(hexToRGBa(titleText, 1));
    setCopyTextColor(hexToRGBa(copyText, 1));
    setContentHeight(cardIsOpen ? content.current.scrollHeight : 0);
    const borderThemeDef = {
      style: 'solid',
      width: '5px',
    };
    setBorderTheme(borderThemeDef);
  }, [cardIsOpen, item.theme]);

  const toggleContent = e => {
    e.preventDefault();
    console.log(`scrollheight= ${content.current.scrollHeight}`);
    setCardIsOpen(!cardIsOpen);
    // setContentHeight(cardIsOpen ? content.current.scrollHeight : 0);
  };

  const goExternalLink = url => {
    // if (e) {
    //   e.preventDefault();
    // }
    console.log(`url ${url}`);
    window.open(url);
  };
  return (
    <CardWrapper
      contentHeight={contentHeight}
      fill={borderColor}
      type={cardShape}
    >
      <Card
        borderColor={borderColor}
        type={cardShape}
        borderTheme={borderTheme}
      >
        <CardHeader
          collapsable={item.collapsable}
          backColor={headerBackColor}
          textColor={headerTextColor}
          actionColor={headerActionColor}
          isOpen={cardIsOpen}
          handleClick={toggleContent}
        >
          {item.header}
        </CardHeader>
        <CardContent
          ref={content}
          minHeight="50px"
          maxHeight={`${contentHeight}px`}
        >
          {cardContent.items && (
            <ContentList
              items={cardContent.items}
              backColor={itemBackColor}
              hoverColor={headerBackColor}
              textColor={headerActionColor}
            ></ContentList>
          )}
          {cardContent.media && <ContentMedia media={cardContent.media} />}
          {cardContent.title && (
            <ContentTitle item={cardContent.title} textColor={titleTextColor} />
          )}
          {cardContent.subtitle && (
            <ContentSubtitle
              item={cardContent.subtitle}
              textColor={titleTextColor}
            />
          )}
          {cardContent.hasOwnProperty('copy') && (
            <ContentCopy color={copyTextColor} content={cardContent.copy} />
          )}
          {cardContent.cta && (
            <ContentFooter>
              <BtnMain
                label={cardContent.cta.label}
                textColor={headerTextColor}
                fillColor={colorSecondary}
                component={Link}
                onClick={e => goExternalLink(cardContent.cta.url)}
              />
            </ContentFooter>
          )}
        </CardContent>
        <CardBottom />
      </Card>
    </CardWrapper>
  );
};

export default DashCard;
