import styled from 'styled-components/macro';
import {
  Button,
  InputBase,
  FormControl,
  FormHelperText,
  Link,
} from '@material-ui/core';
import { mediaMinDevice } from 'styles/responsive';
import { colors } from 'styles/default';

const FormLogoHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;

  margin-top: 2rem;
height: 100px;

  /* ${mediaMinDevice.deviceM} {
    height: 200px;
    padding: 1.5rem;
  } */

`;

const FormLinkButton = styled(Link)``;
const FormElement = styled.form`
  padding: 1rem;
`;
const FormContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 300px;
  margin: 0 0.5rem 0.5rem 0.5rem;
  ${mediaMinDevice.deviceM} {
    margin: 0 1.5rem 1.5rem 1.5rem;
  }
`;
const FormTopRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
`;

const FormBottomRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: 150px;
`;
const FormHeader = styled.div`
  font-family: Montserrat;

  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;

  text-align: center;
  color: ${colors.primary.dark};
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 2.5px;
  ${mediaMinDevice.deviceM} {
    font-size: 2.5rem;
    letter-spacing: 3.5px;
  }
`;

const FormSubheader = styled.div`
  font-family: Montserrat;

  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 1.5px;
  text-align: center;
  color: ${colors.primary.dark};
  font-size: 1.1rem;
  ${mediaMinDevice.deviceM} {
    font-size: 1.3rem;
    letter-spacing: 1.87px;
  }
`;

const FormSubmitButton = styled(Button)`
  font-family: Montserrat;
  font-size: 19px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: 1.69px;
  text-align: center;
  color: ${colors.formButton.activeTextColor};
  margin-bottom: 15px;
  margin-top: 15px;
  background-color: ${colors.formButton.activeBackColor};
  :hover {
    background-color: ${colors.formButton.hoverBackColor};
  }
`;

const FormFieldControl = styled(FormControl)`
  &&& {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
`;
const FormFieldErrorText = styled(FormHelperText)`
  font-family: 'Roboto';
  size: 0.8rem;
  line-height: 1;
  color: ${colors.text.error};
`;
// const AuthErrorTxt = styled.div`
//   color: ${color.redMain};
//   font-weight: ${fontWeight.semiBold};
//   font-size: ${fontType.copy};
//   text-align: center;
// `;

const FormFieldHelperText = styled(FormHelperText)`
  font-family: 'Roboto';
  size: 0.8rem;
  line-height: 1;
`;
const FormFieldInput = styled(InputBase)`
  &&& .MuiInputBase-root {
    font-family: 'Roboto';
    line-height: 1;
    font-size: 1rem;
  }
  &&& .MuiInputBase-input {
    font-family: 'Roboto';
    line-height: 1;
    font-size: 1rem;
    border-radius: 4px;
    background-color: ${colors.textArea.focusBack};
    padding: 1rem;
    border-width: 2px;
    border-style: solid;
    border-color: ${props =>
      props.error ? `${colors.text.error}` : `${colors.primary.dark}`};
    background-color: ${colors.common.white};
    &:focus {
      outline: none;
      outline-offset: 0;
      font-size: 1rem;
      background-color: ${colors.textArea.focusBack};
    }
  }
`;

const FormFieldLabel = styled.label`
  padding: 0;
  width: 100%;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.text.primary};
  margin-bottom: 0.5rem;
`;

export {
  FormElement,
  FormContent,
  FormTopRow,
  FormBottomRow,
  FormHeader,
  FormSubheader,
  FormSubmitButton,
  FormLinkButton,
  FormFieldControl,
  FormFieldHelperText,
  FormFieldInput,
  FormFieldLabel,
  FormFieldErrorText,
  FormLogoHeader,
};
