import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { Dialog } from '@material-ui/core';
import { colors } from 'styles/default';
import Icon from 'elements/Icon';
import { mdiClose } from '@mdi/js';
import { Cart } from './Cart';
import { CartBasket, CartBasketBtn } from 'components/CartBasket';
import Fade from '@material-ui/core/Fade';

// const BasketAvatar = styled(({ ...other }) => <Avatar {...other} />)`
//   && {
//     width: 65px;
//     height: 65px;
//     background-color: ${colors.primary.dark};
//     color: ${colors.primary.contrastText};
//   }
// `;
// const IconHam = styled(Icon)`
//   margin-top: 40px;
// `;

const CartArea = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 99;
`;
const CartClosed = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 323px;
  height: 180px;
  padding-right: 60px;
  /* opacity: ${({ open }) => (open ? '0' : '1')};
  transition: all 0.3s ease-in-out 0.4s; */
  z-index: 55;
`;
const CartOpen = styled.div`
  top: 0;
  right: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 323px;
  transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
  transition: transform 0.3s ease-in-out 0.4s;
  background-color: #ffffff;
  border-radius: 42px 0 0 42px;
  box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.24);
  z-index: 58;
  padding-bottom: 2rem;
`;
const CartOpenDialog = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  background-color: #ffffff;

  z-index: 58;
`;
const CartTopArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;
const IconCloseArea = styled.div`
  display: flex;
  padding: 18px;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;
// const CartTitle = styled.div`
//   font-family: Montserrat;
//   font-size: 30px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: 1.2;
//   letter-spacing: normal;
//   text-align: center;
//   color: ${colors.primary.dark};
//   text-transform: uppercase;
// `;
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Fade in={true} ref={ref} {...props} />;
});
const SideCart = ({ isOpen, handleCartClose, handleCartOpen }) => {
  const screenSize = useSelector(state => state.app.screenSize);
  const cart = useSelector(state => state.cart);
  const cartTotalItems = useSelector(state => state.cart.totalItems);
  // const [isCartOpen, setIsCartOpen] = useState(isOpen);
  // const handleCartOpen = e => {
  //   if (e) {
  //     e.preventDefault();
  //   }
  //   if (!isCartOpen) {
  //     setIsCartOpen(true);
  //   }
  // };
  // const handleCartClose = e => {
  //   if (e) {
  //     e.preventDefault();
  //   }

  //   if (isCartOpen) {
  //     setIsCartOpen(false);
  //   }
  // };
  //const [cartOpen, setCartOpen] = useState(false);
  // const [title, setTitle] = useState('Your Cart');

  // const toggleMenuClose = e => {
  //   e.preventDefault();
  //   setCartOpen(false);
  // };
  // const toggleMenuOpen = e => {
  //   e.preventDefault();
  //   setCartOpen(true);
  // };

  return (
    <>
      <Dialog
        TransitionComponent={Transition}
        fullScreen={screenSize === 'xs'}
        open={isOpen && screenSize === 'xs'}
        onClose={handleCartClose}
        aria-labelledby="responsive-dialog-title"
      >
        <CartOpenDialog>
          <CartTopArea>
            <IconCloseArea>
              <Icon
                path={mdiClose}
                color={colors.primary.dark}
                onClick={handleCartClose}
              />
            </IconCloseArea>
            <CartBasket
              totalItems={cartTotalItems > 0 ? cartTotalItems : '·'}
            />
          </CartTopArea>

          <Cart
            cart={cart}
            // items={cart.items}
            // totalItems={cart.totalItems}
            // totalPrice={cart.totalPrice}
          />
        </CartOpenDialog>
      </Dialog>
      <CartArea>
        <CartOpen open={isOpen && screenSize !== 'xs'}>
          <CartTopArea>
            <IconCloseArea>
              <Icon
                path={mdiClose}
                color={colors.primary.dark}
                onClick={handleCartClose}
              />
            </IconCloseArea>
            <CartBasket
              totalItems={cartTotalItems > 0 ? cartTotalItems : '·'}
            />
          </CartTopArea>

          <Cart
            cart={cart}
            // items={cart.items}
            // totalItems={cart.totalItems}
            // totalPrice={cart.totalPrice}
          />
        </CartOpen>

        <CartClosed>
          <CartBasketBtn
            handleClick={handleCartOpen}
            totalItems={cartTotalItems > 0 ? cartTotalItems : '·'}
          />
        </CartClosed>
      </CartArea>
    </>
  );
};

export default SideCart;
