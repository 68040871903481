import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// import styled from 'styled-components/macro';
// import { useDispatch } from 'react-redux';
// import EmailSig from 'components/EmailSig';
// import { GroupCardBanner } from 'components/GroupCard';
import _ from 'lodash';
// import { colors } from 'styles/default';
import EditArea from 'components/EditArea';
import ContentArea from 'components/ContentArea';
// import EmailTopBarIcons from 'assets/icons/EmailTopBarIcons';
// import IconEnvelope from 'assets/icons/IconEnvelope';
// import { setProjectClipboard } from 'store/actions/projectPlatform';
// import TemplateImageBanner from 'components/TemplateImageBanner';

// import DivText from 'components/DivText';

import {
  CardMedia,
  CardMediaCaption,
  CardMediaCaptionText,
  LineDivider,
  EmailWrapper,
  EmailCard,
  EmailTopBar,
  LineSubject,
  LinePreHead,
  EmailCardSubjectRow,
  EmailCardSubjectRowIcon,
  EmailCardSubjectRowContent,
  Headline,
  HeadlineSpan,
  Subheadline,
  SubheadlineSpan,
  ImgBanner,
  EmailSig,
} from 'templates/EmailParts';

const Email = ({ item, outputs, editMode, changeOutputValue, isDesktop }) => {
  const authUser = useSelector(state => state.sessionState.authUser);
  // const dispatch = useDispatch();
  // const [itemText] = useState({ html: item.text });
  const [itemContent, setItemContent] = useState();
  const [tempValues, setTempValues] = useState(item.extras ? item.extras : {});
  // const [itemFieldValues] = useState(item.fields);
  //const [contentBlocks, setContentBlocks] = useState();
  // Array.isArray(item.content) ? item.content : `[${item.content}]`
  const templateEval = tempstring => {
    //console.log(`tempstring=>${tempstring}`);
    const compiled = _.template(tempstring);
    return compiled(outputs);
  };

  const handleTempValues = (id, val) => {
    const updateObj = { [id]: val };
    // console.log(`handleTempValues ___${JSON.stringify(updateObj)}`);
    setTempValues({ ...tempValues, ...updateObj });
  };

  useEffect(() => {
    const extras = item.extras ? item.extras : {};
    const allFields = { ...outputs, ...extras };
    let blocks;
    if (Array.isArray(item.content)) {
      blocks = item.content;
    } else {
      blocks = [item.content];
    }

    // const contentBlocks = blocks.map(str => {
    //   if (Array.isArray(str)) {
    //     console.log(`isisisisisiissiisaaaar`);
    //     const arrayComp = _.template(
    //       '<% _.forEach(str, function(item) { %><li><%- _.capitalize(item) %></li><% }); %>'
    //     );
    //     const mainAContent = arrayComp(allFields);
    //     return `<p>${mainAContent}</p>`;
    //   } else {
    //     const compiled = _.template(str);
    //     const mainContent = compiled(allFields);
    //     return `<p>${mainContent}</p>`;
    //   }
    // });
    // const contentBlocksString = _.join(contentBlocks, '');

    // setItemContent(contentBlocksString);
    // dispatch(setProjectClipboard(contentBlocksString));
  }, [item.content, item.extras, outputs]);
  // useEffect(() => {
  //   const compiled = _.template(item.content);
  //   const clip = compiled(item.fieldValues);
  //   dispatch(setProjectClipboard(clip));
  // }, [dispatch, item.content, item.fieldValues]);
  return (
    <EmailWrapper isDesktop={isDesktop}>
      <EmailTopBar isDesktop={isDesktop} />

      <EmailCard isDesktop={isDesktop}>
        <EmailCardSubjectRow>
          <EmailCardSubjectRowIcon />
          <EmailCardSubjectRowContent>
            {item.testLine && (
              <LineSubject size="1.5rem">
                {templateEval(item.testLine)}
              </LineSubject>
            )}
            {item.subjectLine && (
              <LineSubject size="1.5rem">
                {templateEval(item.subjectLine)}
              </LineSubject>
            )}
            {item.preheader && (
              <LinePreHead>{templateEval(item.preheader)}</LinePreHead>
            )}
          </EmailCardSubjectRowContent>
        </EmailCardSubjectRow>

        <LineDivider />

        {item.headline && (
          <Headline>
            <HeadlineSpan>{templateEval(item.headline)}</HeadlineSpan>
          </Headline>
        )}

        {item.media &&
          (item.media.type === 'placeholder' ? (
            <CardMedia>
              <CardMediaCaption>
                <CardMediaCaptionText>
                  {item.media.caption}
                </CardMediaCaptionText>
              </CardMediaCaption>

              {/* <img
                src={require(`assets/${item.type}/${item.media.filename}`)}
                alt={item.media.caption}
              /> */}
            </CardMedia>
          ) : (
            <ImgBanner path="ResultArchive" item={item} />
          ))}

        {item.subheadline && (
          <Subheadline>
            <SubheadlineSpan>{templateEval(item.subheadline)}</SubheadlineSpan>
          </Subheadline>
        )}

        {!editMode ? (
          <ContentArea
            editBlocks={item.blocks}
            tempValues={tempValues}
            outputs={outputs}
          />
        ) : (
          <EditArea
            editBlocks={item.blocks}
            tempValues={tempValues}
            handleTempValues={handleTempValues}
            outputs={outputs}
            changeOutputValue={changeOutputValue}
          />
        )}

        <EmailSig user={authUser} />
      </EmailCard>
    </EmailWrapper>
  );
};

export default Email;
