import React from 'react';
import styled from 'styled-components/macro';
// import { useDispatch } from 'react-redux';
import EmailTopBarIcons from './EmailTopBarIcons';
// import Icon from 'elements/Icon';
// import { mdiEmailOutline } from '@mdi/js';
import { colors } from 'styles/default';
import EmailIconEnvelope from './EmailIconEnvelope';
export const OutputSpan = styled.span`
  background: ${colors.template.highlightYellow};
  color: #000000;
`;
export const CardMediaCaption = styled.div``;
export const EmailBodyDiv = styled.div``;

export const EmailBodyCopy = styled.p`
  font-family: Montserrat;
  font-size: 1rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.common.black};
`;
export const CardMediaCaptionText = styled.div`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.04;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
`;

export const LineDivider = styled.hr`
  border: none;
  height: 2.2px;
  margin: 10px 0;
  flex-shrink: 0;
  background-color: ${colors.primary.dark};
`;
export const EmailWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  padding: 0;
  margin: 0;
`;
export const EmailTemplateArea = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  outline: 0;
  border-radius: 0;
  justify-content: center;
  border: solid 4px ${colors.primary.dark};
`;
export const EmailCard = styled.div`
  margin: auto;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  outline: 0;
  padding: ${props => (props.isDesktop ? '1rem' : '0.5rem')};
  border-radius: 0;
  justify-content: center;
  background-color: #ffffff;
`;

const TopBar = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  outline: 0;
  padding: 0.5rem 1rem;
  border-radius: 0;
  /* height: 45px; */
  border-bottom: solid 5px ${colors.primary.dark};
  background-color: ${colors.template.blueLighter};
`;

export const EmailTopBar = ({ isDesktop }) => {
  return (
    <TopBar>
      <EmailTopBarIcons width={isDesktop ? '110px' : '75px'} />
    </TopBar>
  );
};

export const LineSubject = styled.div`
  font-family: Montserrat;
  line-height: 1;
  text-align: left;
  font-size: ${props => (props.size ? props.size : '1rem')};
  font-weight: 700;
  margin: 0;
  padding: 0;
  color: ${colors.primary.dark};
`;
export const LinePreHead = styled.div`
  font-family: Montserrat;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1;
  text-align: left;
  color: ${colors.primary.dark};
  padding: 0;
  margin: 0.25rem 0;
`;

export const EmailCardSubjectRow = styled.div`
  display: flex;
  justify-content: space-between;
`;
const SubjectRowIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 0 0;
  margin-right: 1rem;
  width: 100%;
`;
export const EmailCardSubjectRowIcon = () => {
  return (
    <SubjectRowIcon>
      <EmailIconEnvelope width="44" stroke={colors.primary.dark} fill="#fff" />
    </SubjectRowIcon>
  );
};

export const EmailCardSubjectRowContent = styled.div`
  flex: 1 1;
`;

export const Headline = styled.p`
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  text-align: center;
  margin: 0.5rem 0;
  /* padding-top: 0.5rem;
  padding-bottom: 0.5rem; */
`;

export const HeadlineSpan = styled.span`
  font-family: Montserrat;
  font-size: 30px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.primary.dark};
  text-transform: uppercase;
`;
export const Subheadline = styled.p`
  font-family: Montserrat;
  font-size: 21px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  line-height: 1.5;
  orphans: 2;
  widows: 2;
  margin: 0.5rem 0;
  text-align: center;
  padding: 0 0.5rem;
`;
export const SubheadlineSpan = styled.span`
  color: ${colors.primary.dark};
`;

export const CardImgSpan = styled.span`
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: center;
  padding: 1rem;
`;

export const CardMedia = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 338px;
  background-color: #ccc;
  padding: 2rem;
`;

// background-color: #f5f6f7;
const Banner = styled.div`
  border-top: 1px solid #dddfe2;
  overflow: hidden;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
  margin: 0.5rem 0;
  > img {
    max-width: 100%;
    object-fit: scale-down; /*magic*/
    border-bottom: 1px solid #dddfe2;
  }
`;

export const ImgBanner = ({ item, path }) => {
  return (
    <Banner>
      <img
        src={require(`assets/${path}/${item.type}/${item.media.filename}`)}
        alt={item.media.caption}
      />
    </Banner>
  );
};
const EmailSigContent = styled.div`
  flex: 1 1 auto;
  padding-left: 10px;
`;
const EmailSigLines = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: left;
  color: ${colors.common.black};
`;
export const EmailSig = ({ user }) => {
  return (
    <EmailSigContent>
      <EmailSigLines>Sincerely,</EmailSigLines>
      <EmailSigLines>
        {user.firstName} {user.lastName}
      </EmailSigLines>
      <EmailSigLines>{user.company}</EmailSigLines>
    </EmailSigContent>
  );
};
// assets/ResultArchive
