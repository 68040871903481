import React from 'react';

const SvgIconCartBasket = props => {
  const { totalItems, ...rest } = props;
  return (
    <svg viewBox="0 0 68 68" {...rest}>
      <g data-name="Layer 2">
        <g data-name="Layer 1">
          <circle
            cx={34}
            cy={34}
            r={32.5}
            fill="#282964"
            stroke="#fff"
            strokeMiterlimit={10}
            strokeWidth={3}
          />
          <path
            d="M50.93 29.78h-8.11l-7.43-11.12a1.69 1.69 0 00-2.81 0l-7.43 11.12h-8.08a1.69 1.69 0 00-1.69 1.69 1.47 1.47 0 00.07.46l4.3 15.69A3.37 3.37 0 0023 50.09h22a3.41 3.41 0 003.27-2.47l4.3-15.69v-.46a1.69 1.69 0 00-1.64-1.69zM34 22.65l4.76 7.13h-9.53z"
            fill="#fff"
          />
          <text
            transform="translate(34 44)"
            fontSize={12}
            fill="#282964"
            fontFamily="Montserrat-SemiBold, Montserrat"
            fontWeight={600}
            textAnchor="middle"
          >
            {totalItems}
          </text>
        </g>
      </g>
    </svg>
  );
};

export default SvgIconCartBasket;
