import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import styled from 'styled-components/macro';

import { withFirebase } from 'api/Firebase';

import { addItem } from 'store/actions/cart';
// import ProductListContainer from './ProductListContainer';
import ProductItem from './ProductItem';
import { SceneHeader, SceneSubHeader } from 'elements/Headers';

// import { CartContainer } from 'components/Cart';
import * as PATHS from 'routes/paths';
// import SideCart from './SideCart';
import SideCart from 'modules/SideCart';
import { GridBase } from 'elements/LayoutElements';
// import Loading from 'components/Loading';
import CardLoader from 'elements/CardLoader';

import { mediaMinDevice } from 'styles/responsive';
// const MarketScreen = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100%;
// `;
// const ScrollWindow = styled.div`
//   display: block;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   height: 50%;
//   overflow-y: auto;
//   overflow-x: hidden;
//   padding-bottom: 2rem;
// `;

const SideCartArea = styled.div`
  top: 0;
  right: 0;
  position: absolute;
  display: flex;

  z-index: 54;
`;

const GridCardHolder = styled(GridBase)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  ${mediaMinDevice.deviceL} {
    justify-content: space-between;
  }
`;

const Marketplace = ({ history, firebase }) => {
  // const isDesktop = useSelector(state => state.app.isDesktop);
  // const screenSize = useSelector(state => state.app.screenSize);
  const [isCartOpen, setIsCartOpen] = useState(false);
  const userId = useSelector(state => state.sessionState.authUser.uid);

  const [marketPlaceReady, setMarketPlaceReady] = useState(false);
  const [mainProjects, setMainProjects] = useState();
  const [memberProjects, setMemberProjects] = useState();
  const mainProjectCollRef = useRef(firebase.fstore.collection('projects'));
  const memberProjectCollRef = useRef(
    firebase.fstore
      .collection('members')
      .doc(userId)
      .collection('projects')
  );

  useEffect(() => {
    const memberProjects = memberProjectCollRef.current;

    const unsubscribe = memberProjects.onSnapshot(function(snapshot) {
      var data = snapshot.docs
        .map(function(documentSnapshot) {
          console.log(`> ${documentSnapshot.data().uid}`);
          return documentSnapshot.data();
        })
        .reduce((obj, item) => {
          // console.log(`item.key ${item.id}`);
          obj[item.uid] = item;
          return obj;
        }, {});

      setMemberProjects(data);
    });
    return () => unsubscribe();
  }, [memberProjectCollRef]);

  useEffect(() => {
    const fetchMainProjects = async () => {
      const query = mainProjectCollRef.current.where('active', '==', true);
      const snaps = await query.get();

      console.log(`fetchMainProjects=>${snaps}`);
      setMainProjects(snaps);
      setMarketPlaceReady(true);
    };
    fetchMainProjects();
  }, [mainProjectCollRef]);

  // useEffect(() => {
  //   const fetchProjects = async () => {
  //     console.log(`fetchProjects`);
  //     const snapshot = await firebase.projects(userId).get();
  //     //const snapshot = await projectInputsRef.get();
  //     const data = snapshot.docs
  //       .map(docsnap => docsnap.data())
  //       .reduce((obj, item) => {
  //         obj[item.uid] = item;
  //         return obj;
  //       }, {});

  //     console.log(`fetchProjects=>${data}`);
  //     setProjects(data);
  //   };
  //   fetchProjects();
  // }, [firebase, userId]);

  const dispatch = useDispatch();
  const cart = useSelector(state => state.cart);
  // const [snapshots, loading, error] = useList(firebase.dbase.ref('projects'));
  // const [openCart, setOpenCart] = useState(false);
  // const [snapshots, loading, error] = useCollection(
  //   firebase.fstore.collection('projects'),
  //   {
  //     snapshotListenOptions: { includeMetadataChanges: true },
  //   }
  // );
  const handleViewChange = () => {
    history.push(PATHS.MEMBER_WORKZONES);
  };

  const handleCartOpen = e => {
    if (e) {
      e.preventDefault();
    }
    if (!isCartOpen) {
      setIsCartOpen(true);
    }
  };
  const handleCartClose = e => {
    if (e) {
      e.preventDefault();
    }

    if (isCartOpen) {
      setIsCartOpen(false);
    }
  };

  const addItemToCart = data => {
    if (!isCartOpen) {
      console.log(`isCartOpen => ${isCartOpen}`);
      setIsCartOpen(true);
    }
    dispatch(addItem(data));
  };

  if (!marketPlaceReady) {
    return <CardLoader />;
  }
  return (
    <GridBase container>
      <SideCartArea>
        <SideCart
          isOpen={isCartOpen}
          handleCartOpen={handleCartOpen}
          handleCartClose={handleCartClose}
        />
      </SideCartArea>

      <GridBase container justify="flex-start" spacing={0}>
        <GridBase item xs={12}>
          <SceneHeader>WELCOME TO THE MARKETPLACE!</SceneHeader>
        </GridBase>
        <GridBase item xs={12}>
          <SceneSubHeader>
            Discover and shop projects designed to empower your entrepreneurial
            journey.
          </SceneSubHeader>
        </GridBase>
      </GridBase>

      <GridCardHolder
        container
        // justify={isDesktop ? 'space-around' : 'center'}
        // spacing={isDesktop ? 10 : 4}
        spacing={0}
      >
        {memberProjects &&
          mainProjects &&
          mainProjects.docs.map(doc => (
            <ProductItem
              key={doc.id}
              item={doc.data()}
              inProjects={memberProjects.hasOwnProperty(doc.id)}
              inCart={cart.items.hasOwnProperty(doc.id)}
              onAddToCartClicked={() => addItemToCart(doc.data())}
              // onAddToCartClicked={() => dispatch(addItem(doc.data()))}
              onCheckWorkzoneClicked={handleViewChange}
              onCheckCart={handleCartOpen}
              // onAddToCartClicked={() =>
              //   dispatch({ type: ADD_ITEM, item: product })
              // }
            />
          ))}
      </GridCardHolder>
    </GridBase>
  );
};

export default withFirebase(Marketplace);
