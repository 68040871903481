import React, { useState, useEffect, useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import * as _ from 'lodash';

// import MuiAlert from '@material-ui/lab/Alert';
import styled from 'styled-components/macro';
import { Redirect } from 'react-router-dom';
import { saveAs } from 'file-saver';
import { useSelector } from 'react-redux';
import { withFirebase } from 'api/Firebase';

import DialogModal, { DialogModalContentHtml } from 'components/DialogModal';
import useModal from 'hooks/useModal';
import CollectionLoader from 'elements/CollectionLoader';
import Loading from 'components/Loading';

import ResultsHeader from './ResultsHeader';

import { Description, FooterArea, ContentArea } from 'elements/WorkzoneText';
import createDocx from 'utils/createDocx';

// import ResultsHolder from './ResultsHolder';
import BtnMain from 'elements/BtnMain';
import BtnDownload from 'elements/BtnDownload';
import * as PATHS from 'routes/paths';
import WorkzoneCard, { WorkzoneCardContent } from 'components/WorkzoneCard';

import JSZip from 'jszip';

// const ResultsBtnsContainer = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   margin-bottom: 1rem;
// `;
// const ResultsRowSpaceBetween = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: space-between;
//   flex-wrap: wrap;
//   margin-top: 1rem;
//   margin-bottom: 1rem;
// `;
const ResultsRowSpaceBetween = styled(FooterArea)`
  justify-content: space-between;
`;

const ResultsRowCenter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;
`;
// const ResultsContainer = styled.div`
//   width: 100%;
//   display: flex;
//   justify-content: flex-start;
// `;
// const ResultTextArea = styled.input`
//   border: solid 2px #00ff00;
// `;

// const action = key => (
//   <>
//       <Button onClick={() => { this.props.closeSnackbar(key) }}>
//           'Dismiss'
//       </Button>
//   </>
// );
// function AlertPhotos(props) {
//   return (
//     <BigAlert
//       onClose={() => }
//       severity="success"
//       elevation={6}
//       variant="filled"
//       {...props}
//     >
//       Photos Downloaded
//     </BigAlert>
//   );
// }

// function AlertTemplate(props) {
//   return <BigAlert elevation={6} variant="filled" {...props} />;
// }

export const fileToBase64 = (filename, filepath) => {
  return new Promise(resolve => {
    var file = new File([filename], filepath);
    var reader = new FileReader();
    // Read file content on file loaded event
    reader.onload = function(event) {
      //console.log(`reader=> ${event.target.result}`);
      resolve(event.target.result);
    };

    // Convert data to base64
    //reader.readAsArrayBuffer(file);
    reader.readAsDataURL(file);
  });
};
const WorkzoneProjectResultsBuilder = ({ history, firebase }) => {
  const authId = useSelector(state => state.sessionState.authUser.uid);
  const projectId = useSelector(state => state.projectState.projectId);
  const projectStatus = useSelector(state => state.projectState.status);
  const resultId = useSelector(state => state.projectState.resultId);
  const projectData = useSelector(state => state.projectState.data);

  const [resultScreens, setResultScreens] = useState();
  const [screenType, setScreenType] = useState('confirmation');

  const [checkingProject, setCheckingProject] = useState(true);
  const [projectComplete, setProjectComplete] = useState(false);
  //const outputs = useSelector(state => state.projectState.outputs);
  const [templateData, setTemplateData] = useState();
  // const [sectionItem, setSectionItem] = useState();
  // const [resultString, setResultString] = useState();
  // const [resultStart, setResultStart] = useState(false);
  const [downloadingTemplates, setDownloadingTemplates] = useState(false);
  const [downloadingPhotos, setDownloadingPhotos] = useState(false);
  const [isBuilding, setIsBuilding] = useState(false);
  //const [downloadingPhotos, setDownloadingPhotos] = useState(false);
  const TEMPLATE_ZIP_NAME = 'Facebook-JumpstartTemplates.zip';
  const PHOTOS_ZIP_NAME = 'Facebook-JumpstartPhotos.zip';
  const [itemModalOpen, setItemModalOpen] = useModal();

  const { enqueueSnackbar } = useSnackbar();
  const memberProjectRef = useRef(
    firebase.fstore
      .collection('members')
      .doc(authId)
      .collection('projects')
      .doc(projectId)
  );
  const mainProjectRef = useRef(
    firebase.fstore
      .collection('projects')
      .doc(projectId)
      .collection('activitys')
      .doc(resultId)
  );
  const projectTemplatesRef = useRef(
    firebase.dbase.ref(`/projects/${projectId}/templates`)
  );
  const projectStorageRef = useRef(
    firebase.bucket.ref(`/projects/${projectId}`)
  );

  const memberStorageRef = useRef(
    firebase.bucket.ref(`/members/${authId}/projects/${projectId}/`)
  );

  useEffect(() => {
    const getResultScreens = async () => {
      const actRef = mainProjectRef.current;
      const screens = await actRef.get();
      const screensData = screens.data().resultScreens;
      //console.log(`screensData ${JSON.stringify(screensData)}`);
      setResultScreens(screensData);
    };
    getResultScreens();
  }, [mainProjectRef]);

  useEffect(() => {
    const dataStatus = projectData ? projectData.status : projectStatus;

    if (dataStatus === 'complete') {
      setScreenType('complete');
      setProjectComplete(true);
      setCheckingProject(false);
    } else {
      setCheckingProject(false);
    }
  }, [projectData, projectStatus]);

  useEffect(() => {
    const createTemplateData = async () => {
      const outputRef = memberProjectRef.current.collection('outputs');

      const myout = await outputRef.get();
      const data = myout.docs.map(doc => doc.data());
      const outputs = data.reduce((obj, item) => {
        // console.log(`item.key ${item.id}`);
        obj[item.id] = item;
        return obj;
      }, {});

      const pref = projectTemplatesRef.current;
      const snapshot = await pref.once('value');
      const templates = snapshot.val();

      const templateKeys = Object.keys(templates);

      const templateData = templateKeys.map(key => {
        const sectionData = templates[key];
        const { views } = sectionData;
        const resultTemplates = Object.keys(views).map(key => {
          const resItems = {};
          let fieldValues;
          const view = views[key];

          if (view.fields) {
            fieldValues = view.fields.reduce((field, item) => {
              // console.log(`field item ${item}`);
              field[item] = outputs[item] ? outputs[item].value : item;
              return field;
            }, {});
          }

          if (view.extras) {
            fieldValues = {
              ...fieldValues,
              ...view.extras,
            };
            //return { ...view, fieldValues };
          } else {
            fieldValues = { ...fieldValues };
          }
          // resItems.fields = fieldValues;
          resItems.name = view.name;

          // resultStringArray.push(view.name);
          if (view.doc) {
            resItems.doc = view.doc.map(item => {
              const itemTemp = _.template(item.text);
              return {
                ...item,
                text: itemTemp(fieldValues),
              };
            });
          }

          return resItems;
        });

        console.log(`resultTemplates =${resultTemplates.length}`);
        const sectionItem = {
          title: 'Facebook Jumpstart',
          folder: sectionData.folder,
          section: sectionData.name,
          directions: sectionData.directions,
          tips: sectionData.tips,
          templates: resultTemplates,
          footer: sectionData.footer,
        };

        return sectionItem;
      });

      setTemplateData(templateData);
      // setResultStart(true);
    };
    if (projectComplete) {
      createTemplateData();
    }
  }, [projectComplete]);

  const downloadPhotoZip = () => {
    checkIfZipExists(projectStorageRef, PHOTOS_ZIP_NAME)
      .then(function(url) {
        // Insert url into an <img> tag to "download"
        console.log(`url=>${url}`);
        downloadZipFile(url, PHOTOS_ZIP_NAME);
      })
      .catch(function(error) {
        console.log(`error =${JSON.stringify(error.code)}`);
      });
  };
  const downloadPhotos = e => {
    setDownloadingPhotos(true);
    downloadPhotoZip();
  };

  const downloadZipFile = (url, fileName) => {
    const message = `${fileName} has downloaded`;
    const packageType = PHOTOS_ZIP_NAME === fileName ? 'photos' : 'templates';
    const variant = packageType === 'photos' ? 'info' : 'success';
    var xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function(event) {
      const blob = xhr.response;
      saveAs(blob, fileName);
      if (packageType === 'photos') {
        setDownloadingPhotos(false);
      } else {
        setDownloadingTemplates(false);
      }
      //setBuilding(false);
      enqueueSnackbar(message, {
        variant,
      });
    };
    xhr.open('GET', url);
    xhr.send();
  };

  const completeActivityAndProject = async () => {
    console.log(`completeActivityAndProject`);
    const batch = firebase.fstore.batch();
    const projectDoc = memberProjectRef.current;
    batch.update(projectDoc, { status: 'complete' });

    const activityDoc = projectDoc.collection('activitys').doc(resultId);
    batch.update(activityDoc, { completed: true });

    const statusComplete = await batch.commit();
    console.log(`statusComplete ${statusComplete}`);
    const { name, type, uid } = projectData;

    firebase.sendAnalyticsLogEvent('project_completed', { uid, name, type });
    setProjectComplete(true);
    setIsBuilding(false);
  };

  async function checkIfZipExists(ref, zipName) {
    const zipRef = await ref.current.child(zipName);
    // Get the download URL
    return zipRef.getDownloadURL();
  }
  async function fileToBase(file) {
    return new Promise(resolve => {
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';
      xhr.onload = function(event) {
        resolve(xhr.response);
      };
      xhr.open('GET', file);
      xhr.send();
    });
  }
  async function buildResultDocs(zipName) {
    const zip = new JSZip();

    const resultDocsRef = await projectStorageRef.current.child('docs');

    const imgCalRef = resultDocsRef.child('5-CALENDAR_FOR_PLANNING.jpg');

    const imgCalUrl = await imgCalRef.getDownloadURL();
    console.log(`imgCalRef _____- ${imgCalRef.fullPath}`);
    const imgCalFile = await fileToBase(imgCalUrl);

    zip.file('5-CALENDAR_FOR_PLANNING.jpg', imgCalFile);

    const howDocRef = resultDocsRef.child('_READ_ME_FIRST.docx');
    const howDocUrl = await howDocRef.getDownloadURL();
    console.log(`howDocUrl _____- ${howDocUrl.fullPath}`);
    const howDocFile = await fileToBase(howDocUrl);

    zip.file('_READ_ME_FIRST.docx', howDocFile);

    const imageRef = await projectStorageRef.current
      .child('ea-logo-results.jpg')
      .getDownloadURL();
    //const imageurl =
    console.log(`imageurl _____- ${imageRef.fullPath}`);

    await templateData.forEach(item => {
      const fileName = `${item.folder}.docx`;
      const file = createDocx(item, imageRef);
      zip.file(fileName, file);
    });

    var promise = null;
    if (JSZip.support.blob) {
      promise = zip.generateAsync({ type: 'blob' });
    } else {
      promise = zip.generateAsync({ type: 'string' });
    }

    promise.then(blob => {
      memberStorageRef.current
        .child(zipName)
        .put(blob, { contentType: 'application/zip' })
        .then(() => console.log(`blob`));
    });

    promise
      .then(blob => {
        saveAs(blob, zipName);
      })
      .then(() => {
        const { name, type, uid } = projectData;

        firebase.sendAnalyticsLogEvent('results_downloaded', {
          name,
          type,
          uid,
        });
        setDownloadingTemplates(false);
      });
  }

  const startBuildTemplates = e => {
    console.log(`startBuildTemplate`);
    e.preventDefault();
    setItemModalOpen(true);
  };
  const continueBuildTemplates = () => {
    console.log(`continueBuildTemplates`);
    setIsBuilding(true);
    completeActivityAndProject();
  };
  const gotoResults = () => {
    history.push(`${PATHS.WORKZONE_PROJECT_RESULTS}`);
  };

  function downloadTemplates(e) {
    e.preventDefault();
    setDownloadingTemplates(true);
    checkIfZipExists(memberStorageRef, TEMPLATE_ZIP_NAME)
      .then(function(url) {
        // Insert url into an <img> tag to "download"
        console.log(`url=>${url}`);
        downloadZipFile(url, TEMPLATE_ZIP_NAME);
      })
      .catch(function(error) {
        console.log(`error =${JSON.stringify(error.code)}`);
        buildResultDocs(TEMPLATE_ZIP_NAME);
      });
  }

  if (!projectId) {
    return <Redirect to={PATHS.MEMBER_WORKZONES} />;
  }
  if (checkingProject) {
    return (
      <WorkzoneCard>
        <CollectionLoader />
      </WorkzoneCard>
    );
  }

  if (!projectComplete) {
    return (
      <>
        {resultScreens && (
          <DialogModal
            title="Are you sure?"
            isOpen={itemModalOpen}
            actionClose={() => setItemModalOpen(false)}
            actionCancel={gotoResults}
            actionOk={continueBuildTemplates}
            disableBackDrop={true}
            labelCancel="Go Back"
            labelOk="I'm Sure!"
          >
            {isBuilding ? (
              <Loading />
            ) : (
              <DialogModalContentHtml
                dangerouslySetInnerHTML={{
                  __html: resultScreens['warning'].copy,
                }}
              />
            )}
          </DialogModal>
        )}
        <WorkzoneCard>
          <WorkzoneCardContent>
            {resultScreens && (
              <>
                <ResultsHeader screen={resultScreens[screenType]} />
                <ResultsRowSpaceBetween>
                  <BtnMain label="Back For Changes" onClick={gotoResults} />
                  <BtnMain label="Start" onClick={startBuildTemplates} />
                </ResultsRowSpaceBetween>
              </>
            )}
          </WorkzoneCardContent>
        </WorkzoneCard>
      </>
    );
  }

  return (
    <WorkzoneCard>
      {resultScreens && (
        <WorkzoneCardContent>
          <ResultsHeader screen={resultScreens[screenType]} />
          <ContentArea>
            <ResultsRowCenter>
              <BtnDownload
                label="Download Your Templates"
                building={downloadingTemplates}
                disabled={downloadingTemplates}
                onClick={downloadTemplates}
              />
            </ResultsRowCenter>
            <ResultsRowCenter>
              <BtnDownload
                building={downloadingPhotos}
                disabled={downloadingPhotos}
                label="Download Your Images"
                onClick={downloadPhotos}
              />
            </ResultsRowCenter>
          </ContentArea>
          <FooterArea>
            <Description
              dangerouslySetInnerHTML={{
                __html: resultScreens[screenType].tips,
              }}
            />
          </FooterArea>
        </WorkzoneCardContent>
      )}
    </WorkzoneCard>
  );
};

export default withRouter(withFirebase(WorkzoneProjectResultsBuilder));
