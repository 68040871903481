import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CardActions from '@material-ui/core/CardActions';

import { removeItem } from 'store/actions/cart';
// import CartItemList from './CartItemList';
import CartItem from './CartItem';

import { formatPrice } from 'utils';
import CheckoutForm from '../CheckoutForm';
import BtnMain from 'elements/BtnMain';
import styled from 'styled-components/macro';
import { colors } from 'styles/default';

import { mediaMinDevice } from 'styles/responsive';

const CartTitle = styled.div`
  font-family: Montserrat;

  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.primary.dark};
  text-transform: uppercase;
  font-size: 1.4rem;
  margin-bottom: 0.5rem;
  ${mediaMinDevice.deviceS} {
    font-size: 1.8rem;
    margin-bottom: 2rem;
  }
`;
// const CartDivider = styled.div`
//   margin: 20px 0;
//   width: 100%;
//   height: 1px;
//   background-color: ${colors.primary.light};
// `;
const EmptyCart = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.cart.textColor};
  padding: 16px 0;
  border-top: solid 1px ${colors.primary.light};
  border-bottom: solid 1px ${colors.primary.light};
  min-height: 44px;
`;

// const CartItemRow = styled.div`
//   width: 100%;
//   display: flex;
//   flex-wrap: no-wrap;
//   box-sizing: border-box;
//   justify-content: space-between;
//   align-items: center;
//   padding: 8px 0;
//   border-top: solid 1px ${colors.primary.light};
// `;
const CartEstimatedTotal = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.cart.textColor};
  padding: 8px 0;
  border-top: solid 1px ${colors.primary.light};
`;
const CartSubTotal = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: ${colors.cart.textColor};
  padding: 8px 0;
  border-top: solid 1px ${colors.primary.light};
`;
const CartTax = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: ${colors.cart.textColor};
  padding: 0 0 8px 0;
`;
const CartTotalPrice = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: right;
  color: ${colors.cart.textColor};
  padding: 8px 0;
  border-top: solid 1px ${colors.primary.light};
`;
const CartContentArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 1.5rem 1.5rem 0 1.5rem;
  ${mediaMinDevice.deviceS} {
    margin: 3rem 1.5rem 0 1.5rem;
  }
`;

const Cart = () => {
  const dispatch = useDispatch();
  const cartItems = useSelector(state => state.cart.items);
  const cartTotalItems = useSelector(state => state.cart.totalItems);
  const cartSubTotal = useSelector(state => state.cart.subTotal);
  const [addPayment, setAddPayment] = useState(false);
  const hasProducts = cartTotalItems > 0;
  const [cartTotals, setCartTotals] = useState({
    subTotal: 0,
    tax: 0,
    totalPriceRaw: 0,
    totalPrice: 0,
  });

  useEffect(() => {
    const subTotal = cartSubTotal / 100;
    const taxRaw = subTotal * 0.06;
    const taxAdjust = Math.round(taxRaw * 100);
    const taxCost = taxAdjust / 100;
    const tax = taxCost.toFixed(2);
    const totalAmount = Number(subTotal) + Number(tax);
    const totalPriceRaw = totalAmount * 100;
    setCartTotals({
      subTotal,
      tax,
      totalPriceRaw,
      totalPrice: totalAmount,
    });
  }, [cartSubTotal]);

  const showPaymentForm = e => {
    e.preventDefault();
    setAddPayment(true);
  };
  const hidePaymentForm = e => {
    e.preventDefault();
    setAddPayment(false);
  };
  const nodes = hasProducts ? (
    Object.keys(cartItems).map(key => (
      <CartItem
        key={key}
        item={cartItems[key]}
        onRemoveCartItem={() => dispatch(removeItem(key))}
      />
    ))
  ) : (
    <EmptyCart>Please add some products to cart.</EmptyCart>
  );

  if (!addPayment) {
    return (
      <CartContentArea>
        <CartTitle>Your Cart</CartTitle>
        {hasProducts && (
          <CartEstimatedTotal>
            Estimated Total: &#36;{formatPrice(cartSubTotal)}
          </CartEstimatedTotal>
        )}

        {nodes}
        {/* <CartItemList title="Cart Items">{nodes}</CartItemList> */}

        <CardActions>
          {hasProducts && (
            <BtnMain
              fullWidth
              label="Add Payment"
              disabled={!hasProducts}
              onClick={showPaymentForm}
            />
          )}
        </CardActions>
      </CartContentArea>
    );
  }

  return (
    <CartContentArea>
      <CartTitle>Your Cart</CartTitle>

      {hasProducts &&
        Object.keys(cartItems).map(key => (
          <CartItem key={key} item={cartItems[key]} />
        ))}
      {hasProducts && (
        <CartSubTotal>Subtotal: &#36;{cartTotals.subTotal}</CartSubTotal>
      )}
      {hasProducts && <CartTax>Tax: &#36;{cartTotals.tax}</CartTax>}
      {hasProducts && (
        <CartTotalPrice>Total Due: &#36;{cartTotals.totalPrice}</CartTotalPrice>
      )}
      <CardActions>
        <BtnMain
          fullWidth
          label="Continue Shopping"
          disabled={!hasProducts}
          onClick={hidePaymentForm}
        />
      </CardActions>

      <CardActions>
        {hasProducts && (
          <CheckoutForm
            products={cartItems}
            totalPrice={cartTotals.totalPriceRaw}
          />
        )}
      </CardActions>
    </CartContentArea>
  );
};

export default Cart;
