import React from 'react';

const PanelBackSmall = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="100%"
    width="100%"
    viewBox="0 0 270 210"
    {...props}
  >
    <defs>
      <pattern
        id="PatternCardSmallFill"
        width="9"
        height="9"
        data-name="New Pattern 6"
        patternTransform="translate(19.55 -33.38)"
        patternUnits="userSpaceOnUse"
      >
        <path d="M0 0h9v9H0z" fill="none" />
        <path d="M4.5 3L3 6h3L4.5 3z" fill="#000" />
      </pattern>
      <pattern
        id="PatternCardSmallStroke"
        width="9"
        height="9"
        data-name="New Pattern 6"
        patternTransform="translate(21.55 -92.38)"
        patternUnits="userSpaceOnUse"
      >
        <path d="M0 0h9v9H0z" fill="none" />
        <path d="M4.5 3L3 6h3L4.5 3z" />
      </pattern>
    </defs>
    <g id="ProjectCardSmall">
      <path
        id="ProjectCardSmallCorner"
        fill="fill"
        d="M0 91.9h200.88V210H17.26A17.26 17.26 0 0 1 0 192.74V91.9z"
        opacity=".58"
      />
      <path
        id="ProjectCardSmallPattern"
        fill="url(#PatternCardSmallFill)"
        stroke="url(#PatternCardSmallStroke)"
        strokeMiterlimit="10"
        strokeWidth=".39"
        d="M55.58 17.88h213.24v137.8H55.58z"
        opacity=".67"
      />
      <path
        id="ProjectCardSmallPath"
        fill="fill"
        d="M15.6 0h218.66a18.63 18.63 0 0 1 18.63 18.63v177.11H34.78a19.18 19.18 0 0 1-19.18-19.18V0z"
      />
      <path
        id="ProjectCardSmallInner"
        fill="#fff"
        d="M28.13 9h197a18.23 18.23 0 0 1 18.18 18.2V187H46.9a18.77 18.77 0 0 1-18.77-18.77V9z"
      />
    </g>
  </svg>
);
const PanelBack = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 270 366"
    {...props}
  >
    <defs>
      <pattern
        id="ProductPattern01"
        width="9"
        height="9"
        patternTransform="translate(29.53 -21.38)"
        patternUnits="userSpaceOnUse"
      >
        <path d="M0 0h9v9H0z" fill="none" />
        <path d="M4.5 3L3 6h3L4.5 3z" fill="#000" />
      </pattern>
      <pattern
        id="ProductStrokePattern"
        width="9"
        height="9"
        patternTransform="translate(31.53 -80.38)"
        patternUnits="userSpaceOnUse"
      >
        <path d="M0 0h9v9H0z" fill="none" />
        <path d="M4.5 3L3 6h3L4.5 3z" />
      </pattern>
    </defs>
    <g id="ProductCardBack">
      <path
        id="ProductCardCorner"
        fill="fill"
        d="M0 165.13h200.88V366H22.52A22.52 22.52 0 0 1 0 343.5V165.13z"
        opacity=".58"
      />
      <path
        id="ProductCardPattern"
        fill="url(#ProductPattern01)"
        stroke="url(#ProductStrokePattern)"
        strokeMiterlimit="10"
        strokeWidth=".5"
        d="M55.58 28.93h213.24v222.99H55.58z"
        opacity=".86"
      />
      <path
        id="ProductCardPath"
        fill="fill"
        d="M15.6 0h212.32a25 25 0 0 1 25 25v326.71H41.31A25.71 25.71 0 0 1 15.6 326V0z"
      />
      <path
        id="ProductCardInner"
        fill="#fff"
        d="M28.13 11.05h190.31a24.87 24.87 0 0 1 24.87 24.87v306.69H53.74A25.61 25.61 0 0 1 28.13 317V11.05z"
      />
    </g>
  </svg>
);

export { PanelBack, PanelBackSmall };
