import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { FormHelperText } from '@material-ui/core';
import ToolTip from '@material-ui/core/Tooltip';
import { colors } from 'styles/default';
// import IconHelpTip from 'assets/icons/IconHelpTip';
import { IconHelp } from 'elements/UiIcons';
import useCharMaxLimit from 'hooks/useCharMaxLimit';
// const ControlForm = styled.div`
//   &&&& {
//     display: flex;
//     flex-direction: ${props => (props.vertical ? 'column' : 'row')};
//     align-items: ${props => (props.vertical ? 'flex-start' : 'center')};
//     width: 100%;
//     border: 0;
//     margin: 0;
//     padding: 0;
//     position: relative;
//     min-width: 0;
//     vertical-align: top;
//     margin-top: 8px;
//     padding-bottom: 10px;
//   }
// `;
const CharsMaxTextArea = styled.div`
  position: absolute;
  bottom: -1rem;
  right: 0;
  > p {
    color: ${props => (props.warn ? '#ff0000' : '#cdcdcd')};
  }
`;

const CharsMaxText = styled(FormHelperText)`
  text-align: right;
  font-style: italic;
`;

const ControlForm = styled.div`
  &&&& {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 0;
    margin: 0;
    padding: 0;
    min-width: 0;
    text-align: center;
    margin-bottom: 20px;
  }
`;
const LabelArea = styled.div`
  display: flex;
  font-weight: 700;
  line-height: 1.2;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
`;
// const LabelText = styled.div`
//   display: flex;
//   flex: 1 1 auto;
//   word-wrap: false;
//   text-align: center;
// `;
const LabelText = styled.div`
  color: ${colors.text.primary};
  line-height: 1;
  text-align: center;
  font-size: 12px;
  font-family: Montserrat;
  font-weight: 400;
  text-transform: uppercase;
`;
const InputArea = styled.div`
  position: relative;
  display: flex;
  flex: 1 1 auto;
  width: 100%;
  > input {
    width: 100%;
    text-align: center;
  }
`;
const InputField = styled(TextareaAutosize)`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
  color: ${colors.text.primary};

  border-radius: 0;
  border-width: 0;
  border-bottom: solid 2.2px ${colors.primary.dark};
  padding: 0.5rem 1rem;
  height: auto;
  width: 100%;
  text-align: center;
  max-width: 100%;
  resize: none;

  &:focus {
    outline: none;
    outline-offset: 0;
    background-color: ${colors.textArea.focusBack};
  }
  &&& .MuiInput-underline:before {
    border-bottom: none;
  }

  && .MuiInput-underline:hover {
    border-bottom: none;
  }
  && .MuiInput-underline:after {
    border-bottom: none;
  }
`;
// const TextInput = styled.input`
//   width: 100%;
//   border: 0;
//   height: 1.2em;
//   margin: 0;
//   display: block;
//   padding: 6px 0 7px;
//   min-width: 0;
//   background: none;
//   box-sizing: content-box;
//   font-family: Montserrat;
//   font-size: 24px;
//   font-weight: bold;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.2;
//   letter-spacing: normal;
//   text-align: center;
//   color: ${colors.text.primary};
// `;

// const InputText = styled(({ ...other }) => <Input {...other} />)`
//   &&&&&&&& {
//     flex: 1 1 auto;
//     font-family: Montserrat;
//     font-size: 18px;
//     font-weight: bold;
//     font-style: normal;
//     font-stretch: normal;
//     line-height: 1.2;
//     letter-spacing: normal;
//     text-align: center;
//     color: ${colors.text.primary};
//     > input {
//       width: 100%;
//       text-align: center;
//     }
//   }
// `;
// const InputText = styled(({ isVert, ...other }) => <Input {...other} />)`
//   flex: 1 1 auto;
//   &&&& {
//     width: ${props => (props.isVert ? '100%' : 'auto')};
//   }
// `;
// const InputText = styled(InputBase)`
//   flex: 1 1 auto;
//   &&&& {
//     width: ${props => (props.isvert ? '100%' : 'auto')};
//   }
// `;
const ToolTipBtn = styled.button`
  padding: 8px;
  border-width: 1px;
  border-style: solid;
  border-color: #fff;
  background-color: #fff;
`;
const NoToolSpacer = styled.span`
  width: 12px;
  height: 42px;
`;
const MadLibField = ({ doc, inputValue, onChange, onBlur }) => {
  const {
    hasCharsMax,
    charsMaxWarnValue,
    chars,
    charsLeft,
    charsMax,
  } = useCharMaxLimit(inputValue, doc);

  return (
    <ControlForm>
      <InputArea>
        <InputField
          id={doc.id}
          value={inputValue}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={charsMax ? charsMax : 100}
        />
        {hasCharsMax && (
          <CharsMaxTextArea warn={chars >= charsMax ? true : false}>
            {chars >= charsMaxWarnValue && (
              <CharsMaxText>
                {chars} / {charsMax}
              </CharsMaxText>
            )}
          </CharsMaxTextArea>
        )}
      </InputArea>
      <LabelArea>
        <LabelText>{doc.label}</LabelText>
        {doc.helpers ? (
          <ToolTip
            title={doc.helpers}
            placement="left-start"
            disableTouchListener
            leaveDelay={1500}
            leaveTouchDelay={1500}
          >
            <ToolTipBtn>
              <IconHelp />
            </ToolTipBtn>
          </ToolTip>
        ) : (
          <NoToolSpacer />
        )}
      </LabelArea>
    </ControlForm>
  );
};

export default MadLibField;
// <IconHelpTip width="14px" height="14px" />;
