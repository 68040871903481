import styled from 'styled-components/macro';

export const ContentFooter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
  margin: 0;
  padding: 0.2rem 0;
`;
export default ContentFooter;
