import React from 'react';

function SvgComponent(props) {
  return (
    <svg viewBox="0 0 237.48 309.2" {...props}>
      <g data-name="Layer 2">
        <g data-name="Layer 1" fillRule="evenodd">
          <path
            className="tri01"
            fill="#f7a31c"
            d="M164.09 138.2l-23.92 23.92-23.92 23.92-8.75-32.67-8.75-32.68 32.67 8.76 32.67 8.75z"
          />
          <path
            className="tri02"
            fill="#ea3e25"
            d="M98.75 120.69l32.67 8.76 32.67 8.75-8.75-32.67-8.76-32.67-23.92 23.92-23.91 23.91z"
          />
          <path
            className="tri03"
            fill="#ed1c24"
            d="M211.92 90.37L188 114.29l-23.91 23.91-8.76-32.67-8.75-32.67 32.67 8.75 32.67 8.76z"
          />
          <path
            className="tri04"
            fill="#f7a31c"
            d="M189.65 235.5l-23.92 23.92-23.92 23.91-8.75-32.67-8.76-32.67 32.67 8.76 32.68 8.75z"
          />
          <path
            className="tri05"
            fill="#ea3e25"
            d="M124.3 217.99l32.67 8.76 32.68 8.75-8.76-32.67-8.75-32.67-23.92 23.92-23.92 23.91z"
          />
          <path
            className="tri06"
            fill="#ed1c24"
            d="M237.48 187.67l-23.92 23.91-23.91 23.92-8.76-32.67-8.75-32.67 32.67 8.75 32.67 8.76z"
          />
          <path
            className="tri07"
            fill="#1e459d"
            d="M65.34 113.18L56.59 80.5l-8.76-32.67 32.67 8.76 32.68 8.75-23.92 23.92-23.92 23.92z"
          />
          <path
            className="tri08"
            fill="#23368e"
            d="M95.67 0L71.75 23.92 47.83 47.83l32.67 8.76 32.68 8.75-8.76-32.67L95.67 0z"
          />
          <path
            className="tri09"
            fill="#1b2368"
            d="M113.18 65.34l-8.76-32.67L95.67 0l32.67 8.75 32.67 8.76-23.92 23.91-23.91 23.92z"
          />
          <path
            className="tri10"
            fill="#2d4ea2"
            d="M47.83 47.83L23.92 71.75 0 95.67l32.67 8.75 32.67 8.75-8.75-32.67-8.76-32.67z"
          />
          <path
            className="tri11"
            fill="#2b54a5"
            d="M17.51 161.01l-8.76-32.67L0 95.67l32.67 8.75 32.67 8.76-23.92 23.91-23.91 23.92z"
          />
          <path
            className="tri12"
            fill="#355caa"
            d="M65.34 113.18l-23.92 23.91-23.91 23.92 32.67 8.75 32.67 8.76-8.75-32.67-8.76-32.67z"
          />
          <path
            className="tri13"
            fill="#3b6fb7"
            d="M35.02 226.35l-8.76-32.67-8.75-32.67 32.67 8.75 32.67 8.76-23.92 23.91-23.91 23.92z"
          />
          <path
            className="tri14"
            fill="#4994d0"
            d="M82.85 178.52l-23.92 23.91-23.91 23.92 32.67 8.75 32.67 8.76-8.75-32.67-8.76-32.67z"
          />
          <path
            className="tri15"
            fill="#44c2f1"
            d="M52.52 291.69l-8.75-32.67-8.75-32.67 32.67 8.75 32.67 8.76-23.92 23.92-23.92 23.91z"
          />
          <path
            className="tri16"
            fill="#76cfe5"
            d="M100.36 243.86l-23.92 23.92-23.91 23.91 32.67 8.76 32.67 8.75-8.76-32.67-8.75-32.67z"
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgComponent;
