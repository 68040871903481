import React, { useState } from 'react';
import { SimpleTextField } from 'components/FormFields';

const FormField = ({ formType, doc, initialValue, handleFieldChange }) => {
  const [inputValue, setInputValue] = useState(initialValue);
  //console.log(`doc-id=${doc.id}=> ${initialValue}`);

  const handleInputChange = e => {
    //console.log(`handleInputChange=> ${e.target.value}`);
    setInputValue(e.target.value);
  };
  const handleInputBlur = e => {
    //setInputValue(e.target.value);
    const { inputCompleted, inputStarters, ...other } = doc;
    //console.log(`handleInputBlur=> ${e.target.value}`);
    handleFieldChange({
      [doc.id]: {
        ...other,
        value: e.target.value,
      },
    });
  };
  return (
    <SimpleTextField
      doc={doc}
      initialValue={inputValue}
      inputValue={inputValue}
      onChange={handleInputChange}
      onBlur={handleInputBlur}
    />
  );
};

const SimpleFormBlur = ({ activityType, inputs, values, handleChange }) => {
  // console.log(`values=> ${JSON.stringify(values)}`);
  return inputs.map(doc => (
    <FormField
      key={doc.uid}
      doc={doc}
      formType={activityType}
      initialValue={values[doc.id] ? values[doc.id].value : ''}
      handleFieldChange={handleChange}
    />
  ));
};

export default SimpleFormBlur;
