import { breakpoints } from 'styles/responsive';

export const screenResize = dimensions => {
  const { width } = dimensions;
  const { sm, md, lg, xl } = breakpoints;
  const isDesktop = width > md;
  const screenSize =
    width >= xl
      ? 'xl'
      : width >= lg
      ? 'lg'
      : width >= md
      ? 'md'
      : width >= sm
      ? 'sm'
      : 'xs';

  return (dispatch, getState) => {
    dispatch({
      type: 'SCREEN_RESIZE',
      screenWidth: dimensions.width,
      screenHeight: dimensions.height,
      isDesktop: isDesktop,
      screenSize: screenSize,
    });
  };
};
