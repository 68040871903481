import React, { useRef, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { withFirebase } from 'api/Firebase';

// import CardContent from '@material-ui/core/CardContent';

// import { useDocumentOnce } from 'react-firebase-hooks/firestore';
import ActivityContent from './ActivityContent';

import * as PATHS from 'routes/paths';
// import Loading from 'components/Loading';
// import WorkzoneCard from 'components/WorkzoneCard';
// import { setActivityData } from 'store/actions/activity';
const ProjectPlatformActivity = ({ firebase }) => {
  const authId = useSelector(state => state.sessionState.authUser.uid);
  const projectId = useSelector(state => state.projectState.projectId);
  const activityId = useSelector(state => state.activity.actId);
  const outputs = useSelector(state => state.projectState.outputs);
  const [mainActivityData, setMainActivityData] = useState();
  const [memberActivityData, setMemberActivityData] = useState();
  const [activityDataReady, setActivtyDataReady] = useState(false);

  const mainProjectRef = useRef(
    firebase.fstore.collection('projects').doc(projectId)
  );

  const memberProjectRef = useRef(
    firebase.fstore
      .collection('members')
      .doc(authId)
      .collection('projects')
      .doc(projectId)
  );

  useEffect(() => {
    const fetchMainActivityData = async () => {
      //console.log(`aaaaaaaactId${activityId}`);
      const currentMainProjectRef = mainProjectRef.current;
      const currentMainDocRef = currentMainProjectRef
        .collection('activitys')
        .doc(activityId);
      const currentMainDoc = await currentMainDocRef.get();
      //console.log(`doc${currentMainDoc.data()}`);

      const currentMemberProjectRef = memberProjectRef.current;
      const currentMemberActivityDocRef = currentMemberProjectRef
        .collection('activitys')
        .doc(activityId);
      const currentMemberActivityDoc = await currentMemberActivityDocRef.get();

      const { uid, name, type, started } = currentMemberActivityDoc;

      if (started === false) {
        await currentMemberActivityDocRef.update({
          dateStarted: firebase.fieldValue.serverTimestamp(),
          started: true,
        });

        await firebase.sendAnalyticsLogEvent('activity_started', {
          uid: uid,
          name: name,
          type: type,
        });
      }
      //console.log(`doc${currentMemberDoc.data()}`);

      setMainActivityData(currentMainDoc.data());
      setMemberActivityData(currentMemberActivityDoc.data());
      setActivtyDataReady(true);
    };
    fetchMainActivityData();
  }, [
    mainProjectRef,
    memberProjectRef,
    activityId,
    firebase.fieldValue,
    firebase,
  ]);

  // const [value, loading, error] = useDocumentOnce(activityRef);
  // const startActivity = ev => {
  //   ev.preventDefault();
  //   activityRef.update({ dateStarted: new Date(), started: true }).then(() => {
  //     setHasStarted(true);
  //   });
  // };
  if (!projectId) {
    return <Redirect to={PATHS.MEMBER_WORKZONES} />;
  }
  return (
    <>
      {activityDataReady && (
        <ActivityContent
          projectId={projectId}
          activityId={activityId}
          activityType={memberActivityData.type}
          outputs={outputs}
          completed={memberActivityData.completed}
          mainActivityData={mainActivityData}
        />
      )}
    </>
  );
};

// return (
//   <WorkzoneCard>
//     {activityDataReady ? (
//       <ActivityContent
//         projectRef={mainProjectRef}
//         projectId={projectId}
//         activityId={activityId}
//         activityType={memberActivityData.type}
//         outputs={outputs}
//         completed={memberActivityData.completed}
//         mainActivityData={mainActivityData}
//       />
//     ) : (
//       <Loading />
//     )}
//   </WorkzoneCard>
// );

export default withFirebase(ProjectPlatformActivity);
