import React, { useState, useEffect, useRef } from 'react';

import styled from 'styled-components/macro';
import Img from 'react-image';

import { withFirebase } from 'api/Firebase';
import Loading from 'components/Loading';
const MediaCaption = styled.div``;

const MediaCaptionText = styled.div`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
`;

// const ImgStyle = styled.img`
//   max-width: 100%;
//   object-fit: cover; /*magic*/
//   /* object-fit: scale-down; magic */
//   /* border-bottom: 1px solid #dddfe2; */
// `;

const MediaWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0.5rem 0 0 0;
  /* padding: 2rem;
  min-height: 220px; */
`;

const Media = styled.div`
  width: 200px;
  min-height: ${props => (props.height ? props.height : '100px')};
  height: ${props => (props.height ? props.height : '100px')};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ContentMedia = ({ firebase, media, uid }) => {
  const [imgUrl, setImgUrl] = useState();
  const [mediaReady, setMediaReady] = useState(false);

  const storageBucket = useRef(firebase.bucket);
  useEffect(() => {
    // const getImage = async () => {
    //   const bucketRef = storageBucket.current.ref(media.fileurl);
    //   try {
    //     const url = await bucketRef.getDownloadURL();
    //     setImgUrl(url);
    //     setMediaReady(true);
    //   } catch (err) {
    //     alert(err); // TypeError: failed to fetch
    //   }
    // };

    if (media.type === 'gs') {
      const bucketRef = storageBucket.current.ref(media.fileurl);
      bucketRef
        .getDownloadURL()
        .then(url => {
          // console.log(`url____- ${url}`);
          setImgUrl(url);
          setMediaReady(true);
        })
        .catch(function(error) {
          // Handle any errors
          console.log(`errrrrr=${error}`);
        });
    } else if (media.type === 'placeholder') {
      setMediaReady(true);
    } else {
      setImgUrl(media.fileurl);
      setMediaReady(true);
    }
  }, [media.fileurl, media.type]);

  return (
    <MediaWrapper>
      {media.type === 'placeholder' ? (
        <MediaCaption>
          <MediaCaptionText>{media.caption}</MediaCaptionText>
        </MediaCaption>
      ) : (
        <Media height={media.height}>
          {mediaReady && (
            <Img
              src={imgUrl}
              loader={<Loading />}
              // loader={`${require('assets/dashboard/loadingimage.jpg')}`}
              alt={media.caption}
              container={children => {
                return <div className="imgStyle">{children}</div>;
              }}
            />
          )}
        </Media>
      )}
    </MediaWrapper>
  );
};
export default withFirebase(ContentMedia);
