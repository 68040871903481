import React from 'react';
import styled from 'styled-components/macro';

import { useSelector } from 'react-redux';
import { GridContainer, GridBase } from 'elements/LayoutElements';
import {
  CSSGrid,
  measureItems,
  makeResponsive,
  layout,
} from 'react-stonecutter';
import { DashCard } from 'components/DashCard';
import {
  breakpoints,
  mediaMinDevice,
  mediaMinPadding,
} from 'styles/responsive';

const mdadjust = breakpoints.lg - 240;
const lgadjust = breakpoints.xl - 240;
const xladjust = 1500;

// xs: 0,
// sm: 426,
// md: 769,
// lg: 961,
// xl: 1281,
const Mason = makeResponsive(
  measureItems(CSSGrid, { measureImages: true, background: true }),
  {
    maxWidth: 1500,
  }
);
const MasonXl = makeResponsive(
  measureItems(CSSGrid, { measureImages: true, background: true }),
  {
    maxWidth: 1500,
    minPadding: mediaMinPadding.xl,
  }
);
const MasonLg = makeResponsive(
  measureItems(CSSGrid, { measureImages: true, background: true }),
  {
    maxWidth: 1280,
    minPadding: mediaMinPadding.lg,
  }
);
const MasonMd = makeResponsive(
  measureItems(CSSGrid, { measureImages: true, background: true }),
  {
    maxWidth: 960,
    minPadding: mediaMinPadding.md,
  }
);
const MasonSm = makeResponsive(
  measureItems(CSSGrid, { measureImages: true, background: true }),
  {
    maxWidth: 768,
    minPadding: mediaMinPadding.sm,
  }
);
const MasonXs = makeResponsive(
  measureItems(CSSGrid, { measureImages: true, background: true }),
  {
    maxWidth: 425,
    minPadding: mediaMinPadding.xs,
  }
);

const CardGridBase = styled(GridBase)`
  justify-content: center;
  ${mediaMinDevice.deviceS} {
    justify-content: space-between;
  }
`;

const CardGrid = ({ screenSize, cardList }) => {
  // const screenSize = useSelector(state => state.app.screenSize);

  //const [cardBaseList] = useState(baseCards);

  // const gridwidth = screenWidth - 240;
  // const NewMason = makeResponsive(
  //   measureItems(CSSGrid, { measureImages: true, background: true }),
  //   {
  //     maxWidth: gridwidth,
  //   }
  // );
  return (
    <CardGridBase container align="center" item xs={12}>
      {screenSize === 'xs' && (
        <MasonXs
          component="div"
          columns={1}
          columnWidth={270}
          gutterWidth={4}
          gutterHeight={50}
          duration={800}
          easing="ease-out"
          layout={layout.pinterest}
        >
          {cardList.map((doc, index) => (
            <div key={index}>
              <DashCard className={doc.target} key={index} item={doc} />
            </div>
          ))}
        </MasonXs>
      )}
      {screenSize === 'sm' && (
        <MasonSm
          component="div"
          columns={2}
          columnWidth={270}
          gutterWidth={4}
          gutterHeight={50}
          duration={800}
          easing="ease-out"
          layout={layout.pinterest}
        >
          {cardList.map((doc, index) => (
            <div key={index}>
              <DashCard className={doc.target} key={index} item={doc} />
            </div>
          ))}
        </MasonSm>
      )}
      {screenSize === 'md' && (
        <MasonMd
          component="div"
          columns={2}
          columnWidth={270}
          gutterWidth={4}
          gutterHeight={50}
          duration={800}
          easing="ease-out"
          layout={layout.pinterest}
        >
          {cardList.map((doc, index) => (
            <div key={index}>
              <DashCard className={doc.target} key={index} item={doc} />
            </div>
          ))}
        </MasonMd>
      )}
      {screenSize === 'lg' && (
        <MasonLg
          component="div"
          columns={4}
          columnWidth={270}
          gutterWidth={4}
          gutterHeight={50}
          duration={800}
          easing="ease-out"
          layout={layout.pinterest}
        >
          {cardList.map((doc, index) => (
            <div key={index}>
              <DashCard className={doc.target} key={index} item={doc} />
            </div>
          ))}
        </MasonLg>
      )}
      {screenSize === 'xl' && (
        <MasonXl
          component="div"
          columns={4}
          columnWidth={270}
          gutterWidth={4}
          gutterHeight={50}
          duration={800}
          easing="ease-out"
          layout={layout.pinterest}
        >
          {cardList.map((doc, index) => (
            <div key={index}>
              <DashCard className={doc.target} key={index} item={doc} />
            </div>
          ))}
        </MasonXl>
      )}
    </CardGridBase>
  );
};

export default CardGrid;
