import React, { useState } from 'react';
import styled from 'styled-components/macro';

import { QuizQuestionField, QuizResponseField } from 'components/FormFields';
import { Transition, SwitchTransition } from 'react-transition-group';

export const Fade2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  transition: 2s;
  opacity: ${({ state }) => (state === 'entered' ? 1 : 0)};
`;
export const FormField = ({
  animate,
  doc,
  initialValue,
  handleFieldChange,
  handleTriggerNext,
  handleTriggerPrevious,
  handleLocalFieldChange,
  step,
  type,
}) => {
  const [inputValue, setInputValue] = useState(initialValue);

  const handleInputChange = e => {
    // console.log(`handleInputChange=> ${e.target.value}`);
    setInputValue(e.target.value);
  };
  const handleSelectChange = (id, value) => {
    const { inputCompleted, inputStarters, ...other } = doc;
    //console.log(`handleSelectChange=>id ${id} value => ${value}`);
    if (doc.activityOutputType === 'local') {
      handleLocalFieldChange({
        [doc.id]: {
          ...other,
          value: value,
        },
      });
    } else {
      handleFieldChange({
        [doc.id]: {
          ...other,
          value: value,
        },
      });
    }

    setInputValue('');
    handleTriggerNext();
  };
  // const handleInputChange = e => {
  //   const input = e.target;
  //   //const name = input.name;
  //   const value = input.type === 'checkbox' ? input.checked : input.value;
  //   setInputValue(value);
  //   //this.setState({ [name]: value });
  // };
  const handleInputBlur = e => {
    //setInputValue(e.target.value);
    const { inputCompleted, inputStarters, ...other } = doc;
    const value = e.target.value;
    //console.log(`handleInputBlur=> ${e.target.value}`);

    if (doc.activityOutputType === 'local') {
      handleLocalFieldChange({
        [doc.id]: {
          ...other,
          value: value,
        },
      });
    } else {
      handleFieldChange({
        [doc.id]: {
          ...other,
          value: e.target.value,
        },
      });
    }
    setInputValue('');
    handleTriggerNext();
  };
  const handleInputSubmit = e => {
    //setInputValue(e.target.value);
    const { inputCompleted, inputStarters, ...other } = doc;
    const value = inputValue;

    //console.log(`handleInputBlur=> ${inputValue}`);

    if (doc.activityOutputType === 'local') {
      handleLocalFieldChange({
        [doc.id]: {
          ...other,
          value: value,
        },
      });
    } else {
      handleFieldChange({
        [doc.id]: {
          ...other,
          value: inputValue,
        },
      });
    }
    setInputValue('');
    handleTriggerNext();
  };

  const resetWindowScroll = () => {
    console.log(`reset`);
    window.scrollTo(0, 0);
  };
  return (
    <SwitchTransition mode="out-in">
      <Transition
        key={step}
        appear={true}
        in={animate}
        timeout={500}
        unmountOnExit
        mountOnEnter
        onEnter={resetWindowScroll}
      >
        {state => (
          <Fade2 state={state}>
            {type === 'response' ? (
              <QuizResponseField
                doc={doc}
                inputValue={initialValue}
                onContinue={handleTriggerNext}
              />
            ) : (
              <QuizQuestionField
                step={step}
                doc={doc}
                inputValue={inputValue}
                onSelectChange={handleSelectChange}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onSubmit={handleInputSubmit}
                onContinue={handleTriggerNext}
                onPrevious={handleTriggerPrevious}
              />
            )}
          </Fade2>
        )}
      </Transition>
    </SwitchTransition>
  );
};
export default FormField;
