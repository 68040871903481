import React from 'react';
import { useSelector } from 'react-redux';
import Profile from 'screens/Profile';
import { SceneHeader } from 'elements/Headers';
import { GridBase } from 'elements/LayoutElements';
const Account = () => {
  const authUser = useSelector(state => state.sessionState.authUser);

  return (
    <GridBase item xs={12}>
      <GridBase container item xs={12}>
        <SceneHeader>Account Profile</SceneHeader>
        <GridBase container item xs={12}>
          <Profile member={authUser} />
        </GridBase>
      </GridBase>
    </GridBase>
  );
};
export default Account;
