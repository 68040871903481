import React, { useEffect, useState } from 'react';
// import styled from 'styled-components/macro';
// import Grid from '@material-ui/core/Grid';
// import EmailSig from 'components/EmailSig';
// import Icon from 'elements/Icon';
// import { mdiEmailOutline } from '@mdi/js';
// import { colors } from 'styles/default';
// import EmailTopBarIcons from 'assets/icons/EmailTopBarIcons';
// import GlobalTemplate from '../GlobalTemplate';
import { SimpleFormBot2 } from 'components/Bots';

import {
  CardImgSpan,
  LineDivider,
  EmailCard,
  EmailTopBar,
  LineSubject,
  LinePreHead,
  EmailCardSubjectRow,
  EmailCardSubjectRowIcon,
  EmailCardSubjectRowContent,
  Headline,
  HeadlineSpan,
  Subheadline,
  SubheadlineSpan,
  OutputSpan,
  EmailBodyCopy,
  EmailTemplateArea,
} from 'templates/EmailParts';
// import photo from 'assets/svg/basicBuilderImage.svg';

// const LineSubject = styled.div`
// font-family: Montserrat;
//   margin: 0;
//   padding: 0;
//   color: ${colors.primary.dark};
//   line-height: 1.2;
//   text-align: left;
//   font-size: 16px;

//   font-weight: 700;
// `;

// const LineHead = styled.div`
//   font-family: inherit;
//   font-size: 1.375rem;
//   font-weight: 500;
//   line-height: 28px;
//   margin: 0;
//   padding: 0;
// `;
// const LineSubHead = styled.div`
//   font-family: inherit;
//   font-size: 1.1rem;
//   font-weight: 300;
//   line-height: 1.1rem;
//   margin: 0;
//   padding: 0;
// `;
// const Content = styled.div`
//   font-family: inherit;
//   font-size: 0.9rem;

//   line-height: 1rem;
//   padding: 10px 0;
// `;
// const MyCard = styled(Card)`
//   background: #ffffff;
//   border-radius: 0;
//   color: black;
//   padding: 10px;
// `;

// const TemplateArea = styled.div`
//   margin: auto;
//   display: flex;
//   flex-direction: column;
//   outline: 0;
//   border-radius: 0;
//   justify-content: center;
//   border: solid 4px ${colors.primary.dark};
// `;

// const EmailTopBar = styled.div`
//   margin: 0;
//   display: flex;
//   outline: 0;
//   padding: 10px;
//   border-radius: 0;
//   justify-content: flex-end;
//   background-color: ${colors.template.blueLighter};
//   border-radius: 0;
//   border-bottom: solid 4px ${colors.primary.dark};
// `;
// const EmailCard = styled.div`
//   margin: auto;
//   display: flex;
//   flex-direction: column;
//   outline: 0;
//   padding: 1rem;
//   border-radius: 0;
//   justify-content: center;
//   background-color: #ffffff;
// `;
// const EmailCardHeader = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;
// const EmailCardSubjectRow = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;
// const EmailCardSubjectRowIcon = styled.div`
//   flex: 0 0;
//   margin-right: 1rem;
// `;
// const EmailCardSubjectRowContent = styled.div`
//   flex: 1 1;
// `;
// const MyGrid = styled(Grid)`
//   background: '#cccccc';
//   padding: '10px';
// `;

// const LineDivider = styled.hr`
//   border: none;
//   height: 2.2px;
//   margin: 10px 0;
//   flex-shrink: 0;
//   background-color: ${colors.primary.dark};
// `;

// const BodyDiv = styled.div`
//   background-color: #ffffff;
//   /* max-width: 468pt;
//   padding: 72pt 72pt 72pt 72pt; */
// `;

// const Subhead = styled.p`
//   padding-top: 0pt;
//   padding-bottom: 0pt;
//   line-height: 1;
//   orphans: 2;
//   widows: 2;
//   text-align: center;
//   margin: 0;
// `;

// const Head = styled(Subhead)``;
// const SubheadSpan = styled.span`
//   font-family: Montserrat;
//   font-size: 26px;
//   font-weight: bold;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.2;
//   letter-spacing: normal;
//   text-align: center;
//   color: ${colors.primary.dark};
// `;

// const HeadSpan = styled.span`
//   font-family: Montserrat;
//   font-size: 19px;
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.2;
//   letter-spacing: normal;
//   text-align: center;
//   color: ${colors.primary.dark};
// `;
// const ImgSpan = styled.span`
//   display: flex;
//   margin: 0;
//   justify-content: center;
//   align-items: center;

//   /* > img {
//     height: 250px;
//   } */
// `;

// const ImgSpan = styled.span`
//   overflow: hidden;
//   display: inline-block;
//   margin: 0px 0px;
//   border: 0px solid #000000;
//   transform: rotate(0rad) translateZ(0px);
//   -webkit-transform: rotate(0rad) translateZ(0px);
//   width: 398.82px;
//   height: 265.5px;
//   > img {
//     width: 398.82px;
//     height: 265.5px;
//     margin-left: 0px;
//     margin-top: 0px;
//     transform: rotate(0rad) translateZ(0px);
//     -webkit-transform: rotate(0rad) translateZ(0px);
//   }
// `;

// const C3 = styled.p`
//   padding-top: 0pt;
//   padding-bottom: 0pt;
//   line-height: 1.15;
//   orphans: 2;
//   widows: 2;
//   text-align: left;
// `;

// const BodyCopy = styled.p`
//   font-family: Montserrat;
//   font-size: 16px;
//   font-weight: normal;
//   font-style: normal;
//   font-stretch: normal;
//   line-height: 1.2;
//   letter-spacing: normal;
//   text-align: left;
//   color: ${colors.common.black};
// `;
// const C3C4 = styled.p`
//   padding-top: 0pt;
//   padding-bottom: 0pt;
//   line-height: 1.15;
//   orphans: 2;
//   widows: 2;
//   text-align: left;
//   height: 11pt;
// `;
// const C7 = styled.span`
//   font-size: 12pt;
//   font-weight: 400;
// `;
// const C7C8 = styled.span`
//   font-size: 12pt;
//   font-weight: 400;
//   color: #000000;
//   text-decoration: none;
//   vertical-align: baseline;
//   font-style: normal;
// `;
// const C7C10 = styled.span`
//   background-color: ${highlightYellow};
// `;
// const C7C11 = styled.span`
//   font-size: 12pt;
//   font-weight: 400;
//   -webkit-text-decoration-skip: none;

//   text-decoration: none;
//   text-decoration-skip-ink: none;
// `;

// const OutputField = ({ children }) => {
//   return <OutputSpan>{children ? children.value : 'missing value'}</OutputSpan>;
// };
const FieldOutput = ({ globalState, fieldId }) => {
  if (globalState.hasOwnProperty(fieldId)) {
    return <OutputSpan>( {globalState[fieldId].value} )</OutputSpan>;
  }
  return <OutputSpan>({fieldId})</OutputSpan>;
};
const EmailTemplate = ({ values, outputs, isDesktop }) => {
  // const emailos = Object.keys(outputs).map(key => {
  //   if (outputs[key].outputType === 'Email') {
  //     return outputs[key];
  //   }
  // });
  // const [actState, setActState] = useState();
  const [globalState, setGlobalState] = useState();
  // console.log(`values====== ${JSON.stringify(values)}`);
  useEffect(() => {
    if (values) {
      setGlobalState(values);
    } else {
      setGlobalState({});
    }
  }, [values]);

  // const arrayToObject = (array, keyField) =>
  //   array.reduce((obj, item) => {
  //     obj[item[keyField]] = item;
  //     return obj;
  //   }, {});

  if (globalState) {
    return (
      <EmailTemplateArea isDesktop={isDesktop}>
        <EmailTopBar isDesktop={isDesktop} />
        <EmailCard>
          <EmailCardSubjectRow>
            <EmailCardSubjectRowIcon />

            <EmailCardSubjectRowContent>
              <LineSubject>
                Connect with{' '}
                <FieldOutput globalState={globalState} fieldId="businessName" />
                {/* <OutputField>
                {globalState.hasOwnProperty('businessName')
                  ? globalState.businessName
                  : 'missing'}
              </OutputField>{' '} */}{' '}
                on Facebook
              </LineSubject>
              <LinePreHead>
                We have a new, powerful way to share more with you.
              </LinePreHead>
            </EmailCardSubjectRowContent>
          </EmailCardSubjectRow>

          <LineDivider />

          <Headline>
            <HeadlineSpan>WE&rsquo;VE DONE IT!</HeadlineSpan>
          </Headline>
          <Subheadline>
            <SubheadlineSpan>
              We’re officially on Facebook with a brand new business page.
            </SubheadlineSpan>
          </Subheadline>
          <CardImgSpan>
            <SimpleFormBot2
              width="100%"
              height="250px"
              main="#272964"
              bota="#76cbe2"
              botb="#f2a01e"
              botc="#e82127"
              greymd="#e6e7e8"
              greydk="#d0d2d3"
              greylt="#f2f2f2"
              pinka="#e7c7df"
              purplea="#6c63ff"
              bluea="#76cbe2"
              golda="#f2a01e"
              reda="#e82127"
            />
          </CardImgSpan>

          <EmailBodyCopy>
            We are thrilled to tell you that we have created a Facebook page for{' '}
            <FieldOutput globalState={globalState} fieldId="businessName" />.
          </EmailBodyCopy>
          <EmailBodyCopy>
            How does our Facebook page impact your life? Well, it’s a whole new
            way for us to stay in touch and connected! We will be sharing{' '}
            <FieldOutput
              globalState={globalState}
              fieldId="importantItem1email"
            />
            ,
            <FieldOutput
              globalState={globalState}
              fieldId="importantItem2email"
            />{' '}
            and{' '}
            <FieldOutput
              globalState={globalState}
              fieldId="importantItem3email"
            />{' '}
            on Facebook moving forward.
          </EmailBodyCopy>
          <EmailBodyCopy>Please take a moment and like our page.</EmailBodyCopy>

          <LineDivider />
        </EmailCard>
      </EmailTemplateArea>
    );
  }
  return <p></p>;
};

export default EmailTemplate;
