import React, { useState, useEffect, useRef } from 'react';
import * as _ from 'lodash';
import { withFirebase } from 'api/Firebase';
import { useSelector } from 'react-redux';
// import styled from 'styled-components/macro';
// import BtnMain from 'elements/BtnMain';
// import { globals, colors } from 'styles/default';
// import LogoJumpstart from 'assets/logos/LogoJumpstart';
// import DashCardShadow from 'components/DashCardShadow';
import StatusCard from './StatusCard';
const lookUpCardContent = {
  current: {
    header: 'Almost there!',
    copy: 'Finish up your project now.',
    cta: { label: 'Continue', url: 'workzones' },
  },
  ready: {
    header: 'You have a Project Ready to Launch!',
    copy: 'Get started now.',
    cta: { label: 'Continue', url: 'workzones' },
  },
  new: {
    header: 'What’s next?',
    copy: 'This project would be perfect for you.',
    cta: { label: 'shop', url: 'marketplace' },
  },
  like: {
    header: 'You might like... ',
    copy: 'We have a suggestion for you.',
    cta: { label: 'shop', url: 'marketplace' },
  },
  pre: {
    header: 'Coming soon!',
    copy: 'We’ll let you know once it’s available.',
  },
  error: {
    header: 'Error!',
    copy: 'Sorry cannot connect',
  },
};

const ProjectSummaryCard = ({ firebase, type, onSummaryClick }) => {
  const authUser = useSelector(state => state.sessionState.authUser);
  const screenSize = useSelector(state => state.app.screenSize);
  // const [mainProjects, setMainProjects] = useState();
  // const [memberProjects, setMemberProjects] = useState();
  const [projectSummaryData, setProjectSummaryData] = useState();
  // const [projectData, setProjectData] = useState();
  const mainProjectCollRef = useRef(firebase.fstore.collection('projects'));
  const memberProjectCollRef = useRef(
    firebase.fstore
      .collection('members')
      .doc(authUser.uid)
      .collection('projects')
  );

  useEffect(() => {
    const fetchProject = async () => {
      // const summaryObject = {
      //   total: 0,
      //   current: 0,
      //   new: 0,
      //   complete: 0,
      //   available: 0,
      // };

      const summaryObjectMain = {
        total: 0,
        new: 0,
        pre: 0,
      };

      const summaryObjectMember = {
        total: 0,
        current: 0,
        new: 0,
        complete: 0,
      };

      /***
       * get a list of all active projects
       *
       */
      const queryMain = mainProjectCollRef.current.where('active', '==', true);
      const mainProjectDocs = await queryMain.get();

      const mainProjectList = mainProjectDocs.docs.map(docsnap => {
        return docsnap.data();
      });

      const mainProjectsTotal = _.size(mainProjectList);
      console.log(`mainProjectsTotal => ${mainProjectsTotal}`);

      // const mainProjectsGroupCount = _.countBy(mainProjectList, item =>
      //   _.trim(item.status)
      // );
      // console.log(
      //   `mainProjectsGroupCount => ${JSON.stringify(mainProjectsGroupCount)}`
      // );

      // const mainProjectsGrouped = _.groupBy(mainProjectList, item =>
      //   _.trim(item.status)
      // );

      // console.log(
      //   `mainProjectsGrouped => ${JSON.stringify(mainProjectsGrouped)}`
      // );
      /***
       * get a list of all member purchased  projects
       *
       */

      const queryMemmber = memberProjectCollRef.current;
      const memberProjectDocs = await queryMemmber.get();
      const memberProjectList = memberProjectDocs.docs.map(docsnap => {
        return docsnap.data();
      });

      /***
       * Count the total number of Member Projects
       */

      const memberProjectsTotal = _.size(memberProjectList);
      console.log(`memberProjectsTotal => ${memberProjectsTotal}`);

      /*** Group the Member projects by status
       *  new: purchased but not started
       *  current: purchased but not complete
       *  complete: purchased and completed
       *
       */

      const memberProjectsGroupCount = _.countBy(memberProjectList, item =>
        _.trim(item.status)
      );
      console.log(
        `memberProjectsGroupCount => ${JSON.stringify(
          memberProjectsGroupCount
        )}`
      );

      //console.log(`projectsGrouped => ${JSON.stringify(projectsGrouped)}`);

      const memberProjectsGrouped = _.groupBy(memberProjectList, item =>
        _.trim(item.status)
      );
      console.log(
        `memberProjectGrouped => ${JSON.stringify(memberProjectsGrouped)}`
      );

      const mainProjectsNotPurchased = _.differenceBy(
        mainProjectList,
        memberProjectList,
        item => item.uid
      );

      const mainProjectsNotPurchasedTotal = _.size(mainProjectsNotPurchased);
      console.log(
        `mainProjectsNotPurchasedTotal => ${mainProjectsNotPurchasedTotal}`
      );

      // console.log(
      //   `mainProjectsNotPurchased => ${JSON.stringify(
      //     mainProjectsNotPurchased
      //   )}`
      // );

      const mainProjectsNotPurchasedGroup = _.groupBy(
        mainProjectsNotPurchased,
        item => _.trim(item.status)
      );

      const mainProjectsNotPurchasedGroupCount = _.countBy(
        mainProjectsNotPurchased,
        item => _.trim(item.status)
      );
      console.log(
        `mainProjectsNotPurchasedGroupCount => ${JSON.stringify(
          mainProjectsNotPurchasedGroupCount
        )}`
      );

      const combinedSummaryObjectMain = {
        ...summaryObjectMain,
        ...mainProjectsNotPurchasedGroupCount,
        total: mainProjectsNotPurchasedTotal,
      };
      const combinedSummaryObjectMember = {
        ...summaryObjectMember,
        ...memberProjectsGroupCount,
        total: memberProjectsTotal,
      };

      console.log(
        `combinedSummaryObjectMain => ${JSON.stringify(
          combinedSummaryObjectMain
        )}`
      );

      console.log(
        `combinedSummaryObjectMember => ${JSON.stringify(
          combinedSummaryObjectMember
        )}`
      );

      // const sortedMainProjectsNotPurchased = _.sortBy(
      //   mainProjectsNotPurchased,
      //   item => item.dateCreated
      // );

      /**
       * dynamicProjectStatus
       * if member total > 0
       *  if member.current > 0  showmemberProject status Current
       *  if member.current === 0 see if any other member projects not complete
       *
       */
      let dynamicProject;
      let dynamicProjectStatus;

      if (combinedSummaryObjectMember.total > 0) {
        if (combinedSummaryObjectMember.current > 0) {
          dynamicProject = _.head(memberProjectsGrouped.current);
          dynamicProjectStatus = 'current';
        } else if (combinedSummaryObjectMember.new > 0) {
          dynamicProject = _.head(memberProjectsGrouped.new);
          dynamicProjectStatus = 'ready';
        } else {
          console.log(`no purchased projects available`);
          if (combinedSummaryObjectMain.new > 0) {
            console.log(`new project available to purchase`);
            dynamicProject = _.head(mainProjectsNotPurchasedGroup.new);
            dynamicProjectStatus = 'new';
          } else if (combinedSummaryObjectMain.pre > 0) {
            console.log(`show preorder projects available`);
            dynamicProject = _.head(mainProjectsNotPurchasedGroup.pre);
            dynamicProjectStatus = 'pre';
          } else {
            console.log('ERRRRROROROROROROR with MemberProjectsCount');
            dynamicProjectStatus = 'error';
          }
        }
      } else {
        console.log(`no purchased projects available`);
        if (combinedSummaryObjectMain.new > 0) {
          console.log(`new project available to purchase`);
          dynamicProject = _.head(mainProjectsNotPurchasedGroup.new);
          dynamicProjectStatus = 'new';
        } else if (combinedSummaryObjectMain.pre > 0) {
          console.log(`show preorder projects available`);
          dynamicProject = _.head(mainProjectsNotPurchasedGroup.pre);
          dynamicProjectStatus = 'pre';
        } else {
          console.log('ERRRRROROROROROROR with MAIN');
          dynamicProjectStatus = 'error';
        }
      }

      // const pdata = {
      //   ...summaryObject,
      //   ...memberProjectsGrouped,
      //   total: mainProjectsTotal,
      //   available: mainProjectsNotPurchasedTotal,
      // };

      // console.log(`pdata = ${JSON.stringify(pdata)}`);

      // let dynamicProject;
      // let dynamicProjectStatus;

      // if (pdata.total === 0) {
      //   // console.log(`total=== 0`);
      //   dynamicProject = _.head(mainProjectsNotPurchased);
      // }
      // if (pdata.current > 0) {
      //   // console.log(`pdata.current > 0`);
      //   dynamicProjectStatus = 'current';
      //   dynamicProject = _.head(memberProjectsGrouped.current);
      // }
      // if (pdata.current === 0 && pdata.new > 0) {
      //   // console.log(`pdata.current === 0 && pdata.new > 0`);
      //   dynamicProjectStatus = 'new';
      //   dynamicProject = _.head(memberProjectsGrouped.new);
      // }
      // if (pdata.current === 0 && pdata.new === 0 && pdata.available > 0) {
      //   // console.log(`______else____`);
      //   dynamicProjectStatus = 'available';
      //   dynamicProject = _.head(mainProjectsNotPurchased);
      // }
      console.log(`dynamicProjectStatus ${dynamicProjectStatus}____`);
      console.log(`______dynamicProject ${JSON.stringify(dynamicProject)}____`);
      const statusContent = lookUpCardContent[dynamicProjectStatus];
      setProjectSummaryData({
        memberProjectData: combinedSummaryObjectMember,
        mainProjectData: combinedSummaryObjectMain,

        statusContent,
        dynamicProjectStatus,
        dynamicProject: dynamicProject,
      });
      //setProjectData(dynamicProject);
    };
    fetchProject();
  }, []);

  // const ctaClick = (ev, obj) => {
  //   ev.preventDefault();
  //   const view = _.get(obj, 'statusContent.cta.url');
  //   console.log(`view=> ${view}`);
  //   onSummaryClick(view);
  // };
  if (projectSummaryData) {
    return (
      <StatusCard
        data={projectSummaryData}
        onCtaClick={() => onSummaryClick(projectSummaryData)}
        screenSize={screenSize}
      />
    );
  }
  return null;
};

export default withFirebase(ProjectSummaryCard);
