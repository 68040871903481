import React, { useState } from 'react';

import BuzzQuizForm from './BuzzQuizForm';
import {
  ContentRow,
  Subtitle,
  Name,
  Tips,
  Description,
  CopyArea,
  HeaderArea,
  FooterArea,
  ContentArea,
} from 'elements/WorkzoneText';
import BtnMain from 'elements/BtnMain';

import { WorkzoneCardContent } from 'components/WorkzoneCard';
const BuzzQuiz = ({
  ready,
  submitting,
  actSaved,
  fields,
  values,
  handleFormChange,
  handleSave,
  handleEnd,
  mainActData,
}) => {
  const [quizStarted, setQuizStarted] = useState(false);

  return (
    <WorkzoneCardContent>
      <HeaderArea>
        <Name>{mainActData.name}</Name>
        <Subtitle>{mainActData.subtitle}</Subtitle>
      </HeaderArea>

      {!quizStarted && (
        <CopyArea>
          <Description
            dangerouslySetInnerHTML={{
              __html: mainActData.description,
            }}
          />

          <Tips
            dangerouslySetInnerHTML={{
              __html: mainActData.tips,
            }}
          />
        </CopyArea>
      )}

      {!quizStarted && (
        <FooterArea>
          <BtnMain label="Start Quiz" onClick={() => setQuizStarted(true)} />
        </FooterArea>
      )}

      {quizStarted && (
        <ContentArea>
          <ContentRow
            container
            direction="column"
            justify="center"
            spacing={0}
            alignItems="stretch"
          >
            {ready && fields && (
              <BuzzQuizForm
                inputs={fields}
                values={values}
                handleSave={handleSave}
                handleChange={handleFormChange}
              />
            )}
          </ContentRow>
        </ContentArea>
      )}
    </WorkzoneCardContent>
  );
};

export default BuzzQuiz;
