import styled, { css } from 'styled-components/macro';
export const Card = styled.div`
  border-radius: 25px;
  border-width: ${props =>
    props.borderTheme ? props.borderTheme.width : '5px'};
  border-style: ${props =>
    props.borderTheme ? props.borderTheme.style : 'solid'};
  border-color: ${props => (props.borderColor ? props.borderColor : '#000')};
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  width: ${props => (props.width ? props.width : '240px')};
  ${props =>
    props.type === 'rssr' &&
    css`
      border-radius: 25px 0 0 25px;
    `};
  ${props =>
    props.type === 'srrs' &&
    css`
      border-radius: 0 25px 25px 0;
    `};
  ${props =>
    props.type === 'side-left' &&
    css`
      border-radius: 25px 0 25px 0;
    `};
  ${props =>
    props.type === 'side-right' &&
    css`
      border-radius: 25px 0 25px 25px;
    `};
  ${props =>
    props.type === 'top-right-round' &&
    css`
      border-radius: 0 25px;
    `};
  ${props =>
    props.type === 'bottom-left-angle' &&
    css`
      border-radius: 25px 0;
    `};
  ${props =>
    props.type === 'bottom-left-square' &&
    css`
      border-radius: 0 25px;
    `};
  ${props =>
    props.type === 'bottom-right-square' &&
    css`
      border-radius: 25px 0;
    `};
`;

export default Card;
