import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// import styled from 'styled-components/macro';
import Ad from './templates/Ad';
// import First5 from '../ResultTemplates/First5';
// import Email01 from '../ResultTemplates/Email01';
import Post from './templates/Post';
import Email from './templates/Email';
// import { colors } from 'styles/default';

// const ConWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   flex-direction: column;
//   outline: 0;
//   padding: 0 12px;
//   border-radius: 0;
//   min-width: 300px;
//   max-width: 600px;
//   min-height: 200px;
//   background-color: transparent;
//   margin-bottom: 20px;
// `;
// const ScreenWindow = styled.div`
//   overflow: hidden;
//   justify-content: center;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   width: 100%;
//   border: solid 5px ${colors.primary.dark};
//   background-color: ${colors.template.blueLighter};
// `;

const WindowResultContent = ({
  sectionType,
  view,
  editMode,
  setOutputFieldValue,
  isDesktop,
}) => {
  const [viewReady, setViewReady] = useState(false);
  const outputFields = useSelector(state => state.projectResults);
  // const outputFields = useSelector(state => state.projectState.outputs);
  //console.log(`WindowResultContent=== secctionType=== ${sectionType}`);

  const [item, setItem] = useState();

  useEffect(() => {
    if (view.fields) {
      // const fieldValues = ad.fields.map(field => {
      //   return {[field]: outputs[field].value}
      // })

      const fields = view.fields.reduce((field, item) => {
        //console.log(`field item ${item}`);

        field[item] = outputFields[item] ? outputFields[item] : item;
        console.log(`fieldsssss-${JSON.stringify(field)}`);
        return field;
      }, {});

      let fieldValues;
      if (view.extras) {
        fieldValues = { ...fields, ...view.extras };
        //return { ...view, fieldValues };
      } else {
        fieldValues = { ...fields };
      }
      //fieldValues = { ...fields };
      //console.log(`>>>>fieldValues=> ${JSON.stringify(fieldValues)}`);
      setItem({ ...view, fieldValues });
      setViewReady(true);
      //return { ...item, fieldValues };
    } else {
      setItem({ ...view });
      setViewReady(true);
      //return item;
    }
  }, [view, outputFields]);

  if (!viewReady) {
    return <div>Loading</div>;
  }

  if (sectionType === 'first5') {
    return (
      <Post
        isDesktop={isDesktop}
        section={sectionType}
        item={item}
        outputs={outputFields}
        editMode={editMode}
        changeOutputValue={setOutputFieldValue}
      />
    );
  }

  if (sectionType === 'ongoingPosts') {
    return (
      <Post
        isDesktop={isDesktop}
        section={sectionType}
        item={item}
        outputs={outputFields}
        editMode={editMode}
        changeOutputValue={setOutputFieldValue}
      />
    );
  }

  if (sectionType === 'ads') {
    return (
      <Ad
        isDesktop={isDesktop}
        section={sectionType}
        item={item}
        outputs={outputFields}
        editMode={editMode}
        changeOutputValue={setOutputFieldValue}
      />
    );
  }

  if (sectionType === 'email') {
    return (
      <Email
        isDesktop={isDesktop}
        section={sectionType}
        item={item}
        outputs={outputFields}
        editMode={editMode}
        changeOutputValue={setOutputFieldValue}
      />
    );
  }

  return <div>Loading</div>;
};

// return (
//   <>
//     {sectionType === 'first5' && (
//       <Post
//         section={sectionType}
//         item={item}
//         outputs={outputFields}
//         editMode={editMode}
//         changeOutputValue={setOutputFieldValue}
//       />
//     )}
//     {sectionType === 'ongoingPosts' && (
//       <Post
//         section={sectionType}
//         item={item}
//         outputs={outputFields}
//         editMode={editMode}
//         changeOutputValue={setOutputFieldValue}
//       />
//     )}
//     {sectionType === 'ads' && (
//       <Ad
//         section={sectionType}
//         item={item}
//         outputs={outputFields}
//         editMode={editMode}
//         changeOutputValue={setOutputFieldValue}
//       />
//     )}
//     {sectionType === 'email' && (
//       <Email
//         section={sectionType}
//         item={item}
//         outputs={outputFields}
//         editMode={editMode}
//         changeOutputValue={setOutputFieldValue}
//       />
//     )}
//   </>
// );
// // return (
// //   <ConWrapper>
// //     {sectionType === 'first5' && (
// //       <First5
// //         outputs={outputFields}
// //         item={currentItem}
// //         editMode={editMode}
// //         changeOutputValue={setOutputFieldValue}
// //       />
// //     )}
// //     {sectionType === 'ads' && (
// //       <Ad01
// //         outputs={outputFields}
// //         item={currentItem}
// //         editMode={editMode}
// //         changeOutputValue={setOutputFieldValue}
// //       />
// //     )}
// //     {sectionType === 'email' && (
// //       <Email01
// //         outputs={outputFields}
// //         item={currentItem}
// //         editMode={editMode}
// //         changeOutputValue={setOutputFieldValue}
// //       />
// //     )}
// //     {sectionType === 'ongoingPosts' && (
// //       <First5
// //         outputs={outputFields}
// //         item={currentItem}
// //         editMode={editMode}
// //         changeOutputValue={setOutputFieldValue}
// //       />
// //     )}
// //   </ConWrapper>
// // );
// };

export default WindowResultContent;
